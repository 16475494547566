import { Company } from "../../../../core/models/db/company";
import { isStepBlocked } from "../DSubstep/subcomponentsConfig";
import { SET_INITIAL_DATA, UPDATE_STEP, SET_CURRENT_STEP, SET_CURRENT_COMPANY } from "../actions/dashboardStep-action";
import { Step, DashboardStepActionTypes, StatusStep as Status, StatusSubstep, Substep } from "../types";

interface DashboardStepState {
  steps: Step[];
  company: Company;
  currentStepId: number;
  currentSubstepId: number;
  idUser: number;
  idCompany: number;

}

const initialState: DashboardStepState = {
  steps: [],
  company: undefined,
  currentStepId: undefined,
  currentSubstepId: undefined,
  idUser: undefined,
  idCompany: undefined
};


// const initialState: DashboardStepState = {
//   steps: [],
// };

const dashboardStepReducer = (
  state: DashboardStepState = initialState,
  action: DashboardStepActionTypes): DashboardStepState => {
  switch (action.type) {
    case SET_CURRENT_COMPANY: {
      const { company, idCompany } = action.payload;
      return {
        ...state,
        company,
        idCompany
      };
    }
    case SET_CURRENT_STEP:
      return {
        ...state,
        currentStepId: action.payload.currentStepId,
        // currentSubstepId: undefined
      };
    case SET_INITIAL_DATA:
      const { steps, company, currentStepId, currentSubstepId, idCompany, idUser } = action.payload;
      return {
        ...state,
        steps,
        company,
        currentStepId,
        currentSubstepId,
        idCompany,
        idUser
      };
    // case UPDATE_STEP:
    //   const { step: STEP, substep: SUBSTEP } = action.payload;

    //   return {
    //     ...state,
    //     steps: state.steps.map(step => {
    //       if (step.id === STEP.id) {
    //         if (SUBSTEP) {
    //           return {
    //             ...step,
    //             substeps: step.substeps.map(sub =>
    //               sub.id === SUBSTEP.id ? SUBSTEP : sub
    //             )
    //           };
    //         } else {
    //           return STEP;
    //         }
    //       }
    //       return step;
    //     })



    //   }; // fin UPDATE_STEP

    case UPDATE_STEP:
      const { step: STEP, substep: SUBSTEP } = action.payload;

      let updatedSteps = state.steps.map(step => {
        if (step.id === STEP.id) {
          if (SUBSTEP) {
            return {
              ...step,
              substeps: step.substeps.map(sub => {
                return sub.id === SUBSTEP.id ? SUBSTEP : sub
              }
              )
            };
          } else {
            return STEP;
          }
        }
        return step;
      });
   
      // Comprobar y actualizar dependencias
      updatedSteps = updatedSteps.map(step => {
        console.log(step.id, step);
        console.log(isStepBlocked(step, updatedSteps))
        return {
          ...step,
          disabled: isStepBlocked(step, updatedSteps)
        };
      });

      return {
        ...state,
        steps: updatedSteps
      };

    default:
      return state;
  }
};

export default dashboardStepReducer;
