import { useDispatch, useSelector } from "react-redux";
import { useCompany } from "../../../../../core/hooks/db/company/useCompany";
import { SubcomponentBasicProp } from "./Subcomponent1";
import { RootState } from "../../../../../core/store/store";
import { useEffect, useState } from "react";
import * as Yup from 'yup';
import { useFormik } from "formik";
import validationCompanySchema from "../../../../pages/@external-pages/registationAndPayment/company/company_validator_yup";
import { CAlert, CCol, CContainer, CFormInput, CFormLabel, CFormTextarea, CInputGroup, CInputGroupText, CLoadingButton, CRow } from "@coreui/react-pro";
import { cilInstitution, cilWarning } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import { useUser } from "../../../../../core/hooks/db/user/useUser";
import { LoginService } from "../../../../../core/services/login/login-service";
import { StatusStep, StatusSubstep } from "../../types";
import { setCurrentCompany, updateStep } from "../../actions/dashboardStep-action";
import { useUserSubstep } from "../../../../../core/hooks/db/userSubstep/useUserSubstep";
import { Company } from "../../../../../core/models/db/company";
import { COLORS } from "../../../../../core/constants/colors";



const Subcomponent2 = (props: SubcomponentBasicProp) => {
  const { showModal, handleModal, substepDashboard } = props;

  // REDUX: Paso y subpaso actual
  const dispatch = useDispatch();
  const { steps, company, idCompany, idUser, currentStepId } = useSelector((state: RootState) => state.dashboardStep);

  const currentStep = steps.find(s => s.id === currentStepId);
  const currentSubstep = substepDashboard;



  const { updateCompany } = useCompany();
  const { updateUserSubstep } = useUserSubstep();

  const { checkAdmin } = useUser();



  const [companyAux, setCompanyAux] = useState(company);
  const [isAdmin, setIsAdmin] = useState(undefined);
  const [loading, setLoading] = useState(undefined);

  const [disabledByStatus, setDisabledByStatus] = useState(false);


  useEffect(() => {
    if (currentSubstep && (currentSubstep.status === "EN_REVISION" || currentSubstep.status === "ACEPTADO")) {
      setDisabledByStatus(true);
    } else {
      setDisabledByStatus(false);
    }
  }, [currentSubstep]);

  useEffect(() => {
    (async () => {
      await initPage();
    })();
  }, [])

  const initPage = async () => {
    try {
      setLoading(true);
      const token = await LoginService.getTokenAsync();
      const result = await checkAdmin(token);
      setIsAdmin(result && result.item === true)
      setLoading(false);

    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  }

  const updateCompany2 = async (company: Company) => {
    try {
      setLoading(true);
      const token = await LoginService.getTokenAsync();

      if (isAdmin && company.companyName) {
        company.companyNameTest1 = "";
        company.companyNameTest2 = "";
        company.companyNameTest3 = "";
      } else {
        company.companyName = "";
      }

      const result = await updateCompany(token, company)

      if (isAdmin && company.companyName) {
        // setTimeout(() => {
        // NO HACER NADA
        // NO HACER NADA
        // NO HACER NADA
        // NO HACER NADA
        // dispatch(setCurrentCompany(result.item))
        // }, 10000)
        setLoading(false);
        handleModal(false);



      } else {
        if (result && result.ok) {
          await updateUserStep();
        } else {
          new Error(result)
        }
      }

    } catch (e) {
      console.error(e)
      setLoading(false);
      handleModal(false);

    }
  }


  const updateUserStep = async () => {
    try {
      const token = await LoginService.getTokenAsync();
      const ID_EN_REVISION_STATUS = 3;
      const result = await updateUserSubstep(token,
        {
          id: +currentSubstep.id,
          idStatusSubstepFk: ID_EN_REVISION_STATUS,
          comment: ""
        })
      if (result && result.ok) {

        let step = currentStep;
        let substep = currentSubstep;

        step.status = StatusStep.EN_REVISION;
        substep.status = StatusSubstep.EN_REVISION;

        console.log(values)
        dispatch(setCurrentCompany(values));

        dispatch(updateStep(step, substep));
        setLoading(false);
        handleModal(false);

      } else {
        throw Error("Hubo un error", result)
      }
    } catch (error) {
      setLoading(false);

      console.error(error);
      handleModal(false);
    }
  }


  // FORMIK
  const { values, errors, touched, isValid,

    handleChange,
    handleSubmit,
    handleBlur,
    getFieldProps,
    setFieldValue,
    setValues
  } = useFormik({
    initialValues: { ...companyAux },
    validateOnBlur: false,
    onSubmit: (value) => {
      console.log(value)
      console.log("Hay que hacer algo")
      updateCompany2(value)
    },
    validationSchema: Yup.lazy(values => validationCompanySchema(isAdmin))
  })

  return (
    <form onSubmit={handleSubmit}>
      {/* <pre>{JSON.stringify(errors, null, 2)}</pre> */}
      {currentSubstep.status !== "ACEPTADO" && (
        <CRow>

          <div className="d-flex justify-content-center">

            <CCol lg={6}>
              <div className="input-container" style={{ marginBottom: 30 }}>
                <label className="input-label" style={{ color: COLORS.principalColor }}>Nombre Empresa Provisional 1</label>
                <CFormInput
                  disabled={currentSubstep.status !== "RECHAZADO" || isAdmin}
                  id="companyName1"
                  placeholder='Nombre Empresa Provisional  1'
                  {...getFieldProps("companyNameTest1")} />
                {touched.companyNameTest1 && errors.companyNameTest1 && (
                  <div className='d-flex align-items-center mt-2' style={{ fontSize: 12, color: "red" }}>
                    <CIcon size="sm" icon={cilWarning} />
                    <div className='small' style={{ marginLeft: '5px' }}>{errors.companyNameTest1}</div>
                  </div>
                )}

              </div>


              <div className="input-container" style={{ marginBottom: 30 }}>
                <label className="input-label" style={{ color: COLORS.principalColor }}>Nombre Empresa Provisional 2</label>

                <CFormInput disabled={currentSubstep.status !== "RECHAZADO" || isAdmin} id="companyName2" placeholder='Nombre Empresa Provisional  2' {...getFieldProps("companyNameTest2")} />
                {touched.companyNameTest2 && errors.companyNameTest2 && (
                  <div className='d-flex align-items-center mt-2' style={{ fontSize: 12, color: "red" }}>
                    <CIcon size="sm" icon={cilWarning} />
                    <div className='small' style={{ marginLeft: '5px' }}>{errors.companyNameTest2}</div>
                  </div>
                )}
              </div>


              <div className="input-container" style={{ marginBottom: 30 }}>
                <label className="input-label" style={{ color: COLORS.principalColor }}>Nombre Empresa Provisional 3</label>
                <CFormInput disabled={currentSubstep.status !== "RECHAZADO" || isAdmin} id="companyName3" placeholder='Nombre Empresa Provisional 3' {...getFieldProps("companyNameTest3")} />
                {touched.companyNameTest3 && errors.companyNameTest3 && (
                  <div className='d-flex align-items-center mt-2' style={{ fontSize: 12, color: "red" }}>
                    <CIcon size="sm" icon={cilWarning} />
                    <div className='small' style={{ marginLeft: '5px' }}>{errors.companyNameTest3}</div>
                  </div>
                )}
              </div>
            </CCol>
          </div>

        </CRow>
      )}


      {/* <CRow>
        <div style={{ marginTop: 50 }}></div>
        <CFormLabel htmlFor="companyDescription" className="mb-2" style={{ color: "white" }}>Descripción de la Empresa</CFormLabel>
        <CInputGroup className="mb-3">


          <CFormTextarea
            id="companyDescription"
            rows={2}
            placeholder='Descripción de la empresa'
            {...getFieldProps("description")}
          ></CFormTextarea>

        </CInputGroup>
      </CRow> */}


      {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
      {currentSubstep.status === "ACEPTADO" && (
        <div style={{ width: "100%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
          <span style={{ fontSize: 18, fontFamily: "Raleway", fontWeight: 700 }}>El nombre de la empresa es</span>
          <span style={{ fontSize: 24, fontFamily: "Raleway", fontWeight: 700, color: COLORS.principalColor }}>{companyAux.companyName} {companyAux.typeCommercialCompany}</span>
        </div>
      )}


      {/* {((isAdmin && currentSubstep.status === "EN_REVISION") || (currentSubstep.status === "ACEPTADO" && companyAux.companyName)) && (
        <CRow>
          <div className="d-flex justify-content-center">
            <CCol lg={6}>
              <div className="input-container">
                <label className="input-label" style={{ color: COLORS.principalColor }}>Nombre Empresa Definitivo</label>
                <CFormInput
                  disabled={!isAdmin || (currentSubstep.status === "ACEPTADO" && !!companyAux?.companyName)}
                  id="definitiveName"
                  placeholder='Nombre Empresa Definitivo'
                  {...getFieldProps("companyName")} />
                {touched.companyName && errors.companyName && (
                  <div style={{
                    width: "100%",
                    backgroundColor: "#f8d7da", 
                    borderColor: "#f5c6cb", 
                    color: "#721c24", 
                    padding: "5px 10px", 
                    borderRadius: "4px", 
                    marginTop: "5px", 
                  }}>
                    {errors.companyName}
                  </div>
                )}
              </div>
            </CCol>
          </div>
        </CRow>
      )} */}


      <div style={{ marginTop: 50 }}></div>

      {!disabledByStatus && !isAdmin && (
        <CRow className="justify-content-center">
          <CCol xs={6} className="text-center">
            <CLoadingButton
              style={{ backgroundColor: COLORS.principalColor }}
              disabled={
                (isAdmin && (currentSubstep.status === "RECHAZADO" || !!companyAux?.companyName))
                || (!isAdmin && disabledByStatus)
                || !isValid
              }
              loading={null}

              onClick={() => handleSubmit()}
            >
              {isAdmin ? (
                <span>Actualizar</span>
              )
                : <span>Mandar a revisión</span>
              }
            </CLoadingButton>
          </CCol>
        </CRow>
      )}





    </form >
  )
}

export default Subcomponent2