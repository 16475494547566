import { CRow } from "@coreui/react-pro";
import { useContext, useState, useEffect } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { useCompany } from "../../../../../core/hooks/db/company/useCompany";
import { useProduct } from "../../../../../core/hooks/db/product/useProduct";
import { User } from "../../../../../core/models/db/user";
import { DataBasicEncoder } from "../../../../../core/util/data-basic-encoder";
import { useSnackbar } from "../../../../components/snackbar/hook/useSnackbar";
import { PaymentContext } from "../contexts/PaymentContext";
import CompanyForm from "./CompanyForm";
import { LoginService } from "../../../../../core/services/login/login-service";
import { Company } from "../../../../../core/models/db/company";
import { Util } from "../../../../../core/util/util";
import { AuthContext } from "../../../../../core/contexts/AuthContext";
import { BASE_ROUTE } from "../../../../../core/constants/route_name";


const LABEL_PAGE = "Compañía"

const CompanyBase = () => {

    const { checkToken } = useContext(AuthContext);


    const navigate = useNavigate();
    const location = useLocation();
    const stateLocation = location.state;
    const { id } = useParams();

    // Pasar paso
    const {
        stepsData,
        data,

        handleStepClick,
        setData,
        handleLoading,
    } = useContext(PaymentContext)



    const {
        addCompany
    } = useCompany();

    const {
        getMainProduct: getProduct,
    } = useProduct();


    const [company, setCompany] = useState<{
        companyNameTest1: string,
        companyNameTest2: string,
        companyNameTest3: string,
        description: string,
        typeCommercialCompany?: string

    }>({
        companyNameTest1: "",
        companyNameTest2: "",
        companyNameTest3: "",
        description: "",
    })
    const [loadingButton, setLoadingButton] = useState(false);
    const [typeAction, setTypeAction] = useState<"add" | "update">(null);

    const [token, setToken] = useState(null);

    const { state, handleSnackbar } = useSnackbar({
        color: "",
        wait: 3000,
        message: "",
        changeState: false,
    })

    /**
  * Se usa al comienzo de cargar la página para rellenar la tabla
  */
    useEffect(() => {
        (async () => {
            // changeStyle({ backgroundColor: "rgba(111, 214, 250, 0.5)" })
            // handleLoading(true);
            // const token =
            const token = "";

            setToken(token)




            const path = location.pathname;

            // if (path.includes("actualizar") && stateLocation && stateLocation.row) {
            //     let row: User = { ...stateLocation.row };
            //     row.password = "";
            //     row.priv_confirmPassword = "";
            //     delete row.token;
            //     delete row.roles;
            //     delete row.priv_role;
            //     delete row.createdDate;
            //     delete row.deletedDate;
            //     delete row.updatedDate;

            //     setUser(row);
            //     setTypeAction("update");
            // } else if (path.includes("actualizar") && id) {
            //     let result = await getUserById(token, id)
            //     if (result && result.ok) {
            //         console.log("what is the result", result)
            //         let user = result.item;
            //         user.password = "";
            //         user.priv_confirmPassword = "";
            //         delete user.token;
            //         delete user.roles;
            //         delete user.createdDate;
            //         delete user.deletedDate;
            //         delete user.updatedDate;
            //         setUser(user);
            //         setTypeAction("update");
            //     } else {
            //         console.warn("No se encontró ningún id");
            //         navigate(BASE_ROUTE + ROUTE_NAME.usuariosCrear);
            //     }
            // } else {
            // Esto es un caso especial de la pantalla donde se encuentra este componente
            if (data && data.company) {
                setCompany({
                    companyNameTest1: data.company.companyNameTest1 || "",
                    companyNameTest2: data.company.companyNameTest2 || "",
                    companyNameTest3: data.company.companyNameTest3 || "",
                    description: data.company.description || "",
                    typeCommercialCompany: data.company.typeCommercialCompany
                })
            }
            setTypeAction("add");
            // }

            // handleLoading(false);
        })()
    }, []);





    /**
     * Crea el usuario
     * @param company 
     */
    const add = async (company: any) => {
        console.log("/pago")
        handleLoading(true);

        company.idUserFk = data.user.id;
        data.company = company;
        const {
            product,
            company: companyCreated } = await manageCompanyAndMainProduct(data.company)
        data.product = product;
        data.company.id = companyCreated.id

        // Agrega 20 minutos al tiempo actual
        const currentTime = new Date();
        const expiryTime = new Date(currentTime.getTime() + 12000000 * 60 * 1000);
        data.expire = expiryTime.toISOString();
        data.isPaymentCorrect = false;
        data.isPaymentError = false;

        // Para acceder dentro del dashboard se hace la comprobación de token e identity
        console.log("antes")
        await checkToken();
        await Util.delay(1000);
        handleLoading(false);
        console.log("mira esto de data")
        console.log(data);

        const encodedData = encodeURIComponent(DataBasicEncoder.encode(data));
        navigate(`${BASE_ROUTE}/pago/${encodedData}`, { state: { encodedData } });
        // try {
        //     let u = { ...user };
        //     delete u.priv_confirmPassword;
        //     // setLoadingButton(true);
        //     if (!u.password || u.password === '') {
        //         u.password = "gk13!12";
        //     }

        //     // const result = await addUser(token, u);
        //     const result = await register(u);

        //     if (result && result.ok) {
        //         handleSnackbar("El registro se ha creado", "success");
        //         setData({
        //             ...data, user: {
        //                 username: u.username
        //             }
        //         })

        //         navigate(BASE_ROUTE + "/pago");
        //     }

        // } catch (e) {
        //     handleSnackbar("Ha habido un error al crear el registro", "success");
        //     setLoadingButton(false);
        //     console.error(e)
        // }
    }

    const manageCompanyAndMainProduct = async (companyAux) => {
        try {
            let token = await LoginService.getTokenAsync();

            let result = await Promise.all([
                createCompany(token, companyAux),
                getMainProduct(token)
            ]);
            const company = result[0];
            const product = result[1];

            if (!company || !product) {
                throw new Error("No se encontraron elementos")
            }

            return {
                company,
                product
            }

        } catch (e) {
            throw e;
        }
    }


    const createCompany = async (token, company: Company) => {
        try {
            const result = await addCompany(token, company);
            if (result && result.ok && result.item) {
                return result.item;
            } else {
                throw new Error("Hubo un error al intentar hacer la compra")
            }
        } catch (e) {

            throw e;
        }

    }

    /**
     * Descarga el producto principal de la APP
     * Sin esta compra, el usuario no podrá hacer casi nada dentro del dashboard
     */
    const getMainProduct = async (token) => {
        try {
            const result = await getProduct(token);
            if (result && result.ok && result.item) {
                return result.item;
            } else {
                throw new Error("No se ha podido realizar la compra")
            }
        } catch (e) {
            throw e;
        }
    }

    /**
    * Actualiza el usuario
    * @param user 
    */
    // const update = async (user: User) => {
    //     try {
    //         let u = { ...user };
    //         delete u.priv_confirmPassword;
    //         setLoadingButton(true);
    //         if (!u.password || u.password === '') {
    //             delete u.password;
    //         }

    //         const result = await updateUser(token, u);
    //         if (result && result.ok) {
    //             handleSnackbar("El registro se ha actualizado", "success");

    //             // let identity = await LoginService.getIdentityAsync();


    //             // setTimeout(() => {
    //             //     setLoadingButton(false);
    //             //     // setUser({
    //             //     //   ...u,
    //             //     //   priv_confirmPassword: ''
    //             //     // })
    //             //     navigate("/dashboard/usuarios");
    //             // }, 1600)
    //         }

    //     } catch (e) {
    //         handleSnackbar("Ha habido un error al crear el registro", "success");
    //         setLoadingButton(false);
    //         console.error(e)
    //     }
    // }

    return (
        <div>
            {/* <pre>{JSON.stringify(data, null, 2)}</pre> */}
            <CRow>
                {company && typeAction && (
                    <CompanyForm

                        loadingButton={loadingButton}
                        company={company}
                        typeAction={typeAction}
                        add={add}
                        update={undefined}


                        updateStepData={e => {
                            let company = e
                            setData({
                                ...data,
                                company: {
                                    ...company,
                                    typeCommercialCompany: data.company.typeCommercialCompany,
                                }
                            })
                        }}
                    />
                )}

            </CRow>
        </div>

    )
}

export default CompanyBase



