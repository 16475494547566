import { CRow } from '@coreui/react-pro';
import React, { useEffect, useState } from 'react'
import { environment } from '../../environments';
import { Identity, LoginService } from '../../../core/services/login/login-service';
import ModalCover from '../modalCover/ModalCover';
import UserForm from '../../pages/user/UserForm';
import { User } from '../../../core/models/db/user';
import UserFormDetailFromNav from '../../pages/user/UserFormDetailFromNav/UserFormDetailFromNav';
import { COLORS } from '../../../core/constants/colors';

interface IdentityUser {
    username?: string;
    avatar?: string;
    idRole?: number;
    idUser?: number;
}

const UserNav = () => {
    const [identity, setIdentity] = useState<Identity>(undefined);
    const [showModal, setShowModal] = useState<boolean>(false);


    const generateAvatarColor = (username: string) => {
        const hue = username.charCodeAt(0) % 360;
        return `hsl(${hue}, 50%, 50%)`;
    }

    const generateTextColor = (backgroundColor: string) => {
        const hsl = backgroundColor.slice(4, -1).split(',');
        const lightness = parseInt(hsl[2]);
        return lightness > 50 ? 'black' : 'white';
    }


    useEffect(() => {
        (async () => {
            let identityAux = await LoginService.getIdentityAsync();
            if (!identity) {
                setIdentity(identityAux);
            } else if (identityAux.username != identity.username || identityAux.idRole != identity.idRole || identityAux.avatar != identity.avatar) {
                setIdentity(identityAux);
            }
        })();
    });


    return (
        <CRow >
            {identity && identity.avatar && (
                <div
                    onClick={() => setShowModal(true)}
                    style={{
                        borderRadius: '50%',
                        overflow: 'hidden',
                        height: '40px',
                        width: '40px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundImage: `url(${environment.urlApi}${identity.avatar})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center'
                    }}
                />
            )}

            {identity && !identity.avatar && identity.username && (
                <div
                    onClick={() => setShowModal(true)}
                    style={{
                        // backgroundColor: generateAvatarColor(identity.username),
                        // color: generateTextColor(generateAvatarColor(identity.username)),
                        backgroundColor: COLORS.tertiaryColor,
                        borderWidth: 2,
                        borderColor: COLORS.quaternaryColor,
                        borderStyle: "solid",
                        fontSize: 16,
                        borderRadius: '50%',
                        width: '40px',
                        height: '40px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        textAlign: 'center',
                        fontWeight: 'bold',
                    }}
                >
                    {identity.username.substring(0, 2).toUpperCase()}
                </div>
            )}


            {identity && identity.idUser && (
                <ModalCover
                    styleBody={{ backgroundColor: "whitesmoke" }}
                    title={'Actualizar usuario'}
                    // size='lg'

                    hasSuccessButton={false}
                    hasCloseButton={false}
                    show={showModal}
                    handleModal={(value) => setShowModal(value)}
                >
                    <UserFormDetailFromNav
                        idFromModal={identity.idUser}
                        handleModal={(value) => setShowModal(value)} />
                </ModalCover>
            )}
        </CRow>
    )
}

export default UserNav
