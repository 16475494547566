import { cilLockLocked, cilInfo, cilWarning, cilArrowLeft } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import { CRow, CCol, CInputGroup, CInputGroupText, CFormInput, CAlert, CFormCheck, CFormLabel, CContainer } from "@coreui/react-pro";
import { useFormik } from "formik";
import React, { useState, useEffect, useRef } from "react";
import { MyDocument } from "../../../../core/models/db/myDocument";
import { LoginService } from "../../../../core/services/login/login-service";
import ButtonLoadingSucessForm from "../../../components/buttonLoadingSucessForm/ButtonLoadingSucessForm";
import CheckPassword from "../../../components/checkPassword/CheckPassword";
import DragDrop from "../../../components/drag-drop/DragDrop";
import { FileItemObservableService } from "../../../components/drag-drop/services/rxJS/fileitem-observable.service";
import Autocomplete from "../../../components/autocomplete/Autocomplete";
import * as Yup from 'yup';
import DragDropSimple from "../../../components/drag-drop-simple/DragDropSimple";
import { COLORS } from "../../../../core/constants/colors";
import { useNavigate } from "react-router-dom";
import { BASE_ROUTE, ROUTE_NAME, ROUTE_NAME_ID, RouteName } from "../../../../core/constants/route_name";
import { gsap } from 'gsap';


// VALIDACIÓN FORMULARIO
const validationSchema = Yup.object().shape({
    name: Yup.string()
        .required('Campo obligatorio')
        .max(100, 'El nombre no puede superar los 100 caracteres'),
    description: Yup.string()
        .nullable(),
    extension: Yup.string()
        .nullable()
        .max(10, 'La extensión no puede superar los 10 caracteres'),
    url: Yup.string()
        .required('El archivo es obligatorio'),
    documentAccess: Yup.object().shape({
        isAdminUploaded: Yup.boolean()
            .nullable(),
        idTypeDocumentFk: Yup.string()
            .required('Tipo de documento es obligatorio')
    })
});

interface MyDocumentFormProps {
    myDocument: MyDocument;
    typeAction: "add" | "update";
    loadingButton?: boolean;

    isModal?: boolean

    add?: (myDocument: MyDocument) => Promise<any>;
    update?: (myDocument: MyDocument) => Promise<any>;
    checkPassword?: (id: any, password: any) => Promise<any>
    checkMyDocumentname?: (id: any, myDocumentname: string) => Promise<any>

    idCompany: number;
}



const MyDocumentForm = (props: MyDocumentFormProps) => {

    /**
     * Este fragmento es necesario porque el navegador rellena de manera
     * automática los inputs myDocumentname y password. Después de intentarlo mediante
     * otros métodos se ha optado por el siguiente
     */
    const autocompleteOff = () => setTimeout(() => setAutoOff(false), 100)
    const [autoOff, setAutoOff] = useState(null);

    const [identity, setIdentity] = useState(undefined);

    useEffect(() => {
        (async () => {
            autocompleteOff()
            let id = await LoginService.getIdentityAsync();
            setIdentity(id);
        })();

    }, [])


    // Comienzo de la página
    // Comienzo de la página
    // Comienzo de la página

    const navigate = useNavigate();

    const {
        myDocument,
        typeAction,
        loadingButton,

        isModal = false,

        add,
        update,
        checkPassword,
        checkMyDocumentname,
        idCompany,
    } = props;


    const [showPassword, setShowPassword] = useState(false);
    const [imageMyDocument, setImageMyDocument] = useState<string>(myDocument && myDocument.url ? myDocument.url : "");
    const [disabled, setDisabled] = useState(true);

    const { values, errors, touched, isValid,

        handleChange,
        handleSubmit,
        handleBlur,
        getFieldProps,
        setFieldValue,
        setFieldTouched,
        setValues
    } = useFormik({
        initialValues: myDocument,
        validateOnBlur: false,
        onSubmit: (value) => {
            if (typeAction === "add") {
                add(value);
            } else if (typeAction === "update") {
                update(value);
            }
        },
        validationSchema: validationSchema
    })


    /**
     * Si hay que mantener la pagina tras haber creado el registro, 
     * descomenta este trozo de código
     * @param value 
     */
    // useEffect(() => {
    //     console.log("Activating myDocument update");
    //     setValues({
    //         ...myDocument,
    //         blocked: Boolean(myDocument.blocked)
    //     })
    // }, [myDocument])


    const changeToBackup = (value: boolean) => {
        if (value) {
            setValues(myDocument);

            if (value) {
                let backImage = myDocument.url;
                setImageMyDocument(null)
                setTimeout(() => {
                    setImageMyDocument(backImage)
                }, 400)
            }
        }
    }

    const isEdit = (value: boolean) => {
        setDisabled(value);
    }


    const backButtonRef = useRef(null);

    useEffect(() => {
        const btn = backButtonRef.current;

        if (btn) {  // Verifica si btn no es null
            const tl = gsap.timeline({ paused: true });

            tl.to(btn, { y: 0, scale: 1.01, duration: 0.2 });
            btn.onmouseenter = () => tl.play();
            btn.onmouseleave = () => tl.reverse();

        }
    }, [backButtonRef]);

    const handleBack = () => {

        if (loadingButton) return;

        gsap.to(backButtonRef.current, { opacity: 0, duration: 0.1 })
            .then(() => {
                navigate(BASE_ROUTE + ROUTE_NAME_ID(idCompany, RouteName.documentAccesses), { replace: true })
            });
    };

    return (

        <>

            {/* <pre style={{ color: "white" }}> {JSON.stringify(errors, null, 2)}</pre>
            <pre style={{ color: "white" }}> {JSON.stringify(isValid, null, 2)}</pre> */}

            {/* 
            <h4>Original</h4>
            <pre>
                {JSON.stringify(myDocument, null, 2)}
            </pre>
            <h4> Modificado</h4>
            <pre>
                {JSON.stringify(values, null, 2)}
            </pre> 
            */}
            <form onSubmit={handleSubmit}>

                <CRow style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "98%" }}>
                    <CCol sm={6} lg={6}>

                        <div style={{ color: COLORS.secondaryColor, fontSize: 30, fontWeight: 700, userSelect: "none" }}>Crear documento</div>
                        <span ref={backButtonRef} style={{ color: "white", display: "flex", alignItems: "center" }} onClick={handleBack}>
                            <CIcon icon={cilArrowLeft} size="sm" /> <span style={{ marginLeft: 5, userSelect: "none" }}>Volver</span>
                        </span>
                    </CCol>

                    <CCol xs={6} lg={6}>
                        <ButtonLoadingSucessForm
                            secondaryColor={COLORS.secondaryColor}
                            loadingButton={loadingButton}
                            className="d-flex d-row"
                            changeToBackup={changeToBackup}
                            onSubmit={handleSubmit}
                            setDisabled={isEdit}
                            typeAction={typeAction}
                            disabled={!isValid}
                        />
                    </CCol>
                </CRow>

                <div style={{ marginBottom: 50 }}></div>
                <CRow>
                    <CCol sm={6}>

                        <label className="input-label">Tipo de documento</label>

                        <Autocomplete
                            styleWesingular={true}
                            id="basic-url"
                            table='typeDocuments'
                            disabled={(disabled && typeAction !== "add") || loadingButton}
                            name={'idTypeDocumentFk'}
                            defaultValue={{ key: "id", value: values.documentAccess.idTypeDocumentFk }}
                            onChange={(e: any) => {
                                // console.log(e)
                                handleChange(e);

                                if (e && e?.target?.value) {
                                    setFieldValue("documentAccess.idTypeDocumentFk", e?.target?.value);
                                } else {
                                    // setFieldValue("name", "");
                                    setFieldValue("documentAccess.idTypeDocumentFk", "");
                                }
                                setFieldTouched("documentAccess.idTypeDocumentFk", true);

                            }}
                            onBlur={handleBlur} />

                        <div style={{ height: "auto", marginTop: 5 }}>
                            {touched?.documentAccess?.idTypeDocumentFk && errors?.documentAccess?.idTypeDocumentFk && (
                                <div className='d-flex align-items-center' style={{ height: "25px", fontSize: 12, color: "red" }}>
                                    <CIcon size="sm" icon={cilWarning} />
                                    <div className='small' style={{ marginLeft: '5px' }}>{errors.documentAccess.idTypeDocumentFk}</div>
                                </div>
                            )}
                        </div>

                    </CCol>


                    <CCol sm={6}>

                        <div className="input-container">
                            <label className="input-label">Nombre del documento</label>

                            <CFormInput
                                className="dark-input"
                                disabled={(disabled && typeAction !== "add") || loadingButton}
                                // placeholder='Username'
                                {...getFieldProps("name")}
                                onChange={(event) => {
                                    getFieldProps("name").onChange(event);
                                }}
                                readOnly={autoOff}
                                autoComplete="new-name"
                            />
                            <div style={{ height: "auto", marginTop: 5 }}>
                                {touched.name && errors.name && (
                                    <div className='d-flex align-items-center' style={{ height: "25px", fontSize: 12, color: "red" }}>
                                        <CIcon size="sm" icon={cilWarning} />
                                        <div className='small' style={{ marginLeft: '5px' }}>{errors.name}</div>
                                    </div>
                                )}
                            </div>
                        </div>



                    </CCol>

                    <span style={{ marginTop: 30 }}></span>
                    <CCol sm={6}>
                        <div className="input-container">
                            <label className="input-label">Agrega un archivo</label>
                            <DragDropSimple
                                // styleDD={{ maxWidth: "auto", height: 250, marginTop: identity && identity.idRole == 1 ? 50 : 0 }}
                                specialCase={true}
                                handleFiles={(item) => {


                                    if (item && item.length > 0) {
                                        values.url = item[0].pathRelative;
                                        setFieldValue('url', item[0].pathRelative);
                                        setFieldValue("name", item[0].name);
                                    } else {
                                        setFieldValue('url', "");
                                    }
                                }}
                                id={"test-id"}
                                // valueDefault={imageMyDocument}
                                isOnlyOneFile={true}
                                disabled={(disabled && typeAction !== "add") || loadingButton}
                                directoryNameList={["fromReact"]}
                                maxSizeMB={19}
                                typeAllowed={[
                                    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                                    "application/pdf",
                                    "image/*",
                                    "image/gif",
                                    "image/jpeg",
                                    "image/png",
                                    "image/jpg"
                                ]} />

                            <div style={{ height: "auto", marginTop: 5 }}>
                                {!values.url && (
                                    <div className='d-flex align-items-center' style={{ height: "25px", fontSize: 12, color: "red" }}>
                                        <CIcon size="sm" icon={cilWarning} />
                                        <div className='small' style={{ marginLeft: '5px' }}>El archivo es obligatorio</div>
                                    </div>
                                )}
                            </div>

                        </div>

                    </CCol>




                </CRow>
            </form >
        </>


    )
}

export default MyDocumentForm



