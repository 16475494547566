import React from 'react'
import CardPaymentForm from '../stripe/cardPaymentForm/CardPaymentForm'
import CompanyTypeSelector from '../companyTypeSelector/CompanyTypeSelector'

const stepPageTwo = () => {
    return (
        <div>
            {/* <div>stepPageTwo</div> */}
            {/* <CardPaymentForm /> */}
            <CompanyTypeSelector />
        </div>
    )
}

export default stepPageTwo
