import React, { useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { AuthContext } from '../../../../core/contexts/AuthContext'
import { useAuth } from '../../../../core/hooks/useAuth'
import { Login } from '../../../../core/models/login/login'
import { LoginService } from '../../../../core/services/login/login-service'
import { LoaderContext } from '../../../components/loader/context/LoaderContext'

import LoginForm from './LoginForm'
import { useDispatch } from 'react-redux'
import { setInitialData } from '../../../components/@dashboardSteps/actions/dashboardStep-action'
import { identity } from 'rxjs'
import { DataBasicEncoder } from '../../../../core/util/data-basic-encoder'

const LoginPage = () => {

  const dispatch = useDispatch();
  // Necesario para resetear el estado del dashboard
  dispatch(setInitialData(null, null, null, null, null, null));

  const navigate = useNavigate();
  const { checkToken } = useContext(AuthContext);

  const [loadingButton, setLoadingButton] = useState(false)


  useEffect(() => {
    localStorage.removeItem("identity");
    localStorage.removeItem("token");

    // localStorage.clear();
    // handleLogin();
  }, [])

  const handleLogin = async () => {
    try {
      if (!localStorage.getItem('reloaded')) {
        localStorage.setItem('reloaded', 'true');
        window.location.reload();
      } else {
        localStorage.clear();
      }
    } catch (error) {
      console.error(error)
      // Maneja el error...
    }
  };


  const access = (values: Login) => {

    return new Promise<any>((res, rej) => {

      console.log("que es values", values)
      // handleLoading(true);
      setLoadingButton(true);
      LoginService.login(values)
        .then(async ({ data }) => {
          console.log(data);
          if (data.ok) {
            if (data.item?.identity && data.item?.token) {
              // TODO: Antiguo LS de identity
              // localStorage.setItem("identity", JSON.stringify(data.item.identity));
              const encode = DataBasicEncoder.encode(data.item.identity);
              localStorage.setItem("identity", encode)
              localStorage.setItem("token", data.item.token);
              await checkToken();
              // handleLoading(false);
              setLoadingButton(false);
              navigate(`/dashboard`);
            } else {
              // handleLoading(false);
              setLoadingButton(false);

              throw new Error("Error desde response.ok", data);
            }
          } else {
            // handleLoading(false);
            setLoadingButton(false);

          }
        })
        .catch(e => {
          // handleLoading(false);
          setLoadingButton(false);

          console.error(e);
        })
    });

  }



  return (
    <div className="container-fluid">
      <section className="login">
        <div className="row">

          <div className="col-sm-12  col-lg-3 bg-login">
            <div className="form-wp">
              <LoginForm access={access} loadingButton={loadingButton} />
            </div>
          </div>

          <div className="col-sm-12 col-lg-9 px-0 login-right">
            <div className="login-right_image"></div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default LoginPage;