import { CContainer, CCard, CCardBody, CRow } from "@coreui/react-pro"
import { COLORS } from "../../../../core/constants/colors"
import { useTypeDocument } from "../../../../core/hooks/db/typeDocument/typeDocument"
import { useMyDocument } from "../../../../core/hooks/db/myDocument/useMyDocument";
import { useContext, useEffect, useState } from "react";
import { MyDocument } from "../../../../core/models/db/myDocument";
import { useUser } from "../../../../core/hooks/db/user/useUser";
import { LoginService } from "../../../../core/services/login/login-service";
import { useSnackbar } from "../../../components/snackbar/hook/useSnackbar";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { BASE_ROUTE, ROUTE_NAME, ROUTE_NAME_ID, RouteName } from "../../../../core/constants/route_name";
import { LoaderContext } from "../../../components/loader/context/LoaderContext";
import { TypeDocument } from "../../../../core/models/db/typeDocument";
import DocumentForm from "./DocumentForm";
import { useSelector } from "react-redux";
import { RootState } from "../../../../core/store/store";
import Snackbar from "../../../components/snackbar/Snackbar";








const DocumentFormDetail = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const stateLocation = location.state;
    const { id } = useParams();


    const { company: companyRedux } = useSelector((state: RootState) => state.dashboardStep);




    const { checkAdmin } = useUser();
    const { getTypeDocument } = useTypeDocument();
    const { addWithDocumentAccess, updateMyDocument, getMyDocumentById, } = useMyDocument();


    const [document, setDocument] = useState<MyDocument>({
        name: "",
        description: "",
        extension: "",
        url: "",
        documentAccess: {
            isAdminUploaded: true,
            idTypeDocumentFk: undefined
        }
    });
    const [typeDocumentList, setTypeDocumentList] = useState<TypeDocument[]>([]);


    const { handleLoading, changeStyle } = useContext(LoaderContext);
    const [loadingButton, setLoadingButton] = useState(false);
    const [typeAction, setTypeAction] = useState<"add" | "update">(null);

    const { state, handleSnackbar } = useSnackbar({
        // color: "",
        wait: 3000,
        message: "",
        changeState: false,
        color: COLORS.secondaryColor
    })

    useEffect(() => {
        (async () => {
            if (!companyRedux) {
                // Realmente lo mandará al Dashboard tras mandarlo atrás por no haber compañía
                navigate(BASE_ROUTE + ROUTE_NAME.documentAccesses);
            }
            await initPage();
        })();
    }, [id])

    const initPage = async () => {
        try {
            const token = await LoginService.getTokenAsync();
            changeStyle({ backgroundColor: "rgba(111, 214, 250, 0.5)" })
            handleLoading(true);

            const path = location.pathname;



            if (path.includes("actualizar") && stateLocation && stateLocation.row) {


                let row: MyDocument = { ...stateLocation.row };
                setDocument(row);
                setTypeAction("update");
            } else if (path.includes("actualizar") && id) {


                let result = await getMyDocumentById(token, id)
                if (result && result.ok) {



                    let doc = result.item;
                    setDocument(doc);
                    setTypeAction("update");
                } else {

                    navigate(BASE_ROUTE + ROUTE_NAME.documentAccesses);
                }
            } else {
                setTypeAction("add");
            }

            handleLoading(false);


        } catch (error) {
            console.log(error)
        }
    }

    const addDocument = async (doc: MyDocument) => {
        try {
            setLoadingButton(true);
            const token = await LoginService.getTokenAsync();
            let d = { ...doc };
            d.idCompanyFk = companyRedux.id;
            d.idUserFk = companyRedux.idUserFk;
            const result = await addWithDocumentAccess(token, d, d.documentAccess);


            if (result.ok) {
                handleSnackbar("El registro se ha creado", "success");
            } else {
                handleSnackbar("Ha habido un error al crear el registro", "error");
            }


            setTimeout(() => {
                setLoadingButton(false);
                // setUser({
                //   ...u,
                //   priv_confirmPassword: ''
                // })
                navigate(BASE_ROUTE + ROUTE_NAME_ID(companyRedux.id, RouteName.documentAccesses));
            }, 1600)
            // setDocument({
            //     name: "",
            //     description: "",
            //     extension: "",
            //     url: "",
            //     documentAccess: {
            //         isAdminUploaded: true,
            //         idTypeDocumentFk: undefined
            //     }
            // });

        } catch (error) {
            console.error(error);
            setLoadingButton(false);

        }
    }



    return (
        <CContainer className="mb-4 mt-4" style={{ minHeight: 400 }}>
            <Snackbar  {...state} />

            <CCard>
                <CCardBody style={{
                    backgroundColor: COLORS.tertiaryColor,
                    borderRadius: 5,
                    borderColor: COLORS.tertiaryColor,
                    minHeight: 400
                }}>
                    {/* <pre style={{ color: "white" }}>{JSON.stringify(companyRedux, null, 2)}</pre> */}

                    <div style={{ marginTop: 50, marginBottom: 60 }}>

                        {document && typeAction && (
                            <DocumentForm
                                idCompany={companyRedux.id}
                                loadingButton={loadingButton}
                                myDocument={document}
                                typeAction={typeAction}
                                add={addDocument}
                                update={null}
                            />
                        )}
                    </div>

                </CCardBody>
            </CCard>
        </CContainer >
    )
}

export default DocumentFormDetail