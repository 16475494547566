import UserRegistration from "./userRegistration/UserRegistration"


const BaseUserRegistration = () => {
    return (
        <>
            <UserRegistration />
        </>

    )
}

export default BaseUserRegistration
