import React, { useEffect, useRef, useState } from 'react'
import { CMultiSelect } from '@coreui/react-pro';
import { TableNamesType } from '../../../core/models/interfaces/interfaces';
import { useUser } from '../../../core/hooks/db/user/useUser';
import { LoginService } from '../../../core/services/login/login-service';
import { Util } from '../../../core/util/util';
import { useRole } from '../../../core/hooks/db/role/useRole';
import { useDocumentAndorraResidence } from '../../../core/hooks/specialCaseAutocomplete/useDocumentAndorraResidence';
import { useCompany } from '../../../core/hooks/db/company/useCompany';
import { useUserStep } from '../../../core/hooks/db/userStep/useUserStep';
import { useStatusSubstep } from '../../../core/hooks/db/statusSubstep/useStatusSubstep';
import { classNames } from '../../utils/classes-css';
import styled from 'styled-components';

// import "./autocomplete-style.scss";
import { setRef } from '@mui/material';
import { useTypeDocument } from '../../../core/hooks/db/typeDocument/typeDocument';
import { COLORS } from '../../../core/constants/colors';
import StyledCMultiSelect from './StyledCMultiSelect';


// const StyledCMultiSelect = styled(CMultiSelect)`
// background-color: ${COLORS.principalColor};
// border: none;

// &:focus {
//   border-color: #f08a3b;
//   box-shadow: 0 0 0 0.2rem rgba(240, 138, 59, 0.25);
// }

// &:blur {
//   border-color: transparent;
//   box-shadow: none;
// }

// div, span, input {
//   color: white;
// }

// .form-multi-select-search {
//   background-color: ${COLORS.principalColor};
//   border: none;
//   padding: 5px 10px;
//   border-radius: 5px;
//   color: orange;

//   &::placeholder {
//     color: #fff;
//   }
// }

// .dropdown-menu {
//   background-color: #1d1d26;
// }

// &.form-multi-select{
//     padding: 0px;
// }

// &.form-multi-select.show {
//   background-color: ${COLORS.principalColor};
//   box-shadow: 0 0 0 0.25rem rgba(240, 138, 59, 0.25);
// }

// .virtual-scroller-content {
//   background-color: #1d1d26;
// }

// .form-multi-select-option {
//   background-color: #1d1d26;
//   color: white;
//   border-top: 1px solid #323343;
//   padding: 5px 10px;
//   border-radius: 5px;

//   &.elegida {
//     background-color: #f08a3b;
//   }

//   &:first-child {
//     border-top: none;
//   }
// }

// .virtual-scroller {
//   &::-webkit-scrollbar {
//     width: 6px;
//     background-color: transparent;
//     padding: 4px;
//   }

//   &::-webkit-scrollbar-thumb {
//     border-radius: 5px;
//     background-color: #4f6f88;
//   }

//   &::-webkit-scrollbar-track {
//     border-radius: 5px;
//     background-color: transparent;
//   }
// }
// `;





const ChooseHook = (table: TableNamesType) => {
    const userHook = useUser();
    const roleHook = useRole();
    const companyHook = useCompany();
    const userSubtepHook = useStatusSubstep();
    const typeDocumentHook = useTypeDocument();



    const documentAndorraResidenceHook = useDocumentAndorraResidence();


    let hookFunction;
    switch (table) {
        case "users":
            hookFunction = userHook;
            break;
        case "typeDocuments":
            hookFunction = typeDocumentHook;
            break;
        case "roles":
            hookFunction = roleHook;
            break;
        case "special_case_typeDocumentStep_1" as any:
            hookFunction = documentAndorraResidenceHook;
            break
        case "companies":
            hookFunction = companyHook;
            break;
        case "statusSubsteps_modal" as any:
            hookFunction = userSubtepHook;
            break;
        case "special_case_typeCompany" as any:
            hookFunction = "No existe hook";
            break;
    }

    return hookFunction
}

function fillOutObject(table: TableNamesType, rows: any[], defaultRow) {

    return new Promise<any>((res, rej) => {
        let body = [];
        // console.log(rows)

        if (rows.length === 0) {
            res(body);
        }

        for (let [i, row] of rows.entries()) {
            switch (table) {
                case "users":
                    row = { ...row, text: Util.capitalize(row.username), value: row.id }
                    break;
                case "typeDocuments":
                    row = { ...row, text: Util.capitalize(row.title), value: row.id }
                    break;
                case "roles":
                    row = { ...row, text: Util.capitalize(row.title), value: row.id }
                    break;
                case "special_case_typeDocumentStep_1" as any:
                    row = { ...row, text: row.value, key: row.value };
                    break;
                case "companies":
                    row = { ...row, text: "(ID:" + row.id + ")" + Util.capitalize(row.companyName) + "-" + Util.capitalize(row.companyNameTest1), value: row.id };
                    break;
                // Solamente habrá dos status
                case "statusSubsteps_modal" as any:
                    row = { ...row, text: Util.capitalize(row.name_transform), value: row.id };
                    break;
                case "special_case_typeCompany" as any:
                    row = { ...row, text: row, value: row };
                    break;
                // case "customers":
                //     row = {
                //         ...row,
                //         text: Util.capitalize(row.name),
                //         value: row.id
                //     }
                //     break;
                // case "partners":
                //     row = {
                //         ...row,
                //         text: row.code + " - " + Util.capitalize(row.name),
                //         value: row.id
                //     }
                //     break;
                // case "works":
                //     row = {
                //         ...row,
                //         text: row.code + " - " + Util.capitalize(row.name),
                //         value: row.id
                //     }
                //     break;
            }
            if (defaultRow && defaultRow.value === row[defaultRow.key]) {
                row.selected = true;
            } else {
                row.selected = false;
            }

            body.push(row)

            if (rows.length - 1 == i) {
                res(body);
            }
        }
    })
}

interface AutocompleteProps {
    /** Si obtiene los datos a través de esta variable entonces no hará la petición al back */
    defaultData?: any[];
    multiselect?: boolean;

    placeholder?: string;
    disabled?: boolean;
    id?: string;
    name: string;
    defaultValue?: {
        key: string,
        value: any
    };
    table: TableNamesType;
    onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
    onBlur: (e: React.FocusEvent<HTMLSelectElement>) => void;

    styleWesingular?: boolean;
}

const Autocomplete = (props: AutocompleteProps) => {

    const {
        styleWesingular,


        defaultData,
        multiselect = false,
        table,
        name,
        defaultValue,
        placeholder,
        id,
        disabled,
        onChange,
        onBlur, } = props;
    const { autocomplete } = ChooseHook(table);

    const [token, setToken] = useState(null);
    const [body, setBody] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    const [bodyAutocomplete, setBodyAutocomplete] = useState<{ text: string, value: number }[]>([]);














    useEffect(() => {
        (async () => {

            setLoading(true);
            const token = await LoginService.getTokenAsync();
            setToken(token)

            // console.log("cuidado defaultData", defaultData);
            if (defaultData) {

                let transformed = await fillOutObject(table, defaultData, defaultValue);
                // console.log(transformed)
                setBody(transformed);
                setLoading(false);
            } else {
                const response = await autocomplete(token);
                console.log(response)
                if (response.ok) {
                    let rows = response.item;
                    let transformed = await fillOutObject(table, rows, defaultValue);
                    setBody(transformed);
                    setLoading(false);
                } else {
                    setLoading(false);
                }
            }

        })()
    }, []);

    useEffect(() => {
        (async () => {

            if (defaultData) {
                setLoading(true);
                let transformed = await fillOutObject(table, defaultData, defaultValue);
                // console.log(transformed)
                setBody(transformed);
                setLoading(false);
            }

        })()
    }, [defaultData]);






    // Importar estilos de "styleWesingular" de forma condicional
    // if (styleWesingular) {

    //     require('./autocomplete-style.scss');
    // }

    const refSpecialStyle = useRef();
    // useEffect(() => {
    //     if (styleWesingular && refSpecialStyle.current) {
    //         const dropdownElem: any = refSpecialStyle.current;

    //         const applyStyles = () => {
    //             // Colores
    //             const principalColor = '#3d4f65';
    //             const secondaryColor = '#f08a3b';
    //             const tertiaryColor = '#1d1d26';
    //             const quaternaryColor = '#323343';

    //             // Estilos para el componente principal
    //             dropdownElem.style.backgroundColor = principalColor;
    //             dropdownElem.style.border = 'none'; // Elimina el borde

    //             dropdownElem.addEventListener('focus', () => {
    //                 dropdownElem.style.borderColor = secondaryColor;
    //                 dropdownElem.style.boxShadow = `0 0 0 0.2rem rgba(240, 138, 59, 0.25)`;
    //             });

    //             dropdownElem.addEventListener('blur', () => {
    //                 dropdownElem.style.borderColor = 'transparent';
    //                 dropdownElem.style.boxShadow = 'none';
    //             });

    //             // Estilos para todos los textos dentro del componente principal
    //             const allTextElements = dropdownElem.querySelectorAll('div, span, input');
    //             allTextElements.forEach(elem => {
    //                 elem.style.color = 'white';
    //             });




    //             const searchElem2 = dropdownElem.querySelector('.form-multi-select-search');
    //             if (searchElem2) {
    //                 searchElem2.style.backgroundColor = principalColor;
    //                 searchElem2.style.border = 'none';
    //                 searchElem2.style.padding = '5px 10px';
    //                 searchElem2.style.borderRadius = '5px';
    //                 searchElem2.style.color = 'orange'; // Estilo para el texto regular en el input

    //                 // Estilo para el placeholder del input
    //                 const placeholderStyle = document.createElement('style');
    //                 placeholderStyle.innerHTML = `
    //                     .form-multi-select-search::placeholder {
    //                         // color: #f08a3b;
    //                         color: #fff;

    //                     }
    //                 `;
    //                 document.head.appendChild(placeholderStyle);
    //             }



    //             // Estilos para el campo de búsqueda
    //             const searchElem = dropdownElem.querySelector('.form-multi-select-search');
    //             if (searchElem) {
    //                 searchElem.style.backgroundColor = principalColor;
    //                 searchElem.style.border = 'none';
    //                 searchElem.style.padding = '5px 10px';
    //                 searchElem.style.borderRadius = '5px';
    //             }

    //             // Selecciona el elemento con la clase .form-multi-select
    //             // ESTO ESTABA COMENTADO HACE NADA 29 de octubre
    //             const formMultiSelectElem: any = document.querySelector('.form-multi-select');
    //             if (formMultiSelectElem) {
    //                 // formMultiSelectElem.addEventListener('focus', () => {
    //                 //     // Verifica si el elemento tiene la clase .show antes de aplicar el estilo
    //                 //     if (formMultiSelectElem.classList.contains('show')) {
    //                 //         formMultiSelectElem.style.boxShadow = '0 0 0 0.25rem rgba(240, 138, 59, 0.25)';
    //                 //     }
    //                 // });

    //                 // formMultiSelectElem.addEventListener('blur', () => {
    //                 //     // Remueve el estilo cuando se pierde el foco
    //                 //     formMultiSelectElem.style.boxShadow = 'none';
    //                 // });
    //             }




    //             // Estilos para el menú desplegable
    //             const dropdownMenuElem = dropdownElem.querySelector('.dropdown-menu');
    //             if (dropdownMenuElem) {
    //                 dropdownMenuElem.style.backgroundColor = tertiaryColor;
    //             }

    //             // Estilos para el contenedor de las opciones
    //             const scrollerContentElem = dropdownElem.querySelector('.virtual-scroller-content');
    //             if (scrollerContentElem) {
    //                 scrollerContentElem.style.backgroundColor = tertiaryColor;
    //             }

    //             // Estilos para las opciones del menú desplegable
    //             const optionElems = dropdownElem.querySelectorAll('.form-multi-select-option');
    //             optionElems.forEach(option => {
    //                 option.style.backgroundColor = tertiaryColor;
    //                 option.style.color = 'white';
    //                 option.style.borderTop = `1px solid ${quaternaryColor}`;
    //                 option.style.padding = '5px 10px';
    //                 option.style.borderRadius = '5px';

    //                 // Si tiene la clase "elegida"
    //                 if (option.classList.contains('elegida')) {
    //                     option.style.backgroundColor = secondaryColor;
    //                 }
    //             });

    //             // Eliminamos la línea superior de la primera opción
    //             if (optionElems.length > 0) {
    //                 optionElems[0].style.borderTop = 'none';
    //             }



    //             const scrollerElem = dropdownElem.querySelector('.virtual-scroller');
    //             if (scrollerElem) {
    //                 // Los estilos se le da mediante hoja de estilo
    //                 scrollerElem.classList.add('custom-scrollbar');
    //             }


    //         } // end

    //         // Aplica estilos de inmediato
    //         applyStyles();

    //         // Configura el observador para detectar cambios en el DOM
    //         const observer = new MutationObserver((mutationsList) => {
    //             for (const mutation of mutationsList) {
    //                 if (mutation.type === 'childList') {
    //                     applyStyles();
    //                 }
    //             }
    //         });

    //         // Comienza la observación
    //         observer.observe(dropdownElem, { childList: true, subtree: true });

    //         // Limpiar el observador cuando el componente se desmonte
    //         return () => observer.disconnect();
    //     }
    // }, [styleWesingular, refSpecialStyle]);






    const sendUniqueItem = (e) => {
        console.log("on change", e)

        let value: any;
        if (e && e.length > 0) {
            value = e[0].value;

            let item = body.find(item => item.id == value);
            let obj: any = {
                target: {
                    name: name,
                    value: value,
                    // NUEVO, para incluir el objeto entero. name y value son obligatorios por el
                    item
                }
            }
            onChange(obj)
        } else {
            if (!name) {
                throw new Error("No existe la propiedad name en el autocomplete");
            } else {
                console.warn(e)
                // throw new Error("Hubo un error en el autocomplete");
                let obj: any = {
                    target: {
                        name: null,
                        value: null,
                    }
                }
                onChange(obj)


            }
        }
    }

    const sendMultipleItem = (e) => {
        console.log("on change", e)

        let values: any;
        if (e && e.length > 0) {
            values = e;
            let itemList = [];
            itemList = body.filter(itemf => values.find(valuesAutoComp => valuesAutoComp.value == itemf.id))

            // let item = body.find(item => item.id == values);
            let obj: any = itemList
            onChange(obj)
        } else {
            if (!name) {
                throw new Error("No existe la propiedad name en el autocomplete");
            } else {
                console.warn(e)
                // throw new Error("Hubo un error en el autocomplete");
                let obj: any = []
                onChange(obj)


            }
        }
    }
    return (
        <>
            {styleWesingular ? (
                <StyledCMultiSelect
                    className={styleWesingular ? "theme-wesingular" : ""}
                    ref={refSpecialStyle}
                    id={id ? id : "autocomplete-gk"}
                    multiple={multiselect}
                    selectAllLabel="Seleccionar todos"
                    disabled={disabled}
                    loading={loading}
                    placeholder={placeholder ? placeholder : "Selecciona un registro"}
                    searchNoResultsLabel={"No se encontro ningún resultado"}
                    optionsStyle={"text"}
                    options={body as any}
                    clearSearchOnSelect={true}
                    // cleaner={true}
                    virtualScroller={true}
                    visibleItems={4}

                    onFilterChange={(e) => {
                        console.log("onFilterChange", e)
                    }}

                    onChange={multiselect ? sendMultipleItem : sendUniqueItem}

                    onBlur={onBlur as any}
                />
            )
                :
                (
                    <CMultiSelect
                        className={styleWesingular ? "theme-wesingular" : ""}
                        ref={refSpecialStyle}
                        id={id ? id : "autocomplete-gk"}
                        multiple={multiselect}
                        selectAllLabel="Seleccionar todos"
                        disabled={disabled}
                        loading={loading}
                        placeholder={placeholder ? placeholder : "Selecciona un registro"}
                        searchNoResultsLabel={"No se encontro ningún resultado"}
                        optionsStyle={"text"}
                        options={body as any}
                        clearSearchOnSelect={true}
                        // cleaner={true}
                        virtualScroller={true}
                        visibleItems={4}

                        onFilterChange={(e) => {
                            console.log("onFilterChange", e)

                            // let obj: any = {
                            //     target: {
                            //         name: name,
                            //         value: null,
                            //     }
                            // }
                            // onChange(obj)
                        }}

                        onChange={multiselect ? sendMultipleItem : sendUniqueItem}
                        // onChange={(e: any) => {
                        //     console.log("on change", e)

                        //     let value: any;
                        //     if (e && e.length > 0) {
                        //         value = e[0].value;

                        //         let item = body.find(item => item.id == value);
                        //         let obj: any = {
                        //             target: {
                        //                 name: name,
                        //                 value: value,
                        //                 // NUEVO, para incluir el objeto entero. name y value son obligatorios por el
                        //                 item
                        //             }
                        //         }
                        //         onChange(obj)
                        //     } else {
                        //         if (!name) {
                        //             throw new Error("No existe la propiedad name en el autocomplete");
                        //         } else {
                        //             console.warn(e)
                        //             // throw new Error("Hubo un error en el autocomplete");
                        //             let obj: any = {
                        //                 target: {
                        //                     name: null,
                        //                     value: null,
                        //                 }
                        //             }
                        //             onChange(obj)


                        //         }
                        //     }


                        // }}
                        onBlur={onBlur as any}
                    />
                )

            }

        </>
    )
}

export default Autocomplete

