import { useContext, useEffect, useState } from "react";
import { LoaderContext } from "../../components/loader/context/LoaderContext";
import { LoginService } from "../../../core/services/login/login-service";
import { usePurchase } from "../../../core/hooks/db/purchase/usePurchase";
import { useSelector } from "react-redux";
import { RootState } from "../../../core/store/store";
import { useCompany } from "../../../core/hooks/db/company/useCompany";
import { Util } from "../../../core/util/util";
import { companyBackupLS } from "../../../core/util/save-company-choosed";
import { useNavigate, useParams } from "react-router-dom";
import { Purchase } from "../../../core/models/db/purchase";
import { CContainer, CCard, CCardBody } from "@coreui/react-pro";
import { COLORS } from "../../../core/constants/colors";
import { BASE_ROUTE } from "../../../core/constants/route_name";
import useCheckMainProduct from "../../../core/hooks/checkMainProduct/useCheckMainProduct";
import WarnBuyMainProduct from "../../components/warnBuyMainProduct/WarnBuyMainProduct";
import { Company } from "../../../core/models/db/company";


const BillingPage = () => {

    const navigate = useNavigate();
    const { id } = useParams();

    const { company: companyRedux } = useSelector((state: RootState) => state.dashboardStep);
    const [company, setCompany] = useState<Company>(undefined)

    const { loading, handleLoading } = useContext(LoaderContext);
    const { getPurchaseByUserAndCompany } = usePurchase();
    const { getCompanyById } = useCompany();


    const [isAdmin, setIsAdmin] = useState<boolean>(false);
    const [purchaseList, setPurchaseList] = useState<Purchase[]>();
    const [hasAttemptedLoad, setHasAttemptedLoad] = useState(false);

    const { hasMainProduct, isLoading: loadingCheckMainProduct } = useCheckMainProduct();


    useEffect(() => {
        (async () => {
            // if (!companyRedux) {
            //     navigate(BASE_ROUTE);
            // }
            initPage();
        })();

        // if (companyRedux) {
        //     initPage();
        // } else if (hasAttemptedLoad) {
        //     navigate(BASE_ROUTE);
        // } else {
        //     setHasAttemptedLoad(true);
        // }
    }, [id])

    const initPage = async () => {
        try {
            await Util.delay(100);
            const token = await LoginService.getTokenAsync();
            const identity = await LoginService.getIdentityAsync();
            setIsAdmin(identity.idRole === 1);
            const isAdmin = identity.idRole === 1;
            const idUser = identity.idUser;

            let promiseUser;
            let ID_COMPANY: any = companyRedux ? companyRedux.id : id;

            if (companyRedux) {
                setCompany(companyRedux);

                if (id && companyRedux.id != +id) {
                    console.warn("No existen permisos para hacer esto");
                    navigate("/login");
                }
            } else {
                let comp = await getCompanyById(token, ID_COMPANY);
                if (comp && comp.ok && (isAdmin || !isAdmin && comp.item.idUserFk == idUser)) {
                    setCompany(comp.item);
                } else {
                    console.warn("No hay permisos para hacer esto");
                    setCompany(undefined);
                    navigate("/login");
                }
            }
            // if (!comp) {
            //     navigate("/dashboard/home")
            // }
            promiseUser = getPurchaseByUserAndCompany(token,
                identity.idUser,
                ID_COMPANY)

            // Solamente un usuario puede recibirlo
            if (!isAdmin) {
                const result: any = await promiseUser;

                if (result.ok) {
                    console.log(result.item)
                    setPurchaseList(result.item);
                }
            }

        } catch (e) {
            console.error(e);
        }
    }

    // No tiene producto
    if (!hasMainProduct && company) {
        return (
            <>
                {/* <pre style={{ color: "white" }}>{JSON.stringify(hasMainProduct, null, 2)}</pre> */}
                <WarnBuyMainProduct company={company} />
            </>
        )
    }


    return (
        <CContainer className="mb-4 mt-4">
            <CCard>

                <CCardBody style={{
                    backgroundColor: COLORS.tertiaryColor,
                    borderRadius: 5,
                    borderColor: COLORS.tertiaryColor
                }}>
                    <div style={{
                        color: COLORS.secondaryColor,
                        fontSize: 30,
                        fontWeight: 700,
                        marginTop: 50,
                        marginBottom: 50
                    }}>
                        Facturación
                    </div>

                    <div className="billing-container">
                        {/* <div className="billing-header">Billing</div> */}

                        {purchaseList && purchaseList.map((purchase, i) => {
                            return (
                                <div key={i} >
                                    <div className="billing-item">
                                        <div className="product-title">{purchase.products.name}</div>
                                        <div className="product-description">{purchase.products.description}</div>
                                        <div className="product-date">Pago efectuado: {new Date(purchase.paymentDate).toLocaleDateString()}</div>
                                    </div>
                                    {i != purchaseList.length - 1 && (
                                        <div style={{ borderBottom: "1px solid #323343", marginTop: 12, marginBottom: 12, }}></div>

                                    )}
                                </div>
                            )
                        })}
                    </div>

                </CCardBody>
            </CCard>
        </CContainer>
    )
}

export default BillingPage
