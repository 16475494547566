import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { Pagination } from '../../../models/pagination';
import { ReasonForDeactivationProps } from '../../check-table/useCheckTable';
import { StatusSubstepService } from '../../../services/db/statusSubstep/statusSubstep.service';
import { StatusSubstep } from '../../../models/db/steps/statusSubstep';






export const useStatusSubstep = () => {

    const getAllIdStatusSubstep = (token: any, reasonForDeactivation: ReasonForDeactivationProps[]) => {
        return new Promise<any>((res, rej) => {
            StatusSubstepService.getAllId(token, reasonForDeactivation)
                .then(({ data }) => {
                    console.log(data)
                    if (data.ok) {
                        res(data);
                    } else {
                        res(undefined)
                    }
                })
                .catch((e) => {
                    console.log(e)
                    rej(e)
                })

        })
    }

    const getStatusSubstepById = (token, id) => {
        return new Promise<any>((res, rej) => {
            StatusSubstepService.get(token, id)
                .then(({ data }) => {
                    console.log(data)
                    if (data.ok) {
                        res(data);
                    } else {
                        res(undefined)
                    }
                })
                .catch((e) => {
                    console.log(e)
                    rej(e)
                })

        })
    }


    const getStatusSubstep = (token, pagination: Pagination = { page: 1, itemsPerPage: 5 }) => {
        return new Promise<any>((res, rej) => {
            StatusSubstepService.getAll(token, pagination)
                .then(({ data }) => {
                    console.log(data)
                    if (data.ok) {
                        res(data);
                    } else {
                        res(undefined)
                    }
                })
                .catch((e) => {
                    console.log(e)
                    rej(e)
                })

        })
    }

    const getStatusSubstepAutocomplete = (token, pagination: Pagination = { page: 1, itemsPerPage: 5 }) => {
        return new Promise<any>((res, rej) => {
            StatusSubstepService.getAutocomplete(token)
                .then(({ data }) => {
                    // console.log(data)
                    if (data.ok) {
                        const desiredStatuses = ["EN_REVISION", "ACEPTADO", "RECHAZADO"];

                        const result = data.item
                            .filter(item => desiredStatuses.includes(item.name))
                            .map(item => ({
                                ...item,
                                name_transform: item.name.split('_').map(word =>
                                    word.charAt(0) + word.slice(1).toLowerCase()  // Capitaliza cada palabra
                                ).join(' ')  // Junta las palabras con un espacio
                            }));
                        data.item = result;
                        res(data);
                    } else {
                        res(undefined)
                    }
                })
                .catch((e) => {
                    console.log(e)
                    rej(e)
                })

        })
    }


    const addStatusSubstep = (token, user: StatusSubstep) => {
        return new Promise<any>((res, rej) => {
            StatusSubstepService.add(token, { ...user })
                .then(({ data }) => {
                    // console.log(data)
                    if (data.ok) {
                        res(data);
                    } else {
                        res(undefined)
                    }
                })
                .catch((e) => {
                    console.log(e)
                    rej(e)
                })

        })
    }


    const updateStatusSubstep = (token, user: StatusSubstep) => {
        return new Promise<any>((res, rej) => {
            StatusSubstepService.update(token, { ...user })
                .then(({ data }) => {
                    console.log(data)
                    if (data.ok) {
                        res(data);
                    } else {
                        res(undefined)
                    }
                })
                .catch((e) => {
                    console.log(e)
                    rej(e)
                })

        })
    }

    const deleteStatusSubstep = (token, idList: string[] | number[], action: boolean) => {
        return new Promise<any>((res, rej) => {
            StatusSubstepService.delete(token, idList, action)
                .then(({ data }) => {
                    console.log(data)
                    if (data.ok) {
                        res(data);
                    } else {
                        res(undefined)
                    }
                })
                .catch((e) => {
                    console.log(e)
                    rej(e)
                })

        })
    }


    return {
        getAllIdStatusSubstep,
        getStatusSubstepById,
        getStatusSubstep,
        addStatusSubstep,
        updateStatusSubstep,
        deleteStatusSubstep,

        get: getStatusSubstep,
        add: addStatusSubstep,
        update: updateStatusSubstep,
        autocomplete: getStatusSubstepAutocomplete,
        delete: deleteStatusSubstep
    }
}