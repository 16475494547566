import { useState, useRef, useEffect } from 'react';
import gsap from 'gsap';
import React from 'react';


import "../scss/subcomp1-style.scss";
import { Tooltip } from 'react-tooltip';
import { Util } from '../../../../../../core/util/util';
import { environment } from '../../../../../environments';
import { COLORS } from '../../../../../../core/constants/colors';


const notResidenceInAndorraList = [
    { key: '1', value: "Pasaporte", uploaded: false },
    { key: '2', value: "Certificado de antecedentes penales", uploaded: false },
    { key: '3', value: "Certificado Médico válido", uploaded: false },
    { key: '4', value: "Documento de prueba de recursos financieros", uploaded: false },
    { key: '5', value: "Seguro médico en AND", uploaded: false },
    { key: '6', value: "Justificante de inversión extranjera o contrato de trabajo", uploaded: false },
    { key: '7', value: "Contrato de alquiler o vivienda en propiedad", uploaded: false },
    { key: '8', value: "Fotografías de carnet", uploaded: false },
]

const hasResidenceInAndorraList = [
    { key: '1', value: "Adjunta el certificado de empadronamiento", uploaded: false },
    { key: '2', value: "Adjunta la tarjeta de residencia", uploaded: false },
]




export const DocumentAndorraItem = ({ documents, disabled, hasResidenceInAndorra, onDelete }) => {
    const [deletingDocIndex, setDeletingDocIndex] = useState(null);
    const [filteredDocuments, setFilteredDocuments] = useState([]);

    const documentRowRef = useRef([]);
    const confirmRef = useRef([]);
    const confirmContentRef = useRef([]);

    const prevDeletingDocIndex = useRef(null);


    useEffect(() => {
        const documentsToShow = hasResidenceInAndorra ? hasResidenceInAndorraList : notResidenceInAndorraList;
        let list = documents.filter(doc => documentsToShow.some(uploadedDoc => uploadedDoc.value === doc.description));
        setFilteredDocuments(list)
    }, [hasResidenceInAndorra, documents])


    useEffect(() => {
        if (prevDeletingDocIndex.current !== null && prevDeletingDocIndex.current !== deletingDocIndex) {
            gsap.to(documentRowRef.current[prevDeletingDocIndex.current], { width: "100%", duration: 0.5 });
            gsap.to(confirmRef.current[prevDeletingDocIndex.current], { width: "0%", opacity: 0, duration: 0.5 });

            // Ocultar el contenido interno cuando se contrae el div de confirmación
            gsap.to(confirmContentRef.current[prevDeletingDocIndex.current], { opacity: 0, duration: 0.2 });
        }

        if (deletingDocIndex !== null) {
            gsap.to(documentRowRef.current[deletingDocIndex], { width: "70%", duration: 0.5 });
            gsap.fromTo(confirmRef.current[deletingDocIndex], { width: '0%', opacity: 0 }, { width: '30%', opacity: 1, duration: 0.5 });

            // Mostrar el contenido interno con un retraso después de que el div de confirmación haya empezado a expandirse
            gsap.to(confirmContentRef.current[deletingDocIndex], { opacity: 1, delay: 0.3 });
        }
    }, [deletingDocIndex]);

    useEffect(() => {
        prevDeletingDocIndex.current = deletingDocIndex;
    }, [deletingDocIndex]);


    const downloadDocument = (url, filename) => {
        // Crea un elemento <a> temporal
        const link = document.createElement('a');

        // Configura el enlace
        link.href = `${environment.urlApi}${url}`;
        link.download = filename || 'document';  // Nombre predeterminado si no se especifica
        link.target = '_blank';  // Abre el archivo en una nueva pestaña
        document.body.appendChild(link);

        // Simula un clic en el enlace para iniciar la descarga
        link.click();
        link.remove();

    }


    return (
        filteredDocuments && filteredDocuments.length > 0 && (
            <div>
                {filteredDocuments.map((doc, i) => (
                    <React.Fragment key={i}>
                        <div style={{ marginTop: 15 }}></div>
                        <div style={{ display: "flex", height: 70 }}>

                            <div className="documentRow" style={{
                                fontFamily: "Raleway",
                                color: "white",
                                width: '100%',
                                backgroundColor: COLORS.principalColor,
                                boxShadow: "inset 0 0 0 1px #1d1d26",
                                borderRadius: 7,

                            }} ref={el => documentRowRef.current[i] = el}>
                                <div id={"document-upload-" + i}>
                                    {Util.cutWord(doc.name, 17)}
                                </div>
                                <div style={{
                                    flex: 1,
                                    textAlign: "left",
                                    marginLeft: 12,
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                   
                                }}>
                                    {doc.description}
                                </div>


                                <svg style={{ marginRight: 15 }} onClick={() => downloadDocument(doc.url, doc.name)} className="icon-download" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M288 32c0-17.7-14.3-32-32-32s-32 14.3-32 32V274.7l-73.4-73.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0l128-128c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L288 274.7V32zM64 352c-35.3 0-64 28.7-64 64v32c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V416c0-35.3-28.7-64-64-64H346.5l-45.3 45.3c-25 25-65.5 25-90.5 0L165.5 352H64zm368 56a24 24 0 1 1 0 48 24 24 0 1 1 0-48z" /></svg>
                                {/* Ocultar botón de borrado si es disabled */}
                                {!disabled && (
                                    <svg className="icon-close" onClick={() => setDeletingDocIndex(i)} xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM175 175c-9.4 9.4-9.4 24.6 0 33.9l47 47-47 47c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l47-47 47 47c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-47-47 47-47c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-47 47-47-47c-9.4-9.4-24.6-9.4-33.9 0z" /></svg>
                                )}
                            </div>

                            {/* Aparacion de div */}
                            <div
                                ref={el => confirmRef.current[i] = el}
                                style={{ display: "flex", justifyContent: "center", width: '0%', opacity: 0, overflow: 'hidden' }}
                            >
                                <div style={{ display: "flex", flexDirection: "column", justifyContent: "center" }} ref={el => confirmContentRef.current[i] = el}>
                                    <div>
                                        <span style={{ fontSize: 13, fontWeight: 800 }}>¿Desear eliminarlo?</span>
                                    </div>
                                    <div style={{ display: "flex", justifyContent: "center", }}>
                                        <button className="button-perla" onClick={() => {
                                            // Por seguridad, si es disabled no se puede borrar
                                            if (disabled) return;
                                            setDeletingDocIndex(null);
                                            onDelete(doc);
                                        }}>Sí</button>
                                        <button className="button-perla" onClick={() => setDeletingDocIndex(null)}>No</button>

                                    </div>

                                </div>
                            </div>
                        </div>
                        <Tooltip style={{ width: 210 }} anchorSelect={"#document-upload-" + i}
                            content={doc.name} place="bottom" />
                    </React.Fragment>
                ))}
            </div>
        )
    );
}
