export class CompanyRelation {
    id?: number;
    name?: string;
    address?: string;
    phone?: string;
    email?: string;
    relationType?: 'owner' | 'partner';
    lastName1?: string;
    lastName2?: string;
    cif?: string;
    town?: string;
    zipCode?: string;
    urlPassport?: string;
    companyPercentage?: number;
    idUserFk?: number;
    idCompanyFk?: number;
    createdDate?: Date;
    updatedDate?: Date;
    deletedDate?: Date;
}