import React from 'react'
import RegistrationBase from '../registration/RegistrationBase'
import CompanyBase from '../company/CompanyBase'


const stepPageThree = () => {
    return (
        <div>
            <CompanyBase />
        </div>
    )
}

export default stepPageThree
