
import { cilBellExclamation } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import { StatusSubstep, Substep } from '../../../types'
import { COLORS } from '../../../../../../core/constants/colors';
import { useEffect, useState } from 'react';
import { Identity, LoginService } from '../../../../../../core/services/login/login-service';

interface ReasonForRejectionProps {
    subStep: Substep
}

const ReasonForRejection = (props: ReasonForRejectionProps) => {
    const { subStep } = props;

    const [identity, setIdentity] = useState<Identity>(undefined)
    useEffect(() => {
        (async () => {
            setIdentity(await LoginService.getIdentityAsync())
        })();
    }, [])

    return (
        <div style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",

            // borderBottom: 1,
            // borderBottomStyle: "solid",
            // borderColor: "#d10a49",
            // marginBottom: 30
        }}>
            {identity && subStep && subStep.status === StatusSubstep.RECHAZADO && identity.idRole !== 1 && (

                <div className="border-bottom pb-3 mt-3"
                    style={{
                        width: "80%",
                        display: "flex",
                        justifyContent: "space-around",
                        alignItems: "end",
                        padding: 10,
                        borderBottom: 1,
                        borderBottomStyle: "solid",
                        borderColor: "#d10a49",
                        marginBottom: 30,
                        backgroundColor: COLORS.quaternaryColor,
                        borderRadius: 10,
                    }}>
                    <div className="mr-4 d-none d-md-block" style={{ marginRight: 25 }}>
                        <CIcon icon={cilBellExclamation} size={"6xl"} style={{ color: "white" }} />
                    </div>
                    <div>
                        <h3 className="mb-2 font-weight-bold text-uppercase" style={{ fontFamily: "Raleway", fontWeight: 700, color: "white" }}>Motivo del rechazo</h3>
                        <p style={{ color: "white", fontStyle: "italic" }}>{subStep.comment}</p>
                    </div>
                </div>



            )}
        </div>
    )
}

export default ReasonForRejection;





