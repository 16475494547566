import { useNavigate } from "react-router-dom";
import UserRegistration from "../../@external-pages/registationAndPayment/userRegistration/UserRegistration";


const CompanyRegistrationPage = () => {

    const navigate = useNavigate();

    return (
        <div >
            <UserRegistration isDashboard={true} />
        </div>
    )
}

export default CompanyRegistrationPage
