import { useFormik, yupToFormErrors } from "formik";
import { useState, useEffect, useRef } from "react";
import * as Yup from 'yup';
import { CompanyRelation } from "../../../../../core/models/db/companyRelation";
import { CAlert, CFormInput } from "@coreui/react-pro";
import { COLORS } from "../../../../../core/constants/colors";
import ModalCover from "../../../../components/modalCover/ModalCover";
import { classNames } from "../../../../utils/classes-css";
import { cilWarning } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import { FileUploader } from "react-drag-drop-files";
import { Util } from "../../../../../core/util/util";
import DragDropSimple from "../../../../components/drag-drop-simple/DragDropSimple";


// VALIDACIÓN FORMULARIO
const validationSchema = Yup.object().shape({
    name: Yup.string()
        .required('Campo obligatorio'),
    address: Yup.string()
        .nullable(),
    phone: Yup.string()
        // .matches(
        //     /^(\+?\d{1,4}?)?((\d{10,12}))$/,
        //     'Número de teléfono inválido'
        // )
        .nullable(),
    email: Yup.string()
        .nullable()
        .email('Correo electrónico inválido'),
    urlPassport: Yup.string()
        .required('El pasaporte es obligatorio'),
    companyPercentage: Yup.number()
        .min(0, 'No puede ser negativo')
        .max(100, 'No puede ser mayor de 100')
        .required('Campo obligatorio'),
    lastName1: Yup.string()
        .nullable()
        .max(100, 'El apellido 1 no puede superar los 100 caracteres'),
    lastName2: Yup.string()
        .nullable()
        .max(100, 'El apellido 2 no puede superar los 100 caracteres'),
    cif: Yup.string()
        .nullable()
        .max(100, 'El CIF no puede superar los 100 caracteres'),
    town: Yup.string()
        .nullable()
        .max(100, 'El nombre de la ciudad no puede superar los 100 caracteres'),
    zipCode: Yup.string()
        .nullable()
});



interface RowCompanyRelationProps {
    isMainUser: boolean;
    index: number;
    companyRelation: CompanyRelation;
    disabled: boolean;


    onValidityChange: (index, value: boolean) => void;
    onDelete: (index: number, rowToDelete: CompanyRelation) => void;
    onDataChange: (index: number, newData: CompanyRelation) => void;

}

const RowCompanyRelation = (props: RowCompanyRelationProps) => {

    const {
        index,
        companyRelation: partner,
        disabled,
        isMainUser,

        onDataChange,
        onDelete,
        onValidityChange
    } = props;

    const [companyRelation, setCompanyRelation] = useState<CompanyRelation>(partner);
    const [loading, setLoading] = useState(undefined);


    const [modal, setModal] = useState(null);

    useEffect(() => {
        (async () => {
            await initPage();
        })();
    }, [])

    const initPage = async () => {
        try {


        } catch (error) {
            setLoading(false);
            console.error(error);
        }
    }


    // const validateForm = async (values) => {
    //     try {
    //         await validationSchema.validate(values, { abortEarly: false });

    //         // Si la validación es exitosa, notifica al padre que es válido
    //         if (onValidityChange) {
    //             onValidityChange(index, true);
    //         }

    //         return {}; // No hay errores
    //     } catch (error) {
    //         // Si hay un error de validación, notifica al padre que no es válido
    //         if (onValidityChange) {
    //             onValidityChange(index, false);
    //         }

    //         return yupToFormErrors(error); // Convierte los errores de Yup a errores de Formik
    //     }
    // }

    // FORMIK
    const { values, errors, touched, isValid,

        handleChange,
        handleSubmit,
        handleBlur,
        getFieldProps,
        setFieldValue,
        setValues,

    } = useFormik({
        initialValues: { ...companyRelation },
        validateOnBlur: false,
        onSubmit: (value) => {
            console.log(value)
        },
        validationSchema: validationSchema,
    })


    useEffect(() => {
        if (values.name === undefined) {
            onValidityChange(index, false)
        } else {
            onValidityChange(index, isValid)
        }
    }, [errors, isValid])

    const handleChangeAndInformParent = (e) => {
        handleChange(e);  // Esta es la función handleChange de Formik
        if (onDataChange) {
            onDataChange(index, { ...values, [e.target.name]: e.target.value });
        }
    }
    return (
        <>

            {/* <pre style={{ color: "white" }}>{JSON.stringify(errors, null, 2)}</pre>
            <pre style={{ color: "white" }}>{JSON.stringify(values, null, 2)}</pre> */}

            <form onSubmit={handleSubmit}>

                <div key={index} className="relation-wrapper">
                    <div className="partner-label">
                        {!disabled && onDelete && !isMainUser && (
                            <span className="m-2">
                                <svg
                                    onClick={() => {
                                        if (companyRelation.id) {
                                            setModal(true);
                                        } else {
                                            onDelete(index, companyRelation)
                                        }
                                    }}
                                    style={{ fill: COLORS.secondaryColor }}
                                    xmlns="http://www.w3.org/2000/svg"
                                    height="1em"
                                    viewBox="0 0 512 512">
                                    <path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM175 175c-9.4 9.4-9.4 24.6 0 33.9l47 47-47 47c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l47-47 47 47c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-47-47 47-47c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-47 47-47-47c-9.4-9.4-24.6-9.4-33.9 0z" />
                                </svg>
                            </span>
                        )}
                        <div>
                            <div>
                                Socio {index + 1}
                                <span style={{ color: COLORS.secondaryColor, marginLeft: 5, fontSize: 12, fontFamily: "Roboto" }}>
                                    {partner?.companyPercentage || 0}%
                                </span>
                            </div>
                            {isMainUser && (
                                <div style={{ color: COLORS.secondaryColor, fontSize: 11, fontFamily: "Roboto" }}><i>Registró la compañía</i></div>
                            )}

                        </div>
                    </div>
                    <div className="details-wrapper">
                        <div className="input-group">

                            <div className="input-container">
                                <label className="input-label">Nombre

                                    {/* {touched.name && errors.name && (
                                        <span style={{ height: "25px", fontSize: 12, color: "red", marginLeft: 5 }}>
                                            <CIcon size="sm" icon={cilWarning} />
                                          
                                        </span>
                                    )} */}
                                </label>
                                <CFormInput
                                    disabled={disabled}
                                    className="dark-input"
                                    {...getFieldProps("name")}
                                    onChange={handleChangeAndInformParent}
                                />
                                <div style={{ height: "auto", marginTop: 5 }}>
                                    {touched.name && errors.name && (
                                        <div className='d-flex align-items-center' style={{ height: "25px", fontSize: 12, color: "red" }}>
                                            <CIcon size="sm" icon={cilWarning} />
                                            <div className='small' style={{ marginLeft: '5px' }}>{errors.name}</div>
                                        </div>
                                    )}
                                </div>
                            </div>


                            <div className="input-container">
                                <label className="input-label">Porcentaje
                                    {/* {touched.companyPercentage && errors.companyPercentage && (
                                        <span style={{ height: "25px", fontSize: 12, color: "red", marginLeft: 5 }}>
                                            <CIcon size="sm" icon={cilWarning} />
                                          
                                        </span>
                                    )} */}
                                </label>
                                <CFormInput

                                    disabled={disabled}
                                    className={classNames("dark-input", "input-number")}
                                    type="number"

                                    defaultValue={partner.companyPercentage}
                                    {...getFieldProps("companyPercentage")}
                                    onChange={handleChangeAndInformParent}


                                />
                                <div style={{ height: "auto", marginTop: 5 }}>
                                    {touched.companyPercentage && errors.companyPercentage && (
                                        <div className='d-flex align-items-center' style={{ height: "25px", fontSize: 12, color: "red" }}>
                                            <CIcon size="sm" icon={cilWarning} />
                                            <div className='small' style={{ marginLeft: '5px' }}>{errors.companyPercentage}</div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>

                        <div className="input-group">
                            <div className="input-container">
                                <label className="input-label">Primer apellido</label>
                                <CFormInput disabled={disabled}
                                    className="dark-input"
                                    defaultValue={partner.lastName1}
                                    {...getFieldProps("lastName1")}
                                    onChange={handleChangeAndInformParent}
                                />
                                <div style={{ height: "auto", marginTop: 5 }}>
                                    {touched.lastName1 && errors.lastName1 && (
                                        <div className='d-flex align-items-center' style={{ height: "25px", fontSize: 12, color: "red" }}>
                                            <CIcon size="sm" icon={cilWarning} />
                                            <div className='small' style={{ marginLeft: '5px' }}>{errors.lastName1}</div>
                                        </div>
                                    )}
                                </div>
                            </div>

                            {/* Segundo apellido */}
                            <div className="input-container">
                                <label className="input-label">Segundo apellido</label>
                                <CFormInput disabled={disabled}
                                    className="dark-input"
                                    defaultValue={partner.lastName2}
                                    {...getFieldProps("lastName2")}
                                    onChange={handleChangeAndInformParent}
                                />
                                <div style={{ height: "auto", marginTop: 5 }}>
                                    {touched.lastName2 && errors.lastName2 && (
                                        <div className='d-flex align-items-center' style={{ height: "25px", fontSize: 12, color: "red" }}>
                                            <CIcon size="sm" icon={cilWarning} />
                                            <div className='small' style={{ marginLeft: '5px' }}>{errors.lastName2}</div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>

                        <div className="input-group">
                            {/* Email */}
                            <div className="input-container">
                                <label className="input-label">Email</label>
                                <CFormInput disabled={disabled}
                                    type="email"
                                    className="dark-input"
                                    defaultValue={partner.email}
                                    {...getFieldProps("email")}
                                    onChange={handleChangeAndInformParent}
                                />
                                <div style={{ height: "auto", marginTop: 5 }}>
                                    {touched.email && errors.email && (
                                        <div className='d-flex align-items-center' style={{ height: "25px", fontSize: 12, color: "red" }}>
                                            <CIcon size="sm" icon={cilWarning} />
                                            <div className='small' style={{ marginLeft: '5px' }}>{errors.email}</div>
                                        </div>
                                    )}
                                </div>
                            </div>

                            {/* Teléfono */}
                            <div className="input-container">
                                <label className="input-label">Teléfono</label>
                                <CFormInput
                                    disabled={disabled}
                                    type="number"
                                    className={classNames("dark-input", "input-number")}

                                    defaultValue={partner.phone}
                                    {...getFieldProps("phone")}
                                    onChange={handleChangeAndInformParent}
                                />
                                <div style={{ height: "auto", marginTop: 5 }}>
                                    {touched.phone && errors.phone && (
                                        <div className='d-flex align-items-center' style={{ height: "25px", fontSize: 12, color: "red" }}>
                                            <CIcon size="sm" icon={cilWarning} />
                                            <div className='small' style={{ marginLeft: '5px' }}>{errors.phone}</div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>

                        <div className="input-container">
                            <label className="input-label">Dirección</label>
                            <CFormInput disabled={disabled}
                                className="dark-input"
                                defaultValue={partner.address}
                                {...getFieldProps("address")}
                                onChange={handleChangeAndInformParent}
                            />
                            <div style={{ height: "auto", marginTop: 5 }}>
                                {touched.address && errors.address && (
                                    <div className='d-flex align-items-center' style={{ height: "25px", fontSize: 12, color: "red" }}>
                                        <CIcon size="sm" icon={cilWarning} />
                                        <div className='small' style={{ marginLeft: '5px' }}>{errors.address}</div>
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className="input-group">
                            <div className="input-container">
                                <label className="input-label">Código Postal</label>
                                <CFormInput disabled={disabled}
                                    className="dark-input"
                                    defaultValue={partner.zipCode}
                                    {...getFieldProps("zipCode")}
                                    onChange={handleChangeAndInformParent}
                                />
                                <div style={{ height: "auto", marginTop: 5 }}>
                                    {touched.zipCode && errors.zipCode && (
                                        <div className='d-flex align-items-center' style={{ height: "25px", fontSize: 12, color: "red" }}>
                                            <CIcon size="sm" icon={cilWarning} />
                                            <div className='small' style={{ marginLeft: '5px' }}>{errors.zipCode}</div>
                                        </div>
                                    )}
                                </div>
                            </div>

                            {/* Ciudad */}
                            <div className="input-container">
                                <label className="input-label">Ciudad</label>
                                <CFormInput disabled={disabled}
                                    className="dark-input"
                                    defaultValue={partner.town}
                                    {...getFieldProps("town")}
                                    onChange={handleChangeAndInformParent}
                                />
                                <div style={{ height: "auto", marginTop: 5 }}>
                                    {touched.town && errors.town && (
                                        <div className='d-flex align-items-center' style={{ height: "25px", fontSize: 12, color: "red" }}>
                                            <CIcon size="sm" icon={cilWarning} />
                                            <div className='small' style={{ marginLeft: '5px' }}>{errors.town}</div>
                                        </div>
                                    )}
                                </div>
                            </div>

                        </div>

                        <div className="input-group">
                            <div className="input-container">

                                <label className="input-label" style={{ marginBottom: -7 }}>Pasaporte</label>
                                <div className="fileUploadContainer" style={{ display: "flex", flexDirection: "column" }}>
                                    <DragDropSimple
                                        specialCase={true}
                                        handleFiles={(item) => {
                                    
                                            if (!onDataChange) return;

                                            if (item && item.length > 0) {
                                                // setFilesAlreadyUploaded(item.fileItemList)
                                                setFieldValue('urlPassport', item[0].pathRelative);
                                                onDataChange(index, { ...values, urlPassport: item[0].pathRelative });
                                            } else {
                                             
                                                // setFilesAlreadyUploaded([])
                                                setFieldValue('urlPassport', "");
                                                onDataChange(index, { ...values, urlPassport: "" });
                                            }
                                        }}

                                        // classDD={'ploadContainer'}
                                        id={"test-id"}
                                        isOnlyOneFile={true}
                                        showFileList={false}
                                        // disabled={loading || typeFilesList.filter(item => item.uploaded).length === countLimit}
                                        disabled={
                                            disabled ||
                                            loading}
                                        valueDefault={companyRelation.urlPassport}

                                        directoryNameList={["fromReact"]}
                                        maxSizeMB={20}
                                        typeAllowed={[
                                            "image/*",
                                            "image/gif",
                                            "image/jpeg",
                                            "image/png",
                                            "image/jpg",
                                            "application/pdf"
                                        ]} />
                                    <div style={{ height: "auto", marginTop: 5 }}>
                                        {!values.urlPassport && !disabled && (
                                            <div className='d-flex align-items-center' style={{ height: "25px", fontSize: 12, color: "red" }}>
                                                <CIcon size="sm" icon={cilWarning} />
                                                <div className='small' style={{ marginLeft: '5px' }}>El pasaporte es obligatorio</div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>



            </form >

            {/* boxShadow: "0 0 6px 0 white" */}
            < ModalCover
                styleModal={{
                    backgroundColor: "whitesmoke",
                    color: "black",
                    fontSize: '18px'
                }
                }
                styleHeader={{
                    borderBottom: "1px solid #000",
                    borderColor: COLORS.secondaryColor,
                    padding: '1rem',
                    fontSize: '24px'
                }}
                title={"Borrar socio"}
                handleModal={setModal}
                show={modal}
                hasCloseButton={false}
            >
                <div style={{ padding: '1rem' }}>
                    <p style={{ marginBottom: '1.5rem' }}>
                        ¿Estás seguro de que quieres borrar este socio?
                    </p>
                    <div style={{
                        display: "flex",
                        justifyContent: "space-around",
                        marginTop: '2rem',
                        alignItems: "center"
                    }}>
                        <div style={{ textAlign: 'right', marginBottom: '1rem' }}>
                            <button onClick={() => {
                                onDelete(index, companyRelation)
                                setModal(true);
                            }}
                                style={{
                                    backgroundColor: '#f08a3b',
                                    color: 'black',
                                    border: 'none',
                                    padding: '0.6rem 1.5rem',
                                    borderRadius: '5px',
                                    fontSize: '18px',
                                    cursor: 'pointer',
                                    transition: 'background-color 0.3s'
                                }}
                                onMouseEnter={e => {
                                    e.currentTarget.style.backgroundColor = 'red';
                                    e.currentTarget.style.color = 'white';

                                }}
                                onMouseLeave={e => e.currentTarget.style.backgroundColor = '#f08a3b'}>
                                SÍ
                            </button>
                        </div>

                        <div style={{ textAlign: 'right', marginBottom: '1rem' }}>
                            <button onClick={() => {
                                setModal(false);
                            }}
                                style={{
                                    backgroundColor: '#f08a3b',
                                    border: 'none',
                                    padding: '0.6rem 1.5rem',
                                    borderRadius: '5px',
                                    fontSize: '18px',
                                    cursor: 'pointer',
                                    transition: 'background-color 0.3s'
                                }}
                                onMouseEnter={e => e.currentTarget.style.backgroundColor = '#d8782b'}
                                onMouseLeave={e => e.currentTarget.style.backgroundColor = '#f08a3b'}>
                                NO
                            </button>
                        </div>
                    </div>
                </div>
            </ModalCover >

        </>
    )
}

export default RowCompanyRelation