import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { Pagination } from '../../../models/pagination';
import { ReasonForDeactivationProps } from '../../check-table/useCheckTable';
import { UserSubstep } from '../../../models/db/steps/userSubstep';
import { UserSubstepService } from '../../../services/db/userSubstep/userSubStep.service';





export const useUserSubstep = () => {

    const getAllIdUserSubstep= (token: any, reasonForDeactivation: ReasonForDeactivationProps[]) => {
        return new Promise<any>((res, rej) => {
            UserSubstepService.getAllId(token, reasonForDeactivation)
                .then(({ data }) => {
                    console.log(data)
                    if (data.ok) {
                        res(data);
                    } else {
                        res(undefined)
                    }
                })
                .catch((e) => {
                    console.log(e)
                    rej(e)
                })

        })
    }

    const getUserSubstepById = (token, id) => {
        return new Promise<any>((res, rej) => {
            UserSubstepService.get(token, id)
                .then(({ data }) => {
                    console.log(data)
                    if (data.ok) {
                        res(data);
                    } else {
                        res(undefined)
                    }
                })
                .catch((e) => {
                    console.log(e)
                    rej(e)
                })

        })
    }


    const getUserSubstep= (token, pagination: Pagination = { page: 1, itemsPerPage: 5 }) => {
        return new Promise<any>((res, rej) => {
            UserSubstepService.getAll(token, pagination)
                .then(({ data }) => {
                    console.log(data)
                    if (data.ok) {
                        res(data);
                    } else {
                        res(undefined)
                    }
                })
                .catch((e) => {
                    console.log(e)
                    rej(e)
                })

        })
    }

    const getUserSubstepAutocomplete = (token, pagination: Pagination = { page: 1, itemsPerPage: 5 }) => {
        return new Promise<any>((res, rej) => {
            UserSubstepService.getAutocomplete(token)
                .then(({ data }) => {
                    // console.log(data)
                    if (data.ok) {
                        res(data);
                    } else {
                        res(undefined)
                    }
                })
                .catch((e) => {
                    console.log(e)
                    rej(e)
                })

        })
    }


    const addUserSubstep= (token, user: UserSubstep) => {
        return new Promise<any>((res, rej) => {
            UserSubstepService.add(token, { ...user })
                .then(({ data }) => {
                    // console.log(data)
                    if (data.ok) {
                        res(data);
                    } else {
                        res(undefined)
                    }
                })
                .catch((e) => {
                    console.log(e)
                    rej(e)
                })

        })
    }


    const updateUserSubstep= (token, user: UserSubstep) => {
        return new Promise<any>((res, rej) => {
            UserSubstepService.update(token, { ...user })
                .then(({ data }) => {
                    console.log(data)
                    if (data.ok) {
                        res(data);
                    } else {
                        res(undefined)
                    }
                })
                .catch((e) => {
                    console.log(e)
                    rej(e)
                })

        })
    }

    const deleteUserSubstep= (token, idList: string[] | number[], action: boolean) => {
        return new Promise<any>((res, rej) => {
            UserSubstepService.delete(token, idList, action)
                .then(({ data }) => {
                    console.log(data)
                    if (data.ok) {
                        res(data);
                    } else {
                        res(undefined)
                    }
                })
                .catch((e) => {
                    console.log(e)
                    rej(e)
                })

        })
    }


    return {
        getAllIdUserSubstep,
        getUserSubstepById,
        getUserSubstep,
        addUserSubstep,
        updateUserSubstep,
        deleteUserSubstep,

        get: getUserSubstep,
        add: addUserSubstep,
        update: updateUserSubstep,
        autocomplete: getUserSubstepAutocomplete,
        delete: deleteUserSubstep
    }
}