import axios from "axios";
import { environment } from "../../../app/environments";
import { Purchase } from "../../models/db/purchase";

export class PaymentService {

    /**
   * Para productos gratuitos (cupón o dado a mano)
   * Solo crea el registro en la tabla
   * @param token 
   * @param item 
   * @returns 
   */
    static addPaymentFree(token, item: Purchase) {

        let headers = {
            'Authorization': `Bearer ${token}`
        };
        return axios.post(`${environment.urlApi}/api/purchases/add`, item, { headers });
    }

    static addPaymentStripeComplete(token, item) {
        let headers = {
            'Authorization': `Bearer ${token}`
        };
        return axios.post(`${environment.urlApi}/api/payments/complete`, item, { headers });
    }

    static addPaymentMultipleStripeComplete(token, item) {
        let headers = {
            'Authorization': `Bearer ${token}`
        };
        return axios.post(`${environment.urlApi}/api/payments/multiple-complete`, item, { headers });
    }

    static cancelSubscription(token: any, idUser: number) {
        let headers = {
            'Authorization': `Bearer ${token}`
        };
        return axios.post(`${environment.urlApi}/api/payments/cancel-subscription`, { idUser }, { headers });
    }
}