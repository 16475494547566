export const COLORS = {

    FILTER_USED: 'rgba(207, 207, 207, 0.8)',

    principalColor: "#3d4f65",
    secondaryColor: "#f08a3b",
    tertiaryColor: "#1d1d26",
    quaternaryColor: '#323343',
    disabledColor: "#474747"

}






