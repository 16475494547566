import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { Pagination } from '../../../models/pagination';
import { ReasonForDeactivationProps } from '../../check-table/useCheckTable';
import { TypeDocument } from '../../../models/db/typeDocument';
import { TypeDocumentService } from '../../../services/db/typeDocument/typeDocument.service';





export const useTypeDocument = () => {

    const getWithDocumentAndDocumentAccessByUserAndCompany = (token: any, idUser: number, idCompany: number) => {
        return new Promise<any>((res, rej) => {
            TypeDocumentService.getWithDocumentAndDocumentAccessByUserAndCompany(token, idUser, idCompany)
                .then(({ data }) => {
                    console.log(data)
                    if (data.ok) {
                        res(data);
                    } else {
                        res(undefined)
                    }
                })
                .catch((e) => {
                    console.log(e)
                    rej(e)
                })

        })
    }

    const getAllIdTypeDocument = (token: any, reasonForDeactivation: ReasonForDeactivationProps[]) => {
        return new Promise<any>((res, rej) => {
            TypeDocumentService.getAllId(token, reasonForDeactivation)
                .then(({ data }) => {
                    console.log(data)
                    if (data.ok) {
                        res(data);
                    } else {
                        res(undefined)
                    }
                })
                .catch((e) => {
                    console.log(e)
                    rej(e)
                })

        })
    }

    const getTypeDocumentById = (token, id) => {
        return new Promise<any>((res, rej) => {
            TypeDocumentService.get(token, id)
                .then(({ data }) => {
                    console.log(data)
                    if (data.ok) {
                        res(data);
                    } else {
                        res(undefined)
                    }
                })
                .catch((e) => {
                    console.log(e)
                    rej(e)
                })

        })
    }


    const getTypeDocument = (token, pagination: Pagination = { page: 1, itemsPerPage: 5 }) => {
        return new Promise<any>((res, rej) => {
            TypeDocumentService.getAll(token, pagination)
                .then(({ data }) => {
                    console.log(data)
                    if (data.ok) {
                        res(data);
                    } else {
                        res(undefined)
                    }
                })
                .catch((e) => {
                    console.log(e)
                    rej(e)
                })

        })
    }

    const getTypeDocumentAutocomplete = (token, pagination: Pagination = { page: 1, itemsPerPage: 5 }) => {
        return new Promise<any>((res, rej) => {
            TypeDocumentService.getAutocomplete(token)
                .then(({ data }) => {
                    // console.log(data)
                    if (data.ok) {
                        res(data);
                    } else {
                        res(undefined)
                    }
                })
                .catch((e) => {
                    console.log(e)
                    rej(e)
                })

        })
    }


    const addTypeDocument = (token, user: TypeDocument) => {
        return new Promise<any>((res, rej) => {
            TypeDocumentService.add(token, { ...user })
                .then(({ data }) => {
                    // console.log(data)
                    if (data.ok) {
                        res(data);
                    } else {
                        res(undefined)
                    }
                })
                .catch((e) => {
                    console.log(e)
                    rej(e)
                })

        })
    }


    const updateTypeDocument = (token, user: TypeDocument) => {
        return new Promise<any>((res, rej) => {
            TypeDocumentService.update(token, { ...user })
                .then(({ data }) => {
                    console.log(data)
                    if (data.ok) {
                        res(data);
                    } else {
                        res(undefined)
                    }
                })
                .catch((e) => {
                    console.log(e)
                    rej(e)
                })

        })
    }

    const deleteTypeDocument = (token, idList: string[] | number[], action: boolean) => {
        return new Promise<any>((res, rej) => {
            TypeDocumentService.delete(token, idList, action)
                .then(({ data }) => {
                    console.log(data)
                    if (data.ok) {
                        res(data);
                    } else {
                        res(undefined)
                    }
                })
                .catch((e) => {
                    console.log(e)
                    rej(e)
                })

        })
    }


    return {
        getWithDocumentAndDocumentAccessByUserAndCompany,
        getAllIdTypeDocument,
        getTypeDocumentById,
        getTypeDocument,
        addTypeDocument,
        updateTypeDocument,
        deleteTypeDocument,

        get: getTypeDocument,
        add: addTypeDocument,
        update: updateTypeDocument,
        autocomplete: getTypeDocumentAutocomplete,
        delete: deleteTypeDocument
    }
}