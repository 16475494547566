

import { CSpinner } from '@coreui/react-pro';
import React, { CSSProperties } from 'react'

interface LoaderProps {
  className?: string;
  style?: CSSProperties;
  styleSpinner?: CSSProperties;
}


const Loader = ({ style, className, styleSpinner }: LoaderProps) => {
  return (
    <div style={style} className={`${className} loader-item`} >
      <CSpinner color={styleSpinner ? "" : "dark"} style={styleSpinner} />
    </div >
    // <div className={`loader-item`} >
    //   <CSpinner color="dark" />
    // </div >

  )
}

export default Loader