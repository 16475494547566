import React, { forwardRef, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { boolean } from 'yup'
import { ConstructionOutlined } from '@mui/icons-material';
import { classNames } from '../../utils/classes-css';
import { useDragDrop } from './hooks/useDragDrop';
import { useAddFileInput } from './hooks/useAddFileInput';
import { FileItem } from './model/FileItem';
import { formatFileAllowedToExt, TypeAlloweds } from './util/format-types';
import { isImage, isVideo } from './util/validators';
import { FileService } from './services/file.service';
import { LoginService } from '../../../core/services/login/login-service';
import { UploadFile } from './model/UploadFile';
import { environment } from '../../environments';
import { FileItemObservableService } from './services/rxJS/fileitem-observable.service';


import { Tooltip } from 'react-tooltip';
import CircularProgressWithLabel from '../circularProgress/CircularProgress';
import { Util } from '../../../core/util/util';
import { cilTrash, cilWarning, cilXCircle } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import { COLORS } from '../../../core/constants/colors';

const MAX_BEFORE_TOOLTIP = 5;

export interface DragDropSimpleProps {
    id: string;
    disabled: boolean;
    directoryNameList: string[];
    maxSizeMB: number;
    typeAllowed: TypeAlloweds[];
    valueDefault?: string;
    /** Avatar, un vídeo, un archivo... */
    isOnlyOneFile?: boolean;
    /** Si quieres mostrar la lista de archivos subidos en este componente */
    showFileList?: boolean,
    /** Dar estilo externo*/
    styleDD?: React.CSSProperties;
    /** Añadir clases externas */
    classDD?: string;


    specialCase?: boolean;


    handleFiles?: (value: any) => void;

}

export interface ErrorDragDropProps {
    size?: boolean;
    type?: boolean;
}


const DragDropSimple = forwardRef((props: DragDropSimpleProps, ref: React.Ref<any>) => {

    const { id, maxSizeMB, disabled, typeAllowed, directoryNameList,
        isOnlyOneFile = false,
        valueDefault,
        styleDD = {},
        classDD = "",
        showFileList = true,
        specialCase = false,


        handleFiles
    } = props;

    // Referencia para verificar si es la inicialización del componente
    const isInitialMount = useRef(true);


    const [error, setError] = useState<ErrorDragDropProps>({ size: false, type: false });
    const [loading, setLoading] = useState<boolean>(false);
    const [fileItemList, setFileItemList] = useState<FileItem[]>([]);
    const [uploadProgress, setUploadProgress] = useState(0);

    useEffect(() => {
        if (valueDefault && !Array.isArray(valueDefault)) {
            setFileItemList([
                {
                    id: "default-img",
                    pathRelative: valueDefault,
                    pathAbsolute: environment.urlApi + valueDefault,
                    name: Util.getFileName(valueDefault)
                }
            ])
        } else if (valueDefault === "") {
            setFileItemList([]);
        } else {
            console.error("El valor por defecto no existe o es un array", valueDefault)
        }

    }, [valueDefault]);

    useEffect(() => {
        (async () => {
            if (isInitialMount.current) {
                isInitialMount.current = false;
            } else {



                await handleUpload(fileItemList);

                if (!specialCase || !handleFiles) {
                    const fileObsService = FileItemObservableService.instance;
                    fileObsService.sendFileItemList({ id, fileItemList });
                } else if (specialCase && handleFiles) {
                    // NUEVO 16/10/23
                    // NUEVO NUEVO
                    handleFiles(fileItemList);
                }
            }
        })();
    }, [fileItemList])

    // Referencia externa

    // Ahora asignamos la función deleteFileItem a la ref:
    React.useImperativeHandle(ref, () => ({
        deleteFileItem
    }));


    /** Se manipula el error mostrado. */
    const handleError = (key, value: boolean) => {
        setError(error => { return { ...error, [key]: value } });
    }


    /** Se manipula el listado de archivos a añadir */
    const handleFileItem = (files: FileItem[]) => {
        if (isOnlyOneFile && files.length > 0) files = [files.pop()];
        setFileItemList(files);
    }

    const deleteFileItem = (e, inx: number, force = false) => {
        if (disabled && !force) return;

        let fileItemListAux = [...fileItemList];
        fileItemListAux.splice(inx, 1);
        setFileItemList(fileItemListAux);

        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }

    }

    // const deleteFileItem

    const handleUpload = async (fileItemList: FileItem[]) => {

        try {
            if (fileItemList.length == 0) return;
            setLoading(true);
            for (const [i, fileItem] of fileItemList.entries()) {
                if (!fileItem.pathAbsolute) {
                    if (fileItemList.length - 1 === i) {
                        await addFile({ file: fileItem.file, directoryNameList }, fileItem);
                        setLoading(false);
                    } else {
                        addFile({ file: fileItem.file, directoryNameList }, fileItem);
                    }
                } else {
                    if (fileItemList.length - 1 === i) {
                        setLoading(false);


                        // NUEVO 16/10/23
                        // NUEVO NUEVO
                        // Por si habia un error con anterioridad, al agregar un nuevo archivo borrar el error
                        setError({ size: false, type: false })
                    }
                }
            }
        } catch (e) {
            console.log(e);
            setLoading(false);
        }
    }

    const addFile = (uploadFile: UploadFile, fileItem: FileItem) => {
        return new Promise<any>(async (res, rej) => {
            const token = await LoginService.getTokenAsync();
            FileService.add(token, uploadFile,
                (progressEvent) => {
                    const percentLoaded = Math.round(
                        (progressEvent.loaded / progressEvent.total) * 100
                    );
                    setUploadProgress(percentLoaded);

                })
                .then(({ data }) => {
                    setUploadProgress(0);

                    if (data.ok) {
                        const pathRelative = `/${data.item[0].destination}/${data.item[0].filename}`
                        fileItem.pathRelative = pathRelative
                        fileItem.pathAbsolute = environment.urlApi + pathRelative
                        res("")
                    } else {
                        res(undefined)
                    }
                })
                .catch(e => {
                    rej(e)
                })
        })
    }


    const inputDragDrop = useRef<HTMLInputElement>(null);

    const {
        isOverDrop,
        handleDrop,
        handleDragOver,
        handleDragLeave,
    } = useDragDrop({ fileItemList, maxSizeMB, typeAllowed, handleError, handleFileItem });

    const {
        onAddFileInput
    } = useAddFileInput({
        reference: inputDragDrop,
        maxSizeMB,
        fileItemList,
        handleError,
        handleFileItem
    });


    // const test = () => {
    //     console.log(inputDragDrop.current.value)
    //     inputDragDrop.current.value = "";
    //     console.log(inputDragDrop.current.value)
    // }





    return (
        <div className={classNames("simple-drop-zone-wp", classDD)} >
            <input
                hidden
                multiple
                accept={typeAllowed.toString()}
                ref={inputDragDrop}
                type="file"
                onChange={(e) => onAddFileInput(e)}
            />
            <div
                className={classNames("simple-drop-zone", isOverDrop || (isOnlyOneFile && fileItemList.length > 0) ? "simple-file-over" : null)}
                style={styleDD}
                onDrop={(e) => !disabled && ((isOnlyOneFile && fileItemList.length === 0) || !isOnlyOneFile) ? handleDrop(e) : null}
                onDragOver={(e) => !disabled && ((isOnlyOneFile && fileItemList.length === 0) || !isOnlyOneFile) ? handleDragOver(e) : null}
                onDragLeave={(e) => !disabled && ((isOnlyOneFile && fileItemList.length === 0) || !isOnlyOneFile) ? handleDragLeave(e) : null}
                onClick={(e) => !disabled && ((isOnlyOneFile && fileItemList.length === 0) || !isOnlyOneFile) ? inputDragDrop.current.click() : null}
            >


                {loading && (
                    <div className="d-flex justify-content-center align-items-center"
                        style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 }}>
                        <CircularProgressWithLabel value={uploadProgress} />
                    </div>
                )}

                {/* {(isOnlyOneFile && fileItemList.length && !loading)
                    ? isImage(fileItemList[0].name)
                        ? (
                            <div>
                                <button className={classNames("btn btn-danger btn-delete btn-sm d-flex justify-content-center align-items-center simple-btn-circle", disabled && 'd-none')}
                                    onClick={(e) => deleteFileItem(e, 0)}>
                                    <CIcon icon={cilTrash} size='xl' />
                                </button>
                                <img src={fileItemList[0].pathAbsolute} alt="imagen" />
                            </div>
                        )
                        : isVideo(fileItemList[0].name)
                            ? (
                                <div>
                                    <button className={classNames("btn btn-danger btn-delete btn-sm d-flex justify-content-center align-items-center simple-btn-circle", disabled && 'd-none')}
                                        onClick={(e) => deleteFileItem(e, 0)}>
                                        <CIcon icon={cilTrash} size='xl' />
                                    </button>
                                    <video className="img-fluid" src={fileItemList[0].pathAbsolute} controls></video>
                                </div>
                            )
                            : (
                                <div>
                                    <button className={classNames("btn btn-danger btn-delete btn-sm d-flex justify-content-center align-items-center simple-btn-circle", disabled && 'd-none')}
                                        onClick={(e) => deleteFileItem(e, 0)}>
                                        <CIcon icon={cilTrash} size='xl' />
                                    </button>
                                    <div>{fileItemList[0].name}</div>
                                </div>
                            )
                    : null
                } */}



                {(isOnlyOneFile && fileItemList.length > 0 && !loading) && (
                    <div className="container-file">
                        <div>
                            <div className="filename-text">
                                {fileItemList[0].name}
                            </div>
                            <div className="filename-link"
                                onClick={() => Util.downloadDocumentBackend(fileItemList[0].pathRelative, fileItemList[0].name)}
                            >
                                Descargar
                            </div>
                        </div>

                        <button
                            className={classNames("btn btn-danger btn-delete btn-sm d-flex justify-content-center align-items-center simple-btn-circle", disabled && 'd-none')}
                            onClick={(e) => deleteFileItem(e, 0)}
                        >
                            <CIcon icon={cilTrash} size='lg' />
                        </button>
                    </div>
                )}





                {!loading && (!isOnlyOneFile || (fileItemList.length === 0 && isOnlyOneFile)) && (
                    <div className="d-flex align-items-center" style={{ width: "100%", fontSize: 12, color: COLORS.secondaryColor, display: "flex", justifyContent: "space-around" }}>
                        <div className="text-dark text-left" style={{ fontSize: 12, color: COLORS.secondaryColor }}>
                            <span style={{ fontSize: 12, color: COLORS.secondaryColor }}>Elige o deja tu archivo</span>
                        </div>
                        <div className="text-dark text-right" style={{ fontSize: 12, color: COLORS.secondaryColor, display: "flex", flexDirection: "column" }}>
                            <div>
                                <span style={{ fontSize: 12, color: COLORS.secondaryColor }}> Max</span> <span style={{ fontSize: 12, color: COLORS.secondaryColor }}>{maxSizeMB}</span> <span style={{ fontSize: 12, color: COLORS.secondaryColor }} >Mb</span>
                            </div>
                            {formatFileAllowedToExt(typeAllowed).length > MAX_BEFORE_TOOLTIP
                                ? <span style={{ marginLeft: 7, color: COLORS.secondaryColor }} id="id-tooltip-types"><span className="text-muted"></span>
                                    {formatFileAllowedToExt(typeAllowed)
                                        .toString()
                                        .substring(0, 20)}...</span>
                                : <span style={{ marginLeft: 7, color: COLORS.secondaryColor }}>{formatFileAllowedToExt(typeAllowed).toString()}</span>
                            }
                        </div>
                    </div>

                )}


            </div>

            {!isOnlyOneFile && showFileList && fileItemList.length > 0 && (
                <div>
                    {fileItemList.map((fileitem, index) => {
                        return (
                            <div key={index}>
                                <button className={classNames("btn btn-sm", disabled && 'd-none')}
                                    onClick={(event) => deleteFileItem(event, index)}>Eliminar</button>
                                <a href={fileitem.pathAbsolute} target="_blank"
                                    className="btn btn-primary btn-download-pdf">
                                    <span className="mr-2">{fileitem.name} </span>
                                    <svg xmlns="http://www.w3.org/2000/svg"
                                        width="20" height="20" viewBox="0 0 20 20">
                                        <g>
                                            <path fill="#ffffff"
                                                d="M15.667 13.167c-0.184 0-0.368-0.070-0.513-0.215-0.293-0.293-0.293-0.768 0-1.061l3.036-3.036h-7.724c-0.414 0-0.75-0.336-0.75-0.75s0.336-0.75 0.75-0.75h7.724l-3.036-3.036c-0.293-0.293-0.293-0.768 0-1.061s0.768-0.293 1.061 0l4.25 4.25c0.293 0.293 0.293 0.768 0 1.061l-4.25 4.25c-0.145 0.145-0.329 0.215-0.513 0.215z"></path>
                                            <path fill="#ffffff"
                                                d="M11.25 19.25h-10c-0.414 0-0.75-0.336-0.75-0.75v-15.5c0-0.414 0.336-0.75 0.75-0.75h7c0.414 0 0.75 0.336 0.75 0.75s-0.336 0.75-0.75 0.75h-6.25v14h9v-4.25c0-0.414 0.336-0.75 0.75-0.75s0.75 0.336 0.75 0.75v5c0 0.414-0.336 0.75-0.75 0.75z"></path>
                                        </g>
                                    </svg>
                                </a>
                            </div>
                        )
                    })}
                </div>
            )}

            {/* Errores que pueden ser mostrados */}
            <div style={{ height: "auto" }}>
                {error.size && (
                    <div className='d-flex align-items-center' style={{ height: "25px", fontSize: 12, color: "red" }}>
                        <CIcon size="sm" icon={cilWarning} />
                        <div className='small' style={{ marginLeft: '5px' }}>
                            Uno de los archivos tiene un tamaño mayor del permitido.
                        </div>
                    </div>
                )}
            </div>

            <div style={{ height: "auto" }}>
                {error.type && (
                    <div className='d-flex align-items-center' style={{ height: "25px", fontSize: 12, color: "red" }}>
                        <CIcon size="sm" icon={cilWarning} />
                        <div className='small' style={{ marginLeft: '5px' }}>
                            Uno de los archivos no es de un tipo permitido.
                        </div>
                    </div>
                )}
            </div>



            <Tooltip style={{ width: 210 }} anchorSelect="#id-tooltip-types"
                content={formatFileAllowedToExt(typeAllowed).toString()} place="bottom" />
        </div>
    )
})


DragDropSimple.displayName = "DragDropSimple";
export default DragDropSimple
