import React, { useEffect, useRef, useState } from 'react';
import CardPaymentForm from '../cardPaymentForm/CardPaymentForm';
import { CRow, CCol } from '@coreui/react-pro';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { DataRegistration } from '../../interfaces/interfaces';
import { DataBasicEncoder } from '../../../../../../core/util/data-basic-encoder';
import { BASE_ROUTE } from '../../../../../../core/constants/route_name';
import { gsap } from 'gsap';
import { COLORS } from '../../../../../../core/constants/colors';

interface PaymentPageModalProps {
    data?: DataRegistration;
    goBack?: (value: boolean) => void;
}


const PaymentPageModal = (props: PaymentPageModalProps) => {

    const navigate = useNavigate();
    const location = useLocation();
    const { data, goBack } = props;


    const [isResponsive, setIsResponsive] = useState(window.innerWidth <= 768);
    const [decodedData, setDecodedData] = useState<DataRegistration>(undefined);


    const backButtonRef = useRef(null);

    useEffect(() => {
        const btn = backButtonRef.current;

        if (btn) {  // Verifica si btn no es null
            const tl = gsap.timeline({ paused: true });

            tl.to(btn, { y: -5, scale: 1.1, duration: 0.2 });
            btn.onmouseenter = () => tl.play();
            btn.onmouseleave = () => tl.reverse();

            // Evento para escuchar el cambio de tamaño de la ventana
            const handleResize = () => {
                setIsResponsive(window.innerWidth <= 768);
            };

            window.addEventListener('resize', handleResize);

            // Limpiar el evento cuando el componente se desmonte
            return () => {
                window.removeEventListener('resize', handleResize);
            };
        }
    }, [backButtonRef]);


    useEffect(() => {
        console.log(data)
        setDecodedData(data)
    }, [data])





    const handleBack = () => {
        gsap.to(backButtonRef.current, { opacity: 0, duration: 0.1 })
            .then(() => {
                goBack(false);
                // navigate(`${BASE_ROUTE}/home`, { replace: true, state: {algo: "mando algo para abrir el step"} });
            });
    };

    return (
        <div style={styles.container}>
            <div style={isResponsive ? styles.responsiveRow : styles.row}>
                {decodedData && decodedData.productList && (
                    <div style={isResponsive ? styles.responsiveHalfScreen : styles.halfScreen}>
                        <button
                            ref={backButtonRef}
                            onClick={handleBack}
                            style={styles.backButton}
                        >
                            Volver
                        </button>

                        {/* Muestra cada producto */}
                        {decodedData.productList.map((product, index) => (
                            <div key={index} style={styles.productContainer}>
                                <h2 style={isResponsive ? styles.responsiveProductName : styles.productName}>
                                    {product.name}
                                </h2>
                                <h3 style={isResponsive ? styles.responsiveProductPrice : styles.productPrice}>
                                    {product.price}€
                                </h3>
                            </div>
                        ))}

                        {/* Muestra el precio total */}
                        <div style={styles.productContainer}>
                            <h3 style={isResponsive ? styles.responsiveProductName : { ...styles.productName, marginTop: 50 }}>
                                Total:
                            </h3>
                            <h3 style={isResponsive ? styles.responsiveTotalPrice : { ...styles.totalPrice, marginTop: 50 }}>
                                {decodedData.productList.reduce((sum, product) => sum + product.price, 0)}€
                            </h3>
                        </div>
                        
                    </div>
                )}

                <div style={isResponsive ? styles.responsiveOtherHalf : styles.otherHalf}>
                    <CardPaymentForm dataRegistration={decodedData} isModal={true} />
                </div>
            </div>
        </div>

    );

};

const styles = {

    productContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        padding: '0 53px'
    },
    productName: {
        color: 'white',
        fontSize: '17px',
        marginBottom: '5px',
        flex: 1, // Ocupa el espacio restante
        textAlign: 'left' // Alinea a la izquierda
    },
    productPrice: {
        color: COLORS.secondaryColor,
        fontSize: '20px',
        textAlign: 'right' // Alinea a la derecha
    },
    responsiveProductName: {
        color: 'white',
        fontSize: '14px',
        marginBottom: '3px',
        flex: 1, // Ocupa el espacio restante
        textAlign: 'left' // Alinea a la izquierda
    },
    responsiveProductPrice: {
        color: COLORS.secondaryColor,
        fontSize: '16px',
        textAlign: 'right' // Alinea a la derecha
    },
    responsiveTotalPrice: {
        color: COLORS.secondaryColor,
        fontSize: '16px',
        textAlign: 'right' // Alinea a la derecha
    },
    totalPrice: {
        color: COLORS.secondaryColor,
        fontSize: '20px',
        textAlign: 'right' // Alinea a la derecha
    },







    container: {
        width: '100%',
        height: '100vh',

        overflowX: 'hidden',
    },
    row: {
        display: 'flex',
        width: '100%',
        height: '100%',
        margin: 0,
        opacity: 1,
    },
    halfScreen: {
        backgroundColor: COLORS.tertiaryColor,

        width: '50%',
        height: '100vh',
        overflowY: 'auto',
        color: 'white',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center', // Centrar contenido verticalmente
        alignItems: 'center',     // Centrar contenido horizontalmente,

    },
    otherHalf: {
        backgroundColor: "whitesmoke",

        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '50%',
        height: '100%',
        overflowY: 'auto',
        padding: '20px'
    },

    responsiveRow: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        opacity: 1,

    },
    responsiveHalfScreen: {
        backgroundColor: COLORS.tertiaryColor,

        width: '100%',
        height: '30vh',
        overflowY: 'auto',
        color: 'white',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
 
    responsiveOtherHalf: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '70vh',
        overflowY: 'auto',
        backgroundColor: "whitesmoke",

        padding: '20px'
    },

    backButton: {
        marginBottom: '20px',
        padding: '10px',
        cursor: 'pointer',
        background: 'none',
        border: 'none',
        fontSize: '16px',
        color: 'white',
        transition: 'opacity 0.3s',

    },
   




} as any;



export default PaymentPageModal;
