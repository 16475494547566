import { cilArrowLeft } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import { CCard, CCardHeader, CCol, CCardBody, CRow } from "@coreui/react-pro";
import { Snackbar } from "@mui/material";
import { useState, useContext, useEffect } from "react";
import { useNavigate, useLocation, useParams, Link } from "react-router-dom";
import { BASE_ROUTE, ROUTE_NAME } from "../../../../core/constants/route_name";
import { useUser } from "../../../../core/hooks/db/user/useUser";
import { User } from "../../../../core/models/db/user";
import { LoginService } from "../../../../core/services/login/login-service";
import { LoaderContext } from "../../../components/loader/context/LoaderContext";
import { useSnackbar } from "../../../components/snackbar/hook/useSnackbar";
import UserForm from "../UserForm";
import { DataBasicEncoder } from "../../../../core/util/data-basic-encoder";


const LABEL_PAGE = "Usuarios del sistema"

interface UserFormDetailProp {
  /** Que exista esta función significa que se está usando desde un modal */
  handleModal?: (value: boolean) => void;
  idFromModal?: number;
}



/**
 * Este es un componente copy&paste de UserFormDetail, aunque posteriormente
 * se le ha eliminado parte del código.
 * 
 * Solamente se usa en el nav para editar el usuario
 * @param props 
 * @returns 
 */
const UserFormDetailFromNav = (props: UserFormDetailProp) => {

  const navigate = useNavigate();
  const location = useLocation();
  const stateLocation = location.state;

  const { idFromModal, handleModal } = props;

  /** Para editar el usuario desde enlace (listado) */
  const { idUser } = useParams();

  const { getUserById, addUser, updateUser, checkPassword, checkUsername } = useUser();

  const [user, setUser] = useState<User>({
    username: "",
    password: "",
    priv_confirmPassword: "",
    image: "",
    email: "",
    blocked: false,
    idRoleFk: undefined,
  })

  const { handleLoading, changeStyle } = useContext(LoaderContext);
  const [loadingButton, setLoadingButton] = useState(false);
  const [typeAction, setTypeAction] = useState<"add" | "update">(null);

  const [token, setToken] = useState(null);

  const { state, handleSnackbar } = useSnackbar({
    color: "",
    wait: 3000,
    message: "",
    changeState: false,
  })

  /**
* Se usa al comienzo de cargar la página para rellenar la tabla
*/
  useEffect(() => {
    (async () => {
      changeStyle({ backgroundColor: "rgba(0, 0, 0, 0.5)" })
      handleLoading(true);
      const token = await LoginService.getTokenAsync();
      setToken(token)

      if (idFromModal && handleModal) {
        let result = await getUserById(token, idUser || idFromModal)
        if (result && result.ok) {
          console.log("what is the result", result)
          let user = result.item;
          user.password = "";
          user.priv_confirmPassword = "";
          delete user.token;
          delete user.roles;
          delete user.createdDate;
          delete user.deletedDate;
          delete user.updatedDate;
          setUser(user);
          setTypeAction("update");
        } else {
          console.warn("No se encontró ningún id");
          navigate(BASE_ROUTE + ROUTE_NAME.usuariosCrear);
        }
      } else {
        console.warn("No se encontró ningún id");
        debugger;

        navigate(BASE_ROUTE + ROUTE_NAME.usuariosCrear);
      }

      handleLoading(false);
    })()
  }, []);

  /**
   * Comprueba que el username está libre
   * @param id 
   * @param password 
   * @returns 
   */
  const handleCheckUsername = async (id, username) => {
    if (!token) {
      console.warn("No se ha proporcionado token");
      return;
    };

    const result = await checkUsername(token, id, username);
    if (result && result.ok) {
      return result.item;
    } else {
      return false;
    }
  }


  const handleCheckPassword = async (id, password) => {
    if (!token) {
      console.warn("No se ha proporcionado token");
      return;
    };
    const result = await checkPassword(token, id, password);
    if (result && result.ok) {
      return result.item;
    } else {
      return false;
    }
  }



  /**
  * Crea el usuario
  * @param user 
  */
  const update = async (user: User) => {
    try {

     
      let u = { ...user };
      delete u.priv_confirmPassword;
      setLoadingButton(true);
      if (!u.password || u.password === '') {
        delete u.password;
      }

      const result = await updateUser(token, u);
      console.log("1")

      if (result && result.ok) {
        handleSnackbar("El registro se ha actualizado", "success");
        console.log("2")
        let identity = await LoginService.getIdentityAsync();
        if (u.id === identity.idUser) {
          // TODO: Antiguo LS de identity
          // localStorage.setItem("identity", JSON.stringify({
          //     ...identity,
          //     username: u.username,
          //     idRole: u.idRoleFk,
          //     avatar: u.image,
          // }))

          const encode = DataBasicEncoder.encode({
            ...identity,
            username: u.username,
            idRole: u.idRoleFk,
            avatar: u.image,
          });

          localStorage.setItem("identity", encode);
        }

        setTimeout(() => {
          setLoadingButton(false);
          // setUser({
          //   ...u,
          //   priv_confirmPassword: ''
          // })
          if (handleModal) {
            handleModal(false)
          } else {
            navigate("/dashboard/usuarios");
          }
        }, 1600)
      }

    } catch (e) {
      handleSnackbar("Ha habido un error al crear el registro", "success");
      setLoadingButton(false);
      console.error(e)
    }
  }

  return (
    <>
      <Snackbar {...state} />

      <CCardBody>
        <CRow>
          {user && typeAction && (
            <UserForm
              loadingButton={loadingButton}
              user={user}
              typeAction={'update'}
              update={update}
              checkPassword={handleCheckPassword}
              checkUsername={handleCheckUsername}
              isModal={true}
            />
          )}

        </CRow>
      </CCardBody>
      {/* <CCardFooter>
          <CRow xs={{ cols: 1 }} md={{ cols: 5 }} className="text-center">
            Footer
          </CRow>
        </CCardFooter> */}
      <CRow>

      </CRow>
    </>
  )
}

export default UserFormDetailFromNav



