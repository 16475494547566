import React from 'react';
import { Navigate } from "react-router-dom";
import { LoginService } from '../../../core/services/login/login-service';
import { useState, useEffect } from 'react';

interface GuardRouteProps {
    hasAccess: boolean;
    redirectPath?: string;
    children: JSX.Element;
    isAllowed?: boolean;

    roles?: any;
}

/**
 * Encargado de proteger las rutas
 * @param param0 
 * @returns 
 */
// const GuardRoute = ({
//     roles,
//     hasAccess,
//     isAllowed,
//     children,
//     redirectPath = "/login"
// }: GuardRouteProps) => {
//     const identity = localStorage.getItem("identity");
//     let idRole = identity
//         ? JSON.parse(localStorage.getItem("identity"))
//             ? JSON.parse(localStorage.getItem("identity")).idRole
//             : undefined
//         : undefined;
//     if (!hasAccess) {
//         return <Navigate to="/login" replace />;
//         // } else if (!isAllowed) {
//         //     console.log("identity test", localStorage.getItem("identity"))
//         //     return <Navigate to={redirectPath} replace />;
//         // }
//     } else if (!roles.includes(idRole)) {
//         return <Navigate to={redirectPath} replace />;
//     }
//     return children;
// };

// const GuardRoute = async ({
//     roles,
//     hasAccess,
//     isAllowed,
//     children,
//     redirectPath = "/login"
// }: GuardRouteProps) => {
//     try {
//         const identity = await LoginService.getIdentityAsync();
//         const idRole = identity.idRole
//         if (!hasAccess) {
//             return <Navigate to="/login" replace />;
//             // } else if (!isAllowed) {
//             //     console.log("identity test", localStorage.getItem("identity"))
//             //     return <Navigate to={redirectPath} replace />;
//             // }
//         } else if (!roles.includes(idRole)) {
//             return <Navigate to={redirectPath} replace />;
//         }
//         return children;
//     } catch (e) {
//         console.error(e);
//     }
// };



const GuardRoute: React.FC<GuardRouteProps> = ({
    roles,
    hasAccess,
    isAllowed,
    children,
    redirectPath = "/login"
}) => {
    const [content, setContent] = useState<JSX.Element | null>(null);

    useEffect(() => {
        const checkAccess = async () => {
            try {
                const identity = await LoginService.getIdentityAsync();
                const idRole = identity.idRole;

                if (!hasAccess) {
                    setContent(<Navigate to="/login" replace />);
                } else if (!roles.includes(idRole)) {
                    setContent(<Navigate to={redirectPath} replace />);
                } else {
                    setContent(children);
                }
            } catch (e) {
                console.error(e);
                setContent(<Navigate to="/login" replace />);
            }
        };
        checkAccess();
    }, [roles, hasAccess, children, redirectPath]);

    return content;
};

export default GuardRoute;