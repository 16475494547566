
import axios from 'axios';
import { Subject, Observable, BehaviorSubject } from 'rxjs';
import { environment } from '../../../../app/environments';
import { Pagination } from '../../../models/pagination';
import { ReasonForDeactivationProps } from '../../../hooks/check-table/useCheckTable';
import { Product } from '../../../models/db/product';


export class ProductService {

    // PRODUCTOS QUE PUEDE TENER EL USUARIO
    static checkMainProduct(token: any, idUser, idCompany) {
        let headers = { 'Authorization': `Bearer ${token}` };
        return axios.get(`${environment.urlApi}/api/public/products/user/${idUser}/company/${idCompany}/check-main-product`, { headers });
    }

    static checkBundleResidencialProduct(token: any, idUser) {
        let headers = { 'Authorization': `Bearer ${token}` };
        return axios.get(`${environment.urlApi}/api/public/products/user/${idUser}/budle-residencial-product`, { headers });
    }

    static checkFondoEmpresaProduct(token: any, idUser, idCompany) {
        let headers = { 'Authorization': `Bearer ${token}` };
        return axios.get(`${environment.urlApi}/api/public/products/user/${idUser}/company/${idCompany}/fondo-empresa-product`, { headers });
    }

    static checkPagoOficinaProduct(token: any, idUser, idCompany) {
        let headers = { 'Authorization': `Bearer ${token}` };
        return axios.get(`${environment.urlApi}/api/public/products/user/${idUser}/company/${idCompany}/pago-oficina`, { headers });
    }

    // Devolver el MAIN producto. El necesario para acceder a varias opciones del Dashboard
    static getMainProduct(token: any) {
        let headers = { 'Authorization': `Bearer ${token}` };
        return axios.get(`${environment.urlApi}/api/public/products/main-product`, { headers });
    }
    // CRUD

    static get(token: any, id: any) {
        let headers = { 'Authorization': `Bearer ${token}` };
        return axios.get(`${environment.urlApi}/api/products/${id}`, { headers });

    }

    static getAll(token, paginated?: Pagination) {
        let headers = { 'Authorization': `Bearer ${token}` };
        return axios.post(`${environment.urlApi}/api/products/active/pagination`, this._paginate2(paginated), { headers });
    }

    static getAllId(token, reasonForDeactivation: ReasonForDeactivationProps[] = []) {
        let object = { reasonForDeactivation };

        let headers = { 'Authorization': `Bearer ${token}` };
        return axios.post(`${environment.urlApi}/api/products/all-id`, object, { headers });
    }

    static _paginate2(pagination2: Pagination) {

        const { itemsPerPage, page, orderBy, order, wordList, relatedTableOrder, startDate, endDate } = pagination2;

        let obj: any = {};
        if (itemsPerPage !== null && itemsPerPage !== undefined) {
            obj.itemsPerPage = itemsPerPage;
        }

        if (page !== null && page !== undefined) {
            /**
                 * En el backend el paginado empieza por 0, por eso hay que restar
                 * un número
                 */
            if (page == 0) obj.page = page;
            else if (page > 0) obj.page = page - 1;
        }

        if (orderBy !== null && orderBy !== undefined) {
            obj.orderBy = orderBy;
        }

        if (order !== null && order !== undefined) {
            obj.order = order;
        }

        if (relatedTableOrder !== null && relatedTableOrder !== undefined) {
            obj.relatedTableOrder = relatedTableOrder;
        }

        if (wordList !== null && wordList !== undefined) {
            // obj.wordList = wordList.split(",");
            obj.wordList = wordList;
            // url += (url.substring(url.length - 10) === "pagination") ? "?" : "&";
            // url += `wordList=${wordList}`;
        }

        if ((startDate !== null && startDate !== undefined)
            && (endDate !== null && endDate !== undefined)) {
            obj.startDate = startDate;
            obj.endDate = endDate;
        }


        // console.log("Obj completed", obj)

        // console.log(encodeURI(url));
        return obj;
    }

    static add(token, item: Product) {

        let headers = {
            'Authorization': `Bearer ${token}`
        };
        return axios.post(`${environment.urlApi}/api/products/add`, item, { headers });
    }

    static update(token, body: Product) {
        let headers = {
            'Authorization': `Bearer ${token}`
        };
        return axios.post(`${environment.urlApi}/api/products/update`, body, { headers });
    }

    /**
     * Borra el producto
     * El el caso del producto es especial, porque adenmás de mandar el id y el action
     * se manda el stripeProductId y el active para desactivar el producto y luego
     * "eliminarlo" de la base de datos
     */
    static delete(token, object: { active: boolean, stripeProductId: string, list: any[], action: boolean }) {
        let headers = {
            'Authorization': `Bearer ${token}`
        };
        return axios.post(`${environment.urlApi}/api/products/delete`, object, { headers });
    }


}

