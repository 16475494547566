import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { Pagination } from '../../../models/pagination';
import { ReasonForDeactivationProps } from '../../check-table/useCheckTable';
import { MyDocumentService } from '../../../services/db/myDocument/myDocument.service';
import { MyDocument } from '../../../models/db/myDocument';
import { DocumentStep } from '../../../models/db/steps/documentStep';
import { DocumentAccess } from '../../../models/db/documentAccess';


export const useMyDocument = () => {

    const getWithDocumentAccessByUserAndCompany = (token, idUser, idCompany) => {
        return new Promise<any>((res, rej) => {

            console.log("idSUser", idUser, idCompany)
            MyDocumentService.getWithDocumentAccessByUserAndCompany(token, idUser, idCompany)
                .then(({ data }) => {
                    console.log(data)
                    if (data.ok) {
                        res(data);
                    } else {
                        res(undefined)
                    }
                })
                .catch((e) => {
                    console.log(e)
                    rej(e)
                })

        })
    }

    const getAllIdMyDocument = (token: any, reasonForDeactivation: ReasonForDeactivationProps[]) => {
        return new Promise<any>((res, rej) => {
            MyDocumentService.getAllId(token, reasonForDeactivation)
                .then(({ data }) => {
                    console.log(data)
                    if (data.ok) {
                        res(data);
                    } else {
                        res(undefined)
                    }
                })
                .catch((e) => {
                    console.log(e)
                    rej(e)
                })

        })
    }

    const getMyDocumentById = (token, id) => {
        return new Promise<any>((res, rej) => {
            MyDocumentService.get(token, id)
                .then(({ data }) => {
                    console.log(data)
                    if (data.ok) {
                        res(data);
                    } else {
                        res(undefined)
                    }
                })
                .catch((e) => {
                    console.log(e)
                    rej(e)
                })

        })
    }


    const getMyDocument = (token, pagination: Pagination = { page: 1, itemsPerPage: 5 }) => {
        return new Promise<any>((res, rej) => {
            MyDocumentService.getAll(token, pagination)
                .then(({ data }) => {
                    console.log(data)
                    if (data.ok) {
                        res(data);
                    } else {
                        res(undefined)
                    }
                })
                .catch((e) => {
                    console.log(e)
                    rej(e)
                })

        })
    }

    const getMyDocumentAutocomplete = (token, pagination: Pagination = { page: 1, itemsPerPage: 5 }) => {
        return new Promise<any>((res, rej) => {
            MyDocumentService.getAutocomplete(token)
                .then(({ data }) => {
                    // console.log(data)
                    if (data.ok) {
                        res(data);
                    } else {
                        res(undefined)
                    }
                })
                .catch((e) => {
                    console.log(e)
                    rej(e)
                })

        })
    }


    const addMyDocument = (token, user: MyDocument) => {
        return new Promise<any>((res, rej) => {
            MyDocumentService.add(token, { ...user })
                .then(({ data }) => {
                    // console.log(data)
                    if (data.ok) {
                        res(data);
                    } else {
                        res(undefined)
                    }
                })
                .catch((e) => {
                    console.log(e)
                    rej(e)
                })

        })
    }



    const addDocumentWithDocumentStep = (token, document: MyDocument, documentStep: DocumentStep) => {
        return new Promise<any>((res, rej) => {
            MyDocumentService.addWithDocumentStep(token, { ...document }, documentStep)
                .then(({ data }) => {
                    // console.log(data)
                    if (data.ok) {
                        res(data);
                    } else {
                        res(undefined)
                    }
                })
                .catch((e) => {
                    console.log(e)
                    rej(e)
                })

        })
    }

    const addWithDocumentAccess = (token, document: MyDocument, documentAccess: DocumentAccess) => {
        return new Promise<any>((res, rej) => {
            MyDocumentService.addWithDocumentAccess(token, { ...document }, documentAccess)
                .then(({ data }) => {
                    // console.log(data)
                    if (data.ok) {
                        res(data);
                    } else {
                        res(undefined)
                    }
                })
                .catch((e) => {
                    console.log(e)
                    rej(e)
                })

        })
    }


    const updateMyDocument = (token, user: MyDocument) => {
        return new Promise<any>((res, rej) => {
            MyDocumentService.update(token, { ...user })
                .then(({ data }) => {
                    console.log(data)
                    if (data.ok) {
                        res(data);
                    } else {
                        res(undefined)
                    }
                })
                .catch((e) => {
                    console.log(e)
                    rej(e)
                })

        })
    }

    const deleteMyDocument = (token, idList: string[] | number[], action: boolean) => {
        return new Promise<any>((res, rej) => {
            MyDocumentService.delete(token, idList, action)
                .then(({ data }) => {
                    console.log(data)
                    if (data.ok) {
                        res(data);
                    } else {
                        res(undefined)
                    }
                })
                .catch((e) => {
                    console.log(e)
                    rej(e)
                })

        })
    }


    return {
        getWithDocumentAccessByUserAndCompany,
        getAllIdMyDocument,
        getMyDocumentById,
        getMyDocument,
        addMyDocument,
        addDocumentWithDocumentStep,
        addWithDocumentAccess,
        updateMyDocument,
        deleteMyDocument,

        get: getMyDocument,
        add: addMyDocument,
        update: updateMyDocument,
        autocomplete: getMyDocumentAutocomplete,
        delete: deleteMyDocument
    }
}