import { CContainer, CCard, CCardBody, CRow, CCol, CButton, CLoadingButton } from "@coreui/react-pro"
import { COLORS } from "../../../../core/constants/colors"
import { useSelector } from "react-redux";
import { RootState } from "../../../../core/store/store";
import { useMyDocument } from "../../../../core/hooks/db/myDocument/useMyDocument";
import { useEffect, useRef, useState } from "react";
import { LoginService } from "../../../../core/services/login/login-service";
import { MyDocument } from "../../../../core/models/db/myDocument";
import { useTypeDocument } from "../../../../core/hooks/db/typeDocument/typeDocument";
import { TypeDocument } from "../../../../core/models/db/typeDocument";
import { useDownloadfile } from "../../../../core/hooks/file/useDownloadFile";
import { environment } from "../../../environments";
import { Util } from "../../../../core/util/util";
import { cilArrowLeft, cilCloudDownload } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import { useUser } from "../../../../core/hooks/db/user/useUser";
import gsap from 'gsap';
import DocumentPageDocument from "./DocumentPageDocument";
import ButtonLoadingSucessForm from "../../../components/buttonLoadingSucessForm/ButtonLoadingSucessForm";
import { useNavigate, useParams } from "react-router-dom";
import { BASE_ROUTE, ROUTE_NAME } from "../../../../core/constants/route_name";
import WarnNoCompany from "../../../components/warnNoCompany/WarnNoCompany";
import WarnBuyMainProduct from "../../../components/warnBuyMainProduct/WarnBuyMainProduct";
import useCheckMainProduct from "../../../../core/hooks/checkMainProduct/useCheckMainProduct";
import { useCompany } from "../../../../core/hooks/db/company/useCompany";

const DocumentPage = () => {

    const navigate = useNavigate();

    const { id } = useParams();
    const { company: companyRedux } = useSelector((state: RootState) => state.dashboardStep);

    const { deleteMyDocument } = useMyDocument();
    const { getCompanyById } = useCompany();

    const { getWithDocumentAndDocumentAccessByUserAndCompany } = useTypeDocument();
    const { checkAdmin } = useUser();

    const [documentList, setDocumentList] = useState<TypeDocument[]>([]);
    const [isAdmin, setIsAdmin] = useState<boolean>(false);

    const [token, setToken] = useState<any>(undefined);
    const [hasAttemptedLoad, setHasAttemptedLoad] = useState(false);


    const {
        hasMainProduct,
        isLoading: loadingCheckMainProduct,

    } = useCheckMainProduct();

    useEffect(() => {
        (async () => {
            // if (!companyRedux) {
            //     navigate(BASE_ROUTE);
            // }
            await initPage();
        })();
        // (async () => {
        //     if (companyRedux) {
        //         await initPage();
        //     } else if (hasAttemptedLoad) {
        //         // navigate(BASE_ROUTE);
        //     } else {
        //         setHasAttemptedLoad(true);
        //     }
        // })();

    }, [id])

    const initPage = async () => {
        try {
            const identity = await LoginService.getIdentityAsync();
            const isAdmin = identity.idRole === 1;
            const idUser = identity.idUser;

            const token = await LoginService.getTokenAsync();
            setToken(token);
            let ID_COMPANY: any = id ? id : companyRedux?.id;
            const result = await Promise.all([
                getWithDocumentAndDocumentAccessByUserAndCompany(
                    token,
                    null,
                    ID_COMPANY
                ),
                checkAdmin(token),
                getCompanyById(token, ID_COMPANY)
            ]);

            const company = result[2].item;

            if (id && companyRedux && companyRedux.id != +id) {
                console.warn("No existen permisos para hacer esto");
                navigate("/login");
            } else if (company.idUserFk != idUser && !isAdmin) {
                console.warn("No hay permisos para hacer esto");
                navigate("/login");
            } else if (companyRedux && companyRedux.id != company.id) {
                console.warn("No puedes acceder a esta pantalla");
                navigate("/login");
            }


            if (result[0].ok) {
                setDocumentList(result[0].item);
            } else {
                setDocumentList([]);
            }


            setIsAdmin(result[1] && result[1].item === true);

        } catch (e) {
            console.error(e);
        }
    }


    const handleDelete = async (doc: MyDocument) => {
        try {

            let listAux = [...documentList];
            for (const [i, type] of listAux.entries()) {
                for (const [j, dc] of type.documentAccesses.entries()) {
                    if (dc.documents.id === doc.id) {
                        const ONE_ELEMENT = type.documentAccesses.length === 1;
                        if (ONE_ELEMENT) {
                            listAux.splice(i, 1)
                        } else {
                            console.log("more one");
                            type.documentAccesses.splice(j, 1);
                        }
                    }
                }
            }

         
            setDocumentList(listAux);
            const result = await deleteMyDocument(token, [doc.id], true);

        } catch (error) {
            console.error(error);
        }
    }
    const handleFormSubmit = (event) => {
        event.preventDefault();
        navigate(BASE_ROUTE + ROUTE_NAME.documentAccessesCrear);
        // Tu lógica de envío aquí
    };


    if (!loadingCheckMainProduct && !hasMainProduct && companyRedux) {
        return (
            <>
                <WarnBuyMainProduct company={companyRedux} />
            </>
        )
    }

    if (hasAttemptedLoad && !companyRedux) {
        return (
            <WarnNoCompany />
        )
    }

    if (companyRedux) {
        return (
            <CContainer className="mb-4 mt-4">
                <CCard>

                    <CCardBody style={{
                        backgroundColor: COLORS.tertiaryColor,
                        borderRadius: 5,
                        borderColor: COLORS.tertiaryColor
                    }}>

                        {/* <span style={{ color: "white" }}>
                            <pre>{JSON.stringify(documentList, null, 2)}</pre>
                        </span> */}

                        <div style={{ marginTop: 50, marginBottom: 60 }}>
                            <form onSubmit={handleFormSubmit}>

                                <CRow style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "98%" }}>
                                    <CCol sm={6} lg={6}>

                                        <div style={{ color: COLORS.secondaryColor, fontSize: 30, fontWeight: 700, userSelect: "none" }}>Documentos</div>

                                    </CCol>


                                    {isAdmin && (
                                        <CCol xs={6} lg={6} >
                                            <ButtonLoadingSucessForm
                                                primaryColor={COLORS.principalColor}
                                                secondaryColor={COLORS.secondaryColor}
                                                loadingButton={undefined}
                                                className="d-flex d-row"
                                                changeToBackup={undefined}
                                                onSubmit={(e) => { }}
                                                setDisabled={undefined}
                                                typeAction={"add"}
                                                disabled={false}
                                                labelCreated="Nuevo"
                                            />
                                        </CCol>
                                    )}

                                </CRow>

                            </form>
                        </div>


                        <CRow>
                            {documentList.length > 0 && documentList.map((type, itype) => {
                                const { documentAccesses } = type;

                                return (
                                    <CCol key={itype} className="document-section">
                                        <div className="document-title">{type.title}</div>
                                        <div className="document-description">{type.description}</div>
                                        <div className="document-list">
                                            <DocumentPageDocument
                                                documentAccesses={documentAccesses}
                                                onDelete={isAdmin ? handleDelete : undefined}
                                            />

                                        </div>
                                    </CCol>
                                )
                            })}
                        </CRow>

                    </CCardBody>
                </CCard>
            </CContainer >
        )
    }
}

export default DocumentPage