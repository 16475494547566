import { useRef, useEffect } from 'react';

const useHorizontalScroll = () => {
    const elementRef = useRef(null);

    const handleWheel = (e) => {
        if (elementRef.current) {
            elementRef.current.scrollLeft += e.deltaY;
        }
    };

    // useEffect(() => {
    //     const currentElement = elementRef.current;
    //     if (currentElement) {
    //         currentElement.addEventListener('wheel', handleWheel);
    //     }
    //     return () => {
    //         if (currentElement) {
    //             currentElement.removeEventListener('wheel', handleWheel);
    //         }
    //     };
    // }, []);

    useEffect(() => {
        setTimeout(() => {
            const currentElement = elementRef.current;
            if (currentElement) {
                currentElement.addEventListener('wheel', handleWheel);
            }
            return () => {
                if (currentElement) {
                    currentElement.removeEventListener('wheel', handleWheel);
                }
            };
        }, 200)
    }, [elementRef.current]);

    return elementRef;
};

export default useHorizontalScroll;
