import axios from "axios";
import { environment } from "../../../../app/environments";
import { Pagination } from "../../../models/pagination";
import { ReasonForDeactivationProps } from "../../../hooks/check-table/useCheckTable";
import { UserStep } from "../../../models/db/steps/userStep";

export type CheckCompanyType = 'ALL' | 'LATEST' | 'FIRST';


export class UserStepService {

    constructor() { }

    static getDashboard(token, idUser: number, idCompany: number) {
        let headers = {
            'Authorization': `Bearer ${token}`
        };
        return axios.post(`${environment.urlApi}/api/usersteps/dashboard`, { idUser, idCompany }, { headers });
    }

    // checks

    static checkCompanyStatus(token: string, idUser: number, type: CheckCompanyType) {
        switch (type) {
            case 'ALL':
                return this.checkAllCompaniesAccepted(token, idUser);
            case 'LATEST':
                return this.checkLatestCompanyAccepted(token, idUser);
            case 'FIRST':
                return this.checkFirstCompanyAccepted(token, idUser);
            default:
                throw new Error(`Invalid type provided: ${type}`);
        }
    }


    static checkAllCompaniesAccepted(token, idUser: number) {
        let headers = {
            'Authorization': `Bearer ${token}`
        };
        return axios.post(
            `${environment.urlApi}/api/usersteps/check-all-companies-accepted`,
            { idUser },
            { headers }
        );
    }

    static checkLatestCompanyAccepted(token, idUser: number) {
        let headers = {
            'Authorization': `Bearer ${token}`
        };
        return axios.post(
            `${environment.urlApi}/api/usersteps/check-latest-company-accepted`,
            { idUser },
            { headers }
        );
    }

    static checkFirstCompanyAccepted(token, idUser: number) {
        let headers = {
            'Authorization': `Bearer ${token}`
        };
        return axios.post(
            `${environment.urlApi}/api/usersteps/check-first-company-accepted`,
            { idUser },
            { headers }
        );
    }

    static get(token: any, id: any) {
        let headers = { 'Authorization': `Bearer ${token}` };
        return axios.get(`${environment.urlApi}/api/usersteps/${id}`, { headers });
    }

    static getAll(token: any, paginated?: Pagination) {

        let headers = { 'Authorization': `Bearer ${token}` };
        // let completePath = (paginated) ? this._paginate(paginated) : `${environment.urlApi}/api/appointments`;
        return axios.post(`${environment.urlApi}/api/usersteps/pagination`, this._paginate2(paginated), { headers });
    }

    static getAllId(token, reasonForDeactivation: ReasonForDeactivationProps[] = []) {
        let object = { reasonForDeactivation };

        let headers = { 'Authorization': `Bearer ${token}` };
        return axios.post(`${environment.urlApi}/api/usersteps/all-id`, object, { headers });
    }

    static getAutocomplete(token) {

        let headers = { 'Authorization': `Bearer ${token}` };
        return axios.get(`${environment.urlApi}/api/usersteps/autocomplete`, { headers });
    }


    static _paginate2(pagination2: Pagination) {

        const { itemsPerPage, page, orderBy, order, wordList, relatedTableOrder, startDate, endDate } = pagination2;

        let obj: any = {};
        if (itemsPerPage !== null && itemsPerPage !== undefined) {
            obj.itemsPerPage = itemsPerPage;
        }

        if (page !== null && page !== undefined) {
            /**
                 * En el backend el paginado empieza por 0, por eso hay que restar
                 * un número
                 */
            if (page == 0) obj.page = page;
            else if (page > 0) obj.page = page - 1;
        }

        if (orderBy !== null && orderBy !== undefined) {
            obj.orderBy = orderBy;
        }

        if (order !== null && order !== undefined) {
            obj.order = order;
        }

        if (relatedTableOrder !== null && relatedTableOrder !== undefined) {
            obj.relatedTableOrder = relatedTableOrder;
        }

        if (wordList !== null && wordList !== undefined) {
            // obj.wordList = wordList.split(",");
            obj.wordList = wordList;
            // url += (url.substring(url.length - 10) === "pagination") ? "?" : "&";
            // url += `wordList=${wordList}`;
        }

        if ((startDate !== null && startDate !== undefined)
            && (endDate !== null && endDate !== undefined)) {
            obj.startDate = startDate;
            obj.endDate = endDate;
        }


        // console.log("Obj completed", obj)

        // console.log(encodeURI(url));
        return obj;
    }


    static add(token, body: UserStep) {
        let headers = {
            'Authorization': `Bearer ${token}`
        };
        return axios.post(`${environment.urlApi}/api/usersteps/add`, body, { headers });
    }


    static update(token, body: UserStep) {
        let headers = {
            // 'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': `Bearer ${token}`
        };
        return axios.post(`${environment.urlApi}/api/usersteps/update`, body, { headers });
    }

    static delete(token, idList: string[] | number[], action: boolean) {

        let params = { list: idList, action };
        let headers = {
            // 'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': `Bearer ${token}`
        };
        return axios.post(`${environment.urlApi}/api/usersteps/delete`, params, { headers });
    }





}
