import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DSubstep from '../DSubstep/DSubstep';
import { Step } from '../types';

import "./dstep-style.scss";
import { RootState } from '../../../../core/store/store';
import { COLORS } from '../../../../core/constants/colors';
import StatusChip from '../DSubstep/components/components/StatusChip';

interface DStepProps {
  step: Step;
  index: number;
  onClick?: () => void;
}

const DStep: React.FC<DStepProps> = ({ step, onClick, index }) => {
  const dispatch = useDispatch();
  const { currentStepId } = useSelector((state: RootState) => state.dashboardStep);

  return (
    <div
      style={{
        display: "flex",
        // justifyContent: "center",
        alignItems: "center",
        backgroundColor: step.disabled ? COLORS.disabledColor : currentStepId === step.id ? COLORS.tertiaryColor : "#26262f",
        flexDirection: "column",
        width: 260,
        height: 150,
        marginTop: 6,
        borderRadius: 8,
        color: "white",
        boxShadow: "0 1.5px 4px 2px rgba(0, 0, 0, 0.5)",
        // boxShadow: "0px 0px 5px 5px rgba(0,0,0,0.1)",
        userSelect: 'none'

      }}
      onClick={onClick} >
      <div
        style={{ display: "flex", justifyContent: "space-between", width: "100%", color: "white", marginTop: 6 }}>
        <div className='m-1'
          style={{
            fontSize: 12, fontWeight: 600, fontFamily: "Roboto",
            color: step.disabled ? "#2b2b2b" : COLORS.secondaryColor
          }}>PASO {index + 1}
        </div>
        <div className='m-1'>
          <StatusChip status={step.status} />
        </div>


      </div>
      <div style={{
        marginTop: 15,
        width: "100%",
        height: "100%",
        // backgroundColor: "green",
        display: "flex",
        flexDirection: "column",
        padding: "5px 10px",
        // justifyContent: "space-between",
        // alignItems: "center",
        color: step.disabled ? "#2b2b2b" : COLORS.secondaryColor

      }}>
        <div style={{ fontSize: 15 }}>{step.name}</div>
        <div style={{ fontSize: 15 }}>{step.description}</div>
      </div>
    </div >
  );
};



export default DStep;
