import React, { useEffect } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useTypedSelector } from './store'
import {
  CContainer,
  CHeader,
  CHeaderBrand,
  CHeaderDivider,
  CHeaderNav,
  CHeaderToggler,
  CNavLink,
  CNavItem,
} from '@coreui/react-pro'
import { cilApplicationsSettings, cilHome, cilMenu } from '@coreui/icons'
import CIcon from '@coreui/icons-react'

import { AppBreadcrumb } from './index'
import { logo } from '../../assets/brand/logo'
import logoNimo from "../../../core/assets/img/logo_nimo_blanco_transparente.png"

import UserNav from '../userNav/UserNav'
import { RootState } from '../../../core/store/store'
import { COLORS } from '../../../core/constants/colors'




const getRouteObject = (path: string) => {
  switch (true) {
    case /^\/dashboard\/compania\/\d+\/detalle$/.test(path):
      return {
        component: () => <div>Detalle empresa</div>,
        name: 'Detalle empresa',
        icon: <CIcon icon={cilHome} />, // Puedes cambiar el icono según lo necesites
        to: path,
      };
    case /^\/dashboard\/compania\/\d+\/documentos$/.test(path):
      return {
        component: () => <div>Documentos de la empresa</div>,
        name: 'Documentos de la empresa',
        icon: <CIcon icon={cilHome} size='lg' />, // Cambia el icono según lo necesites
        to: path,
      };
    case /^\/dashboard\/home$/.test(path):
      return {
        component: () => <div>Dashboard</div>,
        name: 'Dashboard',
        icon: <CIcon icon={cilHome} size='lg' />,
        to: path,
      };
    case /^\/dashboard\/compania\/\d+\/facturacion$/.test(path):
      return {
        component: () => <div>Facturación</div>,
        name: 'Facturación',
        icon: <CIcon icon={cilHome} size='lg' />,
        to: path,
      };
    default:
      return null;
  }
};


const AppHeader = (): JSX.Element => {

  const location = useLocation();
  const dispatch = useDispatch()
  // const sidebarShow = useTypedSelector((state) => state.sidebarShow)
  // const asideShow = useTypedSelector((state) => state.asideShow)

  const { asideShow, sidebarShow } = useSelector((state: RootState) => state.ui);

  useEffect(() => {
    console.log(location);
  }, [location])


  const routeObject = getRouteObject(location.pathname);

  return (
    // <CHeader position="sticky" className="mb-4">
    <CHeader position="sticky" className="cHeaderWithColor">

      <CContainer fluid>
        <CHeaderToggler
          style={{ color: "white" }}
          className="ps-1"

          onClick={() => dispatch({ type: 'set', sidebarShow: !sidebarShow })}
        >
          <CIcon icon={cilMenu} size="lg" style={{ color: "whitesmoke" }} />
        </CHeaderToggler>

        <CHeaderBrand className="mx-auto d-md-none" >
          {/* Aquí podría ir un logo también */}
          {/* <CIcon icon={logo} height={48} /> */}
        </CHeaderBrand>
        <CHeaderNav className="d-none d-md-flex me-auto" >
          {/* <CNavItem >
            <CNavLink to="/dashboard" component={NavLink} style={{ color: "white" }}>
              Dashboard
            </CNavLink>
          </CNavItem> */}

          {routeObject && (
            <>
              {routeObject.name && <div style={{ fontFamily: "Raleway", fontSize: 18, fontWeight: 500, color: "whitesmoke" }}>{routeObject.name}</div>}
              {/* {routeObject.icon && <div style={{ marginLeft: 5 }}>{routeObject.icon}</div>} */}

              {/* {routeObject.component()} */}
            </>
          )}

        </CHeaderNav>

        <div className="px-md-0 me-md-3">
          <UserNav />
        </div>
        {/* <CHeaderToggler
          className="px-md-0 me-md-3"
          onClick={() => dispatch({ type: 'set', asideShow: !asideShow })}
        >
          <CIcon icon={cilApplicationsSettings} size="lg" />
        </CHeaderToggler> */}
      </CContainer>
      {/* <CHeaderDivider />
      <CContainer fluid>
        <AppBreadcrumb />
      </CContainer> */}
    </CHeader>
  )
}

export default AppHeader
