import { CCol, CLoadingButton, CRow } from "@coreui/react-pro"
import { SubcomponentBasicProp } from "./Subcomponent1";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../core/store/store";
import { LoginService } from "../../../../../core/services/login/login-service";
import { updateStep } from "../../actions/dashboardStep-action";
import { StatusStep, StatusSubstep } from "../../types";
import { useUserSubstep } from "../../../../../core/hooks/db/userSubstep/useUserSubstep";
import { useEffect, useState } from "react";
import { COLORS } from "../../../../../core/constants/colors";

/**
 * Subpaso 1 del paso 7
 */
const Subcomponent8 = (props: SubcomponentBasicProp) => {
    const { showModal, handleModal, substepDashboard } = props;

    // REDUX: Paso y subpaso actual
    const dispatch = useDispatch();
    const { steps, company, idCompany, idUser, currentStepId } = useSelector((state: RootState) => state.dashboardStep);

    const currentStep = steps.find(s => s.id === currentStepId);
    const currentSubstep = substepDashboard;
    const [isAdmin, setIsAdmin] = useState(undefined);



    const [loading, setLoading] = useState(undefined);

    const { updateUserSubstep, getUserSubstepById } = useUserSubstep();

    const [disabledByStatus, setDisabledByStatus] = useState(false);

    useEffect(() => {
        if (currentSubstep && (currentSubstep.status === "EN_REVISION" || currentSubstep.status === "ACEPTADO")) {
            setDisabledByStatus(true);
        } else {
            setDisabledByStatus(false);
        }
    }, [currentSubstep]);


    useEffect(() => {
        (async () => {
            const identity = await LoginService.getIdentityAsync();
            setIsAdmin(identity && identity.idRole === 1);
        })();
    }, []);


    const updateUserStep = async () => {
        try {
            const token = await LoginService.getTokenAsync();
            const ID_EN_REVISION_STATUS = 3;
            const result = await updateUserSubstep(token,
                {
                    id: +currentSubstep.id,
                    idStatusSubstepFk: ID_EN_REVISION_STATUS,
                    comment: "",
                    // commentUser
                })
            if (result && result.ok) {

                let step = currentStep;
                let substep = currentSubstep;

                step.status = StatusStep.EN_REVISION;
                substep.status = StatusSubstep.EN_REVISION;


                dispatch(updateStep(step, substep));
                setLoading(false);
                handleModal(false);

            } else {
                throw Error("Hubo un error", result)
            }
        } catch (error) {
            setLoading(false);

            console.error(error);
            handleModal(false);
        }
    }


    return (
        <div>
            <div style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                // backgroundColor: "pink"
            }}>
                <CCol lg={10}>
                    <p style={{ fontSize: 15, fontFamily: "Raleway", marginTop: 20, }}>
                        <b>Estás en el proceso de inscripción en el registro mercantil.</b> No es necesario realizar ninguna acción en este momento. Por favor, espera a que nuestro equipo administrativo revise tu solicitud.
                    </p>
                </CCol>
            </div>

            {!disabledByStatus && !isAdmin && (
                <CRow>
                    <div style={{ marginTop: 20 }}></div>
                    <CLoadingButton
                        style={{ backgroundColor: COLORS.principalColor }}
                        // color="dark"
                        disabled={loading || disabledByStatus}
                        loading={loading}
                        onClick={() => {
                            updateUserStep()
                        }}>Mandar a revisión</CLoadingButton>
                    <div style={{ marginBottom: 20 }}></div>

                </CRow>
            )}
        </div>
    )
}

export default Subcomponent8

