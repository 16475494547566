import { CCol, CFormLabel, CInputGroup } from "@coreui/react-pro"
import { useEffect, useRef, useState } from "react"
import { Company } from "../../../core/models/db/company"
import Autocomplete from "../autocomplete/Autocomplete"
import { useCompany } from "../../../core/hooks/db/company/useCompany"
import { LoginService } from "../../../core/services/login/login-service"

import "./companyselect-style.scss";
import { useDispatch, useSelector } from "react-redux"
import { setCurrentCompany } from "../@dashboardSteps/actions/dashboardStep-action"
import { Util } from "../../../core/util/util"
import { DataBasicEncoder } from "../../../core/util/data-basic-encoder"
import { gsap } from 'gsap';
import { RootState } from "../../../core/store/store"
import { StatusStep } from "../@dashboardSteps/types"
import { useNavigate } from "react-router-dom"
import { BASE_ROUTE, ROUTE_NAME } from "../../../core/constants/route_name"
import { useUserStep } from "../../../core/hooks/db/userStep/useUserStep"

const CompanySelect = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { sidebarUnfoldable: unfoldable, sidebarShow } = useSelector((state: RootState) => state.ui);
  const { company: companyRedux, steps } = useSelector((state: RootState) => state.dashboardStep);

  const { getCompanyByUser } = useCompany();
  const { checkCompanyStatus } = useUserStep();




  const [companySelected, setCompanySelected] = useState<Company>(undefined);
  const [companyList, setCompanyList] = useState<Company[]>([]);


  const [isOpen, setIsOpen] = useState(false);
  const [allStepsAreAccepted, setAllStepsAreAccepted] = useState<boolean>(false)

  const companyListRef = useRef(null);

  useEffect(() => {
    (async () => {

      await initParam();
    })();
  }, []);


  // useEffect(() => {
  //   if (!steps) {
  //     setAllStepsAreAccepted(false);
  //     return;
  //   };
  //   const pass = steps.every(step => step.status === StatusStep.ACEPTADO);
  //   setAllStepsAreAccepted(pass);
  // }, [steps])



  useEffect(() => {

    if (companyRedux &&
      companySelected &&
      companyList && companyList.length > 0 &&
      companyRedux?.id === companySelected?.id) {
      setCompanySelected({ ...companyRedux });
      setCompanyList(list => {
        let i = list.findIndex(com => com.id === companyRedux.id);
        list[i] = { ...companyRedux };
        return list;
      })
    }

  }, [companyRedux])



  const initParam = async () => {
    try {

      console.log("init select, companySelected", companySelected);
      console.log("init select, companyRedux", companyRedux)

      const identity = await LoginService.getIdentityAsync();
      const token = await LoginService.getTokenAsync();
      const { idUser } = identity;

      const result = await Promise.all([
        getCompanyByUser(token, idUser),
        checkCompanyStatus(token, idUser, "FIRST")
      ])

      // const result = await getCompanyByUser(token, idUser);


      if (result[0] && result[0].ok) {
        setCompanyList(result[0].item);

        let companyBackup = await _companyBackupLS("get");


        setCompanySelected(companyBackup ? companyBackup : result[0].item[0]);
        dispatch(setCurrentCompany(companyBackup ? companyBackup : result[0].item[0]));


        handleSelectCompany(companyBackup ? companyBackup : result[0].item[0]);

        if (companyBackup) {
          console.warn("YA NO BORRO MAS -----------------")
          // _companyBackupLS("remove")
        }
      }

      setAllStepsAreAccepted(result[1] && result[1].item === true)

    } catch (error) {
      console.log(error)
    }
  }


  const _companyBackupLS = async (action: "save" | "get" | "remove", company?: Company) => {
    try {
      let result;
      let identity = await LoginService.getIdentityAsync();
      switch (action) {
        case "save": {
          const currentTime = new Date();
          const expiryTime = new Date(currentTime.getTime() + 10080 * 60 * 1000);
          let expire = expiryTime.toISOString();
          let result = DataBasicEncoder.encode({ company, expire });
          localStorage.setItem(`a_com_store_${company.idUserFk}`, result);
          return company;

        }
        case "get": {
          let ls = localStorage.getItem(`a_com_store_${identity.idUser}`);
          if (ls) {
            let { company, expire }: any = DataBasicEncoder.decode(ls);

            // console.log("COMPAÑIA DECODIFICADA", company, company.idUserFk);
            // console.log("id usuario", identity.idUser);

            if (company.idUserFk === identity.idUser && expire) {

              // console.log("1 aqui")
              const currentTime = new Date();
              const expiryTime: any = new Date(expire);
              if (!expiryTime || expiryTime === "Invalid Date" || currentTime > expiryTime) {
                // console.log("2 aqui")

                localStorage.removeItem(`a_com_store_${identity.idUser}`);
                console.error("Los datos han expirado.");
                return undefined;
              } else {
                // console.log("3 aqui")

                return company;
              }
            } else if (company.idUserFk !== identity.idUser || !expire) {
              // console.log("4 aqui")

              console.error("La compañia es de otro usuario");
              localStorage.removeItem(`a_com_store_${identity.idUser}`);
              return undefined;
            } else {
              // console.log("5 aqui")

              return undefined;
            }
          } else {
            // console.log("6 aqui")

            return undefined;
          }
        }
        case "remove": {
          localStorage.removeItem(`a_com_store_${identity.idUser}`);
          break;
        }
      }

    } catch (e: any) {
      console.error(e);
      return undefined;
    }
  }

  // Manejador de eventos global para cerrar el menú desplegable al hacer clic fuera
  useEffect(() => {
    const handleGlobalClick = (e) => {
      if (!e.target.closest('.company-dropdown')) {
        setIsOpen(false);
      }
    };
    window.addEventListener('click', handleGlobalClick);
    return () => {
      window.removeEventListener('click', handleGlobalClick);
    };
  }, []);


  useEffect(() => {
    if (isOpen) {
      gsap.fromTo(
        companyListRef.current,
        { opacity: 0, y: -15 },
        { opacity: 1, y: 0, duration: 0.5 }
      );
    } else if (companyListRef.current) {
      gsap.to(companyListRef.current, { opacity: 0, y: -15, duration: 0.5 });
    }
  }, [isOpen]);

  // Manejador para abrir/cerrar el menú desplegable
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  }



  const handleSelectCompany = (company: Company) => {
    _companyBackupLS("save", company)

    dispatch(setCurrentCompany(company));
    setCompanySelected(company);
    setIsOpen(false);
  }
  // style={{ backgroundColor: isOpen ? "#213444" : null }}
  return (
    <div className="company-dropdown" >
      {companySelected && (
        <div className="selected-company" onClick={toggleDropdown} >

          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span>
              {!unfoldable
                ? companySelected.companyName
                  ? companySelected.companyName
                  : companySelected.companyNameTest1
                : companySelected.companyName
                  ? companySelected.companyName.slice(0, 2)
                  : companySelected.companyNameTest1.slice(0, 2)}
            </span>
            {!companySelected.companyName && (
              <span style={{ fontSize: 12, marginLeft: 5 }}>
                {!unfoldable ? '(Provisional)' : '(P.)'}
              </span>
            )}
          </div>

          {isOpen ?
            <svg style={{ fill: "white" }} xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><path d="M201.4 137.4c12.5-12.5 32.8-12.5 45.3 0l160 160c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L224 205.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l160-160z" /></svg>
            :
            <svg style={{ fill: "white" }} xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" /></svg>
          }
        </div>
      )}
      {isOpen && (
        <CCol ref={companyListRef}>
          <div className="company-list">
            {companyList?.map((company, index) => (
              <div key={company.id} className="company-item" onClick={() => handleSelectCompany(company)}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <span>
                    {company.companyName ? company.companyName : company.companyNameTest1}
                  </span>
                  {!company.companyName && (
                    <span style={{ fontSize: 12, marginLeft: 5 }}>(Provisional)</span>
                  )}
                </div>

              </div>
            ))}
          </div>

          <div style={{ display: 'flex', flexDirection: "column", alignItems: 'center', backgroundColor: "#213444" }} >
            <button
              disabled={!allStepsAreAccepted}
              className="create-company-btn"
              onClick={() => {
                setIsOpen(false);
                navigate(BASE_ROUTE + ROUTE_NAME.compania);
              }}
              style={{
                backgroundColor: allStepsAreAccepted
                  ? null
                  : "#f3ad78"
              }}>Crear Compañía</button>
            {!allStepsAreAccepted && (
              <div className="company-creation-note">Solo puedes crear más compañías tras completar todos los pasos.</div>
            )}
            <div style={{ marginBottom: 10 }}></div>
          </div>


        </CCol>
      )
      }
    </div >
  )
}

export default CompanySelect

