import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { Pagination } from '../../../models/pagination';
import { ReasonForDeactivationProps } from '../../check-table/useCheckTable';
import { MyDocumentService } from '../../../services/db/myDocument/myDocument.service';
import { MyDocument } from '../../../models/db/myDocument';
import { DocumentStep } from '../../../models/db/steps/documentStep';
import { DocumentStepService } from '../../../services/db/documentStep/documentStep.service';






export const useStepDocument = () => {

    const getByUserAndSubstep = (token: any, documentStep: DocumentStep) => {
        return new Promise<any>((res, rej) => {
            DocumentStepService.getByUserAndSubstep(token, documentStep)
                .then(({ data }) => {
                    console.log(data)
                    if (data.ok) {
                        res(data);
                    } else {
                        res(undefined)
                    }
                })
                .catch((e) => {
                    console.log(e)
                    rej(e)
                })

        })
    }




    return {
        getByUserAndSubstep

    }
}