import { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useCompany } from "../../../../../core/hooks/db/company/useCompany";
import { useMyDocument } from "../../../../../core/hooks/db/myDocument/useMyDocument";
import { useStepDocument } from "../../../../../core/hooks/db/stepDocument/useStepDocument";
import { useUser } from "../../../../../core/hooks/db/user/useUser";
import { useUserSubstep } from "../../../../../core/hooks/db/userSubstep/useUserSubstep";
import { MyDocument } from "../../../../../core/models/db/myDocument";
import { RootState } from "../../../../../core/store/store";
import { SubcomponentBasicProp } from "./Subcomponent1";
import { useCompanyRelation } from "../../../../../core/hooks/db/companyRelation/useCompanyRelation";
import RowCompanyRelation from "./components/RowCompanyRelation";
import { LoginService } from "../../../../../core/services/login/login-service";
import { CompanyRelation } from "../../../../../core/models/db/companyRelation";
import { CCol, CLoadingButton, CRow, CSpinner } from "@coreui/react-pro";
import { useProduct } from "../../../../../core/hooks/db/product/useProduct";
import { Product } from "../../../../../core/models/db/product";
import { DataRegistration } from "../../../../pages/@external-pages/registationAndPayment/interfaces/interfaces";
import PaymentPageModal from "../../../../pages/@external-pages/registationAndPayment/stripe/PaymentPageModal/PaymentPageModal";
import { DataBasicEncoder } from "../../../../../core/util/data-basic-encoder";
import { Util } from "../../../../../core/util/util";
import { User } from "../../../../../core/models/db/user";
import { updateStep } from "../../actions/dashboardStep-action";
import { StatusStep, StatusSubstep } from "../../types";
import { COLORS } from "../../../../../core/constants/colors";


/**
 * Subpaso 1 del paso 4
 * @param props 
 * @returns 
 */
const Subcomponent4 = (props: SubcomponentBasicProp) => {
  const { showModal, handleModal, substepDashboard } = props;

  // REDUX: Paso y subpaso actual
  const dispatch = useDispatch();
  const { steps, company, idCompany, idUser, currentStepId } = useSelector((state: RootState) => state.dashboardStep);

  const currentStep = steps.find(s => s.id === currentStepId);
  const currentSubstep = substepDashboard;

  const {
    getCompanyRelationByCompany,
    addCompanyRelation,
    updateCompanyRelation,
    deleteCompanyRelation, } = useCompanyRelation();
  const { checkFondoEmpresaProduct } = useProduct();
  const { getById: getUserById, updateUser } = useUser();
  const { updateUserSubstep } = useUserSubstep();




  const { checkAdmin } = useUser();


  const [identity, setIdentity] = useState<any>(undefined);
  const [isAdmin, setIsAdmin] = useState(undefined);
  const [user, setUser] = useState<User>(undefined);
  const [loading, setLoading] = useState(undefined);

  // Pasarela de pago
  const [productList, setProductList] = useState<Product[]>(undefined);
  const [isPayment, setIsPayment] = useState<any>(undefined);
  // --- Objeto para componente PaymentModal
  const [decodedData, setDecodedData] = useState<DataRegistration>(undefined);
  // --- Si ha hecho la compra
  const [hasMadePurchases, setHasMadePurchases] = useState<boolean>(false);

  /**
   * Registros creados para la tabla companyRelations
   */
  const [rowForm, setRowForm] = useState<CompanyRelation[]>([]);
  const [deletedRows, setDeletedRows] = useState<CompanyRelation[]>([]);
  // Después de comprobar si los inputs de todos los rows son válidos
  const [isChildDataValid, setIsChildDataValid] = useState(false);


  const [disabledByStatus, setDisabledByStatus] = useState(false);
  const [totalPercentage, setTotalPercentage] = useState<number>(0);


  useEffect(() => {
    if (!isPayment) {
      (async () => {
        await initPage();
      })();
    }
  }, [isPayment])

  const initPage = async () => {
    try {
      setLoading(true);
      const identity = await LoginService.getIdentityAsync();
      const token = await LoginService.getTokenAsync();
      setIdentity(identity);

      const result = await Promise.all([
        checkAdmin(token),
        getCompanyRelationByCompany(token, company.id),
        checkFondoEmpresaProduct(token, idUser, company.id),
        getUserById(token, idUser)
      ]);

      setIsAdmin(result[0] && result[0].item === true);
      setLoading(false);


      if (result[1] && result[1].ok) {
        setRowForm(previous => [...result[1].item])
      }

      // products and purchases
      if (result[2] && result[2].ok) {
        let itemList: Product[] = result[2].item;
        setProductList(itemList);

        let count = 0;
        for (const [i, item] of itemList.entries()) {
          if (item.purchases.length > 0) {
            count += 1;
          }
          if (itemList.length - 1 === i && count === 1) {
            setHasMadePurchases(true);
          }
        }
      } else {
        throw Error("No hay productos")
      }

      if (result[3] && result[3].ok) {
        const { id, ...rest } = result[3].item;
        setUser(rest);
      } else {
        throw Error("No hay usuario")
      }


    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  }

  useEffect(() => {
    calculateTotalPercentage();
  }, [rowForm, deletedRows]);


  const calculateTotalPercentage = () => {
    const total = rowForm.reduce((acc, row) => acc + (+row.companyPercentage || 0), 0);
    setTotalPercentage(total);
  };

  /**
  * Modifica el registro en la base de datos
  * @param token 
  * @param id 
  */
  const handleUpdateCompanyRelation = async (token: any, companyRelation: CompanyRelation) => {
    try {
      const result = await updateCompanyRelation(token, companyRelation);
      if (result && result.ok) {

      }

    } catch (e) {
      console.error(e);
    }
  }

  /**
  * Crea el registro en la base de datos
  * @param token 
  * @param id 
  */
  const handleAddCompanyRelation = async (token: any, companyRelation: CompanyRelation) => {
    try {
      const result = await addCompanyRelation(token,
        {
          ...companyRelation,
          idCompanyFk: company.id,
          relationType: "partner",
        });
      if (result && result.ok) {

      }

    } catch (e) {
      console.error(e);
    }
  }

  /**
   * Borra el registro en la base de datos
   * @param token 
   * @param list 
   */
  const handleDeleteCompanyRelation = async (token: any, list: number[]) => {
    try {
      const IS_DELETE = true;
      const result = await deleteCompanyRelation(token, list, IS_DELETE);
      if (result && result.ok) {

      }

    } catch (e) {
      console.error(e);
    }
  }

  const handleCompanyOperations = async () => {
    try {
      setLoading(true);

      await submit();
      await updateUserStep();

      handleModal(false);

    } catch (error) {
      console.error("Error al procesar las operaciones:", error);
      handleModal(false);

    } finally {
      setLoading(false);
    }
  };


  /**
   * Centraliza la modificación en la base de datos de todos los rows
   */
  const submit = async () => {
    const token = await LoginService.getTokenAsync();
    // setLoading(true);


    const addRowPromises = rowForm.filter(row => !row.id).map(row => handleAddCompanyRelation(token, row));
    const updateRowPromises = rowForm.filter(row => row.id).map(row => handleUpdateCompanyRelation(token, row));
    const deleteRowPromises = [handleDeleteCompanyRelation(token, deletedRows.filter(row => row.id).map(row => row.id))];


    try {
      await Promise.all([...addRowPromises, ...updateRowPromises, ...deleteRowPromises]);

      // Después de que todas las operaciones han terminado, haces el get para obtener todos los datos actualizados.
      const resultGet = await getCompanyRelationByCompany(token, company.id);

      if (resultGet && resultGet.ok) {
        setDeletedRows([]);
        setRowForm([...resultGet.item]);
      } else {
        throw Error(resultGet);
      }
      // setLoading(false);

    } catch (error: any) {
      console.error("Error al procesar las operaciones:", error);
      throw Error(error);
      // setLoading(false);
      // handleModal(false)

    }

  }


  const updateUserStep = async () => {
    try {
      const token = await LoginService.getTokenAsync();
      const ID_EN_REVISION_STATUS = 3;
      const result = await updateUserSubstep(token,
        {
          id: +currentSubstep.id,
          idStatusSubstepFk: ID_EN_REVISION_STATUS,
          comment: ""
        })
      if (result && result.ok) {

        let step = currentStep;
        let substep = currentSubstep;

        // step.status = StatusStep.EN_REVISION;
        // substep.status = StatusSubstep.EN_REVISION;
        // dispatch(updateStep(step, substep));

        // Hay que tener en cuenta que los steps pueden tener varios substeps
        let index = step.substeps.findIndex(sub => sub.id === currentSubstep.id);
        // Le cambiamos el estado antes de pasar por el loop
        step.substeps[index].status = StatusSubstep.EN_REVISION;

        if (step.substeps.length > 1) {
          for (let row of step.substeps) {

            if (currentStep.id !== row.id) {
              if (row.status === "EN_REVISION") {
                step.status = StatusStep["EN_REVISION"];
                break;
              } else if (row.status === "RECHAZADO") {
                step.status = StatusStep["RECHAZADO"];
                break;
              } else if (row.status === "REQUERIDO") {
                step.status = StatusStep["PENDIENTE"];
                break;
              } else {
                step.status = StatusStep.EN_REVISION;
              }
            }
          }
        } else {
          step.status = StatusStep.EN_REVISION;
        }
        substep.status = StatusSubstep.EN_REVISION;

        dispatch(updateStep(step, substep));

        // handleModal(false);

      } else {
        throw Error("Hubo un error", result)
      }
    } catch (error: any) {
      throw Error(error)

      // console.error(error);
      // handleModal(false);
    }
  }

  /**
   * Comprueba si es valido todos los formularios
   * @param isValid 
   */
  const handleChildValidityChange = (isValid) => {
    setIsChildDataValid(isValid);
  };

  /**
   * Agrega un nuevo row 
   * @param newRow 
   */
  const handleRowAddition = (newRow: CompanyRelation) => {
    setRowForm(prevState => [...prevState, newRow]);
  }

  /**
   * Borra el registro
   * @param index 
   * @param rowToDelete 
   */
  const handleRowDeletion = (index, rowToDelete: CompanyRelation) => {

    console.log("HAS ENTRADO EN BORRAR")
    if (rowToDelete.relationType === "owner") {
      /**
       * Actualmente el owner es el creador de la empresa. No se puede borrar
       */
      console.warn("Se ha intentado borrar un owner");
      return;
    }

    let aux = [...rowForm];
    let itemDeleted = aux.splice(index, 1);
    setRowForm([...aux]);

    if (itemDeleted.length === 1 && itemDeleted[0].id) {
      setDeletedRows(prevState => [...prevState, itemDeleted[0]]);
    }


    // 16/10/23 Necesario para que refresque bien el listado
    setLoading(true);
    setTimeout(() => {
      setLoading(false)
    }, 100)
  }


  /**
   * Actualiza en padre lo que ocurre en los formularios hijo
   * @param index 
   * @param item 
   */
  const handleRowChange = (index, item) => {
    const newRowForm = [...rowForm];
    newRowForm[index] = item;
    setRowForm(newRowForm);
  }


  // REF
  const dragDropRef = useRef(null);

  useEffect(() => {
    if (currentSubstep && (currentSubstep.status === "EN_REVISION" || currentSubstep.status === "ACEPTADO")) {
      setDisabledByStatus(true);
    } else {
      setDisabledByStatus(false);
    }
  }, [currentSubstep]);






  const goPayment = async () => {
    let data: DataRegistration = {} as any;
    const token = await LoginService.getTokenAsync();

    // company.idUserFk = data.user.id;
    data.user = {
      email: user.email,
      username: user.username,
      id: idUser
    }
    // let result: any = await checkBundleResidencialProduct(token)
    // if (result && result.ok) {
    //   data.productList = [...result.item];
    // }
    data.productList = [...productList];
    // Agrega 20 minutos al tiempo actual
    const currentTime = new Date();
    const expiryTime = new Date(currentTime.getTime() + 12000000 * 60 * 1000);
    data.expire = expiryTime.toISOString();
    data.isPaymentCorrect = false;
    data.isPaymentError = false;
    data.company = company
    await Util.delay(100);

    const encodedData = encodeURIComponent(DataBasicEncoder.encode(data));
    setDecodedData(data);
    setIsPayment(true)

  }






  return (
    <div style={{ width: "100%" }}>
      {loading && (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '300px' }}>
          <CSpinner />
        </div>
      )}


      {/* <span>Subcomponent4</span> */}
      {/* <pre>{JSON.stringify(isChildDataValid, null, 2)}</pre>
      <pre>{JSON.stringify(rowForm, null, 2)}</pre> */}

      {/* <pre>{JSON.stringify(disabledByStatus, null, 2)}</pre> */}
      {!isPayment && (
        <>
          <div>
            {rowForm && !loading && rowForm.map((companyRelation, index) => (

              <>
                <RowCompanyRelation
                  disabled={disabledByStatus || isAdmin}
                  key={index}
                  index={index}
                  onValidityChange={handleChildValidityChange}
                  onDelete={identity.idUser == companyRelation.idUserFk ? undefined : handleRowDeletion}
                  companyRelation={companyRelation}

                  onDataChange={handleRowChange}
                />

                <div style={{ marginBottom: 20 }}></div>

              </>
            ))}

            <div style={{ fontSize: '30px', fontWeight: 'bold', marginBottom: '20px', fontFamily: "Roboto, Arial, sans-serif" }}>
              Porcentaje Compartido: <span style={{ color: totalPercentage !== 100 ? "red" : COLORS.principalColor, }}>{totalPercentage.toLocaleString('es-ES')}%</span>

            </div>
          </div>


          <div style={{ display: "flex", justifyContent: "flex-end", width: "98%" }}>
            <CLoadingButton
              style={{ backgroundColor: COLORS.principalColor }}
              // color="dark"
              disabled={
                (isAdmin)
                || (!isAdmin && disabledByStatus)

              }
              loading={null}

              onClick={() => handleRowAddition(new CompanyRelation())}
            >
              Añadir socio
            </CLoadingButton>

            {/* <CLoadingButton
              style={{ marginLeft: 10 }}
              color="dark"
              disabled={
                (isAdmin)
                || (!isAdmin && disabledByStatus)
                || !isChildDataValid
              }

              loading={null}

              onClick={submit}
            >
              Guardar cambios
            </CLoadingButton> */}
          </div>



          <div style={{ marginTop: 100 }}></div>

          <div style={{ display: "flex", marginBottom: 10, }}>
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", backgroundColor: hasMadePurchases ? COLORS.principalColor : "lightcoral", padding: "5px 10px", borderRadius: 5 }}>
              {hasMadePurchases ?
                (
                  <>
                    <svg fill={"white"} xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" /></svg>
                    <span style={{ marginLeft: 5, color: "white" }}>Compra realizada</span>
                  </>
                )
                :
                (
                  <>
                    <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM175 175c-9.4 9.4-9.4 24.6 0 33.9l47 47-47 47c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l47-47 47 47c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-47-47 47-47c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-47 47-47-47c-9.4-9.4-24.6-9.4-33.9 0z" /></svg>
                    <span style={{ marginLeft: 5 }}>No se ha hecho la compra</span>
                  </>
                )
              }
            </div>
          </div>

          <div style={{ display: "flex", marginBottom: 10, }}>
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", backgroundColor: totalPercentage === 100 ? null : "lightcoral", padding: "5px 10px", borderRadius: 5 }}>
              {/* {totalPercentage === 100 ?
                (
                  <>
                    <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" /></svg>
                    <span style={{ marginLeft: 5 }}>Compra realizada</span>
                  </>
                )
                :
                (
                  <>
                    <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM175 175c-9.4 9.4-9.4 24.6 0 33.9l47 47-47 47c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l47-47 47 47c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-47-47 47-47c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-47 47-47-47c-9.4-9.4-24.6-9.4-33.9 0z" /></svg>
                    <span style={{ marginLeft: 5 }}>No se ha hecho la compra</span>
                  </>
                )
              } */}
              {totalPercentage !== 100 && (
                <>
                  <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM175 175c-9.4 9.4-9.4 24.6 0 33.9l47 47-47 47c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l47-47 47 47c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-47-47 47-47c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-47 47-47-47c-9.4-9.4-24.6-9.4-33.9 0z" /></svg>
                  <span style={{ marginLeft: 5 }}>El porcentaje debe ser del 100%</span>
                </>
              )}
            </div>
          </div>
          {/* <pre>{JSON.stringify(deletedRows, null, 2)}</pre> */}



          <div style={{ display: "flex", justifyContent: "space-between" }}>
            {!disabledByStatus && (identity && identity.idRole !== 1) && (
              <CLoadingButton
                style={{ backgroundColor: COLORS.principalColor }}
                // color="dark"
                disabled={loading || disabledByStatus || (!isChildDataValid && rowForm.length > 1) || totalPercentage != 100 || !hasMadePurchases}
                loading={loading}
                onClick={() => {
                  // console.log("Mandando a revisión... sin hacer")
                  // updateUserStep()
                  /**
                   * NUEVO 16/10/23 He unificado dos funciones para evitar el uso
                   * de varios botones
                   */
                  handleCompanyOperations();
                }}>Mandar a revisión</CLoadingButton>
            )}

            {/* Ocultar el botón de compra si se ha comprado o está en revisión */}
            {!disabledByStatus && !hasMadePurchases && (identity && identity.idRole !== 1) && (
              <CLoadingButton
                style={{ backgroundColor: COLORS.principalColor }}
                // color="dark"
                disabled={hasMadePurchases}
                // disabled={loading || typeFilesList.filter(item => item.uploaded).length === countLimit || !typeFileSelected || filesAlreadyUploaded.length === 0}
                loading={loading}
                onClick={() => {
                  goPayment();
                }}>
                <>
                  <svg
                    style={{ fill: "white" }}
                    xmlns="http://www.w3.org/2000/svg"
                    height="1em"
                    viewBox="0 0 576 512"><path d="M0 24C0 10.7 10.7 0 24 0H69.5c22 0 41.5 12.8 50.6 32h411c26.3 0 45.5 25 38.6 50.4l-41 152.3c-8.5 31.4-37 53.3-69.5 53.3H170.7l5.4 28.5c2.2 11.3 12.1 19.5 23.6 19.5H488c13.3 0 24 10.7 24 24s-10.7 24-24 24H199.7c-34.6 0-64.3-24.6-70.7-58.5L77.4 54.5c-.7-3.8-4-6.5-7.9-6.5H24C10.7 48 0 37.3 0 24zM128 464a48 48 0 1 1 96 0 48 48 0 1 1 -96 0zm336-48a48 48 0 1 1 0 96 48 48 0 1 1 0-96z" /></svg>
                  <span style={{ marginLeft: 8 }}>Ir a la compra</span>
                </>
              </CLoadingButton>
            )}

          </div>

        </>
      )}

      {
        isPayment && !hasMadePurchases && !disabledByStatus && (identity && identity.idRole !== 1) && (
          <PaymentPageModal data={decodedData} goBack={setIsPayment} />
        )
      }


    </div >
  )
}

export default Subcomponent4