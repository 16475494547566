import React, { ElementType, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../core/store/store';

import { cilHome, cilSpreadsheet, cilExitToApp, cilBuilding } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import { CNavItem, CNavGroup } from '@coreui/react-pro';
import { BASE_ROUTE, ROUTE_NAME, ROUTE_NAME_ID, RouteName } from '../../../core/constants/route_name';
import CompanySelect from '../../components/companySelect/CompanySelect';
import { _companyBackupLSAdmin, companyBackupLS } from '../../../core/util/save-company-choosed';
import { useLocation, useNavigate } from 'react-router-dom';
import CompanySelectAdmin from '../../components/companySelectAdmin/CompanySelectAdmin';
import { LoginService } from '../../../core/services/login/login-service';



export type Badge = {
    color: string
    text: string
}

export interface NavItem {
    component: string | ElementType
    name: string | JSX.Element
    icon?: string | JSX.Element
    badge?: Badge
    to: string
    items?: NavItem[]
    roles?: number[] // Aquí añades la propiedad roles
}


const useNavSideBar = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const { company: companyRedux } = useSelector((state: RootState) => state.dashboardStep);
    const [company, setCompany] = useState(undefined)


    useEffect(() => {
        (async () => {
            initPage();
        })();
    }, [companyRedux])

    /**
     * Este fragmento es exclusivo para la ruta de la compañía
     */
    const initPage = async () => {
        try {

            /**
             * TODO: Posible mejora para el futuro
             * En vez de coger el rol del identity para averiguar como capturar el id en el LS
             * Se podria hacer una petición al backend.
             * Esta petición solamente se haría cada vez que el usuario iniciara o clicase en F5
             */
            let identity = await LoginService.getIdentityAsync();

            let comp = !companyRedux
                ? identity.idRole === 1
                    ? await _companyBackupLSAdmin("get")
                    : await companyBackupLS("get")
                : companyRedux;
            // if (!comp) {
            //     navigate(BASE_ROUTE)
            // }
            setCompany(comp);

            const currentLocation = location.pathname;
            const currentCompanyLocation = BASE_ROUTE + ROUTE_NAME_ID(comp?.id, RouteName.companiaDetail);
            /**
             * Este código es necesario para cambiar el id de la ruta
             * después de que hayas elegido una compañía diferente en el select
             */
            if (currentLocation.includes("/dashboard/compania/")
                && currentLocation.includes("/detalle")
                && currentLocation !== BASE_ROUTE + currentCompanyLocation) {
                navigate(BASE_ROUTE + ROUTE_NAME_ID(comp?.id, RouteName.companiaDetail))
            }
            /**
             * Este código es necesario para cambiar el id de la ruta
             * después de que hayas elegido una compañía diferente en el select
             */
            if (currentLocation.includes("/dashboard/compania/")
                && currentLocation.includes("/documentos")
                && currentLocation !== BASE_ROUTE + currentCompanyLocation) {
                navigate(BASE_ROUTE + ROUTE_NAME_ID(comp?.id, RouteName.documentAccesses))
            }
            /**
            * Este código es necesario para cambiar el id de la ruta
            * después de que hayas elegido una compañía diferente en el select
            */
            if (currentLocation.includes("/dashboard/compania/")
                && currentLocation.includes("/facturacion")
                && currentLocation !== BASE_ROUTE + currentCompanyLocation) {
                navigate(BASE_ROUTE + ROUTE_NAME_ID(comp?.id, RouteName.billing))
            }

        } catch (e) {
            console.log("error bonito");
            console.error(e);
        }
    }


    const navSideBar: NavItem[] = [
        {
            component: CompanySelectAdmin,
            name: 'Select Company Admin',
            roles: [1],
            icon: <CIcon icon={cilHome} customClassName="nav-icon" />,
            // badge: {
            //   color: 'info-gradient',
            //   text: 'NEW',
            // },
            to: BASE_ROUTE + ROUTE_NAME.selectAdmin,
        },
        {
            component: CompanySelect,
            name: 'Select Company',
            roles: [2],
            icon: <CIcon icon={cilHome} customClassName="nav-icon" />,
            // badge: {
            //   color: 'info-gradient',
            //   text: 'NEW',
            // },
            to: BASE_ROUTE + ROUTE_NAME.home,
        },






        {
            component: CNavItem,
            name: 'Dashboard',
            roles: [1, 2],
            icon: <CIcon icon={cilHome} customClassName="nav-icon" />,
            // badge: {
            //   color: 'info-gradient',
            //   text: 'NEW',
            // },
            to: BASE_ROUTE + ROUTE_NAME.home,
        },
        //         documentAccesses
        // documentAccessesCrear
        {
            component: CNavGroup,
            name: 'Empresa',
            roles: [1, 2],
            icon: <CIcon icon={cilBuilding} customClassName="nav-icon" />,
            to: '',
            items: [
                {
                    component: CNavItem,
                    name: 'Detalle',
                    roles: [1, 2],
                    to: BASE_ROUTE + ROUTE_NAME_ID(company?.id, RouteName.companiaDetail),
                },
                {
                    component: CNavItem,
                    name: 'Documentos',
                    roles: [1, 2],
                    // to: BASE_ROUTE + ROUTE_NAME.documentAccesses,
                    to: BASE_ROUTE + ROUTE_NAME_ID(company?.id, RouteName.documentAccesses)
                },
                // {
                //     component: CNavItem,
                //     name: 'Documentos [BORRRAR]',
                //     roles: [1, 2],
                //     to: BASE_ROUTE + ROUTE_NAME.documentAccessesCrear,
                // }
            ]
        },
        // {
        //   component: CNavItem,
        //   name: 'Usuarios',
        //   roles: [1],
        //   icon: <CIcon icon={cilUser} customClassName="nav-icon" />,
        //   to: BASE_ROUTE + ROUTE_NAME.usuarios,
        // },
        {
            component: CNavItem,
            name: 'Facturación',
            roles: [2],
            icon: <CIcon icon={cilSpreadsheet} customClassName="nav-icon" />,
            // badge: {
            //   color: 'info-gradient',
            //   text: 'NEW',
            // },
            // to: BASE_ROUTE + ROUTE_NAME.billing,
            to: BASE_ROUTE + ROUTE_NAME_ID(company?.id, RouteName.billing)
        },

        // BillingPage

        {
            component: CNavItem,
            name: 'Salir',
            roles: [1, 2],
            icon: <CIcon icon={cilExitToApp} customClassName="nav-icon" />,
            to: ROUTE_NAME.login,
        },
    ]

    return navSideBar;
}

export default useNavSideBar;
