import React, { useEffect, useRef, useState } from 'react';
import gsap from 'gsap';

import "./scss/subcomp1-style.scss"
import { useUser } from '../../../../../core/hooks/db/user/useUser';
import { LoginService } from '../../../../../core/services/login/login-service';
import { User } from '../../../../../core/models/db/user';
import DragDrop from '../../../drag-drop/DragDrop';
import Autocomplete from '../../../autocomplete/Autocomplete';
import { FileItemObservableService } from '../../../drag-drop/services/rxJS/fileitem-observable.service';
import { classNames } from '../../../../utils/classes-css';
import { useMyDocument } from '../../../../../core/hooks/db/myDocument/useMyDocument';
import { FileItem } from '../../../drag-drop/model/FileItem';
import { DocumentStep } from '../../../../../core/models/db/steps/documentStep';
import { MyDocument } from '../../../../../core/models/db/myDocument';
import { RootState } from '../../../../../core/store/store';
import { useDispatch, useSelector } from 'react-redux';
import { CCol, CFormSwitch, CLoadingButton, CRow } from '@coreui/react-pro';
import { useStepDocument } from '../../../../../core/hooks/db/stepDocument/useStepDocument';
import { DocumentAndorraItem } from './components/DocumentAndorraItem';
import { useUserSubstep } from '../../../../../core/hooks/db/userSubstep/useUserSubstep';
import { useNavigate, useLocation } from 'react-router-dom';
import { BASE_ROUTE } from '../../../../../core/constants/route_name';
import { DataRegistration } from '../../../../pages/@external-pages/registationAndPayment/interfaces/interfaces';
import { Util } from '../../../../../core/util/util';
import { DataBasicEncoder } from '../../../../../core/util/data-basic-encoder';
import { Product } from '../../../../../core/models/db/product';
import PaymentPage from '../../../../pages/@external-pages/registationAndPayment/stripe/PaymentPage/PaymentPage';
import PaymentPageModal from '../../../../pages/@external-pages/registationAndPayment/stripe/PaymentPageModal/PaymentPageModal';
import { useProduct } from '../../../../../core/hooks/db/product/useProduct';
import { Purchase } from '../../../../../core/models/db/purchase';
import { updateStep } from '../../actions/dashboardStep-action';
import { StatusStep, StatusSubstep, Substep } from '../../types';
import { COLORS } from '../../../../../core/constants/colors';

const notResidenceInAndorraList = [
  { key: '1', value: "Pasaporte", uploaded: false },
  { key: '2', value: "Certificado de antecedentes penales", uploaded: false },
  { key: '3', value: "Certificado Médico válido", uploaded: false },
  { key: '4', value: "Documento de prueba de recursos financieros", uploaded: false },
  { key: '5', value: "Seguro médico en AND", uploaded: false },
  { key: '6', value: "Justificante de inversión extranjera o contrato de trabajo", uploaded: false },
  { key: '7', value: "Contrato de alquiler o vivienda en propiedad", uploaded: false },
  { key: '8', value: "Fotografías de carnet", uploaded: false },
]

const hasResidenceInAndorraList = [
  { key: '1', value: "Adjunta el certificado de empadronamiento", uploaded: false },
  { key: '2', value: "Adjunta la tarjeta de residencia", uploaded: false },
]

export interface SubcomponentBasicProp {
  substepDashboard: Substep;
  showModal: boolean;
  handleModal: (value: boolean) => void;
}

const Subcomponent1 = (props: SubcomponentBasicProp) => {

  const { showModal, handleModal, substepDashboard } = props;

  const navigate = useNavigate();
  const location = useLocation();



  // Bloque de hook REST API
  const { getById: getUserById, updateUser } = useUser();
  const { updateUserSubstep } = useUserSubstep();
  const { addDocumentWithDocumentStep, deleteMyDocument } = useMyDocument();
  const { getByUserAndSubstep: getDocumentByUserAndSubstep } = useStepDocument();
  const { checkBundleResidencialProduct } = useProduct();


  // REDUX: Paso y subpaso actual
  const dispatch = useDispatch();
  const { steps, company, idCompany, idUser } = useSelector((state: RootState) => state.dashboardStep);

  const currentStep = steps[0];
  const currentSubstep = steps[0].substeps[0];
  const [disabledByStatus, setDisabledByStatus] = useState(false);

  console.log(currentSubstep);
  console.log(currentSubstep);
  console.log(currentSubstep);
  console.log(currentSubstep);
  console.log(currentSubstep);
  console.log(currentSubstep);
  console.log(currentSubstep);
  console.log(currentSubstep);
  console.log(currentSubstep);
  console.log(currentSubstep);
  console.log(currentSubstep);
  console.log(currentSubstep);
  console.log(currentSubstep);
  console.log(currentSubstep);
  console.log(currentSubstep);
  console.log(currentSubstep);
  console.log(currentSubstep);
  console.log(currentSubstep);
  console.log(currentSubstep);
  console.log(currentSubstep);
  console.log(currentSubstep);


  useEffect(() => {
    if (currentSubstep && (currentSubstep.status === "EN_REVISION" || currentSubstep.status === "ACEPTADO")) {
      setDisabledByStatus(true);
    } else {
      setDisabledByStatus(false);
    }
  }, [currentSubstep]);



  const [user, setUser] = useState<User>(undefined);
  const [productList, setProductList] = useState<Product[]>(undefined);

  const [identity, setIdentity] = useState<any>(undefined);
  const [isPayment, setIsPayment] = useState<any>(undefined);

  const [loading, setLoading] = useState(false);

  // Tipo de archivo
  const [typeFileSelected, setTypeFileSelected] = useState<string>(undefined);

  // Tipo de archivos a subir
  const [typeFilesList, setTypeFileList]
    = useState<{ key: string, value: string, uploaded: boolean }[]>([])


  // Contador para saber si se han añadido todos los documentos necesarios
  const [countLimit, setCountLimit] = useState<number | null>(0);

  // Archivos subidos en el Drag and Drop
  const [filesAlreadyUploaded, setFilesAlreadyUploaded] = useState<any[]>([]);

  // Tabla documents
  const [documents, setDocuments] = useState<MyDocument[]>([]);

  // Compras hechas
  const [hasMadePurchases, setHasMadePurchases] = useState<boolean>(false);



  // Referencias a componentes
  const siRef = useRef(null);
  const noRef = useRef(null);
  const goBackRef = useRef(null);
  const dragDropRef = useRef(null);

  // Payment
  const [decodedData, setDecodedData] = useState<DataRegistration>(undefined);


  useEffect(() => {

    if (showModal === false) {
      handleUpdateUser(countLimit === 2);
    }
  }, [showModal])


  useEffect(() => {
    if (!isPayment) {
      (async () => {
        try {
          const identity = await LoginService.getIdentityAsync();
          setIdentity(identity);

          console.log(identity);

          const token = await LoginService.getTokenAsync();

          // const result = await getUserById(token, idUser);
          const result = await Promise.all([
            getUserById(token, idUser),
            getDocumentByUserAndSubstep(token, {
              idUserFk: idUser,
              idUserSubstepFk: +currentSubstep.id,
              idCompanyFk: company.id,
            }),
            checkBundleResidencialProduct(token, idUser)
          ]);

          console.log(result)

          if (result[0] && result[0].ok) {
            const { id, ...rest } = result[0].item;
            setUser(rest);
            setCountLimit(result[0].item.hasResidenceInAndorra ? 2 : 8);
            setTypeFileList(result[0].item.hasResidenceInAndorra ? hasResidenceInAndorraList : notResidenceInAndorraList);

          } else {
            throw Error("No hay usuario")
          }

          // documents
          if (result[1] && result[1].ok) {
            let itemList = result[1].item;
            let auxList = [];
            for (const doc of itemList) {
              let document: MyDocument = doc.documents;
              document.documentSteps = {
                id: doc.id
              }
              markFileAsUploaded(document.description);
              auxList.push(document);
            }
            setDocuments(auxList)

          } else {
            setDocuments([]);
          }

          // products and purchases
          if (result[2] && result[2].ok) {
            let itemList: Product[] = result[2].item;
            setProductList(itemList);

            let count = 0;
            for (const [i, item] of itemList.entries()) {
              if (item.purchases.length > 0) {
                count += 1;
              }
              if (itemList.length - 1 === i && count === 2) {
                setHasMadePurchases(true);
              }
            }
          } else {
            throw Error("No hay productos")
          }



        } catch (e: any) {
          console.error(e)
        }
      })();
    }
  }, [isPayment]);


  const markFileAsUploaded = (fileType: string) => {
    setTypeFileList(prevList => {
      return prevList.map(item => {
        if (item.value === fileType) {
          return { ...item, uploaded: true };
        }
        return item;
      });
    });
  };

  /**
   * Se actualiza una vez se cierra el modal
   * @param hasResidenceInAndorra 
   */
  const handleUpdateUser = async (hasResidenceInAndorra) => {
    try {
      // const { idUser } = await LoginService.getIdentityAsync();
      const token = await LoginService.getTokenAsync();

      const result = await updateUser(token, { id: idUser, hasResidenceInAndorra });
      if (result && result.ok) {
        setUser({ ...user, hasResidenceInAndorra });

      } else {
        throw Error("Error actualizar usuario")
      }
    } catch (e: any) {
      console.error(e)
    }
  }

  /**
   * Cambias el tipo de listado y el límite de archivos
   * @param selection 
   */
  const handleSelection = (selection) => {
    setCountLimit(selection ? 2 : 8);
    setTypeFileList(selection ? hasResidenceInAndorraList : notResidenceInAndorraList);
    for (const doc of documents) {
      markFileAsUploaded(doc.description)
    }
  };

  // const handleSelection = (selection) => {
  //   const tl = gsap.timeline();

  //   if (selection) {
  //     // Anima el botón NO hacia la derecha y desaparece
  //     gsap.to(noRef.current, { x: '100%', opacity: 0, duration: 0.5 });

  //     // Centra el botón SI y luego desaparece
  //     tl.to(siRef.current, { x: '50%', duration: 0.3 })
  //       .to(siRef.current, { opacity: 0, duration: 0.5 });
  //   } else {
  //     // Anima el botón SI hacia la izquierda y desaparece
  //     gsap.to(siRef.current, { x: '-100%', opacity: 0, duration: 0.5 });

  //     // Centra el botón NO y luego desaparece
  //     tl.to(noRef.current, { x: '-50%', duration: 0.3 })
  //       .to(noRef.current, { opacity: 0, duration: 0.5 });
  //   }


  //   // Establece el estado después de que se complete la animación
  //   // tl.eventCallback("onComplete", () => setResponse(selection));
  //   tl.eventCallback("onComplete", () => handleUpdateUser(selection));

  // };


  // const goBack = () => {

  //   const tl = gsap.timeline();

  //   // Anima la desaparición del botón "Volver"
  //   tl.to(goBackRef.current, { opacity: 0, duration: 0.5 });

  //   // Restablece la posición y opacidad de los botones "SI" y "NO"
  //   tl.to(siRef.current, { x: '0%', opacity: 1, duration: 0.2 }, "<")
  //     .to(noRef.current, { x: '0%', opacity: 1, duration: 0.2 }, "<");

  //   // Establece el estado después de que se complete la animación
  //   // tl.eventCallback("onComplete", () => setResponse(null));
  //   tl.eventCallback("onComplete", () => handleUpdateUser(null));
  // };

  const addDocument = async (fileItem: FileItem) => {
    try {
      setLoading(true);
      console.log(fileItem)
      // const identity = await LoginService.getIdentityAsync();
      const token = await LoginService.getTokenAsync();

      let document: MyDocument;
      let documentStep: DocumentStep;
      document = {
        name: fileItem.name,
        extension: fileItem.type,
        description: typeFileSelected,
        url: fileItem.pathRelative,
        idUserFk: idUser,
        idCompanyFk: company.id
      }

      documentStep = {
        idUserStepFk: +currentStep.id,
        idUserSubstepFk: +currentSubstep.id
      }

      const result = await addDocumentWithDocumentStep(token, document, documentStep);
      if (result && result.item) {
        console.log(result)
        setDocuments(doc => {
          return [...doc, result.item];
        })
        markFileAsUploaded(result.item.description);
      } else {
        new Error("No se ha guardado el documento", result)
      }
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false);
      cleanDocument();
    }
  }


  const updateUserStep = async () => {
    try {
      const token = await LoginService.getTokenAsync();
      const ID_EN_REVISION_STATUS = 3;
      const result = await updateUserSubstep(token,
        {
          id: +currentSubstep.id,
          idStatusSubstepFk: ID_EN_REVISION_STATUS,
          comment: ""
        })
      if (result && result.ok) {

        let step = currentStep;
        let substep = currentSubstep;

        step.status = StatusStep.EN_REVISION;
        substep.status = StatusSubstep.EN_REVISION;
        dispatch(updateStep(step, substep));

        handleModal(false);

      } else {
        throw Error("Hubo un error", result)
      }
    } catch (error) {
      console.error(error);
      handleModal(false);
    }
  }

  const cleanDocument = () => {
    setTypeFileSelected(undefined)
    setFilesAlreadyUploaded([])
    handleExternalDelete(null, 0)
  }


  const handleDelete = async (doc) => {

    try {
      const token = await LoginService.getTokenAsync();
      const result = await deleteMyDocument(token, [doc.id], true);
      if (result && result.ok) {
        let docAux = documents.filter(d => d.id !== doc.id)
        setDocuments(docAux);
        // Poner en false el tipo de documento si fuese necesario
        setTypeFileList(typeFileSelected => {
          // Se asegura que no haya ningún documento con esa descripción para ponerlo en false
          const isAnotherSameDescription = docAux.some(d => d.description === doc.description && d.id !== doc.id);

          if (!isAnotherSameDescription) {
            let result = typeFileSelected.find(row => row.value === doc.description);
            if (result) {
              result.uploaded = false;
            }
          }

          return typeFileSelected;
        });


      }
    } catch (error) {
      console.error(error)
    }
  };

  const goPayment = async () => {
    let data: DataRegistration = {} as any;
    const token = await LoginService.getTokenAsync();

    // company.idUserFk = data.user.id;
    data.user = {
      email: user.email,
      username: user.username,
      id: idUser
    }

    // let result: any = await checkBundleResidencialProduct(token)
    // if (result && result.ok) {
    //   data.productList = [...result.item];
    // }
    data.productList = [...productList];

    // Agrega 20 minutos al tiempo actual
    const currentTime = new Date();
    const expiryTime = new Date(currentTime.getTime() + 12000000 * 60 * 1000);
    data.expire = expiryTime.toISOString();
    data.isPaymentCorrect = false;
    data.isPaymentError = false;
    data.company = company

    await Util.delay(1000);

    const encodedData = encodeURIComponent(DataBasicEncoder.encode(data));
    setDecodedData(data);
    setIsPayment(true)
    // navigate(`${BASE_ROUTE}/pago/${encodedData}`, {
    //   state: {
    //     encodedData,
    //     step: {
    //       step: currentStep,
    //       substep: currentSubstep,
    //       idUser: user.id,
    //       company: company.id,
    //       data: {
    //         hasResidenceAndorra: countLimit === 2,

    //       }
    //     }
    //   }
    // });
  }


  // Empiezo drag and drop

  const controlFile$ = React.useRef(FileItemObservableService.instance.control$);

  /** Cuando existe un cambio en el observable actializo el listado de items */
  useEffect(() => {
    const subscription = FileItemObservableService.instance
      .control$.subscribe(
        item => {
          console.log("Look at item", item)
          if (item && item.fileItemList && item.fileItemList.length > 0) {
            setFilesAlreadyUploaded(item.fileItemList)
            // setFieldValue('image', item.fileItemList[0].pathRelative);
          } else {
            setFilesAlreadyUploaded([])
            // setFieldValue('image', "");
          }
        }
      )
    return () => {
      subscription.unsubscribe();
    }

  }, [controlFile$]);

  const handleExternalDelete = (e, index) => {
    const forceDeleted = true;
    dragDropRef.current.deleteFileItem(e, index, forceDeleted);
  };

  // fin drag and drop

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
      {/* <pre> {JSON.stringify(user, null, 2)}</pre> */}
      {/* <pre>{JSON.stringify(filesAlreadyUploaded, null, 2)}</pre> */}
      {/* <pre>{JSON.stringify(document, null, 2)}</pre> */}
      <>

        {!isPayment && (
          <div style={{ width: "100%" }}>
            <CRow>
              <CCol lg="6">
                {/* CHECK para indicar de donde es */}
                {user && (
                  // <CFormSwitch
                  //   disabled={
                  //     loading
                  //     || disabledByStatus
                  //     || (identity && identity.idRole === 1)}
                  //   defaultChecked={user.hasResidenceInAndorra}
                  //   label="Tengo vivienda en Andorra"
                  //   id="formSwitchCheckChecked"
                  //   onClick={(e: any) => handleSelection(e.target.checked)} />

                  <>
                    <div style={{ marginTop: 20, fontSize: 15, fontFamily: "Raleway", marginBottom: 10 }}>
                      Activa el switch si cuentas con una oficina en Andorra. Esto ajustará las opciones disponibles en el modal según tu situación.
                    </div>



                    <div className="d-flex justify-content-around align-items-center"
                      style={{ marginTop: 30, height: 100 }}>
                      <span style={{ fontSize: 20, fontWeight: 500 }}>Tengo oficina en Andorra</span>

                      <CFormSwitch
                        style={{ height: 30, width: 45 }}
                        size="lg"
                        disabled={
                          loading
                          || disabledByStatus || hasMadePurchases
                        }
                        defaultChecked={user.hasResidenceInAndorra}
                        // label="Tengo una oficina en Andorra"
                        id="1234"
                        onClick={(e: any) => handleSelection(e.target.checked)}
                      />


                    </div>
                  </>
                )}
              </CCol>
              <CCol lg={6}>

                {/* Drag and drop */}
                {identity && identity.idRole !== 1 && (
                  <>
                    <div style={{ marginTop: 20, fontSize: 15, fontFamily: "Raleway", marginBottom: 10 }}>
                      Para subir un archivo, primero asegúrate de tener el documento deseado listo y luego selecciona el tipo de documento correspondiente.
                    </div>

                    <div className="fileUploadContainer" style={{ justifyContent: "center" }} >
                      <DragDrop
                        ref={dragDropRef}
                        styleDD={{ width: 300, height: 150 }}
                        // classDD={'ploadContainer'}
                        id={"test-id"}
                        isOnlyOneFile={true}
                        showFileList={false}
                        // disabled={loading || typeFilesList.filter(item => item.uploaded).length === countLimit}
                        disabled={
                          disabledByStatus
                          || loading}

                        directoryNameList={["fromReact"]}
                        maxSizeMB={19}
                        typeAllowed={[
                          // "image/*",
                          "image/gif",
                          "image/jpeg",
                          "image/png",
                          "image/jpg",
                          "application/pdf"
                        ]} />

                      {/* Contador */}
                      <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                        {/* <div className="fileCounter">
                        {typeFilesList.filter(item => item.uploaded).length} / {countLimit}
                      </div> */}

                        {/* <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                    {filesAlreadyUploaded.length} / 16
                  </div> */}
                      </div>
                    </div>
                  </>
                )}
              </CCol>

            </CRow>
            {/* Autocomplete para elección de archivo */}
            {identity && identity.idRole !== 1 && (
              <div className="autocompleteContainer">

                <Autocomplete
                  disabled={disabledByStatus}
                  placeholder='Selecciona un tipo de documento'
                  defaultData={loading ? [] : typeFilesList}
                  name={'TEST'}
                  table={'special_case_typeDocumentStep_1' as any}
                  onChange={function (e: React.ChangeEvent<HTMLSelectElement>): void {
                    // console.log("onChange", e)
                    const { target } = e;
                    setTypeFileSelected(target.value);
                  }} onBlur={function (e: React.FocusEvent<HTMLSelectElement, Element>): void {
                    // console.log("onBlur", e)
                  }} />

              </div>

            )}

            {/* Botón de subir documento */}
            {identity && identity.idRole !== 1 && (
              <div style={{ marginBottom: 50 }}>
                <div style={{ display: "flex", justifyContent: "center", marginTop: 30 }}>
                  <CLoadingButton
                    style={{ backgroundColor: COLORS.principalColor }}

                    disabled={
                      loading
                      || disabledByStatus
                      || !typeFileSelected
                      || filesAlreadyUploaded.length === 0}
                    loading={loading}
                    onClick={() => addDocument(filesAlreadyUploaded[0])}>Subir documento</CLoadingButton>
                </div>
              </div>
            )}

            {documents && documents.length > 0 && (
              <DocumentAndorraItem
                documents={documents}
                onDelete={handleDelete}
                hasResidenceInAndorra={countLimit === 2}
                disabled={disabledByStatus || identity && identity.idRole === 1}

              />
            )}

            <div style={{ marginTop: 20 }}></div>
            <div>
              {identity && identity.idRole !== 1 && (
                <div style={{ fontSize: 15, marginBottom: 10, fontFamily: "Raleway", }}>Una vez hayas subido todos los archivos requeridos según tu localización y completado tu compra, podrás enviarlos para revisión. Recuerda: después de enviarlos, no podrás realizar cambios o modificaciones en los archivos. <br /> <b>Asegúrate de tener todo listo antes de proceder.</b></div>
              )}
              <div style={{ display: "flex", marginBottom: 10, }}>
                <div style={{ color: "white", display: "flex", flexDirection: "row", alignItems: "center", backgroundColor: hasMadePurchases ? COLORS.principalColor : "lightcoral", padding: "5px 10px", borderRadius: 5 }}>
                  {hasMadePurchases ?
                    (
                      <>
                        <svg xmlns="http://www.w3.org/2000/svg" fill='white' height="1em" viewBox="0 0 448 512"><path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" /></svg>
                        <span style={{ marginLeft: 5 }}>Compra realizada</span>
                      </>
                    )
                    :
                    (
                      <>
                        <svg xmlns="http://www.w3.org/2000/svg" fill='white' height="1em" viewBox="0 0 512 512"><path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM175 175c-9.4 9.4-9.4 24.6 0 33.9l47 47-47 47c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l47-47 47 47c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-47-47 47-47c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-47 47-47-47c-9.4-9.4-24.6-9.4-33.9 0z" /></svg>
                        <span style={{ marginLeft: 5 }}>No se ha hecho la compra</span>
                      </>
                    )
                  }
                </div>
              </div>


              <div style={{ display: "flex", marginBottom: 20 }}>
                <div style={{
                  color: "white",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  backgroundColor: typeFilesList.filter(item => item.uploaded).length === countLimit ? COLORS.principalColor : "lightcoral",
                  padding: "5px 10px",
                  borderRadius: 5
                }}>
                  {
                    typeFilesList.filter(item => item.uploaded).length === countLimit ? (
                      <>
                        <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512" fill='white' >
                          <path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" />
                        </svg>
                        <span style={{ marginLeft: 5 }}>Todos los tipos de documentos han sido cargados ({typeFilesList.filter(item => item.uploaded).length} / {countLimit})</span>
                      </>
                    )
                      :
                      (
                        <>
                          <svg xmlns="http://www.w3.org/2000/svg" fill='white' height="1em" viewBox="0 0 512 512"><path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM175 175c-9.4 9.4-9.4 24.6 0 33.9l47 47-47 47c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l47-47 47 47c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-47-47 47-47c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-47 47-47-47c-9.4-9.4-24.6-9.4-33.9 0z" /></svg>
                          <span style={{ marginLeft: 5 }}>No se han cargado todos los tipos documentos ({typeFilesList.filter(item => item.uploaded).length} / {countLimit})</span>
                        </>
                      )
                  }
                </div>
              </div>



              <div style={{ display: "flex", justifyContent: "space-between" }}>
                {!disabledByStatus && (identity && identity.idRole !== 1) && (
                  <CLoadingButton
                    style={{ backgroundColor: COLORS.principalColor }}

                    disabled={loading || typeFilesList.filter(item => item.uploaded).length !== countLimit || !hasMadePurchases}
                    loading={loading}
                    onClick={() => updateUserStep()}>Mandar a revisión</CLoadingButton>
                )}

                {/* Ocultar el botón de compra si se ha comprado o está en revisión */}
                {!disabledByStatus && !hasMadePurchases && (identity && identity.idRole !== 1) && (
                  <CLoadingButton
                    style={{ backgroundColor: COLORS.principalColor }}

                    disabled={hasMadePurchases}
                    // disabled={loading || typeFilesList.filter(item => item.uploaded).length === countLimit || !typeFileSelected || filesAlreadyUploaded.length === 0}
                    loading={loading}
                    onClick={() => {
                      handleUpdateUser(countLimit === 2);
                      goPayment();
                    }}>
                    <>
                      <svg style={{ fill: "white" }} xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 576 512"><path d="M0 24C0 10.7 10.7 0 24 0H69.5c22 0 41.5 12.8 50.6 32h411c26.3 0 45.5 25 38.6 50.4l-41 152.3c-8.5 31.4-37 53.3-69.5 53.3H170.7l5.4 28.5c2.2 11.3 12.1 19.5 23.6 19.5H488c13.3 0 24 10.7 24 24s-10.7 24-24 24H199.7c-34.6 0-64.3-24.6-70.7-58.5L77.4 54.5c-.7-3.8-4-6.5-7.9-6.5H24C10.7 48 0 37.3 0 24zM128 464a48 48 0 1 1 96 0 48 48 0 1 1 -96 0zm336-48a48 48 0 1 1 0 96 48 48 0 1 1 0-96z" /></svg>
                      <span style={{ marginLeft: 8 }}>Ir a la compra</span>
                    </>
                  </CLoadingButton>
                )}

              </div>

            </div>


          </div>
        )}

        {isPayment && !hasMadePurchases && !disabledByStatus && (identity && identity.idRole !== 1) && (
          <PaymentPageModal data={decodedData} goBack={setIsPayment} />
        )}

      </>

    </div >
  );
}

export default Subcomponent1;








