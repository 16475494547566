import { useRef, useEffect } from 'react';

const useDebouncedCallback = (callback, delay) => {
    const callbackRef = useRef(callback);
    const timeoutRef = useRef<NodeJS.Timeout | undefined>();

    useEffect(() => {
        callbackRef.current = callback;
    }, [callback]);

    return (...args) => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
        timeoutRef.current = setTimeout(() => {
            callbackRef.current(...args);
        }, delay);
    };
}

export default useDebouncedCallback;
