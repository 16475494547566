import React, { useContext, useEffect, useState } from 'react';
import { CAlert, CCol, CContainer, CFormInput, CFormLabel, CFormTextarea, CInputGroup, CInputGroupText, CRow } from '@coreui/react-pro';

import { cilInfo, cilInstitution, cilLockLocked, cilUser, cilWarning } from '@coreui/icons';
import CIcon from '@coreui/icons-react';

import { useFormik } from 'formik';
import * as Yup from 'yup';

import validationCompanySchema from './company_validator_yup';
import { User } from '../../../../../core/models/db/user';
import { LoginService } from '../../../../../core/services/login/login-service';
import ButtonLoadingSucessForm from '../../../../components/buttonLoadingSucessForm/ButtonLoadingSucessForm';
import { FileItemObservableService } from '../../../../components/drag-drop/services/rxJS/fileitem-observable.service';
import { PaymentContext } from '../contexts/PaymentContext';
import { COLORS } from '../../../../../core/constants/colors';



interface CompanyFormProps {

    alreadyCreated?: boolean;

    company: {
        companyNameTest1: string;
        companyNameTest2: string;
        companyNameTest3: string;
        description: string;
        typeCommercialCompany?: string
    };
    typeAction: "add" | "update";
    loadingButton?: boolean;

    isModal?: boolean

    add?: (user: any) => Promise<any>;
    update?: (user: any) => Promise<any>;
    checkPassword?: (id: any, password: any) => Promise<any>
    checkUsername?: (id: any, username: string) => Promise<any>

    updateStepData?: (company: any) => void;
}



const CompanyForm = (props: CompanyFormProps) => {

    // Pasar paso
    const {
        stepsData,
        data,
        isResponsive,

        handleStepClick,
        setData,
    } = useContext(PaymentContext)


    // Comienzo de la página
    // Comienzo de la página
    // Comienzo de la página

    const {
        company,
        typeAction,
        loadingButton,
        alreadyCreated,

        isModal = false,

        add,
        update,
        updateStepData,

        checkPassword,
        checkUsername,
    } = props;


    const [showPassword, setShowPassword] = useState(false);
    // const [imageUser, setImageUser] = useState<string>(user && company.image ? company.image : "");
    const [disabled, setDisabled] = useState(true);

    const { values, errors, touched, isValid,

        handleChange,
        handleSubmit,
        handleBlur,
        getFieldProps,
        setFieldValue,
        setValues
    } = useFormik({
        initialValues: {
            ...company,
        },
        validateOnBlur: false,
        onSubmit: (value) => {
            console.log(value)
            if (typeAction === "add") {
                add(value);
            } else if (typeAction === "update") {
                update(value);
            }
        },
        validationSchema: Yup.lazy(values => validationCompanySchema(false))
    })


    useEffect(() => {
        updateStepData(values);
    }, [values]);


    // Cojo la referencia del observable para saber cuando ha habido un cambio
    // const controlFile$ = React.useRef(FileItemObservableService.instance.control$);

    /**
     * Si hay que mantener la pagina tras haber creado el registro, 
     * descomenta este trozo de código
     * @param value 
     */
    // useEffect(() => {
    //     console.log("Activating user update");
    //     setValues({
    //         ...user,
    //         blocked: Boolean(company.blocked)
    //     })
    // }, [user])


    const changeToBackup = (value: boolean) => {
        if (value) {
            setValues(company);


        }
    }

    const isEdit = (value: boolean) => {
        setDisabled(value);
    }

    const companyDisplayStyles = {
        container: {
            backgroundColor: COLORS.quaternaryColor,
            padding: '20px 5px',
            textAlign: 'center',
            borderRadius: '5px',
            marginBottom: '20px'
        },
        companyName: {
            color: COLORS.secondaryColor,
            fontSize: '2em',
            fontWeight: 'bold',
            margin: '0',
            wordWrap: 'break-word',
            overflowWrap: 'break-word',
            whiteSpace: 'normal',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            maxWidth: '100%'
        }
    } as any;



    return (

        <>
            {/* <h4>Original</h4>
            <pre>
                {JSON.stringify(company, null, 2)}
            </pre>
            <h4> Modificado</h4>
            <pre>
                {JSON.stringify(values, null, 2)}
            </pre> */}

            <form onSubmit={handleSubmit}>
                <CRow>

                    <CCol lg={12}>

                        <div className="input-container" style={{ marginBottom: 25 }}>
                            <label className="input-label"
                                style={{ color: COLORS.secondaryColor }}>Nombre Empresa Provisional 1</label>
                            <CFormInput className="dark-input"
                                id="companyName1"
                                {...getFieldProps("companyNameTest1")} />
                            {touched.companyNameTest1 && errors.companyNameTest1 && (
                                <div className='d-flex align-items-center mt-2' style={{ fontSize: 12, color: "red" }}>
                                    <CIcon size="sm" icon={cilWarning} />
                                    <div className='small' style={{ marginLeft: '5px' }}>{errors.companyNameTest1}</div>
                                </div>
                            )}
                        </div>

                        <div style={{ marginTop: 25 }}></div>

                        <div className="input-container" style={{ marginBottom: 25 }}>
                            <label className="input-label"
                                style={{ color: COLORS.secondaryColor }}>Nombre Empresa Provisional 2</label>
                            <CFormInput className="dark-input"
                                id="companyName2"
                                {...getFieldProps("companyNameTest2")} />
                            {touched.companyNameTest2 && errors.companyNameTest2 && (
                                <div className='d-flex align-items-center mt-2' style={{ fontSize: 12, color: "red" }}>
                                    <CIcon size="sm" icon={cilWarning} />
                                    <div className='small' style={{ marginLeft: '5px' }}>{errors.companyNameTest2}</div>
                                </div>
                            )}
                        </div>





                        <div style={{ marginTop: 25 }}></div>

                        <div className="input-container" >
                            <label className="input-label"
                                style={{ color: COLORS.secondaryColor }}>Nombre Empresa Provisional 3</label>
                            <CFormInput className="dark-input"
                                id="companyName3"
                                {...getFieldProps("companyNameTest3")} />
                            {touched.companyNameTest3 && errors.companyNameTest3 && (
                                <div className='d-flex align-items-center mt-2' style={{ fontSize: 12, color: "red" }}>
                                    <CIcon size="sm" icon={cilWarning} />
                                    <div className='small' style={{ marginLeft: '5px' }}>{errors.companyNameTest3}</div>
                                </div>
                            )}
                        </div>

                        <CContainer style={companyDisplayStyles.container}>
                            <h1 style={companyDisplayStyles.companyName}>{values.companyNameTest1} {company.typeCommercialCompany}</h1>
                        </CContainer>


                    </CCol>

                    {/* {!isResponsive && (
                        <CCol sm={6}>
                            <div style={{ color: "white" }}>
                                Aquí podría ir algo de información de por qué hay que poner 3 nombres
                                <br></br>
                                <br></br>
                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Eaque reiciendis quis esse ipsum aut accusantium, ratione atque quos earum velit id dolores deserunt animi sapiente aperiam saepe recusandae doloribus sint.
                            </div>
                        </CCol>
                    )} */}


                </CRow>

                <CRow>
                    <div style={{ marginTop: 30 }}></div>
                    <div className="input-container" >
                        <label className="input-label"
                            style={{ color: COLORS.secondaryColor }}>Descripción de la empresa</label>


                        <CFormTextarea
                            className="dark-input"
                            id="companyDescription"
                            rows={2}
                            // placeholder='Descripción de la empresa'
                            {...getFieldProps("description")}
                        ></CFormTextarea>
                        {touched.description && errors.description && (
                            <div className='d-flex align-items-center mt-2' style={{ fontSize: 12, color: "red" }}>
                                <CIcon size="sm" icon={cilWarning} />
                                <div className='small' style={{ marginLeft: '5px' }}>{errors.description}</div>
                            </div>
                        )}
                    </div>
                </CRow>

                {/* <CCol>
                            <CContainer style={{ marginTop: 50 }}>
                                <ButtonLoadingSucessForm
                                    loadingButton={loadingButton}
                                    className="d-flex d-row"
                                    changeToBackup={changeToBackup}
                                    onSubmit={handleSubmit}
                                    setDisabled={isEdit}
                                    typeAction={typeAction}
                                    disabled={!isValid}
                                />
                            </CContainer>
                        </CCol> */}
                <div style={{ marginTop: 50 }}></div>


                <CRow className="justify-content-center"> {/* Clase para centrar contenido */}
                    {isResponsive && (
                        <>
                            <button
                                type='button'
                                className="continue-button"
                                onClick={() => {
                                    handleStepClick(1)
                                }}>
                                Volver
                            </button>

                            <div style={{ marginBottom: 20 }}></div>
                        </>
                    )}
                    {/* <div style={{ color: "white" }}>{JSON.stringify(isValid, null, 2)}</div> */}
                    <button
                        type='button'
                        disabled={!isValid}
                        className="continue-button"
                        onClick={() => handleSubmit()}>
                        Ir al pago
                    </button>
                </CRow>



            </form >
        </ >


    )
}

export default CompanyForm



