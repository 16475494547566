import { CCol, CContainer, CRow } from '@coreui/react-pro'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import StepNavigator from '../stepNavigator/StepNavigator'
import { DataRegistration, RegistrationAndPaymentProps } from '../interfaces/interfaces';
import { PaymentContext } from '../contexts/PaymentContext';
import StepPageIndex from '../stepPages/stepPageIndex';
import gsap from 'gsap';
import { LoginService } from '../../../../../core/services/login/login-service';
import { useUser } from '../../../../../core/hooks/db/user/useUser';
import { User } from '../../../../../core/models/db/user';
import { COLORS } from '../../../../../core/constants/colors';


interface UserRegistrationProps {
    isDashboard?: boolean;

}
const UserRegistration = (props: UserRegistrationProps) => {
    const { isDashboard } = props;

    const [stepsData, setStepsData] = useState([
        { name: "Step 1", description: "", active: true, complete: false },
        { name: "Step 2", description: "", active: false, complete: false },
        { name: "Step 3", description: "", active: false, complete: false }
    ]);

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (!isDashboard) {
            localStorage.clear();
        }
    }, []);

    const [isResponsive, setIsResponsive] = useState(window.innerWidth <= 768);
    useEffect(() => {
        const handleResize = () => {
            setIsResponsive(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };

    }, []);


    const [data, setData] = useState<DataRegistration>({
        user: undefined, // step 1
        company: undefined, // step 2 y step 3
    });

    const handleStepClick = (stepIndex) => {
        const newSteps = stepsData.map((step, idx) => {
            if (idx === stepIndex) {
                return { ...step, active: true };
            } else if (idx < stepIndex) {
                return { ...step, complete: true, active: false };
            } else {
                return { ...step, active: false };
            }
        });

        setStepsData(newSteps);
        console.log(`Switched to step ${stepIndex + 1}`);
    };

    const currentStep = () => {
        return stepsData.findIndex(step => step.active);
    }

    const handleLoading = (value: boolean) => {
        setLoading(value)
    }


    const loadingAnimationRef = useRef<gsap.core.Timeline | null>(null);


    useEffect(() => {
        if (loading) {
            // Crear la animación y guardarla en la referencia
            loadingAnimationRef.current = gsap.timeline({ repeat: -1, yoyo: true })
                .to('#loadingText', { opacity: 0, duration: 0.5 });
        } else {
            // Si la animación existe, detenerla
            if (loadingAnimationRef.current) {
                loadingAnimationRef.current.kill();
            }
        }
    }, [loading]);

    const paymentMemo = useMemo<RegistrationAndPaymentProps>(
        () => ({
            // values
            stepsData,
            data,
            isResponsive,
            loading,

            // functions
            setStepsData,
            setData,
            handleStepClick,
            currentStep,
            setIsResponsive,
            handleLoading


        }), [stepsData, isResponsive]);







    return (
        <PaymentContext.Provider value={paymentMemo}>


            {loading && (
                <div style={loadingContainer}>
                    <div id="loadingText" style={loadingText}>
                        Cargando...
                    </div>
                    {/* <div style={loadingInfo}>
                        Mientras se muestra este loading se obtendría el producto de la base de datos<br></br>
                        y se guardaría los datos de la compañía
                    </div> */}
                </div>
            )}

            {!loading && (
                <div style={{
                    // backgroundColor: "#1a1a1a",
                    backgroundColor: COLORS.principalColor,

                    overflowX: isResponsive ? "hidden" : null,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',

                }}>
                    <div style={{
                        height: "100%",
                        // maxHeight: '50%', 
                        // backgroundColor: "#1f1f1f",
                        backgroundColor: COLORS.tertiaryColor,

                        maxWidth: isResponsive ? "100%" : '80%',
                        width: '100%',
                        // margian: '20px', 
                        borderRadius: '5px'
                    }}>
                        <CRow>
                            {!isResponsive && (
                                <CCol style={colStyle}>
                                    <StepNavigator position={'vertical'} />
                                </CCol>
                            )}

                            <CCol style={colStyle}>
                                <StepPageIndex />
                            </CCol>
                        </CRow>
                    </div>
                </div>
            )}
        </PaymentContext.Provider>
    )

}

export default UserRegistration

const colStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh'  // puedes ajustar esto según tus necesidades
} as any

const loadingContainer = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    backgroundColor: '#1a1a1a',  // Marrón casi negro
    color: 'white',

} as any
const loadingText = {
    fontSize: '24px',
    fontWeight: 'bold',
    marginBottom: '20px',
} as any
const loadingInfo = {
    fontSize: '16px',
} as any
