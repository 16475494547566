import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useCompany } from "../../../../../core/hooks/db/company/useCompany";
import { useCompanyRelation } from "../../../../../core/hooks/db/companyRelation/useCompanyRelation";
import { useUser } from "../../../../../core/hooks/db/user/useUser";
import { useUserSubstep } from "../../../../../core/hooks/db/userSubstep/useUserSubstep";
import { LoginService } from "../../../../../core/services/login/login-service";
import { RootState } from "../../../../../core/store/store";
import { updateStep } from "../../actions/dashboardStep-action";
import { StatusStep, StatusSubstep } from "../../types";
import { SubcomponentBasicProp } from "./Subcomponent1";
import { CCol, CFormSwitch, CLoadingButton } from "@coreui/react-pro";
import { Company } from "../../../../../core/models/db/company";
import { Util } from "../../../../../core/util/util";
import DragDrop from "../../../drag-drop/DragDrop";
import { useProduct } from "../../../../../core/hooks/db/product/useProduct";
import { Product } from "../../../../../core/models/db/product";
import { DataRegistration } from "../../../../pages/@external-pages/registationAndPayment/interfaces/interfaces";
import { User } from "../../../../../core/models/db/user";
import { DataBasicEncoder } from "../../../../../core/util/data-basic-encoder";
import PaymentPageModal from "../../../../pages/@external-pages/registationAndPayment/stripe/PaymentPageModal/PaymentPageModal";
import { COLORS } from "../../../../../core/constants/colors";


/**
 * Subpaso 2 del paso 4
 * @returns 
 */
const Subcomponent5 = (props: SubcomponentBasicProp) => {
  const { showModal, handleModal, substepDashboard } = props;

  // REDUX: Paso y subpaso actual
  const dispatch = useDispatch();
  const {
    steps,
    company,
    idCompany,
    idUser,
    currentStepId }
    = useSelector((state: RootState) => state.dashboardStep);

  const currentStep = steps.find(s => s.id === currentStepId);
  const currentSubstep = substepDashboard;


  const { getCompanyById, addCompany, updateCompany } = useCompany();
  const { checkPagoOficinaProduct } = useProduct();
  const { getById: getUserById, updateUser } = useUser();
  const { updateUserSubstep, getUserSubstepById } = useUserSubstep();

  const { checkAdmin } = useUser();

  const [companyAux, setCompanyAux] = useState<Company>(company);
  const [isAdmin, setIsAdmin] = useState(undefined);
  const [identity, setIdentity] = useState(undefined);
  const [user, setUser] = useState<User>(undefined);

  const [loading, setLoading] = useState(undefined);
  const [companyRelationList, setCompanyRelationList] = useState([]);

  const [disabledByStatus, setDisabledByStatus] = useState(false);
  /**
   * Campo del objeto UserStep
   */
  const [commentUser, setCommentUser] = useState("");

  // Pasarela de pago
  const [productList, setProductList] = useState<Product[]>(undefined);
  const [isPayment, setIsPayment] = useState<any>(undefined);
  // --- Objeto para componente PaymentModal
  const [decodedData, setDecodedData] = useState<DataRegistration>(undefined);
  // --- Si ha hecho la compra
  const [hasMadePurchases, setHasMadePurchases] = useState<boolean>(false);

  // REF
  const dragDropRef = useRef(null);
  const idDragDrop = "dd-sub5";

  useEffect(() => {
    if (currentSubstep && (currentSubstep.status === "EN_REVISION" || currentSubstep.status === "ACEPTADO")) {
      setDisabledByStatus(true);
    } else {
      setDisabledByStatus(false);
    }
  }, [currentSubstep]);

  useEffect(() => {
    if (!isPayment) {
      (async () => {
        await initPage();
      })();
    }
  }, [isPayment])


  const initPage = async () => {
    try {
      setLoading(true);
      const token = await LoginService.getTokenAsync();
      if (!identity) {
        const identity = await LoginService.getIdentityAsync();
        setIdentity(identity)
      }

      const result = await Promise.all([
        checkAdmin(token),
        getCompanyById(token, company.id),
        checkPagoOficinaProduct(
          token,
          company.idUserFk,
          company.id),
        getUserById(token, idUser)
      ]);

      setIsAdmin(result[0] && result[0].item === true);

      if (result[1] && result[1].ok) {
        setCompanyAux(result[1].item);
      }

      // if (result[2] && result[2].ok) {

      //   console.log(result[2].item)
      //   setCommentUser(result[2]?.item?.commentUser || "")
      // }

      // products and purchases
      if (result[2] && result[2].ok) {
        let itemList: Product[] = result[2].item;
        setProductList(itemList);
        let count = 0;
        for (const [i, item] of itemList.entries()) {
          if (item.purchases.length > 0) {
            count += 1;
          }
          if (itemList.length - 1 === i && count === 1) {
            setCompanyAux(prev => {
              return { ...prev }
            })
            setHasMadePurchases(true);
          }
        }
      } else {
        throw Error("No hay productos")
      }

      if (result[3] && result[3].ok) {
        const { id, ...rest } = result[3].item;
        setUser(rest);
      } else {
        throw Error("No hay usuario")
      }

      setLoading(false);

    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  }


  const handleOperations = async () => {
    try {
      setLoading(true);

      await submit();
      await updateUserStep();

      handleModal(false);

    } catch (error) {
      console.error("Error al procesar las operaciones:", error);
      handleModal(false);

    } finally {
      setLoading(false);
    }
  };


  /**
  * Centraliza la modificación en la base de datos de todos los rows
  */
  const submit = async () => {
    try {
      const token = await LoginService.getTokenAsync();

      let comp: Company = new Company();
      comp = { ...companyAux };
      comp.usePartners = hasMadePurchases;
      let result;
      // if (comp.id) {
      result = await updateCompany(token, {
        id: comp.id,
        usePartners: comp.hasCoworking ? comp.usePartners : false,
        urlContract: comp.hasCoworking ? comp.urlContract : "",
        hasCoworking: comp.hasCoworking
      });
      // } else {
      //   comp.idUserFk = company.idUserFk;
      //   result = await addCompany(token, comp);
      // }

      if (result && result.ok) {

      } else {
        throw Error(result);
      }

    } catch (error: any) {
      console.error("Error al procesar las operaciones:", error);
      throw Error(error);
    }

  }

  const updateUserStep = async () => {
    try {
      const token = await LoginService.getTokenAsync();
      const ID_EN_REVISION_STATUS = 3;
      const result = await updateUserSubstep(token,
        {
          id: +currentSubstep.id,
          idStatusSubstepFk: ID_EN_REVISION_STATUS,
          comment: "",
          commentUser
        })
      if (result && result.ok) {

        let step = currentStep;
        let substep = currentSubstep;

        // Hay que tener en cuenta que los steps pueden tener varios substeps
        let index = step.substeps.findIndex(sub => sub.id === currentSubstep.id);
        // Le cambiamos el estado antes de pasar por el loop
        step.substeps[index].status = StatusSubstep.EN_REVISION;

        if (step.substeps.length > 1) {
          for (let row of step.substeps) {

            if (currentStep.id !== row.id) {
              if (row.status === "EN_REVISION") {
                step.status = StatusStep["EN_REVISION"];
                break;
              } else if (row.status === "RECHAZADO") {
                step.status = StatusStep["RECHAZADO"];
                break;
              } else if (row.status === "REQUERIDO") {
                step.status = StatusStep["PENDIENTE"];
                break;
              } else {
                step.status = StatusStep.EN_REVISION;
              }
            }
          }
        } else {
          step.status = StatusStep.EN_REVISION;
        }
        substep.status = StatusSubstep.EN_REVISION;

        dispatch(updateStep(step, substep));


        setLoading(false);
        handleModal(false);

      } else {
        throw Error("Hubo un error", result)
      }
    } catch (error) {
      setLoading(false);

      console.error(error);
      handleModal(false);
    }
  }

  const goPayment = async () => {
    let data: DataRegistration = {} as any;
    const token = await LoginService.getTokenAsync();

    // company.idUserFk = data.user.id;
    data.user = {
      email: user.email,
      username: user.username,
      id: idUser
    }
    // let result: any = await checkBundleResidencialProduct(token)
    // if (result && result.ok) {
    //   data.productList = [...result.item];
    // }
    data.productList = [...productList];
    // Agrega 20 minutos al tiempo actual
    const currentTime = new Date();
    const expiryTime = new Date(currentTime.getTime() + 12000000 * 60 * 1000);
    data.expire = expiryTime.toISOString();
    data.isPaymentCorrect = false;
    data.isPaymentError = false;
    data.company = company
    await Util.delay(100);

    const encodedData = encodeURIComponent(DataBasicEncoder.encode(data));
    setDecodedData(data);
    setIsPayment(true)

  }





  return (
    <div>
      {/* <pre>{JSON.stringify(companyAux, null, 2)}</pre> */}
      {!isPayment && (
        <>
          <div className="d-flex justify-content-around align-items-center">
            <span style={{ fontSize: 20, fontWeight: 500 }}>Tengo oficina en Andorra</span>

            <CFormSwitch
              style={{ height: 30, width: 45 }}
              size="lg"
              disabled={
                loading
                || disabledByStatus || hasMadePurchases
              }
              defaultChecked={hasMadePurchases ? false : companyAux.hasCoworking}
              // label="Tengo una oficina en Andorra"
              id="123"
              onClick={(e: any) => setCompanyAux(prev => {
                return { ...prev, hasCoworking: e.target.checked };
              })}
            />
          </div>


          <div style={{ borderBottom: '1px solid lightgray', marginTop: 10 }}></div>

          {companyAux.hasCoworking && (
            <div className="fileUploadContainer" style={{ display: "flex", flexDirection: "column" }}>
              <CCol lg={9}>
                <p className="comment-note">
                  <b>Arrastra y suelta el contrato aquí o haz clic para seleccionarlo</b>. Este campo es para cargar una copia digital de tu justificante. Asegúrate de que el archivo cumpla con los formatos permitidos y no exceda el tamaño máximo.
                </p>
              </CCol>

              <DragDrop
                disabled={
                  loading
                  || disabledByStatus || isAdmin
                }
                specialCase={true}
                handleFiles={(item) => {
                  console.log(item)

                  if (companyAux) {
                    if (item && item.length > 0) {
                      setCompanyAux(prev => {
                        return { ...prev, urlContract: item[0].pathRelative }
                      })
                    } else {
                      setCompanyAux(prev => {
                        return { ...prev, urlContract: "" }
                      })
                    }
                  }
                }}

                ref={dragDropRef}
                styleDD={{ width: 300, height: 150 }}
                // classDD={'ploadContainer'}
                id={idDragDrop}
                isOnlyOneFile={true}
                showFileList={false}
                // disabled={loading || typeFilesList.filter(item => item.uploaded).length === countLimit}

                valueDefault={(companyAux && companyAux.urlContract) ? companyAux.urlContract : ""}
                directoryNameList={["fromReact", "company", "office"]}
                maxSizeMB={19}
                typeAllowed={[
                  "image/*",
                  "image/gif",
                  "image/jpeg",
                  "image/png",
                  "image/jpg",
                  "application/pdf"
                ]} />

              {companyAux && companyAux.urlContract && (
                <div style={{
                  display: 'flex',
                  alignItems: 'center',
                  cursor: 'pointer',
                  color: '#007BFF'
                }}
                  onClick={() => Util.downloadDocumentBackend(companyAux.urlContract, companyAux.urlContract)}
                >
                  <span style={{
                    color: COLORS.secondaryColor,
                    marginRight: '10px',
                    textDecoration: 'none'
                  }}
                    onMouseOver={(e: any) => e.target.style.textDecoration = 'underline'}
                    onMouseOut={(e: any) => e.target.style.textDecoration = 'none'}
                  >
                    Descargar documento
                  </span>
                  <svg
                    fill={COLORS.secondaryColor}
                    className="icon-download"
                    xmlns="http://www.w3.org/2000/svg"
                    height="1em"
                    viewBox="0 0 512 512">
                    <path d="M288 32c0-17.7-14.3-32-32-32s-32 14.3-32 32V274.7l-73.4-73.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0l128-128c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L288 274.7V32zM64 352c-35.3 0-64 28.7-64 64v32c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V416c0-35.3-28.7-64-64-64H346.5l-45.3 45.3c-25 25-65.5 25-90.5 0L165.5 352H64zm368 56a24 24 0 1 1 0 48 24 24 0 1 1 0-48z" />
                  </svg>
                </div>

              )}

            </div>
          )}

          {!companyAux.hasCoworking && !hasMadePurchases && (
            <>
              <div className="fileUploadContainer" style={{ display: "flex", flexDirection: "column" }}>
                <CCol lg={9}>
                  <p className="comment-note">
                    Si deseas realizar una compra a través de nuestros partners, se aplicará un pago único. <b>Haz clic en el botón de compra para continuar.</b>
                  </p>
                </CCol>
              </div>
            </>
          )}

          {/* {!companyAux.hasCoworking && hasMadePurchases && (
            <>
              <div className="fileUploadContainer" style={{ display: "flex", flexDirection: "column" }}>
                <CCol lg={9}>
                  <p className="comment-note">
                    Compra hecha
                  </p>
                </CCol>
              </div>
            </>
          )} */}





          <div style={{ marginTop: 100 }}></div>

          {!companyAux.hasCoworking && (
            <div style={{ display: "flex", marginBottom: 10, }}>
              <div style={{ display: "flex", flexDirection: "row", alignItems: "center", backgroundColor: hasMadePurchases ? COLORS.principalColor : "lightcoral", padding: "5px 10px", borderRadius: 5 }}>
                {hasMadePurchases ?
                  (
                    <>
                      <svg fill="white" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" /></svg>
                      <span style={{ marginLeft: 5, color: "white" }}>Compra realizada</span>
                    </>
                  )
                  :
                  (
                    <>
                      <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM175 175c-9.4 9.4-9.4 24.6 0 33.9l47 47-47 47c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l47-47 47 47c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-47-47 47-47c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-47 47-47-47c-9.4-9.4-24.6-9.4-33.9 0z" /></svg>
                      <span style={{ marginLeft: 5 }}>No se ha hecho la compra</span>
                    </>
                  )
                }
              </div>
            </div>
          )}


          {(companyAux.hasCoworking && !companyAux.urlContract) && (
            <div style={{ display: "flex", marginBottom: 10, }}>
              <div style={{ display: "flex", flexDirection: "row", alignItems: "center", backgroundColor: (companyAux.hasCoworking && companyAux.urlContract) ? null : "lightcoral", padding: "5px 10px", borderRadius: 5 }}>
                {/* {totalPercentage === 100 ?
     (
       <>
         <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" /></svg>
         <span style={{ marginLeft: 5 }}>Compra realizada</span>
       </>
     )
     :
     (
       <>
         <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM175 175c-9.4 9.4-9.4 24.6 0 33.9l47 47-47 47c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l47-47 47 47c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-47-47 47-47c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-47 47-47-47c-9.4-9.4-24.6-9.4-33.9 0z" /></svg>
         <span style={{ marginLeft: 5 }}>No se ha hecho la compra</span>
       </>
     )
   } */}
                {(companyAux.hasCoworking && !companyAux.urlContract) && (
                  <>
                    <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM175 175c-9.4 9.4-9.4 24.6 0 33.9l47 47-47 47c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l47-47 47 47c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-47-47 47-47c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-47 47-47-47c-9.4-9.4-24.6-9.4-33.9 0z" /></svg>
                    <span style={{ marginLeft: 5 }}>El documento no está subido</span>
                  </>
                )}
              </div>
            </div>
          )}



          <div style={{ display: "flex", justifyContent: "space-between" }}>
            {!disabledByStatus && (identity && identity.idRole !== 1) && (
              <CLoadingButton
                style={{ backgroundColor: COLORS.principalColor }}

                disabled={loading || disabledByStatus || (companyAux.hasCoworking && !companyAux.urlContract) || (!companyAux.hasCoworking && !hasMadePurchases)}
                loading={loading}
                onClick={() => {
                  // console.log("Mandando a revisión... sin hacer")
                  // updateUserStep()
                  /**
                   * NUEVO 16/10/23 He unificado dos funciones para evitar el uso
                   * de varios botones
                   */
                  handleOperations();
                }}>Mandar a revisión</CLoadingButton>
            )}

            {/* Ocultar el botón de compra si se ha comprado o está en revisión */}
            {!disabledByStatus && !hasMadePurchases && !companyAux.hasCoworking && (identity && identity.idRole !== 1) && (
              <CLoadingButton
                style={{ backgroundColor: COLORS.principalColor }}
                disabled={hasMadePurchases}
                // disabled={loading || typeFilesList.filter(item => item.uploaded).length === countLimit || !typeFileSelected || filesAlreadyUploaded.length === 0}
                loading={loading}
                onClick={() => {
                  goPayment();
                }}>
                <>
                  <svg
                    style={{ fill: "white" }}
                    xmlns="http://www.w3.org/2000/svg"
                    height="1em"
                    viewBox="0 0 576 512"><path d="M0 24C0 10.7 10.7 0 24 0H69.5c22 0 41.5 12.8 50.6 32h411c26.3 0 45.5 25 38.6 50.4l-41 152.3c-8.5 31.4-37 53.3-69.5 53.3H170.7l5.4 28.5c2.2 11.3 12.1 19.5 23.6 19.5H488c13.3 0 24 10.7 24 24s-10.7 24-24 24H199.7c-34.6 0-64.3-24.6-70.7-58.5L77.4 54.5c-.7-3.8-4-6.5-7.9-6.5H24C10.7 48 0 37.3 0 24zM128 464a48 48 0 1 1 96 0 48 48 0 1 1 -96 0zm336-48a48 48 0 1 1 0 96 48 48 0 1 1 0-96z" /></svg>
                  <span style={{ marginLeft: 8 }}>Ir a la compra</span>
                </>
              </CLoadingButton>
            )}

          </div>



        </>
      )}

      {
        isPayment && !hasMadePurchases && !disabledByStatus && (identity && identity.idRole !== 1)
        && (
          <PaymentPageModal data={decodedData} goBack={setIsPayment} />
        )
      }

    </div >
  )
}

export default Subcomponent5