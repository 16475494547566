import { CCol, CFormLabel } from "@coreui/react-pro"
import { useEffect, useRef, useState } from "react"
import { Company } from "../../../core/models/db/company"
import { useCompany } from "../../../core/hooks/db/company/useCompany"
import { LoginService } from "../../../core/services/login/login-service"
import { useDispatch, useSelector } from "react-redux"
import { setCurrentCompany } from "../@dashboardSteps/actions/dashboardStep-action"
import { DataBasicEncoder } from "../../../core/util/data-basic-encoder"
import { gsap } from 'gsap'
import { RootState } from "../../../core/store/store"
import { StatusStep } from "../@dashboardSteps/types"
import { useNavigate } from "react-router-dom"
import { BASE_ROUTE, ROUTE_NAME } from "../../../core/constants/route_name"


import "./companyselectadmin-style.scss"
import { useUser } from "../../../core/hooks/db/user/useUser"
import { User } from "../../../core/models/db/user"
import Autocomplete from "../autocomplete/Autocomplete"
import validationCompanySchema from "../../pages/@external-pages/registationAndPayment/company/company_validator_yup"
import { COLORS } from "../../../core/constants/colors"
import { Util } from "../../../core/util/util"
import { LOCAL_STORAGE_NAME } from "../../../core/constants/local_storage"


const CompanySelectAdmin = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { sidebarUnfoldable: unfoldable, sidebarShow } = useSelector((state: RootState) => state.ui);
  const { company: companyRedux, steps } = useSelector((state: RootState) => state.dashboardStep);

  const { getCompanyByUser, autocomplete: autoCompany } = useCompany();
  const { autocomplete: autoUser, checkAdmin } = useUser();

  const [companySelected, setCompanySelected] = useState<Company>(undefined);

  const [companyList, setCompanyList] = useState<Company[]>([]);
  /**
   * Se usa cuando el usuario decide quitar del autocomplete el usuario marcado
   * por lo tanto el listado ha de cambiar a todas las compañías en vez de la del usuario
   * anterior
   */
  const [allCompanyList, setAllCompanyList] = useState<Company[]>([]);

  const [userList, setUserList] = useState<User[]>([]);

  const [hideCompanySelect, setHideCompanySelect] = useState<boolean>(false)



  const [isOpen, setIsOpen] = useState(false);
  const [allStepsAreAccepted, setAllStepsAreAccepted] = useState<boolean>(false)

  const companyListRef = useRef(null);

  useEffect(() => {
    (async () => {

      await initParam();
    })();
  }, []);


  useEffect(() => {

    if (companyRedux &&
      companySelected &&
      companyList && companyList.length > 0 &&
      companyRedux?.id === companySelected?.id) {
      setCompanySelected({ ...companyRedux });
      setCompanyList(list => {
        let i = list.findIndex(com => com.id === companyRedux.id);
        list[i] = { ...companyRedux };
        return list;
      });
    }

  }, [companyRedux])



  const initParam = async () => {
    try {

      console.log("init select, companySelected", companySelected);
      console.log("init select, companyRedux", companyRedux)

      const identity = await LoginService.getIdentityAsync();
      const token = await LoginService.getTokenAsync();

      const result = await Promise.all([
        checkAdmin(token),
        autoUser(token, { page: 1, itemsPerPage: 10000000 }),
        autoCompany(token)
      ])


      console.log(result);

      // Si es admin
      if (result[0] && result[0].item !== true) {
        console.error("Credenciales erróneas")
        navigate("/login")
      }

      if (result[1] && result[1].item) {
        setUserList(result[1].item.rows.filter(u => u.idRoleFk !== 1));
      }


      if (result[2] && result[2].item) {
        // Se introduce el listado completo
        setCompanyList(result[2].item);
        setAllCompanyList(result[2].item);

        // Se obtiene backup
        let companyBackup = await _companyBackupLSAdmin("get");

        /**
         * TODO 26/10/23
         * Se ha comentado porque no es necesario inicializar con una compañía
         * en el administrador al menos que estuviera guardado
         */
        // setCompanySelected(companyBackup ? companyBackup : result[2].item[0]);
        // dispatch(setCurrentCompany(companyBackup ? companyBackup : result[2].item[0]));

        // handleSelectCompany(companyBackup ? companyBackup : result[2].item[0]);

        console.log("Estás en select, mira el objeto", companyBackup)

        if (companyBackup) {

          setCompanySelected(companyBackup);
          dispatch(setCurrentCompany(companyBackup));
          handleSelectCompany(companyBackup);

          console.warn("YA NO BORRO MAS -----------------")
          // _companyBackupLS("remove")
        }


      }
      // console.log(result[1].item);
      // console.log(result[2].item);

      // const result = await getCompanyByUser(token, idUser);

    } catch (error) {

    }
  }


  const _companyBackupLSAdmin = async (action: "save" | "get" | "remove", company?: Company) => {
    try {
      let result;
      let identity = await LoginService.getIdentityAsync();
      switch (action) {
        case "save": {
          const currentTime = new Date();
          const expiryTime = new Date(currentTime.getTime() + 10080 * 60 * 1000);
          let expire = expiryTime.toISOString();
          result = DataBasicEncoder.encode({ company, expire });
          localStorage.setItem(`${LOCAL_STORAGE_NAME.GET_COMPANY_ADMIN}${identity.idUser}`, result); // Modificando la clave para que sea específica para el admin
          return company;
        }
        case "get": {
          let ls = localStorage.getItem(`${LOCAL_STORAGE_NAME.GET_COMPANY_ADMIN}${identity.idUser}`);
          if (ls) {
            let { company, expire }: any = DataBasicEncoder.decode(ls);

            // Validamos solo el tiempo de expiración.
            const currentTime = new Date();
            const expiryTime: any = new Date(expire);
            if (!expiryTime || expiryTime === "Invalid Date" || currentTime > expiryTime) {
              localStorage.removeItem(`${LOCAL_STORAGE_NAME.GET_COMPANY_ADMIN}${identity.idUser}`);
              console.error("Los datos han expirado.");
              return undefined;
            } else {
              return company;
            }
          } else {
            return undefined;
          }
        }
        case "remove": {
          localStorage.removeItem(`${LOCAL_STORAGE_NAME.GET_COMPANY_ADMIN}${identity.idUser}`);
          break;
        }
      }
    } catch (e: any) {
      console.error(e);
      return undefined;
    }
  }




  const handleSelectCompany = (company: Company) => {

    if (!company) {
      dispatch(setCurrentCompany(undefined));
      setCompanySelected(undefined);
      _companyBackupLSAdmin("remove")

      if (
        window?.location?.href.includes("documentos")
        || window?.location?.href.includes("compania")) {
        // TODO: Ya se ocupan las respectivas pantallas de mandarlo al dashboard
        // console.warn("Tiene que volver al Dashboard");
        // navigate(BASE_ROUTE);
      }

    } else {
      _companyBackupLSAdmin("save", company);
      dispatch(setCurrentCompany(company));
      setCompanySelected(company);
    }


  }


  const handleSelectUser = async (user: User) => {

    try {
      const token = await LoginService.getTokenAsync();

      const result = await getCompanyByUser(token, user.id)

      if (result && result.ok) {
        setCompanyList(result.item);

        // let companyBackup = await _companyBackupLSAdmin("get");
        setCompanySelected(result.item[0]);
        dispatch(setCurrentCompany(result.item[0]));

        // Es redundante, pero se dej
        handleSelectCompany(result.item[0]);

        setHideCompanySelect(true);
        setTimeout(() => {
          setHideCompanySelect(false);
        }, 1)

      }

    } catch (e) {
      setCompanyList(allCompanyList);
      console.error(e)
    }
  }





  // style={{ backgroundColor: isOpen ? "#213444" : null }}
  return (
    <div style={{ marginBottom: 20 }}>

      {userList.length > 0 && companyList.length > 0 && (
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
          <CCol lg={11}>
            <CFormLabel htmlFor="basic-url"
              style={{
                fontFamily: "Raleway",
                color: COLORS.secondaryColor,
                fontWeight: 500,
                fontSize: 18,
                marginTop: 20
              }}>Usuario</CFormLabel>
            {/* <CInputGroup> */}
            <Autocomplete
              styleWesingular={true}
              defaultData={userList}
              placeholder="Elige usuario"

              id="basic-url"
              table='users'
              // disabled={(disabled && typeAction !== "add") || loadingButton}
              name={'company-c-select-admin'}
              // defaultValue={{ key: "id", value: companyChoosed.id }}
              onChange={(e: any) => {
                console.log("LA ES", e)
                if (e && e.target.value) {
                  // setCompanyChoosed(e.target.item)
                  handleSelectUser(e.target.item)
                } else {
                  /**
                   * Si deselecciona el usuario el listado de compañías vuelve a ser el de todos
                   */
                  // setCompanyList(allCompanyList);
                  // setCompanyChoosed(undefined)
                }
              }}
              onBlur={undefined}
            />
            {/* </CInputGroup> */}
          </CCol>

          <div style={{ borderBottom: "1px solid #323343", marginTop: 12, marginBottom: 12, width: "90%" }}></div>

          <CCol lg={11}>

            <CFormLabel htmlFor="basic-url" style={{
              fontFamily: "Raleway",
              color: COLORS.secondaryColor,
              fontWeight: 500,
              fontSize: 18,
            }}>Compañía</CFormLabel>
            {/* <CInputGroup> */}
            {!hideCompanySelect && (
              <Autocomplete
                // styleWesingular={true}
                styleWesingular={true}
                defaultValue={companySelected ? { key: "id", value: companySelected.id } : null}

                defaultData={companyList}
                placeholder="Elige compañía"

                id="basic-url"
                table='companies'
                // disabled={(disabled && typeAction !== "add") || loadingButton}
                name={'company-c-select-admin'}

                onChange={(e: any) => {

                  console.log(e)
                  if (e && e.target.value) {
                    handleSelectCompany(e.target.item);
                    // setCompanyChoosed(e.target.item)
                  } else {
                    handleSelectCompany(undefined);

                    // handleSelectCompany(undefined);
                    // setCompanyChoosed(undefined)
                  }
                }}
                onBlur={undefined} />
            )}



            {/* </CInputGroup> */}
          </CCol>


        </div>


      )}
    </div >
  )
}

export default CompanySelectAdmin

