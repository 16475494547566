// ... (otros imports)

import { cibCcVisa, cibCcMastercard, cibCcAmex, cibCcJcb, cibCcDiscover, cibCcDinersClub } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import { CFormInput, CInputGroup, CInputGroupText, CSpinner } from "@coreui/react-pro";
import { useStripe, useElements, CardNumberElement, CardExpiryElement, CardCvcElement } from "@stripe/react-stripe-js";
import { CSSProperties, useContext, useEffect, useRef, useState } from "react";
import { PaymentContext } from "../../contexts/PaymentContext";
import { DataRegistration } from "../../interfaces/interfaces";
import { usePurchase } from "../../../../../../core/hooks/db/purchase/usePurchase";
import { usePayment } from "../../../../../../core/hooks/stripe/usePayment";
import { useUser } from "../../../../../../core/hooks/db/user/useUser";
import { LoginService } from "../../../../../../core/services/login/login-service";
import { AuthContext } from "../../../../../../core/contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import { BASE_ROUTE } from "../../../../../../core/constants/route_name";
import { DataBasicEncoder } from "../../../../../../core/util/data-basic-encoder";
import { COLORS } from "../../../../../../core/constants/colors";


const ELEMENT_OPTIONS = {
    style: {
        base: {
            color: "#32325d",
            lineHeight: '24px',
            fontFamily: 'Helvetica Neue, sans-serif',
            fontSmoothing: "antialiased",
            fontSize: "16px",
            "::placeholder": {
                color: "#aab7c4"
            },
            iconColor: "#c4f0ff"
        },
        invalid: {
            color: "#fa755a",
            iconColor: "#fa755a"
        },

    }
};

interface CheckFormProps {
    dataRegistration: DataRegistration;
    isModal?: boolean
}

const CheckoutForm = ({ dataRegistration, isModal }: CheckFormProps) => {

    console.log(dataRegistration)
    const { checkToken } = useContext(AuthContext);
    const navigate = useNavigate();

    const [loadingButton, setLoadingButton] = useState(false);
    const [paymentCompleted, setPaymentCompleted] = useState(false);
    const [paymentError, setPaymentError] = useState(false);

    useEffect(() => {
        setPaymentCompleted(dataRegistration?.isPaymentCorrect);
        setPaymentError(dataRegistration?.isPaymentError);
    }, [dataRegistration])

    const [cardholderName, setCardholderName] = useState('');
    const [isCardholderNameFocused, setCardholderNameFocused] = useState(false);

    const stripe = useStripe();
    const elements = useElements();


    const [isCardNumberFocused, setCardNumberFocused] = useState(false);
    const [isExpiryFocused, setExpiryFocused] = useState(false);
    const [isCvcFocused, setCvcFocused] = useState(false);

    const [cardBrand, setCardBrand] = useState<string | null>(null);

    const [isCardValid, setIsCardValid] = useState(false);
    const [isExpiryComplete, setIsExpiryComplete] = useState(false);
    const [isCvcComplete, setIsCvcComplete] = useState(false);

    const [cardError, setCardError] = useState<string | null>(null);


    const { addPaymentByStripe, addPaymentMultipleByStripe } = usePayment();
    const { addPurchase } = usePurchase();
    const { updateUser } = useUser();


    const handleCardNumberChange = (event) => {
        if (event.error) {
            setCardError(event.error.message);
        } else {
            setCardError(null);
        }

        console.log(event.complete)
        setIsCardValid(event.complete);
        setCardBrand(event.brand);

    };

    const handleExpiryChange = (event) => {
        setIsExpiryComplete(event.complete);
    };

    const handleCvcChange = (event) => {
        setIsCvcComplete(event.complete);
    };


    const handleCardNumberFocus = () => {
        setCardNumberFocused(true);
    };

    const handleCardNumberBlur = () => {
        setCardNumberFocused(false);
    };

    const handleCardholderNameFocus = () => {
        setCardholderNameFocused(true);
    };

    const handleCardholderNameBlur = () => {
        setCardholderNameFocused(false);
    };

    const handleExpiryFocus = () => {
        setExpiryFocused(true);
    };

    const handleExpiryBlur = () => {
        setExpiryFocused(false);
    };

    const handleCvcFocus = () => {
        setCvcFocused(true);
    };

    const handleCvcBlur = () => {
        setCvcFocused(false);
    };


    const getCardIcon = (brand: string | null) => {
        switch (brand) {
            case 'visa':
                return cibCcVisa;
            case 'mastercard':
                return cibCcMastercard;
            case 'amex':
                return cibCcAmex;
            case 'jcb':
                return cibCcJcb;
            case 'discover':
                return cibCcDiscover;
            case 'diners':
                return cibCcDinersClub;
            default:
                return null;  // En caso de que no se reconozca el tipo o no haya tipo aún
        }
    };



    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (!stripe || !elements) {
            return;
        }
        setLoadingButton(true);

        const cardNumberElement = elements.getElement(CardNumberElement);
        const result = await stripe.createPaymentMethod({
            type: 'card',
            card: cardNumberElement,
            billing_details: {
                name: cardholderName,
                email: (dataRegistration && dataRegistration.user)
                    ? dataRegistration.user.email
                    : ""
            }
        });

        // Aquí abajo iría el pago y posteriormente el registro de compra
        if (result.error) {
            console.error(result.error);
        } else {
            const token = await LoginService.getTokenAsync();
            const currentTime = new Date();
            const expiryTime = new Date(currentTime.getTime() + 12000000 * 60 * 1000);

            console.log("result", result)
            console.log(result.paymentMethod);
            const paymentMethod = result.paymentMethod;
            const idUser = dataRegistration.user.id;

            const idProduct = dataRegistration.product
                ? dataRegistration.product.id
                : undefined;

            const idProductList = dataRegistration.productList && dataRegistration.productList.length
                ? dataRegistration.productList.map(row => row.id)
                : [];

            let resultPayment;
            try {
                if (!isModal) {
                    resultPayment = await addPaymentByStripe(token, paymentMethod, idProduct, idUser);
                } else {
                    resultPayment = await addPaymentMultipleByStripe(token, paymentMethod, idProductList, idUser);
                }
            } catch (e: any) {
                setLoadingButton(false);
                let data: any = {
                    product: {
                        name: dataRegistration.product.name
                    },
                    user: {
                        email: dataRegistration.user.email
                    },
                    expire: expiryTime,
                    isPaymentCorrect: true
                }
                if (!isModal) {
                    const encodedData = encodeURIComponent(DataBasicEncoder.encode(data));
                    navigate(`${BASE_ROUTE}/pago/${encodedData}`, { state: { encodedData }, replace: true });
                } else {
                    console.warn("Hubo un error durante la compra")
                    setPaymentError(true);
                }
            }

            if (resultPayment && resultPayment.ok) {
                if (dataRegistration.product) {
                    await Promise.all([
                        addPurchase(token, {
                            idCompanyFk: dataRegistration.company.id,
                            idProductFk: idProduct,
                            idUserFk: idUser,
                            paymentDate: new Date(),
                            registrationDate: new Date(),
                            idTypeProductFk: dataRegistration.product.idTypeProductFk
                        }),
                        updateUser(token, {
                            id: idUser,
                            stripeConfig: btoa(JSON.stringify(resultPayment.item)),
                            stripeExpiredDate: null
                        })
                    ])
                } else if (dataRegistration.productList && dataRegistration.productList.length > 0) {

                    const purchasePromises = dataRegistration.productList.map(product => {
                        return addPurchase(token, {
                            idCompanyFk: dataRegistration.company.id,
                            idProductFk: product.id,
                            idUserFk: idUser,
                            paymentDate: new Date(),
                            registrationDate: new Date(),
                            idTypeProductFk: product.idTypeProductFk
                        });
                    });

                    await Promise.all([
                        ...purchasePromises,
                        updateUser(token, {
                            id: idUser,
                            stripeConfig: btoa(JSON.stringify(resultPayment.item)),
                            stripeExpiredDate: null
                        })
                    ]);
                }


                dataRegistration.isPaymentCorrect = true;
                dataRegistration.expire = expiryTime;
                let data: any = {
                    product: dataRegistration.product,
                    user: {
                        email: dataRegistration.user.email
                    },
                    expire: dataRegistration.expire,
                    isPaymentCorrect: true
                }

                setLoadingButton(false);

                if (!isModal) {
                    const encodedData = encodeURIComponent(DataBasicEncoder.encode(data));
                    navigate(`${BASE_ROUTE}/pago/${encodedData}`, { state: { encodedData }, replace: true });
                } else {
                    setPaymentCompleted(true)
                    console.warn("Compra hecha")
                }
            }

        }
    }

    // const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    //     event.preventDefault();

    //     if (!stripe || !elements) {
    //         return;
    //     }
    //     setLoadingButton(true);

    //     const cardNumberElement = elements.getElement(CardNumberElement);
    //     const result = await stripe.createPaymentMethod({
    //         type: 'card',
    //         card: cardNumberElement,
    //         billing_details: {
    //             name: cardholderName,
    //             email: (dataRegistration && dataRegistration.user)
    //                 ? dataRegistration.user.email
    //                 : ""
    //         }
    //     });

    //     // Aquí abajo iría el pago y posteriormente el registro de compra

    //     if (result.error) {
    //         console.error(result.error);
    //     } else {
    //         const token = await LoginService.getTokenAsync();
    //         const currentTime = new Date();
    //         const expiryTime = new Date(currentTime.getTime() + 12000000 * 60 * 1000);

    //         console.log("result", result)
    //         console.log(result.paymentMethod);
    //         const paymentMethod = result.paymentMethod;
    //         const idUser = dataRegistration.user.id;
    //         const idProduct = dataRegistration.product.id;

    //         let resultPayment;
    //         try {
    //             resultPayment = await addPaymentByStripe(token, paymentMethod, idProduct, idUser);
    //         } catch (e) {
    //             setLoadingButton(false);
    //             let data: any = {
    //                 product: {
    //                     name: dataRegistration.product.name
    //                 },
    //                 user: {
    //                     email: dataRegistration.user.email
    //                 },
    //                 expire: expiryTime,
    //                 isPaymentCorrect: true
    //             }

    //             const encodedData = encodeURIComponent(DataBasicEncoder.encode(data));
    //             navigate(`${BASE_ROUTE}/pago/${encodedData}`, { state: { encodedData }, replace: true });
    //             // setPaymentError(true);
    //         }

    //         if (resultPayment && resultPayment.ok) {
    //             // const resultPurchase = 

    //             await Promise.all([
    //                 addPurchase(token, {
    //                     idProductFk: idProduct,
    //                     idUserFk: idUser,
    //                     paymentDate: new Date(),
    //                     registrationDate: new Date(),
    //                     idTypeProductFk: dataRegistration.product.idTypeProductFk
    //                 }),
    //                 updateUser(token, {
    //                     id: idUser,
    //                     stripeConfig: btoa(JSON.stringify(resultPayment.item)),
    //                     stripeExpiredDate: null
    //                 })
    //             ])

    //             dataRegistration.isPaymentCorrect = true;
    //             dataRegistration.expire = expiryTime;
    //             let data: any = {
    //                 product: dataRegistration.product,
    //                 user: {
    //                     email: dataRegistration.user.email
    //                 },
    //                 expire: dataRegistration.expire,
    //                 isPaymentCorrect: true
    //             }
    //             // Salga otra pantalla de pago completado
    //             // navigate(`/dashboard`);
    //             setLoadingButton(false);
    //             // setPaymentCompleted(true);

    //             const encodedData = encodeURIComponent(DataBasicEncoder.encode(data));
    //             navigate(`${BASE_ROUTE}/pago/${encodedData}`, { state: { encodedData }, replace: true });
    //         }

    //     }
    // }





    if (paymentCompleted) {
        return (
            <div className="payment-completed">
                <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M323.8 34.8c-38.2-10.9-78.1 11.2-89 49.4l-5.7 20c-3.7 13-10.4 25-19.5 35l-51.3 56.4c-8.9 9.8-8.2 25 1.6 33.9s25 8.2 33.9-1.6l51.3-56.4c14.1-15.5 24.4-34 30.1-54.1l5.7-20c3.6-12.7 16.9-20.1 29.7-16.5s20.1 16.9 16.5 29.7l-5.7 20c-5.7 19.9-14.7 38.7-26.6 55.5c-5.2 7.3-5.8 16.9-1.7 24.9s12.3 13 21.3 13L448 224c8.8 0 16 7.2 16 16c0 6.8-4.3 12.7-10.4 15c-7.4 2.8-13 9-14.9 16.7s.1 15.8 5.3 21.7c2.5 2.8 4 6.5 4 10.6c0 7.8-5.6 14.3-13 15.7c-8.2 1.6-15.1 7.3-18 15.1s-1.6 16.7 3.6 23.3c2.1 2.7 3.4 6.1 3.4 9.9c0 6.7-4.2 12.6-10.2 14.9c-11.5 4.5-17.7 16.9-14.4 28.8c.4 1.3 .6 2.8 .6 4.3c0 8.8-7.2 16-16 16H286.5c-12.6 0-25-3.7-35.5-10.7l-61.7-41.1c-11-7.4-25.9-4.4-33.3 6.7s-4.4 25.9 6.7 33.3l61.7 41.1c18.4 12.3 40 18.8 62.1 18.8H384c34.7 0 62.9-27.6 64-62c14.6-11.7 24-29.7 24-50c0-4.5-.5-8.8-1.3-13c15.4-11.7 25.3-30.2 25.3-51c0-6.5-1-12.8-2.8-18.7C504.8 273.7 512 257.7 512 240c0-35.3-28.6-64-64-64l-92.3 0c4.7-10.4 8.7-21.2 11.8-32.2l5.7-20c10.9-38.2-11.2-78.1-49.4-89zM32 192c-17.7 0-32 14.3-32 32V448c0 17.7 14.3 32 32 32H96c17.7 0 32-14.3 32-32V224c0-17.7-14.3-32-32-32H32z" /></svg>
                <div className="payment-message">
                    Pago completado
                    <span className="positive-note">¡Gracias por tu compra! Esperamos que disfrutes de tu producto.</span>
                </div>
            </div>
        )
    }

    if (paymentError) {
        return (
            <div className="payment-error">
                <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM175 175c-9.4 9.4-9.4 24.6 0 33.9l47 47-47 47c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l47-47 47 47c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-47-47 47-47c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-47 47-47-47c-9.4-9.4-24.6-9.4-33.9 0z" /></svg>
                <div className="payment-message-error">
                    Error en el pago
                    <span className="error-note">Lo sentimos, hubo un problema procesando tu pago. Por favor, intentalo más tarde.</span>
                </div>
            </div>
        )
    }


    return (
        <form onSubmit={handleSubmit} style={styles.form}>

            <div style={styles.inputWrapper}>
                {/* <pre>{JSON.stringify(dataRegistration, null, 2)}</pre> */}
                {/* <label htmlFor="cardholderName" style={styles.label}>Nombre del titular de la tarjeta:</label> */}
                <CInputGroup className="mb-3">
                    {/* <CInputGroupText id="basic-addon1">@</CInputGroupText> */}
                    <CFormInput
                        disabled
                        readOnly
                        value={(dataRegistration && dataRegistration.user) ? dataRegistration.user.email : ""}
                        style={styles.input}
                        placeholder="Email"
                        aria-label="Email"
                        aria-describedby="basic-addon1" />
                </CInputGroup>
            </div>
            <div style={{ ...styles.inputWrapper }}>


                {/* {JSON.stringify(isCardholderNameFocused, null, 2)} */}
                <label htmlFor="cardholderName" style={styles.label}>Nombre del titular de la tarjeta</label>
                <CFormInput
                    id="cardholderName"
                    type="text"
                    onFocus={handleCardholderNameFocus}
                    onBlur={handleCardholderNameBlur}
                    onChange={e => setCardholderName(e.target.value)}
                    placeholder="Nombre completo"
                    style={{
                        ...styles.input,
                        border: isCardholderNameFocused ? '2px solid  #32325d' : '1px solid #e6e6e6',
                        boxShadow: 'none'
                    }}

                    required
                />


            </div>
            <label style={styles.label}>Información tarjeta</label>
            <div
                style={{
                    ...styles.cardContainer,
                    border: isCardNumberFocused ? '2px solid #32325d' : '1px solid #e6e6e6'
                }}
            >

                <div style={styles.cardNumberContainer}>
                    <CardNumberElement
                        options={ELEMENT_OPTIONS}
                        onFocus={handleCardNumberFocus}
                        onBlur={handleCardNumberBlur}
                        onChange={handleCardNumberChange} />
                </div>
                {cardBrand && <CIcon icon={getCardIcon(cardBrand)} size={'lg'} style={styles.cardIcon} />}

            </div>

            <div
                style={{
                    ...styles.combinedInputContainer,
                    border: (isExpiryFocused || isCvcFocused) ? '2px solid #32325d' : '1px solid #e6e6e6'
                }}
            >
                <div style={styles.halfInput}>
                    <CardExpiryElement
                        options={ELEMENT_OPTIONS}
                        onFocus={handleExpiryFocus}
                        onBlur={handleExpiryBlur}
                        onChange={handleExpiryChange}
                    />
                </div>

                <div style={styles.lastHalfInput}>
                    <CardCvcElement
                        options={ELEMENT_OPTIONS}
                        onFocus={handleCvcFocus}
                        onBlur={handleCvcBlur}
                        onChange={handleCvcChange}  // añadir esto
                    />
                </div>
            </div>

            <button

                type="submit"
                disabled={!stripe || !isCardValid || !isExpiryComplete || !isCvcComplete || !cardholderName
                    || loadingButton || paymentCompleted}
                style={{
                    ...styles.button,
                    backgroundColor: (!stripe || !isCardValid || !isExpiryComplete || !isCvcComplete || !cardholderName
                        || loadingButton || paymentCompleted)
                        ? "rgba(50, 51, 67, 0.4)"  
                        : COLORS.quaternaryColor
                }}
            >
                {loadingButton && (
                    <CSpinner component="span" size="sm" aria-hidden="true" />
                )}
                {!loadingButton && (
                    <span>Continuar</span>
                )}
            </button>

        </form>
    );
};


const styles = {
    form: {
        background: '#ffffff',
        borderRadius: '8px',
        padding: '20px',
        maxWidth: '400px',
        margin: '0 auto',
        backgroundColor: "whitesmoke"

    },
    inlineElements: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '20px'
    },
    inputWrapper: {
        marginBottom: '20px',
    },
    label: {
        marginBottom: '10px',
        display: 'block',
        fontSize: '14px',
        fontWeight: 'bold',
        color: '#32325d',
        letterSpacing: '1px'
    },
    input: {
        width: '100%',
        padding: '10px 15px',
        // borderRadius: '4px',
        border: '1px solid #ccc'
    },

    button: {
        width: '100%',
        marginTop: '20px',
        background: '#32325d',
        color: '#ffffff',
        padding: '15px 20px',   // aumentar el padding para un botón más grande
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
        fontSize: '16px',
        transition: 'background 0.3s ease'
    },
    halfInput: {
        padding: '10px',
        flex: 1,
        borderRight: '1px solid #e6e6e6' // Esto agrega una línea divisoria en medio
    },

    lastHalfInput: {
        padding: '10px',
        flex: 1,
        borderRight: 'none' // Elimina el borde derecho
    },
    cardContainer: {
        display: 'flex',  // Añade display flex
        alignItems: 'center', // Alinea verticalmente al centro
        border: '1px solid #e6e6e6',
        borderRadius: '8px 8px 0 0',
        padding: '10px',
        backgroundColor: "white"

    },
    cardIcon: {
        marginRight: '10px',
    },
    cardNumberElement: {
        flex: 1,  // Permite que se expanda

    },
    cardNumberContainer: {
        flex: 1,
        marginLeft: '10px', // Añade un margen a la izquierda para separarlo del ícono

    },
    // combinedInputContainer: {
    //     marginTop: 0, // elimina el margen superior
    //     display: 'flex',
    //     justifyContent: 'space-between',
    //     border: '1px solid #e6e6e6',
    //     borderTop: 'none', // elimina el borde superior
    //     borderRadius: '0 0 8px 8px', // solo redondea las esquinas inferiores
    //     overflow: 'hidden' // Para asegurarse de que los bordes de los hijos no sobresalgan
    // },
    combinedInputContainer: {
        marginTop: 0,
        display: 'flex',
        justifyContent: 'space-between',
        borderTop: '1px solid #e6e6e6',
        borderRight: '1px solid #e6e6e6',
        borderBottom: '1px solid #e6e6e6',
        borderLeft: '1px solid #e6e6e6',
        borderRadius: '0 0 8px 8px',
        overflow: 'hidden',
        backgroundColor: "white"

    },
};

export default CheckoutForm;
