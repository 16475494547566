import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { Pagination } from '../../../models/pagination';
import { ReasonForDeactivationProps } from '../../check-table/useCheckTable';
import { NotaryAppointment } from '../../../models/db/notaryAppointment';
import { NotaryAppointmentService } from '../../../services/db/notaryAppointment/notaryAppointment.service';





export const useNotaryAppointment = () => {




    const getByUserAndCompany = (token: any, idUser: number, idCompany: number) => {
        return new Promise<any>((res, rej) => {
            NotaryAppointmentService.getByUserAndCompany(token, idUser, idCompany)
                .then(({ data }) => {
                    console.log(data)
                    if (data.ok) {
                        res(data);
                    } else {
                        res(undefined)
                    }
                })
                .catch((e) => {
                    console.log(e)
                    rej(e)
                })

        })
    }


    const getAllIdNotaryAppointment = (token: any, reasonForDeactivation: ReasonForDeactivationProps[]) => {
        return new Promise<any>((res, rej) => {
            NotaryAppointmentService.getAllId(token, reasonForDeactivation)
                .then(({ data }) => {
                    console.log(data)
                    if (data.ok) {
                        res(data);
                    } else {
                        res(undefined)
                    }
                })
                .catch((e) => {
                    console.log(e)
                    rej(e)
                })

        })
    }

    const getNotaryAppointmentById = (token, id) => {
        return new Promise<any>((res, rej) => {
            NotaryAppointmentService.get(token, id)
                .then(({ data }) => {
                    console.log(data)
                    if (data.ok) {
                        res(data);
                    } else {
                        res(undefined)
                    }
                })
                .catch((e) => {
                    console.log(e)
                    rej(e)
                })

        })
    }


    const getNotaryAppointment = (token, pagination: Pagination = { page: 1, itemsPerPage: 5 }) => {
        return new Promise<any>((res, rej) => {
            NotaryAppointmentService.getAll(token, pagination)
                .then(({ data }) => {
                    console.log(data)
                    if (data.ok) {
                        res(data);
                    } else {
                        res(undefined)
                    }
                })
                .catch((e) => {
                    console.log(e)
                    rej(e)
                })

        })
    }

    const getNotaryAppointmentAutocomplete = (token, pagination: Pagination = { page: 1, itemsPerPage: 5 }) => {
        return new Promise<any>((res, rej) => {
            NotaryAppointmentService.getAutocomplete(token)
                .then(({ data }) => {
                    // console.log(data)
                    if (data.ok) {
                        res(data);
                    } else {
                        res(undefined)
                    }
                })
                .catch((e) => {
                    console.log(e)
                    rej(e)
                })

        })
    }


    const addNotaryAppointment = (token, user: NotaryAppointment) => {
        return new Promise<any>((res, rej) => {
            NotaryAppointmentService.add(token, { ...user })
                .then(({ data }) => {
                    // console.log(data)
                    if (data.ok) {
                        res(data);
                    } else {
                        res(undefined)
                    }
                })
                .catch((e) => {
                    console.log(e)
                    rej(e)
                })

        })
    }


    const updateNotaryAppointment = (token, user: NotaryAppointment) => {
        return new Promise<any>((res, rej) => {
            NotaryAppointmentService.update(token, { ...user })
                .then(({ data }) => {
                    console.log(data)
                    if (data.ok) {
                        res(data);
                    } else {
                        res(undefined)
                    }
                })
                .catch((e) => {
                    console.log(e)
                    rej(e)
                })

        })
    }

    const deleteNotaryAppointment = (token, idList: string[] | number[], action: boolean) => {
        return new Promise<any>((res, rej) => {
            NotaryAppointmentService.delete(token, idList, action)
                .then(({ data }) => {
                    console.log(data)
                    if (data.ok) {
                        res(data);
                    } else {
                        res(undefined)
                    }
                })
                .catch((e) => {
                    console.log(e)
                    rej(e)
                })

        })
    }


    return {
        getAllIdNotaryAppointment,
        getNotaryAppointmentById,
        getNotaryAppointment,
        getByUserAndCompany,
        addNotaryAppointment,
        updateNotaryAppointment,
        deleteNotaryAppointment,

        get: getNotaryAppointment,
        add: addNotaryAppointment,
        update: updateNotaryAppointment,
        autocomplete: getNotaryAppointmentAutocomplete,
        delete: deleteNotaryAppointment
    }
}