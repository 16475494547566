import { useEffect, useRef, useState } from 'react';
import { useUser } from '../../../../../../core/hooks/db/user/useUser';
import { LoginService } from '../../../../../../core/services/login/login-service';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../../core/store/store';
import { CContainer, CFormTextarea, CLoadingButton, CRow } from '@coreui/react-pro';
import { cilBellExclamation, cilPencil } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import ModalCover from '../../../../modalCover/ModalCover';
import Autocomplete from '../../../../autocomplete/Autocomplete';
import { UserSubstep } from '../../../../../../core/models/db/steps/userSubstep';
import { StatusSubstep as StatusSubstepWhere } from '../../../../../../core/models/db/steps/statusSubstep';
import { useUserSubstep } from '../../../../../../core/hooks/db/userSubstep/useUserSubstep';
import { StatusStep, StatusSubstep } from '../../../types';
import { updateStep } from '../../../actions/dashboardStep-action';
import { COLORS } from '../../../../../../core/constants/colors';


const AddCommentForStatus = ({ substepDashboard }) => {

    const { checkAdmin } = useUser();
    const { getUserSubstepById, updateUserSubstep } = useUserSubstep();

    const [isAdmin, setIsAdmin] = useState(undefined);

    const [showModal, setShowModal] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [userSubstep, setUserSubstep] = useState<UserSubstep>(undefined);
    const [statusSubstep, setStatusSubstep] = useState<StatusSubstepWhere>(undefined);




    // REDUX: Paso y subpaso actual
    const dispatch = useDispatch();
    const { steps, company, idCompany, idUser, currentStepId, currentSubstepId } = useSelector((state: RootState) => state.dashboardStep);

    const currentStep = steps.find(s => s.id === currentStepId);
    const currentSubstep = substepDashboard;
    const [disabled, setDisabled] = useState(false);


    useEffect(() => {
        (async () => {
            await initPage();
        })()
    }, []);

    const initPage = async () => {
        try {
            setLoading(true);
            const token = await LoginService.getTokenAsync();
            const result = await checkAdmin(token);
            const substep = await getUserSubstepById(token, substepDashboard.id);
            setUserSubstep(substep.item)
            setIsAdmin(result && result.item === true)
            setLoading(false);

        } catch (error) {
            setLoading(false);
            console.error(error);
        }
    }


    const onChange = (key, value) => {
        setUserSubstep({ ...userSubstep, [key]: value })
    }

    const updateUserStep = async () => {
        try {
            const token = await LoginService.getTokenAsync();
            const ID_STATUS = statusSubstep.id;
            const STATUS = StatusSubstep[statusSubstep.name];

            setLoading(true);

            if (STATUS !== "RECHAZADO") {
                userSubstep.comment = "";
            }

            const result = await updateUserSubstep(token,
                {
                    id: +userSubstep.id,
                    idStatusSubstepFk: ID_STATUS,
                    comment: userSubstep.comment
                })
            if (result && result.ok) {

                let step = currentStep;
                let substep = currentSubstep;


                /**
                 * 31/10/23
                 * 
                 * En esta parte del código, el moderador puede elegir diferentes estados
                 * Si es Rechazado, todo tiene que cambiar a rechazado, tanto el subpaso como el paso
                 */


                // Actualiza el estado del substep
                let index = step.substeps.findIndex(sub => sub.id === currentSubstep.id);
                step.substeps[index].status = StatusStep[statusSubstep.name];

                // Si hay varios substeps, vamos a determinar el estado del step basándonos en las prioridades
                if (step.substeps.length > 1) {
                    // Valores por defecto
                    let hasEnRevision = false;
                    let hasRequerido = false;

                    for (let row of step.substeps) {
                        if (row.status === "RECHAZADO") {
                            step.status = StatusStep["RECHAZADO"];
                            break;
                        } else if (row.status === "EN_REVISION") {
                            hasEnRevision = true;
                        } else if (row.status === "REQUERIDO") {
                            hasRequerido = true;
                        }
                    }

                    // Luego de iterar, determinamos el estado en base a las prioridades
                    if (step.status !== StatusStep["RECHAZADO"]) {
                        if (hasEnRevision) {
                            step.status = StatusStep["EN_REVISION"];
                        } else if (hasRequerido) {
                            step.status = StatusStep["PENDIENTE"];
                        } else {
                            step.status = StatusStep[statusSubstep.name];
                        }
                    }
                } else {
                    step.status = StatusStep[statusSubstep.name];
                }

                substep.status = StatusSubstep[statusSubstep.name];


                dispatch(updateStep(step, substep));
                setShowModal(false);
                setLoading(false);


            } else {
                throw Error("Hubo un error", result)
            }
        } catch (error) {
            console.error(error);
            setShowModal(false);
            setLoading(false);

        }
    }



    // ES ADMIN
    // ES ADMIN
    // ES ADMIN
    // if (isAdmin !== undefined && isAdmin && currentSubstep.status === StatusSubstep.EN_REVISION) {
    if (isAdmin !== undefined && isAdmin
        && (currentSubstep.status === StatusSubstep.EN_REVISION
            || currentSubstep.status === StatusSubstep.RECHAZADO
            || currentSubstep.status === StatusSubstep.MODIFICAR)) {

        // if (isAdmin) {

        return (
            <div>
                <div
                    className="triangle-indicator-admin"

                    onClick={() => setShowModal(true)}>
                    <CIcon
                        className='c-icon'
                        icon={cilPencil}
                        size="xl"

                    />
                </div>

                <ModalCover
                    styleBody={{ backgroundColor: "whitesmoke" }}
                    title={currentSubstep.status !== StatusSubstep.EN_REVISION
                        ? "Detalle del Comentario"
                        : "Actualizar estado del paso"}
                    size='lg'
                    hasSuccessButton={false}
                    hasCloseButton={false}
                    show={showModal}
                    handleModal={(value) => setShowModal(value)}
                >

                    <CContainer>
                        <CRow >
                            <div className="input-container">
                                <label className="input-label" style={{ color: COLORS.principalColor }}>Estado</label>
                                <Autocomplete
                                    styleWesingular={false}
                                    disabled={currentSubstep.status !== StatusSubstep.EN_REVISION}
                                    id="basic-url"
                                    table={'statusSubsteps_modal' as any}
                                    // disabled={(disabled && typeAction !== "add") || loadingButton}
                                    name={'idStatusSubstepFk'}
                                    defaultValue={{ key: "id", value: userSubstep.idStatusSubstepFk }}
                                    onChange={(e: any) => {
                                        console.log(e)
                                        if (e && e.target.value) {
                                            onChange("idStatusSubstepFk", e.target.value)
                                            setStatusSubstep(e.target.item);
                                        } else {
                                            onChange("idStatusSubstepFk", undefined)
                                            setStatusSubstep(undefined);

                                        }
                                    }}
                                    onBlur={undefined} />
                            </div>
                        </CRow>

                        <div style={{ marginBottom: 30 }}></div>

                        {/* <h3>ENUM</h3>
                        <pre>{JSON.stringify(StatusSubstep, null, 2)}</pre>
                        <h3>STATUS del subpaso</h3>
                        <pre>{JSON.stringify(statusSubstep, null, 2)}</pre>
                        <h3>OBJETO USUARIO - SUBPASO</h3>
                        <pre>{JSON.stringify(userSubstep, null, 2)}</pre> */}

                        {statusSubstep && statusSubstep.name === "RECHAZADO" && (
                            <CRow>
                                <div className="input-container">
                                    <label className="input-label" style={{ color: COLORS.principalColor }}>Comentario</label>
                                    <CFormTextarea

                                        disabled={currentSubstep.status !== StatusSubstep.EN_REVISION}
                                        className="mb-3"
                                        placeholder="Deja tu comentario"
                                        aria-label="Deja tu comentario"
                                        defaultValue={userSubstep.comment}
                                        onChange={(e) => onChange("comment", e.target.value)}
                                    ></CFormTextarea>
                                </div>
                            </CRow>
                        )}
                        <div style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            // backgroundColor: "pink"
                        }}>
                            <div style={{ marginTop: 20 }}></div>

                            {currentSubstep.status === StatusSubstep.EN_REVISION && (
                                <CLoadingButton
                                    style={{ backgroundColor: COLORS.principalColor }}
                                    color="dark"
                                    disabled={loading || !statusSubstep || (statusSubstep.name === "RECHAZADO" && !userSubstep.comment)}
                                    loading={loading}
                                    onClick={() => updateUserStep()}>Actualizar</CLoadingButton>
                            )}
                            <div style={{ marginBottom: 20 }}></div>

                        </div>

                    </CContainer>
                </ModalCover>
            </div>
        )
    }


    // NO ES ADMIN
    // NO ES ADMIN
    // NO ES ADMIN
    if (isAdmin !== undefined && !isAdmin && currentSubstep.status === StatusSubstep.RECHAZADO) {

        return (
            <div>
                <div
                    className="triangle-indicator"
                    onClick={() => setShowModal(true)}>
                    <CIcon
                        className='c-icon'
                        icon={cilBellExclamation}
                        size="xl"
                    />
                </div>

                <ModalCover
                    title={"Motivo del estado"}
                    size='lg'
                    hasSuccessButton={false}
                    hasCloseButton={false}
                    show={showModal}
                    handleModal={(value) => setShowModal(value)}
                >
                    <CContainer>
                        {/* <pre>{JSON.stringify(statusSubstep, null, 2)}</pre> */}
                        {/* <pre>{JSON.stringify(userSubstep, null, 2)}</pre> */}
                        {userSubstep && userSubstep.statusSubsteps && userSubstep.statusSubsteps.name === "RECHAZADO" && (
                            <CRow>
                                <CFormTextarea
                                    style={{ userSelect: 'none' }}
                                    readOnly
                                    disabled
                                    className="mb-3"
                                    placeholder="Disabled textarea"
                                    aria-label="Disabled textarea example"
                                    defaultValue={userSubstep.comment}
                                    onChange={(e) => onChange("comment", e.target.value)}
                                ></CFormTextarea>
                            </CRow>
                        )}
                    </CContainer>
                </ModalCover>
            </div>
        )
    }


    // Mientras carga
    if (isAdmin !== undefined && !isAdmin && currentSubstep.status !== StatusSubstep.RECHAZADO || isAdmin && currentSubstep.status !== StatusSubstep.EN_REVISION) {
        return (
            <CRow>
                <div className="triangle-invisible"></div>
            </CRow>
        )
    }
}

export default AddCommentForStatus;

