import { Company } from "../../../../core/models/db/company";
import { DashboardStepActionTypes, Step, Substep } from "../types";

export const SET_INITIAL_DATA = 'SET_INITIAL_DATA';
export const UPDATE_STEP = 'UPDATE_STEP';
export const SET_CURRENT_STEP = "SET_CURRENT_STEP";
export const SET_CURRENT_COMPANY = "SET_CURRENT_COMPANY";


export const setCurrentCompany = (company: Company) => ({
  type: SET_CURRENT_COMPANY,
  payload: {
    company: company,
    idCompany: company?.id
  }
});

export const setCurrentStep = (currentStepId: number) => ({
  type: SET_CURRENT_STEP,
  payload: { currentStepId }
});


export function updateStep(step: Step, substep?: Substep): DashboardStepActionTypes {
  return {
    type: UPDATE_STEP,
    payload: {
      step,
      substep
    },

  };
}


export const setInitialData = (
  steps: Step[],
  company: Company,
  currentStepId: number,
  currentSubstepId: number,
  idCompany: number,
  idUser: number): DashboardStepActionTypes => ({
    type: SET_INITIAL_DATA,
    payload: {
      steps: steps,
      company: company,
      currentStepId,
      currentSubstepId,
      idCompany,
      idUser
    }
  });
