import { useEffect, useLayoutEffect, useState } from "react";
import { LoginService } from "../../services/login/login-service";
import { useProduct } from "../db/product/useProduct";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { useNavigate } from "react-router-dom";
import { DataBasicEncoder } from "../../util/data-basic-encoder";
import { LOCAL_STORAGE_NAME } from "../../constants/local_storage";

const useCheckMainProduct = () => {
    const navigate = useNavigate();
    const { company: companyRedux } = useSelector((state: RootState) => state.dashboardStep);
    const { checkMainProduct } = useProduct();

    const [hasMainProduct, setHasMainProduct] = useState<boolean | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);


    useEffect(() => {
        if (!companyRedux) return;

        if (!_checkLS()) {
            handleCheck();
        } else {
            setHasMainProduct(true);
            setIsLoading(false);
        }
    }, [companyRedux]);


    const handleCheck = async () => {
        try {
            const token = await LoginService.getTokenAsync();
       
            // debugger;
            const result = await checkMainProduct(token, companyRedux.idUserFk, companyRedux.id);
            if (result && result.item && result.item.length > 0) {
                _storeLS(result);
                setHasMainProduct(true);
            } else {
                setHasMainProduct(false);
            }

            setIsLoading(false);
        } catch (error) {
            console.error(error);
            navigate("/login");
        }
    };

    const _checkLS = () => {
        let ls = localStorage.getItem(LOCAL_STORAGE_NAME.CHECK_MAIN_PRODUCT + companyRedux.id);
        if (ls) {
            const decode: any = DataBasicEncoder.decode(ls);
            const { expire } = decode;
            const currentDate = new Date().getTime();

            if (currentDate > expire) {
                console.log("El artículo ha expirado.");
                localStorage.removeItem(LOCAL_STORAGE_NAME.CHECK_MAIN_PRODUCT + companyRedux.id);
            }

            if (decode.item.id != companyRedux) {
                console.log("Diferente id");
                localStorage.removeItem(LOCAL_STORAGE_NAME.CHECK_MAIN_PRODUCT + companyRedux.id);
                return false;
            }

            console.log("El artículo es válido.");
            return true;
        } else {
            console.log("No se encontró el artículo en el localStorage.");
            return false;
        }
    };

    const _storeLS = (value: any) => {
        const currentTime = new Date();
        const oneDay = 24 * 60 * 60 * 1000;
        const expiryTime = new Date(currentTime.getTime() + oneDay);
        const encode = DataBasicEncoder.encode({
            expire: expiryTime.toISOString(),
            item: value.item[0]
        });

        localStorage.setItem(LOCAL_STORAGE_NAME.CHECK_MAIN_PRODUCT + companyRedux.id, encode);
    };



    return {
        hasMainProduct,
        isLoading
    };
};

export default useCheckMainProduct;
