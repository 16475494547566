export class Company {

    id?: number;
    companyNameTest1?: string;
    companyNameTest2?: string;
    companyNameTest3?: string;
    companyName?: string;
    typeCommercialCompany?: string;
    description?: string;
    hasCoworking?: boolean;
    urlContract?: string;
    usePartners?: boolean;
    address?: string;
    town?: string;
    zipCode?: string;
    cif?: string;
    email?: string;
    landlinePhone?: string;
    mobile?: string;
    idUserFk?: number;
    createdDate?: Date;
    updatedDate?: Date;
    deletedDate?: Date;

}