import React, { useState } from 'react'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Login } from '../../../../core/models/login/login';
import { CAlert, CCol, CContainer, CFormInput, CInputGroup, CInputGroupText, CLoadingButton, CRow } from '@coreui/react-pro';
import { cilLockLocked, cilUser, cilWarning } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import { COLORS } from '../../../../core/constants/colors';
// import logoNimo from "../../../../core/assets/img/logo_nimo-grupo-positivo-1tinta.png"
import logoAPP from "../../../../core/assets/img/logo_wesingular_positivo.png"
import { useNavigate } from 'react-router-dom';



interface LoginFormProps {
  loadingButton: boolean;
  access: (values: Login) => void;
}

const initialValues: Login = {
  username: "",
  password: ""
}

const LoginForm = (props: LoginFormProps) => {

  const navigate = useNavigate();
  const [isHovered, setIsHovered] = useState(false);
  
  const { access, loadingButton } = props;

  const { handleChange, values, handleSubmit,
    handleBlur, errors, touched, getFieldProps } = useFormik({
      initialValues: initialValues,
      onSubmit: (value) => access(value),
      validationSchema: Yup.object({
        username: Yup
          .string()
          .max(40, "Debe de tener 40 caracteres o menos")
          .required("Requerido"),
        password: Yup
          .string()
          .max(12, "Debe de tener 12 caracteres o menos")
          .required("Requerido"),
        //   email: Yup
        //     .string()
        //     .email("Inserta un email válido")
        //     .required("Requerido"),
      }),
    })




  return (
    <div>
      <form noValidate onSubmit={handleSubmit}>

        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
          <img className="sidebar-brand-full" src={logoAPP} alt="Logo Empresa" height={30} style={{ marginBottom: 20 }} />


          <div className="input-container" >
            <label className="input-label"
              style={{ color: COLORS.principalColor }}>Nombre de usuario</label>

            <div style={{ display: "flex", flexDirection: "row"! }}>
              {/* <CInputGroupText style={{ backgroundColor: COLORS.principalColor, borderColor: COLORS.principalColor }}>
                <CIcon icon={cilUser} style={{ color: "white" }} />
              </CInputGroupText> */}
              <CFormInput type="text" {...getFieldProps("username")} />
              {/* <Field name="username" type="text" /> */}

            </div>
            {touched.username && errors.username &&
              (
                <div className='d-flex align-items-center mt-2' style={{ fontSize: 12, color: "red" }}>
                  <CIcon size="sm" icon={cilWarning} />
                  <div className='small' style={{ marginLeft: '5px' }}>{errors.username}</div>
                </div>
              )
            }
          </div>



          <div
            className="input-container"
            style={{ marginTop: 20 }}>
            <label
              className="input-label"

              style={{ color: COLORS.principalColor }}>Contraseña</label>
            <div style={{ display: "flex", flexDirection: "row"! }}>
              {/* <CInputGroupText style={{ backgroundColor: COLORS.principalColor, borderColor: COLORS.principalColor  }}>
                <CIcon icon={cilLockLocked} style={{ color: "white" }} />
              </CInputGroupText> */}
              <CFormInput type="password" {...getFieldProps("password")} />
            </div>
            {touched.password && errors.password &&
              (
                <div className='d-flex align-items-center mt-2' style={{ fontSize: 12, color: "red" }}>
                  <CIcon size="sm" icon={cilWarning} />
                  <div className='small' style={{ marginLeft: '5px' }}>{errors.password}</div>
                </div>
              )
            }
          </div>
        </div>


        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: 50 }}>
            <CLoadingButton loading={loadingButton} type='submit' style={{ backgroundColor: COLORS.principalColor }}>Entrar</CLoadingButton>
          </div>

          <div
            style={{
              cursor: 'pointer',
              color: COLORS.principalColor,
              fontSize: 12,
              marginTop: 25,
              textDecoration: isHovered ? 'underline' : 'none' // Cambiar el estilo según si el cursor está encima o no
            }}
            onMouseEnter={() => setIsHovered(true)} // Al entrar el cursor
            onMouseLeave={() => setIsHovered(false)} // Al salir el cursor
            onClick={() => navigate('/registro')}
          >
            ¿No tienes cuenta? Regístrate aquí
          </div>
        </div>
      </form>



      {/* <Formik
        initialValues={initialValues}
        onSubmit={(values) => access(values)}
        validationSchema={Yup.object({
          username: Yup
            .string()
            .max(15, "Debe de tener 15 caracteres o menos")
            .required("Requerido"),
          password: Yup
            .string()
            .min(4, "Debe de tener al menos 4 caracteres")
            .required("Requerido"),
        })
        }
      >

        {(formik) => (
          <Form>
            <CContainer>
              <CCol>
                <CInputGroup className="mb-3">
                  <CInputGroupText>
                    <CIcon icon={cilUser} />
                  </CInputGroupText>
                  <CFormInput placeholder="Username" autoComplete="username" name='username' />

                  <Field name="username" type="text" />

                </CInputGroup>
              </CCol>

              <ErrorMessage name={'username'} component="span" />
            </CContainer>
            <CContainer>
              <CCol>
                <CInputGroup className="mb-4">
                  <CInputGroupText>
                    <CIcon icon={cilLockLocked} />
                  </CInputGroupText>
                  <CFormInput name="password" type="password" placeholder='Contraseña' />
                </CInputGroup>
              </CCol>
              <ErrorMessage name={'password'} component="span" />
            </CContainer>
            <button className="btn btn-primary btn-access" type='submit'>Login</button>
          </Form>
        )}
      </Formik> */}
    </div >
  )
}

export default LoginForm