


import * as React from 'react';
import { classNames } from '../../utils/classes-css';
import { CCol, CLoadingButton, CRow } from '@coreui/react-pro';
import { useRef, useState } from 'react';
import { COLORS } from '../../../core/constants/colors';



interface ButtonLoadingSucessFormProps {

    typeAction: "add" | "update";

    primaryColor?: string;
    secondaryColor?: string;

    loadingButton?: boolean;
    className?: string;
    style?: React.CSSProperties;
    disabled?: boolean;
    labelCreated?: string;

    onSubmit: (e?: React.FormEvent<HTMLFormElement>) => void;
    changeToBackup: (value: boolean) => void;
    setDisabled: (value: boolean) => void;
}

const ButtonLoadingSucessForm = (props: ButtonLoadingSucessFormProps) => {

    let {

        typeAction,
        loadingButton,
        disabled,

        primaryColor,
        secondaryColor,

        className,
        style,

        labelCreated,

        onSubmit,
        changeToBackup,
        setDisabled
    } = props;

    const [isEdit, setEdit] = useState(false);

    const handleEdit = (value: boolean, submit = false) => {
        setEdit(value);
        setDisabled(!value);
        if (!value && submit) {
            onSubmit();
        } else if (!value && !submit) {
            changeToBackup(true);
        }

    }



    const editBlock = () => {
        return (
            <>
                {!isEdit ? (
                    <CRow className="justify-content-end align-items-center">
                        <CCol sm="auto">
                            <CLoadingButton style={{ ...style, backgroundColor: secondaryColor || COLORS.principalColor }} loading={loadingButton} disabled={loadingButton} color="primary" onClick={() => handleEdit(true)}>
                                Editar
                            </CLoadingButton>
                        </CCol>
                    </CRow>
                )
                    : (
                        <>
                            <CRow className="justify-content-end align-items-center">
                                <CCol sm="auto">
                                    <CLoadingButton disabled={loadingButton} color="primary" onClick={() => handleEdit(false)} style={{ ...style, backgroundColor: primaryColor || COLORS.tertiaryColor }}>
                                        Cancelar
                                    </CLoadingButton>
                                </CCol>
                                <CCol sm="auto">
                                    <CLoadingButton disabled={loadingButton || disabled} loading={loadingButton} color="primary" onClick={() => handleEdit(false, true)} style={{ ...style, backgroundColor: secondaryColor || COLORS.principalColor }}>
                                        Guardar
                                    </CLoadingButton>
                                </CCol>
                            </CRow>
                        </>

                    )
                }
            </>
        )
    }

    return (

        <CRow className={classNames(className)} style={{ backgroundColor: "blue", width: "180px" }}>
            {typeAction === "add"
                ?
                (
                    <CRow className="justify-content-end align-items-center">
                        <CCol sm="auto">
                            <CLoadingButton
                                disabled={loadingButton || disabled}
                                className='col-12' loading={loadingButton}
                                type='submit'
                                color="primary"
                                style={{ ...style, backgroundColor: secondaryColor || COLORS.principalColor }}>
                                {labelCreated || "Crear"}
                            </CLoadingButton>
                        </CCol>
                    </CRow>
                )
                : typeAction === "update"
                    ? editBlock()
                    : ""

            }
        </CRow>



    );
};

export default ButtonLoadingSucessForm;

