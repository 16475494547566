import React, { useRef, useEffect, useContext } from 'react';
import { gsap } from 'gsap';
import { CContainer } from '@coreui/react-pro';
import { PaymentContext } from '../contexts/PaymentContext';
import { COLORS } from '../../../../../core/constants/colors';

interface StepNavigatorProps {
  position: "vertical" | "horizontal";
}

const StepNavigator = ({ position }: StepNavigatorProps) => {

  const {
    stepsData: steps,
    handleStepClick: onStepChange
  } = useContext(PaymentContext)

  const stepRefs = useRef<Array<HTMLDivElement | null>>([]);

  useEffect(() => {
    stepRefs.current = stepRefs.current.slice(0, steps.length);
  }, [steps]);

  useEffect(() => {
    steps.forEach((step, index) => {
      if (step.active) {
        gsap.to(stepRefs.current[index], { scale: 1.1, duration: 0.5, ease: "power3.out" });
      } else {
        gsap.to(stepRefs.current[index], { scale: 1, duration: 0.5, ease: "power3.out" });
      }
    });
  }, [steps]);

  const handleStepClick = (index: number) => {
    if (index === 0 || (steps[index - 1] && steps[index - 1].complete)) {
      onStepChange(index);
    }
  };

  const BackgroundOscuro = "#2a2a2a"

  const getStepStyle = (step: any) => {
    let style: any = {
      background: '#2a2a2a',
      color: 'white',
      boxShadow: '0px 0px 6px 1px rgba(42,42,42,0.2)',
      position: 'relative',
      cursor: step.complete || step.active ? 'pointer' : 'not-allowed',
      margin: '0px',
      borderRadius: '50%',
      padding: '10px',
      width: '60px',
      height: '60px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    };

    if (step.complete) {
      style = {
        ...style,
        background: '#024b30',
        borderColor: "white",
        boxShadow: '0px 0px 10px 3px rgba(2,75,48,0.5)'
      };
    }

    if (step.active) {
      style = {
        ...style,
        // background: '#1b1b1b',
        background: COLORS.quaternaryColor,
        boxShadow: '0px 0px 10px 3px rgba(27,27,27,0.5)',
        '::before': {
          content: '""',
          position: 'absolute',
          top: '-3px',
          left: '-3px',
          right: '-3px',
          bottom: '-3px',
          borderRadius: '50%',
          background: 'linear-gradient(white, transparent)',
          zIndex: -1
        }
      };
    }

    return style;
  };

  const getOuterRingStyle = (step: any) => {
    if (!step.active) return {};

    return {
      position: 'absolute',
      top: '50%',
      left: '50%',
      width: '70px',
      height: '70px',
      transform: 'translate(-50%, -50%)',  // centra el elemento
      borderRadius: '50%',
      background: 'linear-gradient(white, transparent)',
      zIndex: 0
    } as any;
  };




  return (
    <CContainer style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      {steps.map((step, index) => (
        <div key={index} style={{
          marginBottom: index !== steps.length - 1 ? '30px' : '0px',

          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          position: 'relative',
          minHeight: '70px'
        }}>
          <div style={{ display: 'flex', flexDirection: "column", alignItems: 'center', position: 'relative' }}>
            <div style={getOuterRingStyle(step)} />
            <div
              ref={el => stepRefs.current[index] = el}
              onClick={() => handleStepClick(index)}
              style={getStepStyle(step)}
            >
              {step.complete && !step.active ?
                <svg xmlns="http://www.w3.org/2000/svg" height="1.6em" viewBox="0 0 448 512">
                  <path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" />
                </svg>
                :
                <span tabIndex={-1} style={{ userSelect: 'none', fontSize: 18, cursor: 'default' }}>
                  {index + 1}
                </span>
              }
            </div>
          </div>
          <div style={{ userSelect: 'none', cursor: 'default', marginLeft: '20px', marginBottom: 0 }}>
            <div style={{ fontWeight: 'bold', color: "white" }}>{step.name}</div>
            <div style={{ fontSize: '12px', color: "white" }}>{step.description}</div>
          </div>
        </div>
      ))}
    </CContainer>
  );
};

export default StepNavigator;
