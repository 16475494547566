import { CContainer, CCard, CCardBody, CRow } from "@coreui/react-pro"


const WarnNoCompany = () => {
    return (
        <CContainer className="mb-4 mt-4">
            <CCard>
                <CCardBody className="no-company-container">
                    <CRow>
                        <div>
                            <span className="message">
                                Es necesario que tengas una compañía seleccionada
                            </span>
                        </div>
                    </CRow>
                </CCardBody>
            </CCard>
        </CContainer>
    )
}

export default WarnNoCompany
