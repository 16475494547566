import React, { useContext, useEffect, useState } from 'react'
import { CCard, CCardBody, CCardFooter, CCardHeader, CCol, CProgress, CRow, CSmartTable } from '@coreui/react-pro'
import { useSnackbar } from '../../../../components/snackbar/hook/useSnackbar';
import { cilArrowLeft } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import { useNavigate, useLocation, useParams, Link } from 'react-router-dom';
import { BASE_ROUTE, ROUTE_NAME } from '../../../../../core/constants/route_name';
import { useUser } from '../../../../../core/hooks/db/user/useUser';
import { User } from '../../../../../core/models/db/user';
import { LoginService } from '../../../../../core/services/login/login-service';
import UserForm from '../../../user/UserForm';
import Snackbar from '../../../../components/snackbar/Snackbar';
import RegistrationForm from './RegistrationForm';
import { PaymentContext } from '../contexts/PaymentContext';
import { DataRegistration } from '../interfaces/interfaces';
import { DataBasicEncoder } from '../../../../../core/util/data-basic-encoder';


const cardStyle = {
    display: 'flex',       // Establece el CCard como un contenedor flex.
    flexDirection: 'column',
    justifyContent: 'center', // Centra el contenido en el medio.
    height: 'auto'           // Altura automática.
} as any;


const LABEL_PAGE = "Usuarios del sistema"

const RegistrationBase = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const stateLocation = location.state;
    const { id } = useParams();


    // Pasar paso
    const {
        stepsData,
        data,

        handleStepClick,
        setData
    } = useContext(PaymentContext)



    const {
        register,

        getUserById,
        addUser,
        updateUser,
        checkPassword,
        checkUsername,
        checkPasswordPublic,
        checkUsernamePublic } = useUser();

    // const [user, setUser] = useState<User>({
    //     username: "",
    //     password: "",
    //     priv_confirmPassword: "",
    //     image: "",
    //     email: "",
    //     blocked: false,
    //     idRoleFk: undefined,
    // })

    const [user, setUser] = useState<User>(undefined)

    const [loadingButton, setLoadingButton] = useState(false);
    const [typeAction, setTypeAction] = useState<"add" | "update">(null);

    const [token, setToken] = useState(null);

    const { state, handleSnackbar } = useSnackbar({
        color: "",
        wait: 3000,
        message: "",
        changeState: false,
    })



    /**
      * Nuevo
      * Actualización para usar el componente dentro del Dashboard
      * para crear una nueva compañía
      */
    const { getById } = useUser();
    const [identity, useIdentity] = useState<any>(undefined);
    useEffect(() => {
        (async () => {
            try {
                let i = await LoginService.getIdentityAsync();
                if (i) {
                    let token = await LoginService.getTokenAsync();
                    let result: any = await getById(token, i.idUser);
                    if (result && result.ok) {
                        console.log(result);
                        let u: User = result.item;
                        let userAux = {
                            id: u.id,
                            username: u.username,
                            email: u.email
                        }

                        setData({
                            ...data,
                            user: userAux
                        })

                        setUser(prev => {
                            return {
                                ...prev,
                                id: u.id,
                                email: u.email,
                                username: u.username
                            }
                        });
                    }
                } else { }
            } catch (error) {
                if (error === "Sin permiso i") {
                    setUser({
                        username: "",
                        password: "",
                        priv_confirmPassword: "",
                        image: "",
                        email: "",
                        blocked: false,
                        idRoleFk: undefined,
                    })
                }
                else
                    console.error(error)
            }
        }
        )();
    }, [identity])





    /**
    * Se usa al comienzo de cargar la página para rellenar la tabla
    */
    useEffect(() => {
        (async () => {
            // changeStyle({ backgroundColor: "rgba(111, 214, 250, 0.5)" })
            // handleLoading(true);
            // const token =
            const token = "";
            setToken(token)
            const path = location.pathname;

            if (path.includes("actualizar") && stateLocation && stateLocation.row) {
                let row: User = { ...stateLocation.row };
                row.password = "";
                row.priv_confirmPassword = "";
                delete row.token;
                delete row.roles;
                delete row.priv_role;
                delete row.createdDate;
                delete row.deletedDate;
                delete row.updatedDate;

                setUser(row);
                setTypeAction("update");
            } else if (path.includes("actualizar") && id) {
                let result = await getUserById(token, id)
                if (result && result.ok) {
                    console.log("what is the result", result)
                    let user = result.item;
                    user.password = "";
                    user.priv_confirmPassword = "";
                    delete user.token;
                    delete user.roles;
                    delete user.createdDate;
                    delete user.deletedDate;
                    delete user.updatedDate;
                    setUser(user);
                    setTypeAction("update");
                } else {
                    console.warn("No se encontró ningún id");
                    navigate(BASE_ROUTE + ROUTE_NAME.usuariosCrear);
                }
            } else {
                // Esto es un caso especial de la pantalla donde se encuentra este componente
                if (data && data.user) {
                    setUser({ username: data.user.username, email: data.user.email, id: data.user.id })
                }
                setTypeAction("add");
            }

            // handleLoading(false);
        })()
    }, [data]);

    /**
     * Comprueba que el username está libre
     * @param id 
     * @param password 
     * @returns 
     */
    const handleCheckUsername = async (id, username) => {
        // if (!token) {
        //     console.warn("No se ha proporcionado token");
        //     return;
        // };

        const result = await checkUsernamePublic(id, username);
        if (result && result.ok) {
            return result.item;
        } else {
            return false;
        }
    }


    const handleCheckPassword = async (id, password) => {
        // if (!token) {
        //     console.warn("No se ha proporcionado token");
        //     return;
        // };

        const result = await checkPasswordPublic(id, password);
        if (result && result.ok) {
            return result.item;
        } else {
            return false;
        }
    }


    /**
     * Crea el usuario
     * @param user 
     */
    const add = async (user: User) => {
        try {
            let u = { ...user };
            delete u.priv_confirmPassword;
            // setLoadingButton(true);
            if (!u.password || u.password === '') {
                u.password = "gk13!12";
            }

            u.idRoleFk = 2;

            // const result = await addUser(token, u);
            const result = await register(u);

            if (result && result.ok) {
                handleSnackbar("El registro se ha creado", "success");
                // localStorage.setItem('identity', JSON.stringify(result.item.identity));

                const encode = DataBasicEncoder.encode(result.item.identity);
                localStorage.setItem("identity", encode);


                localStorage.setItem('token', result.item.token);

                let userAux = {
                    id: result.item.identity.idUser,
                    username: u.username,
                    email: u.email
                }

                console.log(userAux)
                setData({
                    ...data,
                    user: userAux
                })
                handleStepClick(1);

                // setTimeout(() => {
                //     setLoadingButton(false);
                //     // setUser({
                //     //   ...result.item,
                //     //   password: "",
                //     //   priv_confirmPassword: ''
                //     // })
                //     navigate("/dashboard/usuarios");
                // }, 1600)
            }

        } catch (e) {
            handleSnackbar("Ha habido un error al crear el registro", "success");
            setLoadingButton(false);
            console.error(e)
        }
    }

    /**
    * Actualiza el usuario
    * @param user 
    */
    const update = async (user: User) => {
        try {
            let u = { ...user };
            delete u.priv_confirmPassword;
            setLoadingButton(true);
            if (!u.password || u.password === '') {
                delete u.password;
            }

            const result = await updateUser(token, u);
            if (result && result.ok) {
                handleSnackbar("El registro se ha actualizado", "success");

                // let identity = await LoginService.getIdentityAsync();
                let identity = {} as any
                if (u.id === identity.idUser) {

                    // TODO: Antiguo LS de identity
                    // localStorage.setItem("identity", JSON.stringify({
                    //     ...identity,
                    //     username: u.username,
                    //     idRole: u.idRoleFk,
                    //     avatar: u.image,
                    // }))

                    const encode = DataBasicEncoder.encode({
                        ...identity,
                        username: u.username,
                        idRole: u.idRoleFk,
                        avatar: u.image,
                    });

                    localStorage.setItem("identity", encode);
                }


                setTimeout(() => {
                    setLoadingButton(false);
                    // setUser({
                    //   ...u,
                    //   priv_confirmPassword: ''
                    // })
                    navigate("/dashboard/usuarios");
                }, 1600)
            }

        } catch (e) {
            handleSnackbar("Ha habido un error al crear el registro", "success");
            setLoadingButton(false);
            console.error(e)
        }
    }

    return (


        <div>
            <CRow>
                {/* <pre style={{ color: "white" }}>{JSON.stringify(data, null, 2)}</pre>
                <pre style={{ color: "white" }}>{JSON.stringify(user, null, 2)}</pre> */}

                {user && data && typeAction && (
                    <RegistrationForm
                        alreadyCreated={!!(data && data.user) || !!user.id}
                        loadingButton={loadingButton}
                        user={user}
                        typeAction={typeAction}
                        add={add}
                        update={update}
                        checkPassword={handleCheckPassword}
                        checkUsername={handleCheckUsername}

                        changeStep={handleStepClick}
                    />
                )}

            </CRow>
        </div>

    )
}

export default RegistrationBase



