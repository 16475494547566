import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { Pagination } from '../../../models/pagination';
import { ReasonForDeactivationProps } from '../../check-table/useCheckTable';
import { CompanyRelationService } from '../../../services/db/companyRelation/companyRelation.service';
import { CompanyRelation } from '../../../models/db/companyRelation';



export const useCompanyRelation = () => {

    const getAllIdCompanyRelation = (token: any, reasonForDeactivation: ReasonForDeactivationProps[]) => {
        return new Promise<any>((res, rej) => {
            CompanyRelationService.getAllId(token, reasonForDeactivation)
                .then(({ data }) => {
                    console.log(data)
                    if (data.ok) {
                        res(data);
                    } else {
                        res(undefined)
                    }
                })
                .catch((e) => {
                    console.log(e)
                    rej(e)
                })

        })
    }


    const getCompanyRelationById = (token, id) => {
        return new Promise<any>((res, rej) => {
            CompanyRelationService.get(token, id)
                .then(({ data }) => {
                    console.log(data)
                    if (data.ok) {
                        res(data);
                    } else {
                        res(undefined)
                    }
                })
                .catch((e) => {
                    console.log(e)
                    rej(e)
                })

        })
    }

    const getCompanyRelationByUser = (token, id) => {
        return new Promise<any>((res, rej) => {
            CompanyRelationService.getByUser(token, id)
                .then(({ data }) => {
                    console.log(data)
                    if (data.ok) {
                        res(data);
                    } else {
                        res(undefined)
                    }
                })
                .catch((e) => {
                    console.log(e)
                    rej(e)
                })

        })
    }

    const getCompanyRelationByCompany = (token, id) => {
        return new Promise<any>((res, rej) => {
            CompanyRelationService.getByCompany(token, id)
                .then(({ data }) => {
                    console.log(data)
                    if (data.ok) {
                        res(data);
                    } else {
                        res(undefined)
                    }
                })
                .catch((e) => {
                    console.log(e)
                    rej(e)
                })

        })
    }


    const getCompanyRelation = (token, pagination: Pagination = { page: 1, itemsPerPage: 5 }) => {
        return new Promise<any>((res, rej) => {
            CompanyRelationService.getAll(token, pagination)
                .then(({ data }) => {
                    console.log(data)
                    if (data.ok) {
                        res(data);
                    } else {
                        res(undefined)
                    }
                })
                .catch((e) => {
                    console.log(e)
                    rej(e)
                })

        })
    }

    const getCompanyRelationAutocomplete = (token, pagination: Pagination = { page: 1, itemsPerPage: 5 }) => {
        return new Promise<any>((res, rej) => {
            CompanyRelationService.getAutocomplete(token)
                .then(({ data }) => {
                    // console.log(data)
                    if (data.ok) {
                        res(data);
                    } else {
                        res(undefined)
                    }
                })
                .catch((e) => {
                    console.log(e)
                    rej(e)
                })

        })
    }


    const addCompanyRelation = (token, user: CompanyRelation) => {
        return new Promise<any>((res, rej) => {
            CompanyRelationService.add(token, { ...user })
                .then(({ data }) => {
                    // console.log(data)
                    if (data.ok) {
                        res(data);
                    } else {
                        res(undefined)
                    }
                })
                .catch((e) => {
                    console.log(e)
                    rej(e)
                })

        })
    }


    const updateCompanyRelation = (token, user: CompanyRelation) => {
        return new Promise<any>((res, rej) => {
            CompanyRelationService.update(token, { ...user })
                .then(({ data }) => {
                    console.log(data)
                    if (data.ok) {
                        res(data);
                    } else {
                        res(undefined)
                    }
                })
                .catch((e) => {
                    console.log(e)
                    rej(e)
                })

        })
    }

    const deleteCompanyRelation = (token, idList: string[] | number[], action: boolean) => {
        return new Promise<any>((res, rej) => {
            CompanyRelationService.delete(token, idList, action)
                .then(({ data }) => {
                    console.log(data)
                    if (data.ok) {
                        res(data);
                    } else {
                        res(undefined)
                    }
                })
                .catch((e) => {
                    console.log(e)
                    rej(e)
                })

        })
    }


    return {
        getAllIdCompanyRelation,
        getCompanyRelationById,
        getCompanyRelationByUser,
        getCompanyRelationByCompany,
        getCompanyRelation,
        addCompanyRelation,
        updateCompanyRelation,
        deleteCompanyRelation,

        get: getCompanyRelation,
        add: addCompanyRelation,
        update: updateCompanyRelation,
        autocomplete: getCompanyRelationAutocomplete,
        delete: deleteCompanyRelation
    }
}