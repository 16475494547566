import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { Pagination } from '../../../models/pagination';
import { ReasonForDeactivationProps } from '../../check-table/useCheckTable';
import { Purchase } from '../../../models/db/purchase';
import { PurchaseService } from '../../../services/db/purchase/purchase.service';





export const usePurchase = () => {

    const getAllIdPurchase = (token: any, reasonForDeactivation: ReasonForDeactivationProps[]) => {
        return new Promise<any>((res, rej) => {
            PurchaseService.getAllId(token, reasonForDeactivation)
                .then(({ data }) => {
                    console.log(data)
                    if (data.ok) {
                        res(data);
                    } else {
                        res(undefined)
                    }
                })
                .catch((e) => {
                    console.log(e)
                    rej(e)
                })

        })
    }

    const getPurchaseById = (token, id) => {
        return new Promise<any>((res, rej) => {
            PurchaseService.get(token, id)
                .then(({ data }) => {
                    console.log(data)
                    if (data.ok) {
                        res(data);
                    } else {
                        res(undefined)
                    }
                })
                .catch((e) => {
                    console.log(e)
                    rej(e)
                })

        })
    }

    const getPurchaseByUserAndCompany = (token, idUser, idCompany) => {
        return new Promise<any>((res, rej) => {
            PurchaseService.getByUserAndCompany(token, idUser, idCompany)
                .then(({ data }) => {
                    console.log(data)
                    if (data.ok) {
                        res(data);
                    } else {
                        res(undefined)
                    }
                })
                .catch((e) => {
                    console.log(e)
                    rej(e)
                })

        })
    }


    const getPurchase = (token, pagination: Pagination = { page: 1, itemsPerPage: 5 }) => {
        return new Promise<any>((res, rej) => {
            PurchaseService.getAll(token, pagination)
                .then(({ data }) => {
                    console.log(data)
                    if (data.ok) {
                        res(data);
                    } else {
                        res(undefined)
                    }
                })
                .catch((e) => {
                    console.log(e)
                    rej(e)
                })

        })
    }

    // const getPurchaseAutocomplete = (token, pagination: Pagination = { page: 1, itemsPerPage: 5 }) => {
    //     return new Promise<any>((res, rej) => {
    //         PurchaseService.getAutocomplete(token)
    //             .then(({ data }) => {
    //                 // console.log(data)
    //                 if (data.ok) {
    //                     res(data);
    //                 } else {
    //                     res(undefined)
    //                 }
    //             })
    //             .catch((e) => {
    //                 console.log(e)
    //                 rej(e)
    //             })

    //     })
    // }


    const addPurchase = (token, user: Purchase) => {
        return new Promise<any>((res, rej) => {
            PurchaseService.add(token, { ...user })
                .then(({ data }) => {
                    // console.log(data)
                    if (data.ok) {
                        res(data);
                    } else {
                        res(undefined)
                    }
                })
                .catch((e) => {
                    console.log(e)
                    rej(e)
                })

        })
    }


    const updatePurchase = (token, user: Purchase) => {
        return new Promise<any>((res, rej) => {
            PurchaseService.update(token, { ...user })
                .then(({ data }) => {
                    console.log(data)
                    if (data.ok) {
                        res(data);
                    } else {
                        res(undefined)
                    }
                })
                .catch((e) => {
                    console.log(e)
                    rej(e)
                })

        })
    }

    // const deletePurchase= (token, idList: string[] | number[], action: boolean) => {
    //     return new Promise<any>((res, rej) => {
    //         PurchaseService.delete(token, idList, action)
    //             .then(({ data }) => {
    //                 console.log(data)
    //                 if (data.ok) {
    //                     res(data);
    //                 } else {
    //                     res(undefined)
    //                 }
    //             })
    //             .catch((e) => {
    //                 console.log(e)
    //                 rej(e)
    //             })

    //     })
    // }


    return {
        getAllIdPurchase,
        getPurchaseById,
        getPurchaseByUserAndCompany,
        getPurchase,
        addPurchase,
        updatePurchase,
        // deletePurchase,

        get: getPurchase,
        add: addPurchase,
        update: updatePurchase,
        // autocomplete: getPurchaseAutocomplete,
        // delete: deletePurchase
    }
}