import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  CSidebar,
  CSidebarBrand,
  CSidebarNav,
  CSidebarToggler,
} from '@coreui/react-pro'

import { useTypedSelector } from './store'

import { AppSidebarNav } from './AppSidebarNav'

import CIcon from '@coreui/icons-react'

import SimpleBar from 'simplebar-react'
import 'simplebar/dist/simplebar.min.css'

import { logoNegative } from '../../assets/brand/logo-negative'
import { sygnet } from '../../assets/brand/sygnet'

// import logoNimo from "../../../core/assets/img/logo_nimo_blanco_transparente.png"
import logoAPP from "../../../core/assets/img/logo_wesingular_negativo.png"

// sidebar nav config
// import navigation from '../../routes/_nav'
import CompanySelect from '../companySelect/CompanySelect'
import { RootState } from '../../../core/store/store'
import useNavSideBar from '../../routes/hook/useNavSideBar'
import { COLORS } from '../../../core/constants/colors'

const AppSidebar = () => {
  const dispatch = useDispatch()
  // const unfoldable = useTypedSelector((state) => state.sidebarUnfoldable)
  // const sidebarShow = useTypedSelector((state) => state.sidebarShow)

  const { sidebarUnfoldable: unfoldable, sidebarShow } = useSelector((state: RootState) => state.ui);

  const navigation = useNavSideBar();
  return (
    <CSidebar
      position="fixed"
      unfoldable={unfoldable}
      visible={sidebarShow}
      onVisibleChange={(visible) => {
        dispatch({ type: 'set', sidebarShow: visible })
      }}
    >
      <CSidebarBrand className="d-none d-md-flex" style={{ backgroundColor: COLORS.tertiaryColor }}>

        {/* <img className="sidebar-brand-full" src={logoAPP} alt="My Cool Image" height={90} />
        <img className="sidebar-brand-narrow" src={logoAPP} alt="My Cool Image" height={30} /> */}

        <img className="sidebar-brand-full" src={logoAPP} alt="My Cool Image" height={20} />
        {/* <img className="sidebar-brand-narrow" src={logoAPP} alt="My Cool Image" height={30} /> */}


      </CSidebarBrand>
      <CSidebarNav>
        <SimpleBar>
          <AppSidebarNav items={navigation} />
        </SimpleBar>
      </CSidebarNav>
      {/* <CSidebarToggler
        className="d-none d-lg-flex"
        onClick={() =>
          dispatch({ type: 'set', sidebarUnfoldable: !unfoldable })
        }
      /> */}
    </CSidebar>
  )
}

export default React.memo(AppSidebar)
