import React, { useContext, useEffect, useState } from 'react';
import { CAlert, CButton, CCol, CContainer, CFormInput, CFormLabel, CInputGroup, CInputGroupText, CLoadingButton, CRow, CSpinner } from '@coreui/react-pro';

import { cilCheckAlt, cilInfo, cilLockLocked, cilUser, cilWarning } from '@coreui/icons';
import CIcon from '@coreui/icons-react';

import { useFormik } from 'formik';
import * as Yup from 'yup';

import validationRegistrationSchema from './regis_validator_yup';
import { User } from '../../../../../core/models/db/user';
import { LoginService } from '../../../../../core/services/login/login-service';
import ButtonLoadingSucessForm from '../../../../components/buttonLoadingSucessForm/ButtonLoadingSucessForm';
import { FileItemObservableService } from '../../../../components/drag-drop/services/rxJS/fileitem-observable.service';
import { PaymentContext } from '../contexts/PaymentContext';
import useDebouncedCallback from '../../../../../core/hooks/debounced-callback/useDebouncedCallback';
import { UserService } from '../../../../../core/services/db/user/user.service';
import { COLORS } from '../../../../../core/constants/colors';



interface UserFormProps {

    alreadyCreated?: boolean;

    user: User;
    typeAction: "add" | "update";
    loadingButton?: boolean;

    isModal?: boolean

    add?: (user: User) => Promise<any>;
    update?: (user: User) => Promise<any>;

    // Esto solo cuando ya se haya creado y se vuelva para atrás
    changeStep?: (value: number) => void;

    checkPassword?: (id: any, password: any) => Promise<any>
    checkUsername?: (id: any, username: string) => Promise<any>

}





const RegistrationForm = (props: UserFormProps) => {

    const [errorCode, setErrorCode] = useState(false);
    const handleCodeChange = useDebouncedCallback(async (value) => {
        try {
            console.log("El usuario dejó de escribir:", value);
            // let token = await LoginService.getTokenAsync();
            const { data } = await UserService.checkEmailPublic(0, value.target.value);
            // console.log(data.item);
            if (data.item) {
                // No se usa YUP porque reinicia el estado cuando se usa otro input
                // setFieldError('code', 'El código ya está siendo usado');
                setErrorCode(true);
            } else {
                setErrorCode(false);
            }
        } catch (e) {
            console.error(e);
        }
    }, 150);

    /**
     * Este fragmento es necesario porque el navegador rellena de manera
     * automática los inputs username y password. Después de intentarlo mediante
     * otros métodos se ha optado por el siguiente
     */
    const autocompleteOff = () => setTimeout(() => setAutoOff(false), 100)
    const [autoOff, setAutoOff] = useState(null);

    const [identity, setIdentity] = useState(undefined);

    useEffect(() => {
        (async () => {
            autocompleteOff()
            // let id = await LoginService.getIdentityAsync();
            let id = ""

            setIdentity(id);
        })();

    }, [])


    // Comienzo de la página
    // Comienzo de la página
    // Comienzo de la página

    const {
        user,
        typeAction,
        loadingButton,
        alreadyCreated,

        isModal = false,

        add,
        update,
        changeStep,

        checkPassword,
        checkUsername,
    } = props;


    const [showPassword, setShowPassword] = useState(false);
    const [imageUser, setImageUser] = useState<string>(user && user.image ? user.image : "");
    const [disabled, setDisabled] = useState(true);

    const { values, errors, touched, isValid,

        handleChange,
        handleSubmit,
        handleBlur,
        getFieldProps,
        setFieldValue,
        setValues
    } = useFormik({
        initialValues: {
            ...user,
            blocked: Boolean(user.blocked)
        },
        validateOnBlur: false,
        onSubmit: (value) => {
            console.log(value)
            if (typeAction === "add") {
                add(value);
            } else if (typeAction === "update") {
                update(value);
            }
        },
        validationSchema: Yup.lazy(values => validationRegistrationSchema(values, typeAction, touched, checkUsername))
    })


    // Cojo la referencia del observable para saber cuando ha habido un cambio
    const controlFile$ = React.useRef(FileItemObservableService.instance.control$);

    /**
     * Si hay que mantener la pagina tras haber creado el registro, 
     * descomenta este trozo de código
     * @param value 
     */
    // useEffect(() => {
    //     console.log("Activating user update");
    //     setValues({
    //         ...user,
    //         blocked: Boolean(user.blocked)
    //     })
    // }, [user])


    const changeToBackup = (value: boolean) => {
        if (value) {
            setValues(
                {
                    ...user,
                    blocked: Boolean(user.blocked)
                }
            );


        }
    }

    const isEdit = (value: boolean) => {
        setDisabled(value);
    }

    return (

        <CRow>
            {/* <h4>Original</h4>
            <pre>
                {JSON.stringify(user, null, 2)}
            </pre>
            <h4> Modificado</h4>
            <pre>
                {JSON.stringify(values, null, 2)}
            </pre> */}

            <form onSubmit={handleSubmit}>
                <CRow className="align-items-center">

                    <CCol sm={12} className={alreadyCreated ? "mx-auto" : ""}>
                        <div className="input-container" style={{ marginBottom: 25 }}>
                            <label className="input-label" style={{ color: COLORS.secondaryColor }}>Nombre de usuario</label>

                            <CFormInput
                                className='dark-input'
                                disabled={alreadyCreated || (disabled && typeAction !== "add") || loadingButton}
                                // placeholder='Username'
                                {...getFieldProps("username")}
                                onChange={(event) => {
                                    getFieldProps("username").onChange(event);
                                }}
                                readOnly={autoOff}
                                autoComplete="new-username"
                            />
                            <div style={{ height: "auto" }}>
                                {errors.username && (
                                    // <CAlert color="danger" className='d-flex align-items-center' style={{ height: "30px" }}>
                                    //     <div className='small'>{errors.username}</div>
                                    // </CAlert>
                                    <div className='d-flex align-items-center mt-2' style={{ fontSize: 12, color: "red" }}>
                                        <CIcon size="sm" icon={cilWarning} />
                                        <div className='small' style={{ marginLeft: '5px' }}>{errors.username}</div>
                                    </div>
                                )}

                                {!errors.username && (values.username !== user.username) && (
                                    // <CAlert color="success" className='d-flex align-items-center' style={{ height: "25px" }}>
                                    //     <div className='small'>Username disponible</div>
                                    // </CAlert>
                                    <div className='d-flex align-items-center mt-2' style={{ fontSize: 12, color: "#32a852" }}>
                                        <CIcon size="sm" icon={cilCheckAlt} />
                                        <div className='small' style={{ marginLeft: '5px' }}>Username disponible</div>
                                    </div>
                                )}
                            </div>
                        </div>


                    </CCol>
                </CRow>

                <CRow className="align-items-center">

                    {/* <CCol sm={12} className={alreadyCreated ? "mx-auto" : ""}>
                        <CFormLabel htmlFor="password" className="mb-2" style={{ color: "white" }}>Email</CFormLabel>

                        <CInputGroup className="mb-3">
                            <CInputGroupText>
                 
                                <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M64 112c-8.8 0-16 7.2-16 16v22.1L220.5 291.7c20.7 17 50.4 17 71.1 0L464 150.1V128c0-8.8-7.2-16-16-16H64zM48 212.2V384c0 8.8 7.2 16 16 16H448c8.8 0 16-7.2 16-16V212.2L322 328.8c-38.4 31.5-93.7 31.5-132 0L48 212.2zM0 128C0 92.7 28.7 64 64 64H448c35.3 0 64 28.7 64 64V384c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V128z" /></svg>
                            </CInputGroupText>
                            <CFormInput
                                disabled={alreadyCreated || (disabled && typeAction !== "add") || loadingButton}
                                placeholder='Email'
                                {...getFieldProps("email")}
                                onChange={(event) => {
                                    getFieldProps("email").onChange(event);
                                }}
                                readOnly={autoOff}
                                autoComplete="new-email"
                            />
                        </CInputGroup>

                        <div style={{ height: "auto" }}>
                            {errors.email && (
                                <CAlert color="danger" className='d-flex align-items-center' style={{ height: "30px" }}>
                                    <div className='small'>{errors.email}</div>
                                </CAlert>
                            )}


                        </div>
                    </CCol> */}




                    <CCol lg={12}>
                        <div className="input-container" style={{ marginBottom: 25 }}>
                            <label className="input-label" style={{ color: COLORS.secondaryColor }}>Email</label>

                            <CFormInput
                                className='dark-input'

                                disabled={alreadyCreated || (disabled && typeAction !== "add") || loadingButton}
                                // placeholder='Email'
                                {...getFieldProps("email")}
                                onChange={(event) => {
                                    getFieldProps("email").onChange(event);
                                    // handleCodeChange(event);
                                }}
                            />
                            <div style={{ height: "auto" }}>
                                {touched.email && !errorCode && errors.email && (
                                    <div className='d-flex align-items-center mt-2' style={{ fontSize: 12, color: "red" }}>
                                        <CIcon size="sm" icon={cilWarning} />
                                        <div className='small' style={{ marginLeft: '5px' }}>{errors.email}</div>
                                    </div>
                                )}

                                {errorCode && (
                                    <div className='d-flex align-items-center mt-2' style={{ fontSize: 12, color: "red" }}>
                                        <CIcon size="sm" icon={cilWarning} />
                                        <div className='small' style={{ marginLeft: '5px' }}>Este email ya está siendo usado</div>
                                    </div>
                                )}
                            </div>
                        </div>


                    </CCol>




                </CRow>




                {!alreadyCreated && (
                    <CRow>

                        <CCol sm={12}>
                            <div className="input-container" style={{ marginBottom: 25 }}>
                                <label className="input-label" style={{ color: COLORS.secondaryColor }}>Contraseña</label>

                                <CFormInput
                                    className='dark-input'
                                    // placeholder='Contraseña'
                                    type="password" {...getFieldProps("password")}
                                    readOnly={autoOff}
                                    disabled={(!showPassword && typeAction === "update") || (disabled && typeAction !== "add") || loadingButton}
                                    autoComplete="new-password" />
                                <div style={{ height: "auto" }}>
                                    {touched.password && errors.password && (
                                        <div className='d-flex align-items-center mt-2' style={{ fontSize: 12, color: "red" }}>
                                            <CIcon size="sm" icon={cilWarning} />
                                            <div className='small' style={{ marginLeft: '5px' }}>{errors.password}</div>
                                        </div>
                                    )}

                                </div>
                            </div>



                        </CCol>
                        <CCol sm={12}>
                            <div className="input-container" style={{ marginBottom: 25 }}>
                                <label className="input-label" style={{ color: COLORS.secondaryColor }}>Repetir Contraseña</label>
                                <CFormInput
                                    className='dark-input'

                                    // placeholder='Repetir contraseña'
                                    type="password" {...getFieldProps("priv_confirmPassword")}
                                    readOnly={autoOff}
                                    disabled={(!showPassword && typeAction === "update") || (disabled && typeAction !== "add") || loadingButton}
                                />
                                <div style={{ height: "auto" }}>

                                    {!errors.password && touched.priv_confirmPassword && errors.priv_confirmPassword && (
                                        // <CAlert color="danger" className='d-flex align-items-center' style={{ height: "25px" }}>
                                        //     <div className='small'>{errors.priv_confirmPassword}</div>
                                        // </CAlert>
                                        <div className='d-flex align-items-center mt-2' style={{ fontSize: 12, color: "red" }}>
                                            <CIcon size="sm" icon={cilWarning} />
                                            <div className='small' style={{ marginLeft: '5px' }}>{errors.priv_confirmPassword}</div>
                                        </div>
                                    )}
                                </div>
                            </div>

                        </CCol>

                    </CRow>



                )}



                {/* {!alreadyCreated && (
                            <CCol>
                                <CContainer style={{ marginTop: 50 }}>
                                    <ButtonLoadingSucessForm
                                        loadingButton={loadingButton}
                                        className="d-flex d-row"
                                        changeToBackup={changeToBackup}
                                        onSubmit={handleSubmit}
                                        setDisabled={isEdit}
                                        typeAction={typeAction}
                                        disabled={!isValid}
                                    />
                                </CContainer>
                            </CCol>
                        )} */}
                <div style={{ marginTop: 50 }}></div>

                <CRow className="justify-content-center">


                    {/* <pre style={{ color: "white" }}>{JSON.stringify(isValid, null, 2)}</pre> */}
                    <button

                        disabled={!isValid || loadingButton || errorCode}
                        className="continue-button"
                        type='button'
                        onClick={() => {
                            if (!alreadyCreated) {
                                handleSubmit()
                            } else {
                                changeStep(1);
                            }
                        }}>
                        {loadingButton && (
                            <CSpinner component="span" size="sm" aria-hidden="true" />
                        )}
                        {!loadingButton && (
                            <span>Continuar</span>
                        )}
                    </button>
                </CRow>





            </form >
        </CRow >


    )
}

export default RegistrationForm



