import * as Yup from 'yup';


const validationUserSchema = (values, typeAction, touched, checkUsername, idRoleRequired: boolean) => Yup.object({
    // image: Yup.string(),
    username: Yup
        .string()
        .max(40, "Debe de tener 40 caracteres o menos")
        .min(1, "Requerido 2")
        .test(
            'username',
            'El nombre de usuario no está disponible',
            async (username) => {
                if (!username) {
                    return true;
                }
                if (username && username.length > 0) {
                    let result = await checkUsername(values.id ? values.id : null, username);
                    return !result;
                } else {
                    return true;
                }
            }
        )
        .when([], (value, schema) => {
            if (touched.username) {
                return schema.required("Requerido");
            }
            return schema;
        }),

    password: Yup.string()
        .min(4, "Debe de tener 4 caracteres o más")
        .max(12, "Debe de tener 12 caracteres o menos")
        .test(
            'password',
            'La contraseña es necesaria',
            (value) => {
                if (typeAction === 'add' && (!value || !value.trim())) {
                    return false;
                } else {
                    return true;
                }
            }
        ),

    priv_confirmPassword: Yup
        .string()
        .test('priv_confirmPassword', 'Confirma la contraseña', function (value) {
            const { password } = this.parent;
            if (!!password) {
                if (value !== password) {
                    return false;   // Retornamos false en vez de un objeto de error
                    // return this.createError({ message: 'Confirma la contraseña' });
                }
            }
            return true;
        }),

    email: Yup
        .string()
        .email("Inserta un email válido")
        .required("Es necesario un email"),

    blocked: Yup.boolean(),

    idRoleFk: idRoleRequired
        ? Yup
            .number()
            .required("Es necesario un rol para el usuario")
        : Yup
            .number()
});

export default validationUserSchema;
