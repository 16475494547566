import React from 'react';
import { COLORS } from '../../../../../../core/constants/colors';

const StatusChip = ({ status }) => {
    const statusStyles = {
        REQUERIDO: { background: '#D32F2F', color: 'white' },
        OPCIONAL: { background: '#1976D2', color: 'white' },
        PENDIENTE: { background: COLORS.quaternaryColor, color: 'white' },
        EN_REVISION: { background: '#FFC107', color: 'black' },
        ACEPTADO: { background: COLORS.principalColor, color: 'white' },
        RECHAZADO: { background: '#F44336', color: 'white' },
        MODIFICAR: { background: '#8E24AA', color: 'white' }
    };

    const statusText = {
        REQUERIDO: 'Requerido',
        OPCIONAL: 'Opcional',
        PENDIENTE: 'Pendiente',
        EN_REVISION: 'En Revisión',
        ACEPTADO: 'Aceptado',
        RECHAZADO: 'Rechazado',
        MODIFICAR: 'Modificar'
    };

    return (
        <div style={{
            ...statusStyles[status],
            padding: '3px 8px',
            borderRadius: '15px',
            display: 'inline-block',
            fontSize: '0.8em',
            fontWeight: '500'
        }}>
            {statusText[status]}
        </div>
    );
}

export default StatusChip;
