export class NotaryAppointment {
    id?: number;
    startDate?: Date;
    endDate?: Date;
    comment?: string;
    urlNotary?: string;
    idUserFk?: number;
    idCompanyFk?: number;
    isLawyer?: boolean;
    createdDate?: Date;
    updatedDate?: Date;
    deletedDate?: Date;
}