
import { CBadge, CButton, CCloseButton, CCol, CForm, CFormInput, CInputGroup, CInputGroupText, CRow } from '@coreui/react-pro';
import * as React from 'react';
import { ChangeEvent, ChangeEventHandler, useEffect, useRef, useState } from 'react';
import { Chip } from '@mui/material';

import CIcon from '@coreui/icons-react';
import * as icon from '@coreui/icons';
import { cilTask } from '@coreui/icons';
import { Util } from '../../../core/util/util';
import { COLORS } from '../../../core/constants/colors';

interface SearchTableProps {
    placeholder?: string;
    handlePassword: (value: string) => void;
    disabled: boolean;
    blockWriting: boolean;

    clean?: boolean;

    differentColor?: boolean;
}

const CheckPassword = (props: SearchTableProps) => {

    const { disabled, clean, blockWriting, placeholder, handlePassword, differentColor } = props;
    const [password, setPassword] = useState("")
    const isInitialMount = useRef(true); // Referencia para verificar si es la inicialización del componente

    const inputRef = useRef(null);

    useEffect(() => {
        // if (isInitialMount.current) {
        //     isInitialMount.current = false;
        // } else {
        const typingTimeout = setTimeout(() => {
            handlePassword(password);
        }, 300);
        return () => {
            clearTimeout(typingTimeout);
        };

        // }
    }, [password])

    useEffect(() => {
        if (disabled) {
            handlePassword(Util.getUniqueId(6));
            inputRef.current.value = '';
        }
    }, [disabled])


    return (
        <CInputGroup className="mb-3">

            <CInputGroupText style={{ backgroundColor: differentColor ? COLORS.principalColor : "" }}>
                <CIcon icon={cilTask} style={{ color: differentColor ? "white" : "" }} />
            </CInputGroupText>
            <CFormInput
                ref={inputRef}
                type="text"
                id="searchtable"
                placeholder={placeholder ? placeholder : "Buscar"}
                onKeyUp={(e: any) => {
                    setPassword(e.target.value)
                }}
                disabled={disabled || blockWriting}
                // style={{ backgroundColor: blockWriting ? "#b9f2aa" : "" }}
                style={{ backgroundColor: blockWriting ? COLORS.secondaryColor : "" }}

            />
        </CInputGroup>
    );
};

export default CheckPassword;


