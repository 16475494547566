import React, { useEffect, useState } from 'react';
import { SUBCOMPONENTS_MAP } from './subcomponentsConfig';

import "./dsubstep-style.scss"
import ModalCover from '../../modalCover/ModalCover';
import AddCommentForStatus from './components/components/AddCommentForStatus';
import ReasonForRejection from './components/components/ReasonForRejection';
import StatusChip from './components/components/StatusChip';
import useHorizontalScroll from '../../../../core/hooks/horizontal-scroll/useHorizontalScroll';





const DSubstep = ({ substep, onComplete }) => {
  const Comp = substep.component ? SUBCOMPONENTS_MAP[substep.component] : undefined;

  const [showModal, setShowModal] = useState(false)
  const [titleModal, setTitleModal] = useState("")
  const [sizeModal, setSizeModal] = useState("")


  useEffect(() => {
    if (Comp) {
      chooseNameForModal(substep.component);
    }
  }, [substep.component, Comp]);


  /**
* Elige nombre para el modal
* @param typeComponent 
* @returns 
*/
  const chooseNameForModal = (typeComponent) => {
    switch (typeComponent) {
      case "Subcomponent1":
        setTitleModal("Gestión de Residencia en Andorra");
        setSizeModal("xl");
        break;

      case "Subcomponent2":
        setTitleModal("Nombre de la Empresa");
        setSizeModal("xl");
        break;

      case "Subcomponent3":
        setTitleModal("Apertura de cuenta bancaria y capital social.");
        setSizeModal("lg");
        break;

      case "Subcomponent4":
        setTitleModal("Configuración de Socios");
        setSizeModal("xl");
        break;

      case "Subcomponent5":
        setTitleModal("Domicilio Social");
        setSizeModal("lg");
        break;

      case "Subcomponent6":
        setTitleModal("Resumen de la Empresa");
        setSizeModal("xl");
        break;

      case "Subcomponent7":
        setTitleModal("Cita para Notaría");
        setSizeModal("lg");
        break;

      case "Subcomponent8":
        setTitleModal("Inscripción en registro mercantil");
        setSizeModal("lg");
        break;

      case "Subcomponent9":
        setTitleModal("Obtención en NRT");
        setSizeModal("lg");
        break;

      default:
        setTitleModal("Componente no reconocido");
        setSizeModal("default");
        break;
    }
  }


  // Scroll horizontal
  const hScrollRef = useHorizontalScroll();
  /**
  * Si el componente no existe (o ha sido comentado) el subpaso
  * no se mostrará
  */
  if (!Comp) {
    return (null)
  }


  return (
    <>
      <div ref={hScrollRef} className="container-substep" style={{ userSelect: 'none' }}>
        <div className='d-flex justify-content-between align-items-center' >
          <div>
            <AddCommentForStatus substepDashboard={substep} />
          </div>

          <div className='m-1' >
            {/* <span><b>{substep.status}</b></span> */}
            <StatusChip status={substep.status} />
          </div>
        </div>
        {/* {substep.status === 'Pendiente' && <button onClick={onComplete}>Completar Substep</button>} */}
        <div className="container-substep-content" style={{ flex: 4 }}>



          <div style={{ paddingRight: 10, paddingLeft: 10 }}>
            {substep.type}
            {/* {Comp && (
            
          )} */}
          </div>
          <div className="container-substep-footer" >
            <span onClick={() => setShowModal(true)}> Ver más <span style={{ fontSize: 14 }}>→</span></span>
          </div>
        </div >
      </div>

      {Comp && (
        <ModalCover
          styleBody={{ backgroundColor: "whitesmoke" }}
          title={titleModal}
          show={showModal}
          handleModal={setShowModal}
          size={sizeModal as any}
          hasSuccessButton={false}
          hasCloseButton={false}>
          <div>
            <ReasonForRejection subStep={substep} />
            <Comp
              showModal={showModal}
              handleModal={setShowModal}
              substepDashboard={substep}
            />
          </div>

        </ModalCover>
      )}
    </>
  );
};

export default DSubstep;
