// src/components/CardPaymentForm.tsx
import React, { CSSProperties } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import CheckoutForm from '../checkoutForm/CheckoutForm';
import { CCol, CRow } from '@coreui/react-pro';
import styled from 'styled-components';
import { DataRegistration } from '../../interfaces/interfaces';
import { environment } from '../../../../../environments';

const stripePromise = loadStripe(environment.stripeKey);

const Container = styled.div`
  width: 100%;
`;

const CardPaymentForm = ({ dataRegistration, isModal }) => {
  return (
    <Container>
      <Elements stripe={stripePromise}>
        <CheckoutForm dataRegistration={dataRegistration} isModal={isModal} />
      </Elements>
    </Container>
  );
};



export default CardPaymentForm;
