import { combineReducers, createStore } from 'redux';
import actionBarReducer from '../../app/components/actionBar/reducers/actionBar-reducer';
import dashboardStepReducer from '../../app/components/@dashboardSteps/reducers/dashboardStep-reducer';
import { uiReducer } from '../../app/components/template/store';



const rootReducer = combineReducers({
    actionBar: actionBarReducer,
    dashboardStep: dashboardStepReducer,
    ui: uiReducer,
    // myComponent: myReducer,
});


const store = createStore(rootReducer);

export type RootState = ReturnType<typeof rootReducer>;
export { store };
export default store;