import { cilInstitution, cilWarning } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import { CRow, CCol, CFormLabel, CInputGroup, CInputGroupText, CFormInput, CCardBody, CCard, CCardHeader, CAccordion, CAccordionBody, CAccordionHeader, CAccordionItem, CAlert } from "@coreui/react-pro";
import { useFormik } from "formik";
import { useState, useEffect, useRef } from "react";
import * as Yup from 'yup';
import { CompanyRelation } from "../../../../../../core/models/db/companyRelation";
import gsap from 'gsap';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import { FileItemObservableService } from "../../../../drag-drop/services/rxJS/fileitem-observable.service";
import DragDrop from "../../../../drag-drop/DragDrop";
import { environment } from "../../../../../environments";
import { Util } from "../../../../../../core/util/util";
import { COLORS } from "../../../../../../core/constants/colors";


// VALIDACIÓN FORMULARIO
const validationSchema = Yup.object().shape({
    name: Yup.string()
        .required('El nombre es obligatorio'),
    address: Yup.string()
        .nullable(),
    phone: Yup.string()
        .nullable(),
    // .matches(
    //     /^(\+?\d{1,4}?)?((\d{10,12}))$/,
    //     'Número de teléfono no válido'
    // ),
    email: Yup.string()
        .nullable()
        .email('Correo electrónico no válido'),
    urlPassport: Yup.string()
        .required('El pasaporte es obligatorio'),
    companyPercentage: Yup.number()
        .min(0, 'El porcentaje no puede ser negativo')
        .max(100, 'El porcentaje no puede ser mayor a 100')
        .required('El porcentaje de la compañía es obligatorio'),

    lastName1: Yup.string()
        .nullable()
        .max(100, 'El apellido 1 no puede superar los 100 caracteres'),
    lastName2: Yup.string()
        .nullable()
        .max(100, 'El apellido 2 no puede superar los 100 caracteres'),
    cif: Yup.string()
        .nullable()
        .max(100, 'El CIF no puede superar los 100 caracteres'),
    town: Yup.string()
        .nullable()
        .max(100, 'El nombre de la ciudad no puede superar los 100 caracteres'),
    zipCode: Yup.string()
        .nullable()
});



interface RowCompanyRelationProps {
    index: number;
    companyRelation: CompanyRelation;
    disabled: boolean;

    onValidityChange: (value: boolean) => void;
    onDelete: (index: number, rowToDelete: CompanyRelation) => void;
    onDataChange: (index: number, newData: CompanyRelation) => void;

}

const RowCompanyRelation = (props: RowCompanyRelationProps) => {

    const {
        index,
        companyRelation: companyRelationParent,
        disabled,

        onDataChange,
        onDelete,
        onValidityChange
    } = props;

    const [companyRelation, setCompanyRelation] = useState<CompanyRelation>(companyRelationParent);
    const [loading, setLoading] = useState(undefined);

    const [isAccordionOpen, setIsAccordionOpen] = useState(false);
    const [idDragDrop, setIdDragDrop] = useState(undefined);

    // useEffect(() => {

    //     setIdDragDrop("partner_substep4_" + index)

    // }, [index])



    const [deletingDocIndex, setDeletingDocIndex] = useState(null);
    const documentRowRef = useRef([]);
    const confirmRef = useRef([]);
    const confirmContentRef = useRef([]);
    const prevDeletingDocIndex = useRef(null);

    useEffect(() => {
        if (prevDeletingDocIndex.current !== null && prevDeletingDocIndex.current !== deletingDocIndex) {
            gsap.to(documentRowRef.current[prevDeletingDocIndex.current], { width: "100%", duration: 0.5 });
            gsap.to(confirmRef.current[prevDeletingDocIndex.current], { width: "0%", opacity: 0, duration: 0.5 });
            gsap.to(confirmContentRef.current[prevDeletingDocIndex.current], { opacity: 0, duration: 0.2 });
        }

        if (deletingDocIndex !== null) {
            gsap.to(documentRowRef.current[deletingDocIndex], { width: "70%", duration: 0.5 });
            gsap.fromTo(confirmRef.current[deletingDocIndex], { width: '0%', opacity: 0 }, { width: '30%', opacity: 1, duration: 0.5 });
            gsap.to(confirmContentRef.current[deletingDocIndex], { opacity: 1, delay: 0.3 });
        }
    }, [deletingDocIndex]);

    useEffect(() => {
        prevDeletingDocIndex.current = deletingDocIndex;
    }, [deletingDocIndex]);



    // Animación GSAP cuando el acordeón se abre o cierra
    useEffect(() => {
        if (isAccordionOpen) {
            gsap.to(".accordion-content", { height: "auto", opacity: 1, duration: 0.5 });
        } else {
            gsap.to(".accordion-content", { height: 0, opacity: 0, duration: 0.5 });
        }
    }, [isAccordionOpen]);



    useEffect(() => {
        (async () => {
            await initPage();
        })();
    }, [])

    const initPage = async () => {
        try {


        } catch (error) {
            setLoading(false);
            console.error(error);
        }
    }

    // FORMIK
    const { values, errors, touched, isValid,

        handleChange,
        handleSubmit,
        handleBlur,
        getFieldProps,
        setFieldValue,
        setValues,

    } = useFormik({
        initialValues: { ...companyRelation },
        validateOnBlur: false,
        onSubmit: (value) => {
            console.log(value)
            console.log("Hay que hacer algo")
        },
        validationSchema: validationSchema
    })

    useEffect(() => {
        if (onValidityChange && values?.name && values?.name.trim() !== "") {
            onValidityChange(isValid);
        } else if (onValidityChange) {
            onValidityChange(false);
        }

    }, [isValid, onValidityChange]);


    const handleChangeAndInformParent = (e) => {
        handleChange(e);  // Esta es la función handleChange de Formik
        if (onDataChange) {
            console.log({ ...values, [e.target.name]: e.target.value })
            onDataChange(index, { ...values, [e.target.name]: e.target.value });
        }
    }

    // Drag and drop
    // Drag and drop
    // Drag and drop

    const [filesAlreadyUploaded, setFilesAlreadyUploaded] = useState<any[]>([]);
    // REF
    const dragDropRef = useRef(null);
    const controlFile$ = useRef(FileItemObservableService.instance.control$);

    /** Cuando existe un cambio en el observable actializo el listado de items
     * 
     * NO SE USA
     * NO SE USA
     * NO SE USA
     */
    useEffect(() => {
        const subscription = FileItemObservableService.instance
            .control$.subscribe(
                item => {

                    //     if (idDrag && item && item.fileItemList && item?.id === idDragDrop && item.fileItemList.length > 0) {

                    //         // setFilesAlreadyUploaded(item.fileItemList)
                    //         setFieldValue('urlPassport', item.fileItemList[0].pathRelative);
                    //         onDataChange(index, { ...values, urlPassport: item.fileItemList[0].pathRelative });
                    //     } else if (idDrag && item && item?.id === idDragDrop) {
                    //         // setFilesAlreadyUploaded([])
                    //         setFieldValue('urlPassport', "");
                    //         onDataChange(index, { ...values, urlPassport: "" });
                    //     }
                }
            )
        return () => {
            subscription.unsubscribe();
        }

    }, [controlFile$, index]);



    return (
        <div style={{ display: "flex" }}>
            <Accordion style={{ width: "100%" }}>

                <AccordionSummary
                    aria-controls="panel1a-content"
                    id="panel1a-header"

                >

                    <div style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%",
                        fontFamily: "Roboto, Arial, sans-serif",
                        padding: "10px",
                        borderBottom: "1px solid #eee"
                    }}>
                        <div>
                            <b style={{ fontSize: "1.3em", fontFamily: "Roboto" }}>#{index + 1} Socio</b>
                            {values.name && (
                                <span style={{
                                    marginLeft: 15,
                                    fontSize: "1.1em",
                                    fontFamily: "Roboto",
                                
                                }}>{values.name}</span>
                            )}
                            {values.companyPercentage !== undefined && values.companyPercentage !== '' as any && (
                                <span style={{
                                    marginLeft: 20,
                                    fontWeight: 700,
                                    fontSize: "1.6em",
                                    color: COLORS.principalColor,
                                    fontFamily: "Roboto",
                                }}>
                                    {values.companyPercentage}%
                                </span>
                            )}
                        </div>

                        {onDelete && !disabled && (
                            <svg
                                style={{ marginRight: 15, fontSize: 30, fill: "red", cursor: "pointer" }}
                                className="icon-close"
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    if (disabled) return;
                                    setDeletingDocIndex(0)
                                }}
                                xmlns="http://www.w3.org/2000/svg"
                                height="1em"
                                viewBox="0 0 512 512"
                            >
                                <path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM175 175c-9.4 9.4-9.4 24.6 0 33.9l47 47-47 47c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l47-47 47 47c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-47-47 47-47c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-47 47-47-47c-9.4-9.4-24.6-9.4-33.9 0z" />
                            </svg>
                        )}

                        {!onDelete && onDataChange && (
                            <span>Creador de la compañía</span>
                        )}

                    </div>


                    <div
                        ref={el => confirmRef.current[0] = el}
                        style={{ display: "flex", justifyContent: "center", width: '0%', opacity: 0, height: 60, overflow: 'hidden' }}
                    >
                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center" }} ref={el => confirmContentRef.current[0] = el}>
                            <div>
                                <span style={{ fontSize: 13, fontWeight: 800 }}>¿Desear eliminarlo?</span>
                            </div>
                            <div style={{ display: "flex", justifyContent: "center", }}>
                                <button className="button-perla" onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    // Por seguridad, si es disabled no se puede borrar
                                    if (disabled || !onDelete) return;

                                    setDeletingDocIndex(null);
                                    onDelete(index, companyRelation);
                                }}>Sí</button>
                                <button className="button-perla" onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();

                                    setDeletingDocIndex(null)
                                }}>No</button>
                            </div>
                        </div>
                    </div>
                </AccordionSummary>
                {/* <pre>ES ESTO{JSON.stringify(values, null, 2)}</pre> */}

                <AccordionDetails>
                    <CCardBody>
                        <form onSubmit={handleSubmit}>
                            {/* <pre>{JSON.stringify(errors, null, 2)}</pre> */}
                            <CRow className="justify-content-center">
                                <CCol lg={4}>
                                    {/* Field: Name */}
                                    <div className="input-container" style={{ marginBottom: 30 }}>
                                        <label className="input-label" style={{ color: COLORS.principalColor }}>Nombre</label>
                                        <CFormInput
                                            disabled={disabled}
                                            id="name"
                                            placeholder="Nombre del socio"
                                            {...getFieldProps("name")}

                                            onChange={handleChangeAndInformParent}
                                        />

                                        {touched.name && errors.name && (
                                            <div className='d-flex align-items-center mt-2' style={{ fontSize: 12, color: "red" }}>
                                                <CIcon size="sm" icon={cilWarning} />
                                                <div className='small' style={{ marginLeft: '5px' }}>{errors.name}</div>
                                            </div>
                                        )}
                                    </div>



                                </CCol>

                                {/* Input: lastName1 */}
                                <CCol lg={4}>
                                    <div className="input-container" style={{ marginBottom: 30 }}>
                                        <label className="input-label" style={{ color: COLORS.principalColor }}>Apellido 1</label>
                                        <CFormInput
                                            disabled={disabled}

                                            id="lastName1"
                                            placeholder="Apellido 1"
                                            {...getFieldProps("lastName1")}
                                            onChange={handleChangeAndInformParent}
                                        />

                                        {touched.lastName1 && errors.lastName1 && (
                                            <div className='d-flex align-items-center mt-2' style={{ fontSize: 12, color: "red" }}>
                                                <CIcon size="sm" icon={cilWarning} />
                                                <div className='small' style={{ marginLeft: '5px' }}>{errors.lastName1}</div>
                                            </div>
                                        )}
                                    </div>
                                </CCol>

                                {/* Input: lastName2 */}
                                <CCol lg={4}>
                                    <div className="input-container" style={{ marginBottom: 30 }}>
                                        <label className="input-label" style={{ color: COLORS.principalColor }}>Apellido 2</label>
                                        <CFormInput
                                            disabled={disabled}

                                            id="lastName2"
                                            placeholder="Apellido 2"
                                            {...getFieldProps("lastName2")}
                                            onChange={handleChangeAndInformParent}
                                        />
                                        {touched.lastName2 && errors.lastName2 && (
                                            <div className='d-flex align-items-center mt-2' style={{ fontSize: 12, color: "red" }}>
                                                <CIcon size="sm" icon={cilWarning} />
                                                <div className='small' style={{ marginLeft: '5px' }}>{errors.lastName2}</div>
                                            </div>
                                        )}
                                    </div>

                                </CCol>


                                {/* Field: Company Percentage */}
                                <CCol lg={2}>
                                    <div className="input-container" style={{ marginBottom: 30 }}>
                                        <label className="input-label" style={{ color: COLORS.principalColor }}>% Compañía</label>
                                        <CFormInput
                                            disabled={disabled}
                                            className='input-number'
                                            id="companyPercentage"
                                            placeholder="Porcentaje de la Compañía"
                                            type="number"
                                            {...getFieldProps("companyPercentage")}

                                            onChange={handleChangeAndInformParent}
                                        />


                                        {touched.companyPercentage && errors.companyPercentage && (
                                            <div className='d-flex align-items-center mt-2' style={{ fontSize: 12, color: "red" }}>
                                                <CIcon size="sm" icon={cilWarning} />
                                                <div className='small' style={{ marginLeft: '5px' }}>{errors.companyPercentage}</div>
                                            </div>
                                        )}

                                    </div>
                                </CCol>



                                {/* Input: CIF */}
                                {/* <CCol lg={6}>
                                    <CFormLabel htmlFor="cif">CIF</CFormLabel>
                                    <CInputGroup className="mb-3">
                                        <CInputGroupText>
                                            <CIcon icon={cilInstitution} />
                                        </CInputGroupText>
                                        <CFormInput
                                            disabled={disabled}

                                            id="cif"
                                            placeholder="CIF"
                                            {...getFieldProps("cif")}
                                            onChange={handleChangeAndInformParent}
                                        />
                                    </CInputGroup>
                                    <div style={{ height: "auto", marginTop: 15 }}>
                                        {touched.cif && errors.cif && (
                                            <CAlert color="danger" className='d-flex align-items-center' style={{ height: "25px" }}>
                                                <div className='small'>{errors.cif}</div>
                                            </CAlert>
                                        )}
                                    </div>
                                </CCol> */}


                                <CCol lg={5}>
                                    {/* Field: Email */}
                                    <div className="input-container" style={{ marginBottom: 30 }}>
                                        <label className="input-label" style={{ color: COLORS.principalColor }}>Correo electrónico</label>
                                        <CFormInput
                                            disabled={disabled}

                                            id="email"
                                            placeholder="Correo electrónico"
                                            {...getFieldProps("email")}

                                            onChange={handleChangeAndInformParent}
                                        />

                                        {touched.email && errors.email && (
                                            <div className='d-flex align-items-center mt-2' style={{ fontSize: 12, color: "red" }}>
                                                <CIcon size="sm" icon={cilWarning} />
                                                <div className='small' style={{ marginLeft: '5px' }}>{errors.email}</div>
                                            </div>
                                        )}

                                    </div>

                                </CCol>

                                {/* Field: Phone */}
                                <CCol lg={5}>
                                    <div className="input-container" style={{ marginBottom: 30 }}>
                                        <label className="input-label" style={{ color: COLORS.principalColor }}>Teléfono</label>
                                        <CFormInput
                                            disabled={disabled}
                                            className='input-number'
                                            type="number"
                                            id="phone"
                                            placeholder="Número de teléfono"
                                            {...getFieldProps("phone")}

                                            onChange={handleChangeAndInformParent}
                                        />

                                    </div>

                                    {touched.phone && errors.phone && (
                                        <div className='d-flex align-items-center mt-2' style={{ fontSize: 12, color: "red" }}>
                                            <CIcon size="sm" icon={cilWarning} />
                                            <div className='small' style={{ marginLeft: '5px' }}>{errors.phone}</div>
                                        </div>
                                    )}

                                </CCol>

                                <CCol lg={8}>

                                    {/* Field: Address */}
                                    <div className="input-container" style={{ marginBottom: 30 }}>
                                        <label className="input-label" style={{ color: COLORS.principalColor }}>Dirección</label>
                                        <CFormInput
                                            disabled={disabled}

                                            id="address"
                                            placeholder="Dirección"
                                            {...getFieldProps("address")}

                                            onChange={handleChangeAndInformParent}
                                        />
                                    </div>

                                    {touched.address && errors.address && (
                                        <div className='d-flex align-items-center mt-2' style={{ fontSize: 12, color: "red" }}>
                                            <CIcon size="sm" icon={cilWarning} />
                                            <div className='small' style={{ marginLeft: '5px' }}>{errors.address}</div>
                                        </div>
                                    )}

                                </CCol>

                                {/* Input: Town */}
                                <CCol lg={4}>
                                    <div className="input-container" style={{ marginBottom: 30 }}>
                                        <label className="input-label" style={{ color: COLORS.principalColor }}>Ciudad</label>
                                        <CFormInput
                                            disabled={disabled}

                                            id="town"
                                            placeholder="Ciudad"
                                            {...getFieldProps("town")}
                                            onChange={handleChangeAndInformParent}
                                        />
                                    </div>

                                    {touched.town && errors.town && (
                                        <div className='d-flex align-items-center mt-2' style={{ fontSize: 12, color: "red" }}>
                                            <CIcon size="sm" icon={cilWarning} />
                                            <div className='small' style={{ marginLeft: '5px' }}>{errors.town}</div>
                                        </div>
                                    )}

                                </CCol>
                            </CRow>
                            <CRow>
                                {/* Input: Zip Code */}
                                <CCol lg={2}>
                                    <div className="input-container" style={{ marginBottom: 30 }}>
                                        <label className="input-label" style={{ color: COLORS.principalColor }}>Código Postal</label>
                                        <CFormInput
                                            disabled={disabled}
                                            id="zipCode"
                                            placeholder="Código Postal"
                                            {...getFieldProps("zipCode")}
                                            onChange={handleChangeAndInformParent}
                                        />

                                    </div>

                                    {touched.zipCode && errors.zipCode && (
                                        <div className='d-flex align-items-center mt-2' style={{ fontSize: 12, color: "red" }}>
                                            <CIcon size="sm" icon={cilWarning} />
                                            <div className='small' style={{ marginLeft: '5px' }}>{errors.zipCode}</div>
                                        </div>
                                    )}

                                </CCol>
                            </CRow>



                            <CRow>

                                <div className="fileUploadContainer" style={{ display: "flex", flexDirection: "column" }}>
                                    <CCol lg={9}>
                                        <p style={{ fontSize: 15, marginBottom: 10, fontFamily: "Raleway", }}>
                                            Arrastra y suelta tu pasaporte aquí o haz clic para seleccionarlo. Este campo es para cargar una copia digital de tu pasaporte. Asegúrate de que el archivo cumpla con los formatos permitidos y no exceda el tamaño máximo.

                                        </p>
                                    </CCol>

                                    <DragDrop
                                        specialCase={true}
                                        handleFiles={(item) => {
                                            console.log(item)
                                            if (!onDataChange) return;

                                            if (item && item.length > 0) {
                                                // setFilesAlreadyUploaded(item.fileItemList)
                                                setFieldValue('urlPassport', item[0].pathRelative);
                                                onDataChange(index, { ...values, urlPassport: item[0].pathRelative });
                                            } else {
                                                console.log("QUE ENTRO AQUI 2")
                                                // setFilesAlreadyUploaded([])
                                                setFieldValue('urlPassport', "");
                                                onDataChange(index, { ...values, urlPassport: "" });
                                            }
                                        }}


                                        ref={dragDropRef}
                                        styleDD={{ width: 300, height: 150 }}
                                        // classDD={'ploadContainer'}
                                        id={idDragDrop}
                                        isOnlyOneFile={true}
                                        showFileList={false}
                                        // disabled={loading || typeFilesList.filter(item => item.uploaded).length === countLimit}
                                        disabled={
                                            loading
                                            || disabled
                                        }
                                        valueDefault={companyRelation && companyRelation.urlPassport ? companyRelation.urlPassport : ""}

                                        directoryNameList={["fromReact", "company"]}
                                        maxSizeMB={19}
                                        typeAllowed={[
                                            "image/*",
                                            "image/gif",
                                            "image/jpeg",
                                            "image/png",
                                            "image/jpg",
                                            "application/pdf"
                                        ]} />

                                    <div style={{ height: "auto", marginTop: 15 }}>
                                        {errors.urlPassport && (
                                            <div className='d-flex align-items-center mt-2' style={{ fontSize: 12, color: "red" }}>
                                                <CIcon size="sm" icon={cilWarning} />
                                                <div className='small' style={{ marginLeft: '5px' }}>{errors.urlPassport}</div>
                                            </div>
                                        )}
                                    </div>


                                    {companyRelation && companyRelation.urlPassport && (
                                        <div style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            cursor: 'pointer',
                                            color: '#007BFF'
                                        }}
                                            onClick={() => Util.downloadDocumentBackend(companyRelation.urlPassport, companyRelation.urlPassport)}
                                        >
                                            <span style={{
                                                color: COLORS.secondaryColor,
                                                marginRight: '10px',
                                                textDecoration: 'none'
                                            }}
                                                onMouseOver={(e: any) => e.target.style.textDecoration = 'underline'}
                                                onMouseOut={(e: any) => e.target.style.textDecoration = 'none'}
                                            >
                                                Descargar documento
                                            </span>
                                            <svg
                                                fill={COLORS.secondaryColor}
                                                className="icon-download"
                                                xmlns="http://www.w3.org/2000/svg"
                                                height="1em"
                                                viewBox="0 0 512 512">
                                                <path d="M288 32c0-17.7-14.3-32-32-32s-32 14.3-32 32V274.7l-73.4-73.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0l128-128c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L288 274.7V32zM64 352c-35.3 0-64 28.7-64 64v32c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V416c0-35.3-28.7-64-64-64H346.5l-45.3 45.3c-25 25-65.5 25-90.5 0L165.5 352H64zm368 56a24 24 0 1 1 0 48 24 24 0 1 1 0-48z" />
                                            </svg>
                                        </div>

                                    )}
                                </div>




                            </CRow>


                        </form >
                    </CCardBody>
                </AccordionDetails >
            </Accordion >



        </div >
    )
}

export default RowCompanyRelation