import { Company } from "../../../core/models/db/company";
import { SUBCOMPONENTS_MAP } from "./DSubstep/subcomponentsConfig";
import { SET_CURRENT_COMPANY, SET_CURRENT_STEP, SET_INITIAL_DATA, UPDATE_STEP } from "./actions/dashboardStep-action";

// Estados del subpaso
export enum StatusSubstep {
  REQUERIDO = 'REQUERIDO',
  OPCIONAL = 'OPCIONAL',
  EN_REVISION = 'EN_REVISION',
  ACEPTADO = 'ACEPTADO',
  RECHAZADO = 'RECHAZADO',
  MODIFICAR = 'MODIFICAR',
}

// Estados del paso
export enum StatusStep {
  PENDIENTE = 'PENDIENTE',
  EN_REVISION = 'EN_REVISION',
  ACEPTADO = 'ACEPTADO',
  RECHAZADO = 'RECHAZADO',
}





export interface Substep {
  id: number;
  type: string;
  status: StatusSubstep;
  component: keyof typeof SUBCOMPONENTS_MAP;
  // disabled?: boolean; 
  comment?: string;
}

export interface Step {
  id: number;
  idReal: number;
  status: StatusStep;
  disabled: boolean;
  dependencias?: number[];
  substeps: Substep[];

  name: string;
  description: string;
}


interface SetInitialDataAction {
  type: typeof SET_INITIAL_DATA;
  payload: {
    steps: Step[],
    company: Company,
    currentStepId: number,
    currentSubstepId: number,
    idCompany: number,
    idUser: number
  };
}

interface UpdateStepAction {
  type: typeof UPDATE_STEP;
  payload: {
    step: Step;
    substep?: Substep;
  }
}

interface SetCurrentStepAction {
  type: typeof SET_CURRENT_STEP;
  payload: {
    currentStepId: number;
  };
}

interface SetCurrentCompanyAction {
  type: typeof SET_CURRENT_COMPANY;
  payload: {
    company: Company,
    idCompany: any
  };
}


export type DashboardStepActionTypes = SetCurrentStepAction | SetCurrentCompanyAction | SetInitialDataAction | UpdateStepAction;




export interface DBSubstep {
  id: number;
  idUserFk: number;
  idSubstepFk: number;
  idStatusSubstepFk: number;
  comment: string | null;
  statusSubsteps: {
    name: string;
  };
  substeps: {
    name: string;
    description: string;
  };
}

export interface DBStep {
  id: number;
  idUserFk: number;
  idStepFk: number;
  idStatusStepFk: number;
  comment: string | null;
  steps: {
    name: string;
    description: string;
  };
  statusSteps: {
    name: string;
  };
  userSubsteps: DBSubstep[];
}

export type DBResponse = {
  message: string;
  ok: boolean;
  status: number;
  item: DBStep[];
}





export interface ComponentMapping {
  [key: number]: string;  // Usando ID
  // [key: string]: string;  // Usando NAME (comentado por ahora, pero disponible para cuando lo necesites)
}
