import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useCompany } from "../../../../../core/hooks/db/company/useCompany";
import { useUser } from "../../../../../core/hooks/db/user/useUser";
import { useUserSubstep } from "../../../../../core/hooks/db/userSubstep/useUserSubstep";
import { LoginService } from "../../../../../core/services/login/login-service";
import { RootState } from "../../../../../core/store/store";
import { SubcomponentBasicProp } from "./Subcomponent1";
import { useCompanyRelation } from "../../../../../core/hooks/db/companyRelation/useCompanyRelation";
import RowCompanyRelation from "./components/RowCompanyRelation";
import { CCol, CFormTextarea, CLoadingButton, CRow } from "@coreui/react-pro";


import "./scss/subcomp6-style.scss"
import { StatusStep, StatusSubstep } from "../../types";
import { setCurrentCompany, updateStep } from "../../actions/dashboardStep-action";
import { COLORS } from "../../../../../core/constants/colors";

/**
 * Subpaso 1 del paso 5
 */
const Subcomponent6 = (props: SubcomponentBasicProp) => {
  const { showModal, handleModal, substepDashboard } = props;

  // REDUX: Paso y subpaso actual
  const dispatch = useDispatch();
  const { steps, company, idCompany, idUser, currentStepId } = useSelector((state: RootState) => state.dashboardStep);

  const currentStep = steps.find(s => s.id === currentStepId);
  const currentSubstep = substepDashboard;


  const { getCompanyById } = useCompany();
  const { getCompanyRelationByCompany } = useCompanyRelation();
  const { updateUserSubstep, getUserSubstepById } = useUserSubstep();

  const { checkAdmin } = useUser();



  const [companyAux, setCompanyAux] = useState(company);
  const [isAdmin, setIsAdmin] = useState(undefined);
  const [identity, setIdentity] = useState(undefined);
  const [loading, setLoading] = useState(undefined);
  const [companyRelationList, setCompanyRelationList] = useState([]);

  const [disabledByStatus, setDisabledByStatus] = useState(false);
  /**
   * Campo del objeto UserStep
   */
  const [commentUser, setCommentUser] = useState("");


  useEffect(() => {
    if (currentSubstep && (currentSubstep.status === "EN_REVISION" || currentSubstep.status === "ACEPTADO")) {
      setDisabledByStatus(true);
    } else {
      setDisabledByStatus(false);
    }
  }, [currentSubstep]);

  useEffect(() => {
    (async () => {
      await initPage();
    })();
  }, [])


  const initPage = async () => {
    try {
      setLoading(true);
      const token = await LoginService.getTokenAsync();
      if (!identity) {
        const identity = await LoginService.getIdentityAsync();
        setIdentity(identity)
      }

      const result = await Promise.all([
        checkAdmin(token),
        getCompanyRelationByCompany(token, company.id),
        getUserSubstepById(token, currentSubstep.id)
      ]);

      setIsAdmin(result[0] && result[0].item === true);

      if (result[1] && result[1].ok) {
        setCompanyRelationList(previous => [...result[1].item])
      }

      if (result[2] && result[2].ok) {

        console.log(result[2].item)
        setCommentUser(result[2]?.item?.commentUser || "")
      }

      setLoading(false);

    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  }

  const updateUserStep = async () => {
    try {
      const token = await LoginService.getTokenAsync();
      const ID_EN_REVISION_STATUS = 3;
      const result = await updateUserSubstep(token,
        {
          id: +currentSubstep.id,
          idStatusSubstepFk: ID_EN_REVISION_STATUS,
          comment: "",
          commentUser
        })
      if (result && result.ok) {

        let step = currentStep;
        let substep = currentSubstep;

        step.status = StatusStep.EN_REVISION;
        substep.status = StatusSubstep.EN_REVISION;


        dispatch(updateStep(step, substep));
        setLoading(false);
        handleModal(false);

      } else {
        throw Error("Hubo un error", result)
      }
    } catch (error) {
      setLoading(false);

      console.error(error);
      handleModal(false);
    }
  }


  return (
    <div>
      <div className="company-card">
        {company && (
          <div className="company-details">
            <h2 className="company-name">
              {company.companyName} {company.typeCommercialCompany}
            </h2>
            {company.description && <p className="company-description">{company.description}</p>}
            {(company.address || company.town || company.zipCode) &&
              <p className="company-address">
                {company.address}, {company.town}, {company.zipCode}
              </p>
            }
            {company.cif && <p className="company-cif">CIF: {company.cif}</p>}
            {company.email && <p className="company-email">Email: {company.email}</p>}
            {company.landlinePhone && <p className="company-phone">Landline: {company.landlinePhone}</p>}
            {company.mobile && <p className="company-mobile">Mobile: {company.mobile}</p>}
          </div>
        )}
      </div>

      <div className="company-relation-list">
        {companyRelationList && !loading && companyRelationList.map((companyRelation, index) => (
          <>
            <RowCompanyRelation
              disabled={true}
              key={index}
              index={index}
              onValidityChange={null}
              onDelete={undefined}
              companyRelation={companyRelation}
              onDataChange={undefined}
            />

            <div className="relation-gap"></div>
          </>
        ))}
      </div>


      <div style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        // backgroundColor: "pink"
      }}>
        <CCol sm={12} md={12} lg={9}>
          <p style={{ fontSize: 15, fontFamily: "Raleway", marginBottom: 30, marginTop: 20, }}>
            Si tiene observaciones o sugerencias específicas relacionadas con la información presentada, por favor, indíquelas en el espacio proporcionado a continuación. Este campo es opcional, pero cualquier comentario proporcionado será revisado atentamente por nuestro equipo para garantizar la precisión y pertinencia de la información.
          </p>
        </CCol>
        <CCol sm={12} md={12} lg={9}>
          <CFormTextarea
            disabled={loading || disabledByStatus || isAdmin}
            className="mb-3"
            rows={6}
            placeholder="Deja tu comentario"
            aria-label="Deja tu comentario"
            defaultValue={commentUser}
            onChange={(e) => setCommentUser(e.target.value)}
          ></CFormTextarea>
        </CCol>


        {!disabledByStatus && !isAdmin && (
          <CRow>
            <div style={{ marginTop: 20 }}></div>
            <CLoadingButton
              style={{ backgroundColor: COLORS.principalColor }}
              // color="dark"
              disabled={loading || disabledByStatus}
              loading={loading}
              onClick={() => {
                updateUserStep()
              }}>Mandar a revisión</CLoadingButton>
            <div style={{ marginBottom: 20 }}></div>

          </CRow>
        )}
      </div>
    </div >
  )

}

export default Subcomponent6