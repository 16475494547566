import React from 'react'
import RegistrationBase from '../registration/RegistrationBase'

const stepPageOne = () => {
    return (
        <div>
            <RegistrationBase />
        </div>
    )
}

export default stepPageOne
