import { useCompany } from "../../../../core/hooks/db/company/useCompany";
import { useCompanyRelation } from "../../../../core/hooks/db/companyRelation/useCompanyRelation";
import { ChangeEvent, FocusEvent, useEffect, useState } from "react";
import { LoginService } from "../../../../core/services/login/login-service";
import { Company } from "../../../../core/models/db/company";
import { COLORS } from "../../../../core/constants/colors";
import { useLoader } from "../../../components/loader/hook/useLoader";
import ButtonLoadingSucessForm from "../../../components/buttonLoadingSucessForm/ButtonLoadingSucessForm";
import { useFormik } from "formik";
import * as Yup from 'yup';
import { cilWarning } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import { CCol, CFormInput, CFormTextarea } from "@coreui/react-pro";
import Autocomplete from "../../../components/autocomplete/Autocomplete";
import { setCurrentCompany } from "../../../components/@dashboardSteps/actions/dashboardStep-action";
import { useDispatch } from "react-redux";


// VALIDACIÓN FORMULARIO
const validationSchema = Yup.object().shape({
    companyName: Yup.string().required('Campo obligatorio'),
    description: Yup.string().nullable(),
    town: Yup.string().nullable().max(100, 'El nombre de la ciudad no puede superar los 100 caracteres'),
    zipCode: Yup.string().nullable(),
    // cif: Yup.string().nullable().max(100, 'El CIF no puede superar los 100 caracteres'),
    typeCommercialCompany: Yup.string().oneOf(['SLU', 'SL', 'SA'], 'Tipo de empresa no válido').required('Campo obligatorio')
});



interface CompanyAuxProps {
    isAdmin: boolean;
    company: Company;
    updateCompany: (token, company: Company) => Promise<any>;
}
const CompanyAuxForm = (props: CompanyAuxProps) => {

    const {
        company: COMPANY_REDUX,
        isAdmin,

        updateCompany
    } = props;


    const dispatch = useDispatch();

    const [company, setCompany] = useState<Company>(COMPANY_REDUX);
    const [company_BACKUP, setCompany_BACKUP] = useState<Company>(COMPANY_REDUX);

    const [companyTypes, setCompanyTypes] = useState<any>(["SLU", "SL", "SA"]);

    const [isEdit, setIsEdit] = useState(true);

    const { loading, style, className, handleLoading, changeStyle, changeClassName } = useLoader({
        isLoading: false
    })

    useEffect(() => {
        if (COMPANY_REDUX && company && COMPANY_REDUX.id != company.id) {
            let c = {
                id: COMPANY_REDUX.id,
                address: COMPANY_REDUX.address || "",
                town: COMPANY_REDUX.town || "",
                companyName: COMPANY_REDUX.companyName || "",
                zipCode: COMPANY_REDUX.zipCode || "",
                description: COMPANY_REDUX.description || "",
                typeCommercialCompany: COMPANY_REDUX.typeCommercialCompany || ""
            }

            setCompany(c);
            setCompany_BACKUP(c);
            setValues(c);

            setIsEdit(true);
        }
    }, [COMPANY_REDUX])


    const submit = async (c: Company) => {
        try {
            handleLoading(true);
            const token = await LoginService.getTokenAsync();
            c.companyNameTest1 = "";
            c.companyNameTest2 = "";
            c.companyNameTest3 = "";


            const result = await updateCompany(token, c);

            if (result && result.ok) {
                setCompany(result.item);
                setCompany_BACKUP(result.item);
                dispatch(setCurrentCompany(values));

                // No hace falta porque ya tiene los valores actualizados de haber escrito antes
                // setValues(result.item);
            } else {
                setCompany({ ...company_BACKUP });
                setCompany_BACKUP({ ...company_BACKUP });
                setValues({ ...company_BACKUP });
            }
            setIsEdit(true);
            handleLoading(false);

        } catch (error) {
            handleLoading(false);


            console.error(error)
        }
    }


    const handleBackup = async () => {
        setCompany({ ...company_BACKUP });
        setValues({ ...company_BACKUP });
    }


    // const [imageUser, setImageUser] = useState<string>(company && company.image ? company.image : "");


    const { values, errors, touched, isValid,

        handleChange,
        handleSubmit,
        handleBlur,
        getFieldProps,
        setFieldValue,
        setValues
    } = useFormik({
        initialValues: company,
        validateOnBlur: false,
        onSubmit: (value) => {
            submit(values);
        },
        validationSchema: validationSchema
    })



    return (
        <div>
            {/* <pre style={{ color: "white" }}>{JSON.stringify(errors, null, 2)}</pre>
            <pre style={{ color: "white" }}>{JSON.stringify(isEdit, null, 2)}</pre> */}

            <div style={{ marginTop: 50, marginBottom: 50 }}>


                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "98%" }}>
                    <div style={{ color: COLORS.secondaryColor, fontSize: 30, fontWeight: 700 }}>Empresa</div>

                    {isAdmin && (
                        <div style={{ flex: 1 }}>
                            <ButtonLoadingSucessForm
                                primaryColor={COLORS.principalColor}
                                secondaryColor={COLORS.secondaryColor}
                                loadingButton={loading}
                                className="d-flex d-row mb-3"
                                changeToBackup={handleBackup}
                                onSubmit={handleSubmit}
                                setDisabled={setIsEdit}
                                typeAction={"update"}
                                disabled={!isValid || isEdit}
                            />
                        </div>
                    )}
                </div>

                <div style={{ marginTop: 20, marginBottom: 100 }}></div>

                <form onSubmit={handleSubmit}>

                    <div className="input-group flex-column">
                        <div className="d-flex justify-content-between align-items-center">
                            <label className="input-label flex-grow-1 mr-3" style={{ display: "flex" }}>Nombre compañía</label>
                            <CCol sm={3}>
                                <div className="flex-grow-1">
                                    <CFormInput
                                        disabled={!isAdmin || isEdit}
                                        className="dark-input w-100"
                                        defaultValue={company.companyName}
                                        {...getFieldProps("companyName")}
                                    />
                                    {touched.companyName && errors.companyName && (
                                        <div className='d-flex align-items-center mt-2' style={{ fontSize: 12, color: "red" }}>
                                            <CIcon size="sm" icon={cilWarning} />
                                            <div className='small' style={{ marginLeft: '5px' }}>{errors.companyName}</div>
                                        </div>
                                    )}
                                </div>
                            </CCol>
                        </div>

                        <div style={{ borderBottom: "1px solid #323343", marginTop: 12, marginBottom: 12, }}></div>

                        <div className="d-flex justify-content-between align-items-center">
                            <label className="input-label flex-grow-1 mr-3">Tipo de compañía</label>
                            <CCol sm={3}>
                                <div className="flex-grow-1">
                                    <select
                                        disabled={!isAdmin || isEdit}
                                        className="dark-input w-100"
                                        defaultValue={company.typeCommercialCompany}
                                        {...getFieldProps("typeCommercialCompany")}
                                        onChange={handleChange}
                                        onBlur={handleBlur}>
                                        {companyTypes.map(type => (
                                            <option key={type} value={type}>
                                                {type}
                                            </option>
                                        ))}
                                    </select>
                                    <div className="mt-2">
                                        {touched.typeCommercialCompany && errors.typeCommercialCompany && (
                                            <div className='d-flex align-items-center' style={{ height: "25px", fontSize: 12, color: "red" }}>
                                                <CIcon size="sm" icon={cilWarning} />
                                                <div className='small' style={{ marginLeft: '5px' }}>{errors.typeCommercialCompany}</div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </CCol>
                        </div>


                        <div style={{ borderBottom: "1px solid #323343", marginTop: 12, marginBottom: 12, }}></div>

                        <div className="d-flex justify-content-between align-items-center">
                            <label className="input-label flex-grow-1 mr-3">Descripción</label>
                            <CCol sm={3}>
                                <div className="flex-grow-1">
                                    <CFormTextarea
                                        disabled={!isAdmin || isEdit}
                                        className="dark-input w-100"
                                        defaultValue={company.description}
                                        {...getFieldProps("description")}
                                    />
                                    {touched.description && errors.description && (
                                        <div className='d-flex align-items-center mt-2' style={{ fontSize: 12, color: "red" }}>
                                            <CIcon size="sm" icon={cilWarning} />
                                            <div className='small' style={{ marginLeft: '5px' }}>{errors.description}</div>
                                        </div>
                                    )}
                                </div>
                            </CCol>
                        </div>

                        <div style={{ borderBottom: "1px solid #323343", marginTop: 12, marginBottom: 12, }}></div>

                        <div className="d-flex justify-content-between align-items-center">
                            <label className="input-label flex-grow-1 mr-3">Dirección</label>
                            <CCol sm={3}>
                                <div className="flex-grow-1">
                                    <CFormInput
                                        disabled={!isAdmin || isEdit}
                                        className="dark-input w-100"
                                        defaultValue={company.address}
                                        {...getFieldProps("address")}
                                    />
                                    {touched.address && errors.address && (
                                        <div className='d-flex align-items-center mt-2' style={{ fontSize: 12, color: "red" }}>
                                            <CIcon size="sm" icon={cilWarning} />
                                            <div className='small' style={{ marginLeft: '5px' }}>{errors.address}</div>
                                        </div>
                                    )}
                                </div>
                            </CCol>
                        </div>

                        <div style={{ borderBottom: "1px solid #323343", marginTop: 12, marginBottom: 12, }}></div>

                        <div className="d-flex justify-content-between align-items-center">
                            <label className="input-label flex-grow-1 mr-3">Ciudad</label>
                            <CCol sm={3}>
                                <div className="flex-grow-1">
                                    <CFormInput
                                        disabled={!isAdmin || isEdit}
                                        className="dark-input w-100"
                                        defaultValue={company.town}
                                        {...getFieldProps("town")}
                                    />
                                    {touched.town && errors.town && (
                                        <div className='d-flex align-items-center mt-2' style={{ fontSize: 12, color: "red" }}>
                                            <CIcon size="sm" icon={cilWarning} />
                                            <div className='small' style={{ marginLeft: '5px' }}>{errors.town}</div>
                                        </div>
                                    )}
                                </div>
                            </CCol>
                        </div>


                        <div style={{ borderBottom: "1px solid #323343", marginTop: 12, marginBottom: 12, }}></div>

                        <div className="d-flex justify-content-between align-items-center">
                            <label className="input-label flex-grow-1 mr-3">Código postal</label>
                            <CCol sm={3}>
                                <div className="flex-grow-1">
                                    <CFormInput
                                        disabled={!isAdmin || isEdit}
                                        className="dark-input w-100"
                                        defaultValue={company.zipCode}
                                        {...getFieldProps("zipCode")}
                                    />
                                    {touched.zipCode && errors.zipCode && (
                                        <div className='d-flex align-items-center mt-2' style={{ fontSize: 12, color: "red" }}>
                                            <CIcon size="sm" icon={cilWarning} />
                                            <div className='small' style={{ marginLeft: '5px' }}>{errors.zipCode}</div>
                                        </div>
                                    )}
                                </div>
                            </CCol>
                        </div>


                        <div style={{ borderBottom: "1px solid #323343", marginTop: 12, marginBottom: 12, }}></div>





                    </div>

                </form>


            </div>
        </div>

    )

}

export default CompanyAuxForm;

