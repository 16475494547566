import React, { useState, useRef, useEffect, useContext } from 'react';
import { gsap } from 'gsap';
import './style.scss';
import { PaymentContext } from '../contexts/PaymentContext';
import { CRow } from '@coreui/react-pro';

function CompanyTypeSelector() {
    const blocksRef = useRef([]);

    // Pasar paso
    const {
        stepsData,
        data,
        isResponsive,

        handleStepClick,
        setData,
    } = useContext(PaymentContext)





    // const [selectedType, setSelectedType] = useState(data && data.typeCompany ? data.typeCompany : '');
    const [selectedType, setSelectedType] = useState(undefined);

    useEffect(() => {

        if (data && data.company) {
            setSelectedType(data.company.typeCommercialCompany ?? "");
        } else {
            setSelectedType("");

        }
    }, [])

    const companyTypes = [
        { acronym: 'SLU', description: 'Sociedad Limitada Unipersonal. Sociedad de un solo socio.' },
        { acronym: 'SL', description: 'Sociedad Limitada. Igual que una SLU pero de 2 o más socios.' },
        { acronym: 'SA', description: 'Sociedad Anonima. Sociedad de participación de 2 socios o más.' }
    ];

    // const [isResponsive, setIsResponsive] = useState(window.innerWidth <= 768);
    // useEffect(() => {
    //     const handleResize = () => {
    //         setIsResponsive(window.innerWidth <= 768);
    //     };
    //     window.addEventListener('resize', handleResize);
    //     return () => {
    //         window.removeEventListener('resize', handleResize);
    //     };
    // }, []);



    useEffect(() => {
        gsap.set(blocksRef.current, { transformOrigin: "50% 50%" });
        if (data && data.company && data.company.typeCommercialCompany) {

            let index: any = companyTypes.findIndex((com: any) => com.acronym === data.company.typeCommercialCompany);
            gsap.to(blocksRef.current[index], { scale: 1.05, duration: 0 });
        }
    }, []);

    const handleMouseEnter = (index) => {
        gsap.to(blocksRef.current[index], { scale: 1.05, duration: 0.3 });
    };

    const handleMouseLeave = (index) => {
        if (selectedType !== companyTypes[index].acronym) {
            gsap.to(blocksRef.current[index], { scale: 1, duration: 0.3 });
        }
    };

    const handleClick = (type, index) => {
        setSelectedType(type);
        gsap.to(blocksRef.current, { scale: 1, duration: 0.3, overwrite: 'auto' });
        gsap.to(blocksRef.current[index], { scale: 1.05, duration: 0.3 });


        setData({ ...data, company: { ...data.company, typeCommercialCompany: type } })
        // Aquí puedes actualizar el objeto que guarda esta información, si es necesario
    };

    return (
        <div className="selector-container">
            {companyTypes.map((type, index) => (
                <div
                    key={index}
                    ref={el => blocksRef.current[index] = el}
                    className={`company-block ${selectedType === type.acronym ? 'selected' : ''}`}
                    onMouseEnter={() => handleMouseEnter(index)}
                    onMouseLeave={() => handleMouseLeave(index)}
                    onClick={() => handleClick(type.acronym, index)}
                >
                    <div className="company-acronym">{type.acronym}</div>
                    <div className="company-description">{type.description}</div>
                </div>
            ))}

            <div style={{ marginTop: 50 }}></div>

            <CRow className="justify-content-center">


                {isResponsive && (
                    <>
                        <button
                            className="continue-button"
                            onClick={() => {
                                handleStepClick(0)
                            }}>
                            Volver
                        </button>

                        <div style={{ marginBottom: 20 }}></div>
                    </>
                )}



                <button
                    className="continue-button"
                    disabled={!selectedType}
                    onClick={() => {
                        handleStepClick(2)
                    }}>
                    Continuar
                </button>

            </CRow>
        </div>
    );
}

export default CompanyTypeSelector;
