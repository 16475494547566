import React, { useEffect, useRef, useState } from 'react'
import { TypeDocument } from '../../../../core/models/db/typeDocument'
import { MyDocument } from '../../../../core/models/db/myDocument'
import { DocumentAccess } from '../../../../core/models/db/documentAccess'
import { cilCloudDownload } from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import { CRow } from '@coreui/react-pro'
import { Util } from '../../../../core/util/util'
import gsap from 'gsap';
import { COLORS } from '../../../../core/constants/colors'
import ModalCover from '../../../components/modalCover/ModalCover'

interface DocumentPageDocuments {
    documentAccesses: DocumentAccess[];
    onDelete: (doc) => void;
}

const DocumentPageDocument = (props: DocumentPageDocuments) => {
    const {
        documentAccesses,
        onDelete
    } = props;

    const [modal, setModal] = useState<boolean>(false);
    const [documentSelected, setDocumentSelected] = useState<MyDocument>(undefined);




    return (
        <>
            {documentAccesses && documentAccesses.length > 0 && documentAccesses.map((docAcc, idoc) => {
                return (

                    <CRow key={idoc} className="document-item">
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
                        >
                            <span
                                style={{ flex: 1, display: "flex", justifyContent: "space-between", alignItems: "center" }}
                                onClick={() => Util.downloadDocumentBackend(
                                    docAcc.documents.url,
                                    docAcc.documents.name)}
                            >
                                {docAcc.documents.name}
                                <CIcon icon={cilCloudDownload} />
                            </span>


                            {onDelete && (
                                <span style={{ fill: COLORS.secondaryColor, width: 40, height: "100%", display: "flex", justifyContent: "end" }}
                                    onClick={(e) => {
                                        if (!onDelete) return;
                                        e.preventDefault();
                                        e.stopPropagation();
                                        // handleAnimation(idoc);
                                        setDocumentSelected(docAcc.documents)
                                        setModal(true)
                                    }}>

                                    <svg className="icon-close" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM175 175c-9.4 9.4-9.4 24.6 0 33.9l47 47-47 47c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l47-47 47 47c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-47-47 47-47c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-47 47-47-47c-9.4-9.4-24.6-9.4-33.9 0z" /></svg>
                                </span>
                            )}
                        </div>

                    </CRow>








                )

            })}



            < ModalCover
                styleModal={{
                    backgroundColor: "whitesmoke",
                    color: "black",
                    fontSize: '18px'
                }
                }
                styleHeader={{
                    borderBottom: "1px solid #000",
                    borderColor: COLORS.secondaryColor,
                    padding: '1rem',
                    fontSize: '24px'
                }}
                title={"Borrar documento"}
                handleModal={setModal}
                show={modal}
                hasCloseButton={false}
            >
                <div style={{ padding: '1rem' }}>
                    <p style={{ marginBottom: '1.5rem' }}>
                        ¿Estás seguro de que quieres borrar este documento?
                    </p>
                    <div style={{
                        display: "flex",
                        justifyContent: "space-around",
                        marginTop: '2rem',
                        alignItems: "center"
                    }}>
                        <div style={{ textAlign: 'right', marginBottom: '1rem' }}>
                            <button onClick={() => {
                                onDelete(documentSelected)
                                setModal(false);
                            }}
                                style={{
                                    backgroundColor: '#f08a3b',
                                    color: 'white',
                                    border: 'none',
                                    padding: '0.6rem 1.5rem',
                                    borderRadius: '5px',
                                    fontSize: '18px',
                                    cursor: 'pointer',
                                    transition: 'background-color 0.3s'
                                }}
                                onMouseEnter={e => {
                                    e.currentTarget.style.backgroundColor = 'red';
                                    // e.currentTarget.style.color = 'white';

                                }}
                                onMouseLeave={e => e.currentTarget.style.backgroundColor = '#f08a3b'}>
                                SÍ
                            </button>
                        </div>

                        <div style={{ textAlign: 'right', marginBottom: '1rem' }}>
                            <button onClick={() => {
                                setModal(false);
                            }}
                                style={{
                                    backgroundColor: '#f08a3b',
                                    color: 'white',

                                    border: 'none',
                                    padding: '0.6rem 1.5rem',
                                    borderRadius: '5px',
                                    fontSize: '18px',
                                    cursor: 'pointer',
                                    transition: 'background-color 0.3s'
                                }}
                                onMouseEnter={e => e.currentTarget.style.backgroundColor = '#d8782b'}
                                onMouseLeave={e => e.currentTarget.style.backgroundColor = '#f08a3b'}>
                                NO
                            </button>
                        </div>
                    </div>
                </div>
            </ModalCover >
        </>
    )
}

export default DocumentPageDocument

