

import React, { useContext } from 'react'
import { PaymentContext } from '../contexts/PaymentContext'
import { CRow } from '@coreui/react-pro'
import StepPageOne from './stepPageOne'
import StepPageThree from './stepPageThree'
import StepPageTwo from './stepPageTwo'
import { map } from 'rxjs'

const StepPageIndex = () => {
    const {
        stepsData,

        handleStepClick,
        currentStep
    } = useContext(PaymentContext)

    const getStep = (index: number) => {
        let mapStep = {
            0: <StepPageOne />,
            1: <StepPageTwo />,
            2: <StepPageThree />,
        }
        return mapStep[index];
    }

    return (
        <div style={styles.container}>
            {getStep(currentStep())}
        </div>
    )
}

export default StepPageIndex


const styles = {
    container: {
        width: '80%',
        alignSelf: 'center',
    },
} as any;