import axios from "axios";
import { environment } from "../../../app/environments";
import { User } from "../../models/db/user";
import { DataBasicEncoder } from "../../util/data-basic-encoder";


export interface AccessLoginArgs {
    username: string;
    password: string;
}

export interface Identity {
    username: string;
    email: string;
    idRole: number;
    idUser: number;
    avatar?: string;
}

export class LoginService {


    constructor() { }

    /**
     * Acceso a la app
     */
    static login = ({ username, password }: AccessLoginArgs) => {
        const headers = {
            'Content-Type': 'application/json',
        }
        return axios.post(`${environment.urlApi}/login`, { username, password }, { headers });
    }

    /**
     * Registro a la app
     */
    // static register = ({ username, password, email }: User) => {
    //     const headers = {
    //         'Content-Type': 'application/json',
    //     }
    //     return axios.post(`${environment.urlApi}/register`, { username, password, email }, { headers });
    // }
    static register = (user: User) => {
        const headers = {
            'Content-Type': 'application/json',
        }
        return axios.post(`${environment.urlApi}/register`, user, { headers });
    }

    /**
     * Cerrar sessión y vuelta al login
     */
    // static logOut = () => {
    //     localStorage.clear();
    // }

    /**
     * Recibir token
     */
    static getTokenAsync = () => {
        return new Promise<any>((res, rej) => {
            let token = localStorage.getItem("token");
            if (!token) rej("Sin permiso t");
            res(token);
        });
    }

    /**
     * Recibir identity
     */
    // static getIdentityAsync = (): Promise<any> => {
    //     return new Promise<any>((res, rej) => {
    //         let identity = localStorage.getItem("identity");
    //         if (!identity) rej("Sin permiso i");
    //         res(JSON.parse(identity!));
    //     });
    // }


    static getIdentityAsync = (): Promise<Identity> => {
        return new Promise<any>((res, rej) => {
            // Este se queda tal como está
            // Este se queda tal como está
            // Este se queda tal como está
            let identity = localStorage.getItem("identity");
            console.log(identity)

            if (!identity) {
                rej("Sin permiso i");
                // return;
            }
            const decode = DataBasicEncoder.decode(identity);


            const referenceIdentity: Partial<Identity> = {
                username: '',
                email: '',
                idRole: 0,
                idUser: 0,
                // avatar: ""
            };

            if (!this._conformsToInterface(decode, referenceIdentity)) {
                rej("Objeto inexistente");
                return;
            }
            res(decode);
        });
    }

    static _conformsToInterface<T>(obj: any, reference: T): obj is T {
        for (const key in reference) {
            if (!obj.hasOwnProperty(key)) {
                return false;
            }
        }
        return true;
    }


}