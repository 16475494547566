import { LOCAL_STORAGE_NAME } from "../constants/local_storage";
import { Company } from "../models/db/company";
import { LoginService } from "../services/login/login-service";
import { DataBasicEncoder } from "./data-basic-encoder";

export const companyBackupLS = async (action: "save" | "get" | "remove", company?: Company) => {
    try {
        let result;
        let identity = await LoginService.getIdentityAsync();
        switch (action) {
            case "save": {
                const currentTime = new Date();
                const expiryTime = new Date(currentTime.getTime() + 10080 * 60 * 1000);
                let expire = expiryTime.toISOString();
                let result = DataBasicEncoder.encode({ company, expire });
                localStorage.setItem(`a_com_store_${company.idUserFk}`, result);
                return company;

            }
            case "get": {
                let ls = localStorage.getItem(`a_com_store_${identity.idUser}`);
                if (ls) {
                    let { company, expire }: any = DataBasicEncoder.decode(ls);

                    // console.log("COMPAÑIA DECODIFICADA", company, company.idUserFk);
                    // console.log("id usuario", identity.idUser);

                    if (company.idUserFk === identity.idUser && expire) {

                        // console.log("1 aqui")
                        const currentTime = new Date();
                        const expiryTime: any = new Date(expire);
                        if (!expiryTime || expiryTime === "Invalid Date" || currentTime > expiryTime) {
                            // console.log("2 aqui")

                            localStorage.removeItem(`a_com_store_${identity.idUser}`);
                            console.error("Los datos han expirado.");
                            return undefined;
                        } else {
                            // console.log("3 aqui")

                            return company;
                        }
                    } else if (company.idUserFk !== identity.idUser || !expire) {
                        // console.log("4 aqui")

                        console.error("La compañia es de otro usuario");
                        localStorage.removeItem(`a_com_store_${identity.idUser}`);
                        return undefined;
                    } else {
                        // console.log("5 aqui")

                        return undefined;
                    }
                } else {
                    // console.log("6 aqui")

                    return undefined;
                }
            }
            case "remove": {
                localStorage.removeItem(`a_com_store_${identity.idUser}`);
                break;
            }
        }

    } catch (e: any) {
        console.error(e);
        return undefined;
    }
}


export const _companyBackupLSAdmin = async (action: "save" | "get" | "remove", company?: Company) => {
    try {
      let result;
      let identity = await LoginService.getIdentityAsync();
      switch (action) {
        case "save": {
          const currentTime = new Date();
          const expiryTime = new Date(currentTime.getTime() + 10080 * 60 * 1000);
          let expire = expiryTime.toISOString();
          result = DataBasicEncoder.encode({ company, expire });
          localStorage.setItem(`${LOCAL_STORAGE_NAME.GET_COMPANY_ADMIN}${identity.idUser}`, result); // Modificando la clave para que sea específica para el admin
          return company;
        }
        case "get": {
          let ls = localStorage.getItem(`${LOCAL_STORAGE_NAME.GET_COMPANY_ADMIN}${identity.idUser}`);
          if (ls) {
            let { company, expire }: any = DataBasicEncoder.decode(ls);

            // Validamos solo el tiempo de expiración.
            const currentTime = new Date();
            const expiryTime: any = new Date(expire);
            if (!expiryTime || expiryTime === "Invalid Date" || currentTime > expiryTime) {
              localStorage.removeItem(`${LOCAL_STORAGE_NAME.GET_COMPANY_ADMIN}${identity.idUser}`);
              console.error("Los datos han expirado.");
              return undefined;
            } else {
              return company;
            }
          } else {
            return undefined;
          }
        }
        case "remove": {
          localStorage.removeItem(`${LOCAL_STORAGE_NAME.GET_COMPANY_ADMIN}${identity.idUser}`);
          break;
        }
      }
    } catch (e: any) {
      console.error(e);
      return undefined;
    }
  }