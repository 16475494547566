
import axios from "axios";
import { environment } from "../../../../app/environments";
import { ReasonForDeactivationProps } from "../../../hooks/check-table/useCheckTable";
import { StatusSubstep } from "../../../models/db/steps/statusSubstep";
import { Pagination } from "../../../models/pagination";


export class StatusSubstepService {

    constructor() { }
  
    static get(token: any, id: any) {
      let headers = { 'Authorization': `Bearer ${token}` };
      return axios.get(`${environment.urlApi}/api/statusSubsteps/${id}`, { headers });
    }
  
    static getAll(token: any, paginated?: Pagination) {
  
      let headers = { 'Authorization': `Bearer ${token}` };
      // let completePath = (paginated) ? this._paginate(paginated) : `${environment.urlApi}/api/appointments`;
      return axios.post(`${environment.urlApi}/api/statusSubsteps/pagination`, this._paginate2(paginated), { headers });
  }
  
    static getAllId(token, reasonForDeactivation: ReasonForDeactivationProps[] = []) {
      let object = { reasonForDeactivation };
  
      let headers = { 'Authorization': `Bearer ${token}` };
      return axios.post(`${environment.urlApi}/api/statusSubsteps/all-id`, object, { headers });
    }
  
    static getAutocomplete(token) {
  
      let headers = { 'Authorization': `Bearer ${token}` };
      return axios.get(`${environment.urlApi}/api/statusSubsteps/autocomplete`, { headers });
    }
  
  
    static _paginate2(pagination2: Pagination) {
  
      const { itemsPerPage, page, orderBy, order, wordList, relatedTableOrder, startDate, endDate } = pagination2;
  
      let obj: any = {};
      if (itemsPerPage !== null && itemsPerPage !== undefined) {
        obj.itemsPerPage = itemsPerPage;
      }
  
      if (page !== null && page !== undefined) {
        /**
             * En el backend el paginado empieza por 0, por eso hay que restar
             * un número
             */
        if (page == 0) obj.page = page;
        else if (page > 0) obj.page = page - 1;
      }
  
      if (orderBy !== null && orderBy !== undefined) {
        obj.orderBy = orderBy;
      }
  
      if (order !== null && order !== undefined) {
        obj.order = order;
      }
  
      if (relatedTableOrder !== null && relatedTableOrder !== undefined) {
        obj.relatedTableOrder = relatedTableOrder;
      }
  
      if (wordList !== null && wordList !== undefined) {
        // obj.wordList = wordList.split(",");
        obj.wordList = wordList;
        // url += (url.substring(url.length - 10) === "pagination") ? "?" : "&";
        // url += `wordList=${wordList}`;
      }
  
      if ((startDate !== null && startDate !== undefined)
        && (endDate !== null && endDate !== undefined)) {
        obj.startDate = startDate;
        obj.endDate = endDate;
      }
  
  
      // console.log("Obj completed", obj)
  
      // console.log(encodeURI(url));
      return obj;
    }
  
  
    static add(token, body: StatusSubstep) {
      let headers = {
        'Authorization': `Bearer ${token}`
      };
      return axios.post(`${environment.urlApi}/api/statusSubsteps/add`, body, { headers });
    }
  
  
    static update(token, body: StatusSubstep) {
      let headers = {
        // 'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': `Bearer ${token}`
      };
      return axios.post(`${environment.urlApi}/api/statusSubsteps/update`, body, { headers });
    }
  
    static delete(token, idList: string[] | number[], action: boolean) {
  
      let params = { list: idList, action };
      let headers = {
        // 'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': `Bearer ${token}`
      };
      return axios.post(`${environment.urlApi}/api/statusSubsteps/delete`, params, { headers });
    }
  
  
  
  
  
  }