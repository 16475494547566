import { ComponentMapping, StatusStep, Step } from "../types";
import Subcomponent1 from "./components/Subcomponent1";
import Subcomponent2 from "./components/Subcomponent2";
import Subcomponent3 from "./components/Subcomponent3";
import Subcomponent4 from "./components/Subcomponent4";
import Subcomponent5 from "./components/Subcomponent5";
import Subcomponent6 from "./components/Subcomponent6";
import Subcomponent8 from "./components/Subcomponent8";
import Subcomponent7 from "./components/Subcomponent7";
import Subcomponent9 from "./components/Subcomponent9";


/**
 * Son los ID de la tabla Step y cuales depende
 * cada paso para que no esté bloqueado (disabled)
 */
export const stepDependencies = {
  3: [2],
  5: [2, 4],
  6: [1, 2, 3, 4, 5],
  7: [6],
};


export const SUBCOMPONENTS_MAP = {
  'Subcomponent1': Subcomponent1,
  'Subcomponent2': Subcomponent2,
  'Subcomponent3': Subcomponent3,
  'Subcomponent4': Subcomponent4,
  'Subcomponent5': Subcomponent5,
  'Subcomponent6': Subcomponent6,
  'Subcomponent7': Subcomponent7,
  'Subcomponent8': Subcomponent8,
  'Subcomponent9': Subcomponent9,


}


/**
 * Funciona dependiendo del ID de los substep. Es crucial que los ID en la base de datos
 * sean los mismos que aqui.
 */
const substepToComponentMapping: ComponentMapping = {
  1: 'Subcomponent1',
  2: 'Subcomponent2',
  3: 'Subcomponent3',
  4: 'Subcomponent4',
  5: 'Subcomponent5',
  6: 'Subcomponent6',
  7: 'Subcomponent7',
  8: 'Subcomponent8',
  9: 'Subcomponent9',
  // ... Expandir cuando sea necesario
}

export function getComponentForSubstep(id: number): string {
  // return substepToComponentMapping[name] || 'DefaultComponent';
  return substepToComponentMapping[id] || 'DefaultComponent';
}

// export function isStepBlocked(step: Step, allSteps: Step[]): boolean {
//   if (!step.dependencias || step.dependencias.length === 0) {
//     return false;
//   }

//   return step.dependencias.some(dependenciaId => {
//     const dependencia = allSteps.find(s => s.id === dependenciaId);
//     return !dependencia || dependencia.status !== StatusStep.ACEPTADO;
//   });
// }


export function isStepBlocked(step: Step, allSteps: Step[]): boolean {
  // Si el paso actual tiene subpasos y no todos están en el estado "ACEPTADO"
  // 20/10/23 Esto sobraría
  // if (step.substeps.some(substep => substep.status !== 'ACEPTADO')) {
  //   return true;
  // }

  // Si el paso no tiene dependencias
  if (!step.dependencias || step.dependencias.length === 0) {
    return false;
  }

  // Si alguna de las dependencias no está en el estado "ACEPTADO"
  return step.dependencias.some(dependenciaIdReal => {
    // Buscamos el step por idReal y no por id
    const stepFindedByDepedencia = allSteps.find(s => s.idReal === dependenciaIdReal);
    return !stepFindedByDepedencia || stepFindedByDepedencia.substeps.some(substep => substep.status !== 'ACEPTADO');
  });
}


