import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { Pagination } from '../../../models/pagination';
import { ReasonForDeactivationProps } from '../../check-table/useCheckTable';
import { Product } from '../../../models/db/product';
import { ProductService } from '../../../services/db/product/product.service';





export const useProduct = () => {



    const getMainProduct = (token: any) => {
        return new Promise<any>((res, rej) => {
            ProductService.getMainProduct(token)
                .then(({ data }) => {
                    console.log(data)
                    if (data.ok) {
                        res(data);
                    } else {
                        res(undefined)
                    }
                })
                .catch((e) => {
                    console.log(e)
                    rej(e)
                })
        })
    }



    const checkBundleResidencialProduct = (token: any, idUser) => {
        return new Promise<any>((res, rej) => {
            ProductService.checkBundleResidencialProduct(token, idUser)
                .then(({ data }) => {
                    console.log(data)
                    if (data?.ok) {
                        res(data);
                    } else {
                        res(undefined)
                    }
                })
                .catch((e) => {
                    console.log(e)
                    rej(e)
                })
        })
    }

    const checkMainProduct = (token: any, idUser, idCompany) => {
        return new Promise<any>((res, rej) => {
            ProductService.checkMainProduct(token, idUser, idCompany)
                .then(({ data }) => {
                    console.log(data)
                    if (data.ok) {
                        res(data);
                    } else {
                        res(undefined)
                    }
                })
                .catch((e) => {
                    console.log(e)
                    rej(e)
                })
        })
    }


    const checkFondoEmpresaProduct = (token: any, idUser, idCompany) => {
        return new Promise<any>((res, rej) => {
            ProductService.checkFondoEmpresaProduct(token, idUser, idCompany)
                .then(({ data }) => {
                    console.log(data)
                    if (data.ok) {
                        res(data);
                    } else {
                        res(undefined)
                    }
                })
                .catch((e) => {
                    console.log(e)
                    rej(e)
                })
        })
    }

    const checkPagoOficinaProduct = (token: any, idUser, idCompany) => {
        return new Promise<any>((res, rej) => {
            ProductService.checkPagoOficinaProduct(token, idUser, idCompany)
                .then(({ data }) => {
                    console.log(data)
                    if (data.ok) {
                        res(data);
                    } else {
                        res(undefined)
                    }
                })
                .catch((e) => {
                    console.log(e)
                    rej(e)
                })
        })
    }



    const getAllIdProduct = (token: any, reasonForDeactivation: ReasonForDeactivationProps[]) => {
        return new Promise<any>((res, rej) => {
            ProductService.getAllId(token, reasonForDeactivation)
                .then(({ data }) => {
                    console.log(data)
                    if (data.ok) {
                        res(data);
                    } else {
                        res(undefined)
                    }
                })
                .catch((e) => {
                    console.log(e)
                    rej(e)
                })

        })
    }

    const getProductById = (token, id) => {
        return new Promise<any>((res, rej) => {
            ProductService.get(token, id)
                .then(({ data }) => {
                    console.log(data)
                    if (data.ok) {
                        res(data);
                    } else {
                        res(undefined)
                    }
                })
                .catch((e) => {
                    console.log(e)
                    rej(e)
                })

        })
    }


    const getProduct = (token, pagination: Pagination = { page: 1, itemsPerPage: 5 }) => {
        return new Promise<any>((res, rej) => {
            ProductService.getAll(token, pagination)
                .then(({ data }) => {
                    console.log(data)
                    if (data.ok) {
                        res(data);
                    } else {
                        res(undefined)
                    }
                })
                .catch((e) => {
                    console.log(e)
                    rej(e)
                })

        })
    }

    // const getProductAutocomplete = (token, pagination: Pagination = { page: 1, itemsPerPage: 5 }) => {
    //     return new Promise<any>((res, rej) => {
    //         ProductService.getAutocomplete(token)
    //             .then(({ data }) => {
    //                 // console.log(data)
    //                 if (data.ok) {
    //                     res(data);
    //                 } else {
    //                     res(undefined)
    //                 }
    //             })
    //             .catch((e) => {
    //                 console.log(e)
    //                 rej(e)
    //             })

    //     })
    // }


    const addProduct = (token, user: Product) => {
        return new Promise<any>((res, rej) => {
            ProductService.add(token, { ...user })
                .then(({ data }) => {
                    // console.log(data)
                    if (data.ok) {
                        res(data);
                    } else {
                        res(undefined)
                    }
                })
                .catch((e) => {
                    console.log(e)
                    rej(e)
                })

        })
    }


    const updateProduct = (token, user: Product) => {
        return new Promise<any>((res, rej) => {
            ProductService.update(token, { ...user })
                .then(({ data }) => {
                    console.log(data)
                    if (data.ok) {
                        res(data);
                    } else {
                        res(undefined)
                    }
                })
                .catch((e) => {
                    console.log(e)
                    rej(e)
                })

        })
    }

    // const deleteProduct= (token, idList: string[] | number[], action: boolean) => {
    //     return new Promise<any>((res, rej) => {
    //         ProductService.delete(token, idList, action)
    //             .then(({ data }) => {
    //                 console.log(data)
    //                 if (data.ok) {
    //                     res(data);
    //                 } else {
    //                     res(undefined)
    //                 }
    //             })
    //             .catch((e) => {
    //                 console.log(e)
    //                 rej(e)
    //             })

    //     })
    // }


    return {
        getMainProduct,
        checkMainProduct,
        checkBundleResidencialProduct,
        checkFondoEmpresaProduct,
        checkPagoOficinaProduct,

        getAllIdProduct,
        getProductById,
        getProduct,
        addProduct,
        updateProduct,
        // deleteProduct,

        get: getProduct,
        add: addProduct,
        update: updateProduct,
        // autocomplete: getProductAutocomplete,
        // delete: deleteProduct
    }
}