import { PaymentMethod } from '@stripe/stripe-js';
import { Purchase } from '../../models/db/purchase';
import { PaymentStripe } from '../../models/stripe/paymentStripe';
import { PaymentService } from '../../services/stripe/payment.service';





export const usePayment = () => {


    const addPaymentFree = (token, item: Purchase) => {
        return new Promise<any>((res, rej) => {
            PaymentService.addPaymentFree(token, item)
                .then(({ data }) => {
                    console.log(data)
                    if (data.ok) {
                        res(data);
                    } else {
                        res(undefined)
                    }
                })
                .catch((e) => {
                    console.log(e)
                    rej(e)
                })

        })
    }

    const addPaymentByStripe = (token, paymentMethod: PaymentMethod, idProduct, idUser) => {
        return new Promise<any>((res, rej) => {
            // Objeto para crear el payment
            // let stripeData = {
            //     // type: "card",
            //     // card: {
            //     //     number: paymentStripe.cardNumber,
            //     //     exp_month: parseInt(paymentStripe.month),
            //     //     exp_year: parseInt(paymentStripe.year),
            //     //     cvc: paymentStripe.cvc
            //     // },
            //     // billing_details: {
            //     //     address: {
            //     //         city: paymentStripe.city,
            //     //         postal_code: paymentStripe.zipCode,
            //     //         state: paymentStripe.province
            //     //     },
            //     //     email: paymentStripe.email,
            //     //     name: paymentStripe.fullName,
            //     //     phone: paymentStripe.phoneNumber
            //     // },
            //     idUser: paymentStripe.idUser,
            //     idProduct: paymentStripe.idProduct
            // }

            let stripeData = {
                paymentMethod,
                idUser,
                idProduct
            }

            PaymentService.addPaymentStripeComplete(token, stripeData)
                .then(({ data }) => {
                    console.log(data)
                    if (data.ok) {
                        res(data);
                    } else {
                        res(undefined)
                    }
                })
                .catch((e) => {
                    console.log(e)
                    rej(e)
                })
        });

    }

    const addPaymentMultipleByStripe = (token, paymentMethod: PaymentMethod, idProductList, idUser) => {
        return new Promise<any>((res, rej) => {
           
            let stripeData = {
                paymentMethod,
                idUser,
                idProductList
            }

            PaymentService.addPaymentMultipleStripeComplete(token, stripeData)
                .then(({ data }) => {
                    console.log(data)
                    if (data.ok) {
                        res(data);
                    } else {
                        res(undefined)
                    }
                })
                .catch((e) => {
                    console.log(e)
                    rej(e)
                })
        });

    }

    

    const unsubscribe = (token, idUser) => {
        return new Promise<any>((res, rej) => {
            PaymentService.cancelSubscription(token, idUser)
                .then(({ data }) => {
                    console.log(data)
                    if (data.ok) {
                        res(data);
                    } else {
                        res(undefined)
                    }
                })
                .catch((e) => {
                    console.log(e)
                    rej(e)
                })
        });
    }

    return {
        addPaymentByStripe,
        addPaymentMultipleByStripe,
        unsubscribe
    }
}