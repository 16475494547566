import React, { useContext, useEffect, useRef, useState } from 'react'
import { CAlert, CCard, CCardBody, CCardFooter, CCol, CContainer, CFormCheck, CFormInput, CFormLabel, CInputGroup, CInputGroupText, CLoadingButton, CProgress, CRow, CSmartTable } from '@coreui/react-pro'
import { useUser } from '../../../core/hooks/db/user/useUser';
import { LoginService } from '../../../core/services/login/login-service';
import { LoaderContext } from '../../components/loader/context/LoaderContext';
import { cilArrowLeft, cilCheckCircle, cilEnvelopeClosed, cilInfo, cilLockLocked, cilUser, cilWarning } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import { Link } from 'react-router-dom';
import { User } from '../../../core/models/db/user';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Autocomplete from '../../components/autocomplete/Autocomplete';
import CheckPassword from '../../components/checkPassword/CheckPassword';
import { debounce } from 'lodash';


import { Subject, from, fromEvent, of } from "rxjs";
import { catchError, debounceTime, delay, distinctUntilChanged, first, pluck, switchMap, take } from "rxjs/operators";
import DragDrop from '../../components/drag-drop/DragDrop';
import { FileItemObservableService } from '../../components/drag-drop/services/rxJS/fileitem-observable.service';
import ButtonLoadingSucessForm from '../../components/buttonLoadingSucessForm/ButtonLoadingSucessForm';
import validationUserSchema from './uf_validator_yup';
import { COLORS } from '../../../core/constants/colors';



interface UserFormProps {
    user: User;
    typeAction: "add" | "update";
    loadingButton?: boolean;

    isModal?: boolean

    add?: (user: User) => Promise<any>;
    update?: (user: User) => Promise<any>;
    checkPassword?: (id: any, password: any) => Promise<any>
    checkUsername?: (id: any, username: string) => Promise<any>

}



const UserForm = (props: UserFormProps) => {

    /**
     * Este fragmento es necesario porque el navegador rellena de manera
     * automática los inputs username y password. Después de intentarlo mediante
     * otros métodos se ha optado por el siguiente
     */
    const autocompleteOff = () => setTimeout(() => setAutoOff(false), 100)
    const [autoOff, setAutoOff] = useState(null);

    const [identity, setIdentity] = useState(undefined);

    useEffect(() => {
        (async () => {
            autocompleteOff()
            let id = await LoginService.getIdentityAsync();
            setIdentity(id);
        })();

    }, [])


    // Comienzo de la página
    // Comienzo de la página
    // Comienzo de la página

    const {
        user,
        typeAction,
        loadingButton,

        isModal = false,

        add,
        update,
        checkPassword,
        checkUsername,
    } = props;


    const [showPassword, setShowPassword] = useState(false);
    const [imageUser, setImageUser] = useState<string>(user && user.image ? user.image : "");
    const [disabled, setDisabled] = useState(true);

    const { values, errors, touched, isValid,

        handleChange,
        handleSubmit,
        handleBlur,
        getFieldProps,
        setFieldValue,
        setFieldTouched,
        setValues
    } = useFormik({
        initialValues: {
            ...user,
            blocked: Boolean(user.blocked)
        },
        validateOnBlur: false,
        onSubmit: (value) => {
            // console.log(value)
            if (typeAction === "add") {
                add(value);
            } else if (typeAction === "update") {
                update(value);
            }
        },
        validationSchema: Yup.lazy(values => validationUserSchema(values, typeAction, touched, checkUsername, identity && identity.idRole == 1))
    })


    // Cojo la referencia del observable para saber cuando ha habido un cambio
    const controlFile$ = React.useRef(FileItemObservableService.instance.control$);

    /** Cuando existe un cambio en el observable actializo el listado de items */
    useEffect(() => {
        const subscription = FileItemObservableService.instance
            .control$.subscribe(
                item => {
                    if (item && item.fileItemList && item.fileItemList.length > 0) {
                        values.image = item.fileItemList[0].pathRelative;
                        setFieldValue('image', item.fileItemList[0].pathRelative);
                    } else {
                        setFieldValue('image', "");
                    }
                }
            )
        return () => {
            subscription.unsubscribe();
        }

    }, [controlFile$]);

    /**
     * Si hay que mantener la pagina tras haber creado el registro, 
     * descomenta este trozo de código
     * @param value 
     */
    // useEffect(() => {
    //     console.log("Activating user update");
    //     setValues({
    //         ...user,
    //         blocked: Boolean(user.blocked)
    //     })
    // }, [user])


    const changeToBackup = (value: boolean) => {
        if (value) {
            setValues(
                {
                    ...user,
                    blocked: Boolean(user.blocked)
                }
            );


            if (value) {
                let backImage = user.image;
                setImageUser(null)
                setTimeout(() => {
                    setImageUser(backImage)
                }, 400)
            }
        }
    }

    const isEdit = (value: boolean) => {
        setDisabled(value);
    }

    return (

        <CRow>
            {/* <h4>Original</h4>
            <pre>
                {JSON.stringify(user, null, 2)}
            </pre>
            <h4> Modificado</h4>
            <pre>
                {JSON.stringify(values, null, 2)}
            </pre> */}
            {/* <pre>
                {JSON.stringify(errors, null, 2)}
            </pre> */}
            <form onSubmit={handleSubmit}>
                <CRow>
                    <CCol sm={12}>

                        {identity && (
                            <DragDrop
                                styleDD={{ maxWidth: "auto", height: 100 }}

                                id={"test-id"}
                                valueDefault={imageUser}
                                isOnlyOneFile={true}
                                disabled={(disabled && typeAction !== "add") || loadingButton}
                                directoryNameList={["user", identity.idUser, "avatar"]}
                                maxSizeMB={19}
                                typeAllowed={[
                                    "image/*",
                                    // "image/gif",
                                    // "image/jpeg",
                                    // "image/png",
                                    // "image/jpg"
                                ]} />
                        )}


                    </CCol>

                    {/* <CCol sm={6}> */}
                    <CCol sm={12}>

                        <div className="input-container">
                            <label className="input-label" style={{ color: COLORS.principalColor }}>Nombre de usuario</label>

                            {/* <CInputGroupText>
                                <CIcon icon={cilUser} />
                            </CInputGroupText> */}
                            <CFormInput
                                disabled={(disabled && typeAction !== "add") || loadingButton}
                                placeholder='Nombre de usuario'
                                {...getFieldProps("username")}
                                onChange={(event) => {
                                    getFieldProps("username").onChange(event);
                                    setFieldTouched("username", true);
                                }}
                                readOnly={autoOff}
                                autoComplete="new-username"
                            />
                        </div>

                        <div style={{ height: "auto" }}>
                            {touched.username && errors.username && (
                                <div className='d-flex align-items-center mt-2' style={{ fontSize: 12, color: "red" }}>
                                    <CIcon size="sm" icon={cilWarning} />
                                    <div className='small' style={{ marginLeft: '5px' }}>{errors.username}</div>
                                </div>
                            )}

                            {!errors.username && (values.username !== user.username) && (
                                <div className='d-flex align-items-center mt-2' style={{ fontSize: 12, color: "green" }}>
                                    {/* <CIcon size="sm" icon={cilWarning} /> */}
                                    <div className='small' style={{ marginLeft: '5px' }}>El nombre de usuario está disponible</div>
                                </div>
                            )}
                        </div>

                        <div style={{ marginBottom: 20 }}></div>


                        <div className="input-container">
                            <label className="input-label" style={{ color: COLORS.principalColor }}>Email</label>
                            <CFormInput
                                disabled={(disabled && typeAction !== "add") || loadingButton}
                                type="text"
                                {...getFieldProps("email")}
                            />
                        </div>

                        <div style={{ height: "auto" }}>
                            {touched.email && errors.email && (
                                <div className='d-flex align-items-center mt-2' style={{ fontSize: 12, color: "red" }}>
                                    <CIcon size="sm" icon={cilWarning} />
                                    <div className='small' style={{ marginLeft: '5px' }}>{errors.email}</div>
                                </div>
                            )}
                        </div>


                        <h3 style={{ fontFamily: "Raleway", marginTop: 30 }}>Contraseña</h3>
                        <div className="input-container">

                            {typeAction === "update" && (

                                <CheckPassword
                                    differentColor
                                    // clean={disabled}
                                    placeholder='Introduce la contraseña'
                                    handlePassword={async function (text: string) {
                                        let result = await checkPassword(user.id, text);
                                        setShowPassword(result);
                                    }}
                                    disabled={disabled || loadingButton}
                                    blockWriting={showPassword}
                                />
                            )}


                            <CInputGroup className="mb-3">
                                <CInputGroupText style={{ backgroundColor: COLORS.principalColor }}>
                                    <CIcon icon={cilLockLocked} style={{ color: "white" }} />
                                </CInputGroupText>
                                <CFormInput
                                    placeholder='Contraseña'
                                    type="password" {...getFieldProps("password")}
                                    readOnly={autoOff}
                                    disabled={(!showPassword && typeAction === "update") || (disabled && typeAction !== "add") || loadingButton}
                                    autoComplete="new-password" />
                                <CFormInput
                                    placeholder='Repetir contraseña'
                                    type="password" {...getFieldProps("priv_confirmPassword")}
                                    readOnly={autoOff}
                                    disabled={(!showPassword && typeAction === "update") || (disabled && typeAction !== "add") || loadingButton}
                                />
                            </CInputGroup>

                            <div style={{ height: "auto" }}>
                                {/* Este fragmento es solo para cuando vayas a editar */}
                                {showPassword && (
                                    // <CAlert color="primary" className="d-flex align-items-center" style={{ height: identity && identity.idRole == 1 ? "25px" : "50px" }}>
                                    //     <CIcon icon={cilInfo} className="flex-shrink-0 me-2" width={18} height={18} />
                                    //     <div className='small'>Deja los campos en blanco para no cambiar la contraseña</div>
                                    // </CAlert>

                                    <div className='d-flex align-items-center mt-2' style={{ fontSize: 12, color: "#1d6ea3" }}>
                                        <CIcon icon={cilInfo} size="sm" />
                                        <div className='small' style={{ marginLeft: '5px', textDecoration: "underline" }}>Deja los campos en blanco para NO cambiar la contraseña</div>
                                    </div>
                                )}

                                {touched.password && errors.password && (
                                    <div className='d-flex align-items-center mt-2' style={{ fontSize: 12, color: "red" }}>
                                        <CIcon size="sm" icon={cilWarning} />
                                        <div className='small' style={{ marginLeft: '5px' }}>{errors.password}</div>
                                    </div>
                                )}
                                {touched.priv_confirmPassword && errors.priv_confirmPassword && (
                                    // <CAlert color="danger" className='d-flex align-items-center' style={{ height: "25px" }}>
                                    //     <div className='small'>{errors.priv_confirmPassword}</div>
                                    // </CAlert>
                                    <div className='d-flex align-items-center mt-2' style={{ fontSize: 12, color: "red" }}>
                                        <CIcon size="sm" icon={cilWarning} />
                                        <div className='small' style={{ marginLeft: '5px' }}>{errors.priv_confirmPassword}</div>
                                    </div>
                                )}
                            </div>

                        </div>


                        {identity && identity.idRole == 1 && !isModal && (

                            <CCol>
                                {/* <CFormCheck className="mb-4" {...getFieldProps("blocked")} /> */}
                                <CFormCheck
                                    disabled={(disabled && typeAction !== "add") || loadingButton}
                                    className="mb-4"
                                    {...getFieldProps("blocked")}
                                    // defaultChecked={values.blocked || false}
                                    checked={values.blocked || false}
                                    label="Bloquear usuario"
                                />

                                {touched.blocked && errors.blocked && <CAlert color="danger">{errors.blocked}</CAlert>}
                            </CCol>
                        )}


                        {identity && identity.idRole == 1 && !isModal && (
                            <>
                                <CCol>
                                    <CFormLabel htmlFor="basic-url">Elige un rol para el usuario</CFormLabel>
                                    <CInputGroup>
                                        <Autocomplete
                                            id="basic-url"
                                            table='roles'
                                            disabled={(disabled && typeAction !== "add") || loadingButton}
                                            name={'idRoleFk'}
                                            defaultValue={{ key: "id", value: user.idRoleFk }}
                                            onChange={handleChange}
                                            onBlur={handleBlur} />
                                    </CInputGroup>
                                </CCol>
                                <div style={{ height: "auto", marginTop: 15 }}>
                                    {touched.idRoleFk && errors.idRoleFk && (
                                        <CAlert color="danger" className='d-flex align-items-center' style={{ height: "25px" }}>
                                            <div className='small'>{errors.idRoleFk}</div>
                                        </CAlert>
                                    )}
                                </div>
                            </>
                        )}



                        <CContainer style={{ marginTop: 50 }}>
                            <ButtonLoadingSucessForm
                                loadingButton={loadingButton}
                                className="d-flex d-row"
                                changeToBackup={changeToBackup}
                                onSubmit={handleSubmit}
                                setDisabled={isEdit}
                                typeAction={typeAction}
                                disabled={!isValid}
                            />
                        </CContainer>


                    </CCol>
                </CRow>
            </form >
        </CRow >


    )
}

export default UserForm



