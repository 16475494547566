import axios from "axios";
import { environment } from "../../../../app/environments";
import { User } from "../../../models/db/user";
import { Pagination } from "../../../models/pagination";
import { ReasonForDeactivationProps } from "../../../hooks/check-table/useCheckTable";



export class UserService {

  constructor() { }

  static get(token: any, id: any) {
    let headers = { 'Authorization': `Bearer ${token}` };
    return axios.get(`${environment.urlApi}/api/users/${id}`, { headers });
  }

  static getAll(token: any, paginated?: Pagination) {

    let headers = { 'Authorization': `Bearer ${token}` };
    let completePath = (paginated) ? this._paginate(paginated) : `${environment.urlApi}/api/users`;
    return axios.get(completePath, { headers });
  }

  static getAllId(token, reasonForDeactivation: ReasonForDeactivationProps[] = []) {
    let object = { reasonForDeactivation };

    let headers = { 'Authorization': `Bearer ${token}` };
    return axios.post(`${environment.urlApi}/api/users/all-id`, object, { headers });
  }


  static _paginate(pagination2: Pagination) {

    const { itemsPerPage, page, orderBy, order, wordList, relatedTableOrder } = pagination2;

    let url = `${environment.urlApi}/api/users/pagination`;
    if (itemsPerPage !== null && itemsPerPage !== undefined) {
      url += (url.substring(url.length - 10) === "pagination") ? "?" : "&";
      url += `itemsPerPage=${itemsPerPage}`;
    }

    if (page !== null && page !== undefined) {
      url += (url.substring(url.length - 10) === "pagination") ? "?" : "&";
      /**
       * En el backend el paginado empieza por 0, por eso hay que restar
       * un número
       */
      if (page == 0) url += `page=${page}`;
      else if (page > 0) url += `page=${page - 1}`;

    }

    if (orderBy !== null && orderBy !== undefined) {
      url += (url.substring(url.length - 10) === "pagination") ? "?" : "&";
      url += `orderBy=${orderBy}`;
    }

    if (order !== null && order !== undefined) {
      url += (url.substring(url.length - 10) === "pagination") ? "?" : "&";
      url += `order=${order}`;
    }

    if (relatedTableOrder !== null && relatedTableOrder !== undefined) {
      url += (url.substring(url.length - 10) === "pagination") ? "?" : "&";
      url += `relatedTableOrder=${relatedTableOrder}`;
    }

    if (wordList !== null && wordList !== undefined) {
      url += (url.substring(url.length - 10) === "pagination") ? "?" : "&";
      url += `wordList=${wordList}`;
    }

    // console.log(encodeURI(url));
    return encodeURI(url);
  }


  static add(token, body: User) {
    // let json = JSON.stringify(body);
    // let params = `json=${json}`;
    let headers = {
      // 'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    };
    return axios.post(`${environment.urlApi}/api/users/add`, body, { headers });
  }

  /**
   * Añade un usuario sin necesidad del token
   * @param body 
   * @returns 
   */
  // addPublic(body: User) {
  //   let json = JSON.stringify(body);
  //   let params = `json=${json}`;
  //   let headers = {
  //     'Content-Type': 'application/x-www-form-urlencoded',
  //   });
  //   return axios.post(`${environment.urlApi}/public/users`, params, { headers });
  // }

  static update(token, body: User) {



    let headers = {
      // 'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': `Bearer ${token}`
    };
    return axios.post(`${environment.urlApi}/api/users/update`, body, { headers });
  }

  static delete(token, idList: string[] | number[], action: boolean) {

    let params = { list: idList, action };
    let headers = {
      // 'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': `Bearer ${token}`
    };
    return axios.post(`${environment.urlApi}/api/users/delete`, params, { headers });
  }

  static checkAdmin(token) {
    let headers = {
      'Authorization': `Bearer ${token}`
    };
    return axios.get(`${environment.urlApi}/api/users-admin`, { headers });
  }

  static checkPassword(token, id, password) {

    let params = { id, password };
    let headers = {
      'Authorization': `Bearer ${token}`
    };
    return axios.post(`${environment.urlApi}/api/users/check-password`, params, { headers });
  }

  static checkUsername(token, id, username) {

    let params = { id, username };
    let headers = {
      'Authorization': `Bearer ${token}`
    };
    return axios.post(`${environment.urlApi}/api/users/check-username`, params, { headers });
  }

  static checkEmail(token, id, email) {

    let params = { id, username: email };
    let headers = {
      'Authorization': `Bearer ${token}`
    };
    return axios.post(`${environment.urlApi}/api/users/check-email`, params, { headers });
  }

  static checkPasswordPublic(id, password) {

    let params = { id, password };
    let headers = {};

    return axios.post(`${environment.urlApi}/api/users/public-check-password`, params, { headers });
  }

  static checkUsernamePublic(id, username) {

    let params = { id, username };
    let headers = {};
    return axios.post(`${environment.urlApi}/api/users/public-check-username`, params, { headers });
  }

  static checkEmailPublic(id, email) {

    let params = { id, email };
    let headers = {};
    return axios.post(`${environment.urlApi}/api/users/public-check-email`, params, { headers });
  }


}
