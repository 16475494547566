import React from "react"

import { ROUTE_NAME } from "../../core/constants/route_name"
import { Auth, Route } from "../../core/interfaces/routes/interfaces"
import UserFormDetail from "./user/UserFormDetail"
import Users from "./user/Users"
import HomePage from "./home/HomePage"
import PaymentPage from "./@external-pages/registationAndPayment/stripe/PaymentPage/PaymentPage"
import PaymentPageModal from "./@external-pages/registationAndPayment/stripe/PaymentPageModal/PaymentPageModal"
import BillingPage from "./billing/Billing"
import CompanyRegistrationPage from "./company/companyRegistrationPage/CompanyRegistrationPage"
import CompanyForm from "./company/companyForm/CompanyForm"
import DocumentPage from "./document/documentPage/DocumentPage"
import DocumentFormDetail from "./document/documentFormDetail/DocumenFormtDetail"


// import Conversation from "./conversation/Conversation"
// const Conversation = React.lazy(() => import('./conversation/ConversationPage'));


const PagesRoute: Route[] = [

    { path: ROUTE_NAME.home, to: ROUTE_NAME.home, name: "Home", Component: HomePage, role: [Auth.ROLE_ADMIN, Auth.ROLE_USER] },
    { path: "/pago/:data", to: "/pago/:data", name: "Pago", Component: PaymentPage, role: [Auth.ROLE_ADMIN, Auth.ROLE_USER] },

    { path: ROUTE_NAME.billing, to: ROUTE_NAME.billing, name: "Facturación", Component: BillingPage, role: [Auth.ROLE_USER] },

    { path: ROUTE_NAME.compania, to: ROUTE_NAME.compania, name: "Compañia crear", Component: CompanyRegistrationPage, role: [Auth.ROLE_USER] },
    /**
     * La compañía tiene un id que debe de ser cambiado de manera dinamica mediante código por que 
     * es una pantalla principal que a su vez está ligada a un select en el menú, a diferencia de las otras
     * pantallas de "/actualizar" que se acceden a ella cuando estás ya dentro de una página y el control de darle
     * un id es más simple
     */
    { path: ROUTE_NAME.companiaDetail, to: ROUTE_NAME.companiaDetail, name: "Compañia Detalle", Component: CompanyForm, role: [Auth.ROLE_ADMIN, Auth.ROLE_USER] },

    { path: ROUTE_NAME.documentAccesses, to: ROUTE_NAME.documentAccesses, name: "Documentos", Component: DocumentPage, role: [Auth.ROLE_ADMIN, Auth.ROLE_USER] },
    { path: ROUTE_NAME.documentAccessesCrear, to: ROUTE_NAME.documentAccessesCrear, name: "Compañia Crear", Component: DocumentFormDetail, role: [Auth.ROLE_ADMIN] },
    // {
    //     path: ROUTE_NAME.documentAccessesActualizar + "/:id",
    //     to: ROUTE_NAME.documentAccessesActualizar + "/:id",
    //     name: "Compañia Crear",
    //     Component: DocumentFormDetail,
    //     role: [Auth.ROLE_ADMIN]
    // },



    // { path: ROUTE_NAME.usuarios, to: ROUTE_NAME.usuarios, name: "Usuarios del sistema", Component: Users, role: [Auth.ROLE_ADMIN] },
    // { path: ROUTE_NAME.usuariosCrear, to: ROUTE_NAME.usuariosCrear, name: "Crear Usuario del sistema", Component: UserFormDetail, role: [Auth.ROLE_ADMIN] },
    // {
    //     path: ROUTE_NAME.usuariosActualizar + "/:idUser",
    //     to: ROUTE_NAME.usuariosActualizar + "/:idUser",
    //     name: "Actualizar usuario del sistema",
    //     Component: UserFormDetail,
    //     role: [Auth.ROLE_ADMIN],
    // },

]


export default PagesRoute