import axios from "axios";
import { environment } from "../../../../app/environments";
import { DocumentStep } from "../../../models/db/steps/documentStep";


export class DocumentStepService {

    constructor() { }

    static getByUserAndSubstep(token, body: DocumentStep) {
        let headers = {
            'Authorization': `Bearer ${token}`
        };
        return axios.post(`${environment.urlApi}/api/documents/steps/by-user-substep`, body, { headers });
    }


   




}
