import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useCompany } from "../../../../../core/hooks/db/company/useCompany";
import { useCompanyRelation } from "../../../../../core/hooks/db/companyRelation/useCompanyRelation";
import { useUser } from "../../../../../core/hooks/db/user/useUser";
import { useUserSubstep } from "../../../../../core/hooks/db/userSubstep/useUserSubstep";
import { LoginService } from "../../../../../core/services/login/login-service";
import { RootState } from "../../../../../core/store/store";
import { updateStep } from "../../actions/dashboardStep-action";
import { StatusStep, StatusSubstep } from "../../types";
import { SubcomponentBasicProp } from "./Subcomponent1";
import { useNotaryAppointment } from "../../../../../core/hooks/db/notaryAppointment/useNotaryAppointment";
import { NotaryAppointment } from "../../../../../core/models/db/notaryAppointment";
import { CCol, CFormTextarea, CLoadingButton, CRow } from "@coreui/react-pro";
import moment from "moment";
import { filter } from "rxjs";
import GKDateRangePicker from "../../../@filters/dateRangePicker/DateRangePicker";
import { START_DATE_DEFAULT, END_DATE_DEFAULT } from "../../../@filters/dateRangePicker/custon-ranges";
import DragDrop from "../../../drag-drop/DragDrop";
import { environment } from "../../../../environments";
import { Util } from "../../../../../core/util/util";
import { COLORS } from "../../../../../core/constants/colors";
import { cilWarning } from "@coreui/icons";
import CIcon from "@coreui/icons-react";

/**
 * Subpaso 1 del paso 6
 */
const Subcomponent7 = (props: SubcomponentBasicProp) => {
    const { showModal, handleModal, substepDashboard } = props;

    // REDUX: Paso y subpaso actual
    const dispatch = useDispatch();
    const { steps, company, idCompany, idUser, currentStepId } = useSelector((state: RootState) => state.dashboardStep);

    const currentStep = steps.find(s => s.id === currentStepId);
    const currentSubstep = substepDashboard;


    const { getCompanyById } = useCompany();
    const { getCompanyRelationByCompany } = useCompanyRelation();
    const { getByUserAndCompany, addNotaryAppointment, updateNotaryAppointment } = useNotaryAppointment();

    const { updateUserSubstep, getUserSubstepById } = useUserSubstep();

    const { checkAdmin } = useUser();



    const [companyAux, setCompanyAux] = useState(company);
    const [isAdmin, setIsAdmin] = useState(undefined);
    const [identity, setIdentity] = useState(undefined);
    const [loading, setLoading] = useState(undefined);
    const [notaryAppointment, setNotaryAppointment] = useState<NotaryAppointment>(undefined);

    const [disabledByStatus, setDisabledByStatus] = useState(false);
    /**
     * Campo del objeto UserStep
     */
    const [commentUser, setCommentUser] = useState("");


    // REF
    const dragDropRef = useRef(null);
    const idDragDrop = "dd-sub7";

    // DataPickerRange

    const [date, setDate] = useState({
        startDate: moment(START_DATE_DEFAULT).format("YYYY-MM-DD HH:mm"),
        endDate: moment(END_DATE_DEFAULT).format("YYYY-MM-DD HH:mm")
    });

    useEffect(() => {
        if (currentSubstep && (currentSubstep.status === "EN_REVISION" || currentSubstep.status === "ACEPTADO")) {
            setDisabledByStatus(true);
        } else {
            setDisabledByStatus(false);
        }
    }, [currentSubstep]);

    useEffect(() => {
        (async () => {
            await initPage();
        })();
    }, [])


    const initPage = async () => {
        try {
            setLoading(true);
            const token = await LoginService.getTokenAsync();
            if (!identity) {
                const identity = await LoginService.getIdentityAsync();
                setIdentity(identity)
            }
            // const result = await checkAdmin(token);



            const result = await Promise.all([
                checkAdmin(token),
                getByUserAndCompany(token,
                    company.idUserFk,
                    company.id),
                getUserSubstepById(token, currentSubstep.id)

            ]);


            setIsAdmin(result[0] && result[0].item === true);

            if (result[1] && result[1].ok && result[1]?.item?.length) {
                setNotaryAppointment(result[1].item[0])
            } else {
                setNotaryAppointment(new NotaryAppointment())
            }

            if (result[2] && result[2].ok) {
                setCommentUser(result[2]?.item?.commentUser || "")
            }

            setLoading(false);

        } catch (error) {
            setLoading(false);
            console.error(error);
        }
    }



    const handleNotaryOperations = async () => {
        try {
            setLoading(true);

            await submit();
            await updateUserStep();

            handleModal(false);

        } catch (error) {
            console.error("Error al procesar las operaciones:", error);
            handleModal(false);

        } finally {
            setLoading(false);
        }
    };


    /**
 * Centraliza la modificación en la base de datos de todos los rows
 */
    const submit = async () => {
        try {
            const token = await LoginService.getTokenAsync();

            let na: NotaryAppointment = new NotaryAppointment();
            na = { ...notaryAppointment };
            na.startDate = date.startDate as any;
            na.endDate = date.endDate as any;

            let result;
            if (na.id) {
                result = await updateNotaryAppointment(token, na);
            } else {
                na.idCompanyFk = company.id;
                na.isLawyer = false;
                na.idUserFk = company.idUserFk;
                result = await addNotaryAppointment(token, na);
            }

            if (result && result.ok) {

            } else {
                throw Error(result);
            }

        } catch (error: any) {
            console.error("Error al procesar las operaciones:", error);
            throw Error(error);
        }

    }

    const updateUserStep = async () => {
        try {
            const token = await LoginService.getTokenAsync();
            const ID_EN_REVISION_STATUS = 3;
            const result = await updateUserSubstep(token,
                {
                    id: +currentSubstep.id,
                    idStatusSubstepFk: ID_EN_REVISION_STATUS,
                    comment: "",
                    commentUser
                })
            if (result && result.ok) {

                let step = currentStep;
                let substep = currentSubstep;

                step.status = StatusStep.EN_REVISION;
                substep.status = StatusSubstep.EN_REVISION;


                dispatch(updateStep(step, substep));
                setLoading(false);
                handleModal(false);

            } else {
                throw Error("Hubo un error", result)
            }
        } catch (error) {
            setLoading(false);

            console.error(error);
            handleModal(false);
        }
    }


    //  DESCARGA

    const downloadDocument = (url, filename) => {
        const link = document.createElement('a');
        link.href = `${environment.urlApi}${url}`;
        link.download = filename || 'document';
        link.target = '_blank';
        document.body.appendChild(link);
        link.click();
        link.remove();
    }

    const handleDate = (startDate: string, endDate: string) => {
        if (startDate && endDate) {
            setDate({
                startDate,
                endDate
            });

        } else {
            setDate(undefined);


        }

    }

    if (!notaryAppointment) {
        return (
            // <span>ho {JSON.stringify(notaryAppointment, null, 2)}</span>
            null
        )
    }

    return (
        <div>
            {/* 
            <pre>{JSON.stringify(notaryAppointment, null, 2)}</pre> */}


            <div style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                // backgroundColor: "pink"
            }}>
                <CCol xs lg={8}>

                    <p style={{ fontSize: 15, fontFamily: "Raleway" }}>
                        Elige un rango de fechas de cuando podrás estar disponible
                    </p>

                    <GKDateRangePicker
                        disabled={loading || disabledByStatus || isAdmin}
                        showRanges={false}
                        handleDate={handleDate}
                        defaultStartDate={notaryAppointment?.startDate || date.startDate}
                        defaultEndDate={notaryAppointment?.endDate || date.endDate}
                    />
                </CCol>
            </div>

            <div style={{ marginTop: 50 }}></div>




            <CRow>

                <div className="fileUploadContainer" style={{ display: "flex", flexDirection: "column" }}>
                    <CCol lg={9}>
                        <p style={{ fontSize: 15, fontFamily: "Raleway" }}>
                            <b> Arrastra y suelta justificante de pago a la notaría aquí o haz clic para seleccionarlo</b>. Este campo es para cargar una copia digital de tu justificante. Asegúrate de que el archivo cumpla con los formatos permitidos y no exceda el tamaño máximo.

                        </p>
                    </CCol>

                    <DragDrop
                        disabled={
                            loading
                            || disabledByStatus || isAdmin
                        }
                        specialCase={true}
                        handleFiles={(item) => {
                            console.log(item)

                            if (notaryAppointment) {
                                if (item && item.length > 0) {
                                    setNotaryAppointment(prev => {
                                        return { ...prev, urlNotary: item[0].pathRelative }
                                    })
                                } else {
                                    setNotaryAppointment(prev => {
                                        return { ...prev, urlNotary: "" }
                                    })
                                }
                            }
                        }}

                        ref={dragDropRef}
                        styleDD={{ width: 300, height: 150 }}
                        // classDD={'ploadContainer'}
                        id={idDragDrop}
                        isOnlyOneFile={true}
                        showFileList={false}
                        // disabled={loading || typeFilesList.filter(item => item.uploaded).length === countLimit}

                        valueDefault={(notaryAppointment && notaryAppointment.urlNotary) ? notaryAppointment.urlNotary : ""}
                        directoryNameList={["fromReact", "notaryAppointment"]}
                        maxSizeMB={19}
                        typeAllowed={[
                            "image/gif",
                            "image/jpeg",
                            "image/png",
                            "image/jpg",
                            "application/pdf"
                        ]} />

                    {!notaryAppointment?.urlNotary && (
                        <div className='d-flex align-items-center mt-2' style={{ fontSize: 12, color: "red" }}>
                            <CIcon size="sm" icon={cilWarning} />
                            <div className='small' style={{ marginLeft: '5px' }}>Sube el justificante de pago</div>
                        </div>
                    )}

                    {notaryAppointment && notaryAppointment.urlNotary && (
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            cursor: 'pointer',
                            color: '#007BFF'
                        }}
                            onClick={() => downloadDocument(notaryAppointment.urlNotary, notaryAppointment.urlNotary)}
                        >
                            <span style={{
                                color: COLORS.secondaryColor,
                                marginRight: '10px',
                                textDecoration: 'none'
                            }}
                                onMouseOver={(e: any) => e.target.style.textDecoration = 'underline'}
                                onMouseOut={(e: any) => e.target.style.textDecoration = 'none'}
                            >
                                Descargar documento
                            </span>
                            <svg
                                fill={COLORS.secondaryColor}
                                className="icon-download"
                                xmlns="http://www.w3.org/2000/svg"
                                height="1em"
                                viewBox="0 0 512 512">
                                <path d="M288 32c0-17.7-14.3-32-32-32s-32 14.3-32 32V274.7l-73.4-73.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0l128-128c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L288 274.7V32zM64 352c-35.3 0-64 28.7-64 64v32c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V416c0-35.3-28.7-64-64-64H346.5l-45.3 45.3c-25 25-65.5 25-90.5 0L165.5 352H64zm368 56a24 24 0 1 1 0 48 24 24 0 1 1 0-48z" />
                            </svg>
                        </div>

                    )}

                </div>




            </CRow>

            <div style={{ marginTop: 20 }}></div>


            <div style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                // backgroundColor: "pink"
            }}>
                <CCol sm={12} md={12} lg={9}>
                    <p style={{ fontSize: 15, fontFamily: "Raleway" }}>
                        Si tiene observaciones o sugerencias específicas relacionadas con la información presentada, por favor, indíquelas en el espacio proporcionado a continuación. Este campo es opcional, pero cualquier comentario proporcionado será revisado atentamente por nuestro equipo para garantizar la precisión y pertinencia de la información.
                    </p>
                </CCol>
                <CCol sm={12} md={12} lg={9}>
                    <CFormTextarea
                        disabled={loading || disabledByStatus || isAdmin}
                        className="mb-3"
                        rows={6}
                        placeholder="Deja tu comentario"
                        aria-label="Deja tu comentario"
                        defaultValue={commentUser}
                        onChange={(e) => setCommentUser(e.target.value)}
                    ></CFormTextarea>
                </CCol>


                {!disabledByStatus && !isAdmin && (
                    <CRow>
                        <div style={{ marginTop: 20 }}></div>
                        <CLoadingButton
                            style={{ backgroundColor: COLORS.principalColor }}
                            // color="dark"
                            disabled={loading || disabledByStatus || !notaryAppointment || (notaryAppointment && !notaryAppointment.urlNotary)}
                            loading={loading}
                            onClick={() => {
                                handleNotaryOperations()
                            }}>Mandar a revisión</CLoadingButton>
                        <div style={{ marginBottom: 20 }}></div>

                    </CRow>
                )}
            </div>
        </div>

    )
}

export default Subcomponent7

