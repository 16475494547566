import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useCompany } from "../../../../../core/hooks/db/company/useCompany";
import { useUser } from "../../../../../core/hooks/db/user/useUser";
import { useUserSubstep } from "../../../../../core/hooks/db/userSubstep/useUserSubstep";
import { RootState } from "../../../../../core/store/store";
import { SubcomponentBasicProp } from "./Subcomponent1";
import { LoginService } from "../../../../../core/services/login/login-service";
import { MyDocument } from "../../../../../core/models/db/myDocument";
import { FileItemObservableService } from "../../../drag-drop/services/rxJS/fileitem-observable.service";
import React from "react";
import { DocumentStep } from "../../../../../core/models/db/steps/documentStep";
import { FileItem } from "../../../drag-drop/model/FileItem";
import { useMyDocument } from "../../../../../core/hooks/db/myDocument/useMyDocument";
import DragDrop from "../../../drag-drop/DragDrop";
import { DocumentAndorraItem } from "./components/DocumentAndorraItem";
import { CLoadingButton } from "@coreui/react-pro";
import { DocumentItem } from "./components/DocumentItem";
import { StatusStep, StatusSubstep } from "../../types";
import { updateStep } from "../../actions/dashboardStep-action";
import { useStepDocument } from "../../../../../core/hooks/db/stepDocument/useStepDocument";
import { COLORS } from "../../../../../core/constants/colors";

const Subcomponent3 = (props: SubcomponentBasicProp) => {
  const { showModal, handleModal, substepDashboard } = props;

  // REDUX: Paso y subpaso actual
  const dispatch = useDispatch();
  const { steps, company, idCompany, idUser, currentStepId } = useSelector((state: RootState) => state.dashboardStep);

  const currentStep = steps.find(s => s.id === currentStepId);
  const currentSubstep = substepDashboard;

  const { updateCompany } = useCompany();
  const { updateUserSubstep } = useUserSubstep();
  const { addDocumentWithDocumentStep, deleteMyDocument } = useMyDocument();

  // TODO: Incluir esto en el init. Para recibir los documentos generados en este paso
  const { getByUserAndSubstep: getDocumentByUserAndSubstep } = useStepDocument();

  const { checkAdmin } = useUser();



  const [companyAux, setCompanyAux] = useState(company);
  const [identity, setIdentity] = useState<any>(undefined);
  const [isAdmin, setIsAdmin] = useState(undefined);
  const [loading, setLoading] = useState(undefined);

  const [disabledByStatus, setDisabledByStatus] = useState(false);

  // Archivos subidos en el Drag and Drop
  const [filesAlreadyUploaded, setFilesAlreadyUploaded] = useState<any[]>([]);

  // Tabla documents
  const [documents, setDocuments] = useState<MyDocument[]>([]);
  const typeFileSelected = "Apertura de cuenta bancaria y capital social.";
  // REF
  const dragDropRef = useRef(null);

  useEffect(() => {
    if (currentSubstep && (currentSubstep.status === "EN_REVISION" || currentSubstep.status === "ACEPTADO")) {
      setDisabledByStatus(true);
    } else {
      setDisabledByStatus(false);
    }
  }, [currentSubstep]);

  useEffect(() => {
    (async () => {
      await initPage();
    })();
  }, [])

  const initPage = async () => {
    try {
      setLoading(true);
      const identity = await LoginService.getIdentityAsync();
      const token = await LoginService.getTokenAsync();
      setIdentity(identity);

      // const result = await checkAdmin(token);

      const result = await Promise.all([
        checkAdmin(token),
        getDocumentByUserAndSubstep(token, {
          idUserFk: idUser,
          idUserSubstepFk: +currentSubstep.id,
          idCompanyFk: company.id,
        }),
      ]);

      setIsAdmin(result[0] && result[0].item === true);


      // documents
      if (result[1] && result[1].ok) {
        let itemList = result[1].item;
        let auxList = [];
        for (const doc of itemList) {
          let document: MyDocument = doc.documents;
          document.documentSteps = {
            id: doc.id
          }
          auxList.push(document);
        }
        setDocuments(auxList)

      } else {
        setDocuments([]);
      }

      setLoading(false);

    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  }

  const addDocument = async (fileItem: FileItem) => {
    try {
      setLoading(true);
      console.log(fileItem)
      // const identity = await LoginService.getIdentityAsync();
      const token = await LoginService.getTokenAsync();

      let document: MyDocument;
      let documentStep: DocumentStep;
      document = {
        name: fileItem.name,
        extension: fileItem.type,
        description: typeFileSelected,
        url: fileItem.pathRelative,
        idUserFk: idUser,
        idCompanyFk: company.id
      }

      documentStep = {
        idUserStepFk: +currentStep.id,
        idUserSubstepFk: +currentSubstep.id
      }

      const result = await addDocumentWithDocumentStep(token, document, documentStep);
      if (result && result.item) {
        console.log(result)
        setDocuments(doc => {
          return [...doc, result.item];
        });

      } else {
        new Error("No se ha guardado el documento", result)
      }
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false);
      cleanDocument();
    }
  }

  // Empiezo drag and drop

  const controlFile$ = React.useRef(FileItemObservableService.instance.control$);

  /** Cuando existe un cambio en el observable actializo el listado de items */
  useEffect(() => {
    const subscription = FileItemObservableService.instance
      .control$.subscribe(
        item => {
          console.log("Look at item", item)
          if (item && item.fileItemList && item.fileItemList.length > 0) {
            setFilesAlreadyUploaded(item.fileItemList)
            // setFieldValue('image', item.fileItemList[0].pathRelative);
          } else {
            setFilesAlreadyUploaded([])
            // setFieldValue('image', "");
          }
        }
      )
    return () => {
      subscription.unsubscribe();
    }

  }, [controlFile$]);

  const handleExternalDelete = (e, index) => {
    const forceDeleted = true;
    dragDropRef.current.deleteFileItem(e, index, forceDeleted);
  };

  const cleanDocument = () => {
    // setTypeFileSelected(undefined)
    setFilesAlreadyUploaded([])
    handleExternalDelete(null, 0)
  }

  const handleDelete = async (doc) => {

    try {
      const token = await LoginService.getTokenAsync();
      const result = await deleteMyDocument(token, [doc.id], true);
      if (result && result.ok) {
        let docAux = documents.filter(d => d.id !== doc.id)
        setDocuments(docAux);
      }
    } catch (error) {
      console.error(error)
    }
  };


  const updateUserStep = async () => {
    try {
      const token = await LoginService.getTokenAsync();
      const ID_EN_REVISION_STATUS = 3;
      const result = await updateUserSubstep(token,
        {
          id: +currentSubstep.id,
          idStatusSubstepFk: ID_EN_REVISION_STATUS,
          comment: ""
        })
      if (result && result.ok) {

        let step = currentStep;
        let substep = currentSubstep;

        step.status = StatusStep.EN_REVISION;
        substep.status = StatusSubstep.EN_REVISION;
        dispatch(updateStep(step, substep));

        handleModal(false);

      } else {
        throw Error("Hubo un error", result)
      }
    } catch (error) {
      console.error(error);
      handleModal(false);
    }
  }


  return (
    <div>
      {identity && identity.idRole !== 1 && (
        <>
          <div style={{ fontSize: 15, fontFamily: "Raleway", marginBottom: 10 }}>
            Justificante de haber aportado un mínimo de 3000 euros
          </div>
          <div className="fileUploadContainer">
            <DragDrop
              ref={dragDropRef}
              styleDD={{ width: 300, height: 150 }}
              // classDD={'ploadContainer'}
              id={"test-id"}
              isOnlyOneFile={true}
              showFileList={false}
              // disabled={loading || typeFilesList.filter(item => item.uploaded).length === countLimit}
              disabled={
                disabledByStatus
                || loading
                || documents.length > 0
              }


              directoryNameList={["fromReact"]}
              maxSizeMB={19}
              typeAllowed={[
                "image/*",
                "image/gif",
                "image/jpeg",
                "image/png",
                "image/jpg",
                "application/pdf"
              ]} />


          </div>
        </>
      )}

      {/* Botón de subir documento */}
      {identity && identity.idRole !== 1 && (
        <div>
          <div style={{ display: "flex", justifyContent: "center", marginTop: 30, marginBottom: 50, }}>
            <CLoadingButton
              style={{ backgroundColor: COLORS.principalColor }}

              disabled={
                loading
                || disabledByStatus
                || !typeFileSelected
                || filesAlreadyUploaded.length === 0
                || documents.length > 0
              }
              loading={loading}
              onClick={() => addDocument(filesAlreadyUploaded[0])}>Subir documento</CLoadingButton>
          </div>
        </div>
      )}

      {/* <pre>{JSON.stringify(documents, null, 2)}</pre> */}
      {documents && documents.length > 0 && (
        <DocumentItem
          documents={documents}
          onDelete={handleDelete}
          disabled={disabledByStatus || identity && identity.idRole === 1}

        />
      )}

      <div style={{ marginTop: 20 }}></div>

      <div style={{ display: "flex", marginBottom: 20 }}>
        <div style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          backgroundColor: documents.length > 0 ? COLORS.principalColor : "lightcoral",
          padding: "5px 10px",
          borderRadius: 5
        }}>
          {
            documents.length > 0 ? (
              <>
                <svg xmlns="http://www.w3.org/2000/svg" fill="white" height="1em" viewBox="0 0 448 512">
                  <path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" />
                </svg>
                <span style={{ marginLeft: 5, color: "white" }}>Todos los tipos de documentos han sido cargados</span>
              </>
            )
              :
              (
                <>
                  <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM175 175c-9.4 9.4-9.4 24.6 0 33.9l47 47-47 47c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l47-47 47 47c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-47-47 47-47c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-47 47-47-47c-9.4-9.4-24.6-9.4-33.9 0z" /></svg>
                  <span style={{ marginLeft: 5 }}>No se han cargado todos los tipos documentos</span>
                </>
              )
          }

        </div>
      </div>



      <div style={{ display: "flex", justifyContent: "space-between" }}>
        {!disabledByStatus && (identity && identity.idRole !== 1) && (
          <CLoadingButton
            style={{ backgroundColor: COLORS.principalColor }}
            // color="dark"
            disabled={loading || documents.length === 0}
            loading={loading}
            onClick={() => updateUserStep()}>Mandar a revisión</CLoadingButton>
        )}

      </div>




    </div>


  )
}

export default Subcomponent3