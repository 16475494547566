import { CCard, CCardBody, CCol, CContainer, CFormLabel, CInputGroup, CRow, CSpinner } from '@coreui/react-pro';
import React, { CSSProperties, useEffect, useLayoutEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { DBResponse, Step } from '../../components/@dashboardSteps/types';
import DSubstep from '../../components/@dashboardSteps/DSubstep/DSubstep';
import DStep from '../../components/@dashboardSteps/DStep/DStep';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../core/store/store';
import { useUserStep } from '../../../core/hooks/db/userStep/useUserStep';
import { LoginService } from '../../../core/services/login/login-service';
import { UserStep } from '../../../core/models/db/steps/userStep';
import { setCurrentStep, setInitialData } from '../../components/@dashboardSteps/actions/dashboardStep-action';
import useHorizontalScroll from '../../../core/hooks/horizontal-scroll/useHorizontalScroll';
import { useCompany } from '../../../core/hooks/db/company/useCompany';
import Autocomplete from '../../components/autocomplete/Autocomplete';
import { Company } from '../../../core/models/db/company';
import { getComponentForSubstep, stepDependencies } from '../../components/@dashboardSteps/DSubstep/subcomponentsConfig';
import WarnNoCompany from '../../components/warnNoCompany/WarnNoCompany';
import WarnBuyMainProduct from '../../components/warnBuyMainProduct/WarnBuyMainProduct';
import useCheckMainProduct from '../../../core/hooks/checkMainProduct/useCheckMainProduct';
import { useProduct } from '../../../core/hooks/db/product/useProduct';
import { COLORS } from '../../../core/constants/colors';
import Loader from '../../components/loader/Loader';



const HomePage = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const dispatch = useDispatch();


    const { steps, company: companyRedux } = useSelector((state: RootState) => state.dashboardStep);


    const [identity, setIdentity] = useState<any>(undefined);
    const [loading, setLoading] = useState<any>(false);


    const [pasoSeleccionado, setPasoSeleccionado] = useState<Step | null>(null);
    const [companyChoosed, setCompanyChoosed] = useState<Company>(undefined);

    // No se usa
    // const [userStep, setUserStep] = useState<UserStep>(undefined);

    // Scroll horizontal
    const hScrollRef = useHorizontalScroll();
    const hScrollRef2 = useHorizontalScroll();


    const { getDashboard } = useUserStep();
    const { getCompany, getCompanyById } = useCompany();
    const {
        hasMainProduct,
        isLoading: loadingCheckMainProduct,

    } = useCheckMainProduct();




    useEffect(() => {
        (async () => {
            if (!identity) {
                let id = await LoginService.getIdentityAsync();
                setIdentity(id);
            }

            if (companyChoosed) {
                await initScreen(identity);
            }
        })();
    }, [companyChoosed]);


    useEffect(() => {
        (async () => {
            if (!identity) {
                let id = await LoginService.getIdentityAsync();
                setIdentity(id);
            }
            if (!companyRedux) {
                setPasoSeleccionado(undefined);
                dispatch(setInitialData(null, null, null, null, null, null));
            } else if (companyChoosed?.id != companyRedux?.id) {
                setCompanyChoosed(companyRedux)
                // await initScreen(identity);
            }
        })();
    }, [companyRedux]);


    const initScreen = async (identity) => {
        try {
            let token = await LoginService.getTokenAsync();
            setPasoSeleccionado(undefined);
            // Si es administrador y no tiene compañía elegida no mostrar nada
            if (identity.idRole === 1 && !companyChoosed) {
                dispatch(setInitialData(null, null, null, null, null, null));
                return;
            };

            const idUser = identity.idRole === 1
                ? companyChoosed.idUserFk
                : identity.idUser;
            const idCompany = companyChoosed.id;
            const result = await Promise.all([
                getDashboard(token, idUser, idCompany),
                getCompanyById(token, idCompany)
            ]);



            if (result[0] && result[0].ok && result[1] && result[1].ok) {
                // setUserStep(result[0].item);

                let company = result[1].item;
                let step: any = adaptDBResponseToSteps(result[0]);
                dispatch(setInitialData(step as Step[], company, null, null, idCompany, idUser));
            } else {
                console.error("ERROR", result);
                // Cuando no encuentra dashboard es debido a que la compañía no ha pagado.
                // Por lo tanto se mantiene, pero los pasos anteriores (de otra compañía) sí han de ocultarse
                dispatch(setInitialData(null, companyRedux, null, null, idCompany, idUser));
            }
        } catch (e) {
            console.error(e);

        }
    }

    const adaptDBResponseToSteps = (dbResponse: DBResponse) => {

        // 1. Crear la lista inicial
        const list = dbResponse.item.map(dbStep => ({
            id: dbStep.id,
            idReal: dbStep.idStepFk,
            status: dbStep.statusSteps.name,
            disabled: false,
            dependencias: stepDependencies[dbStep.idStepFk] || [],
            name: dbStep.steps.name,
            description: dbStep.steps.description,
            substeps: dbStep.userSubsteps.map(dbSubstep => ({
                id: dbSubstep.id,
                type: dbSubstep.substeps.name,
                status: dbSubstep.statusSubsteps.name,
                component: getComponentForSubstep(dbSubstep.idSubstepFk),
                comment: dbSubstep.comment,
            }))
        }));

        // 2. Actualizar la propiedad 'disabled' basada en las dependencias
        list.forEach(step => {
            // console.log(step)
            if (stepDependencies[step.idReal]) {
                step.disabled = stepDependencies[step.idReal].some(dependencyId => {
                    // console.log(step.idReal, dependencyId)
                    const dependentStep = list.find(s => s.idReal === dependencyId);
                    return !dependentStep || dependentStep.substeps.some(substep => substep.status !== 'ACEPTADO');
                });
            }
        });

        return list;
    }


    const handleStepClick = (stepId: number) => {
        dispatch(setCurrentStep(stepId));
    }


    if (!loadingCheckMainProduct && !hasMainProduct && companyChoosed) {
        return (
            <>
                {/* <button onClick={() => console.log(companyChoosed)}>prueba 1</button> */}
                {/* <pre style={{ color: "white" }}>{JSON.stringify(hasMainProduct, null, 2)}</pre> */}
                <WarnBuyMainProduct company={companyChoosed} />
            </>
        )
    }

    if (!companyRedux || !companyChoosed) {
        return (
            <>
                {/* <button onClick={() => console.log(companyChoosed)}>prueba 2</button> */}
                <WarnNoCompany />
            </>
        )
    }


    return (
        <CContainer>
            {/* <button onClick={() => console.log(companyChoosed)}>prueba 3</button> */}
            {/* <pre>{JSON.stringify(companyRedux, null, 2)}</pre> */}

            {/* BOTÓN TEST */}
            {/* {steps && (
                    <button onClick={() => console.log(steps)}>Ver objeto steps en consola</button>
                )} */}

            <CCard style={{ backgroundColor: COLORS.principalColor }}>
                <CCardBody>
                    <CRow>
                        <div style={{ marginBottom: 20 }}></div>

                        {/* <pre>{JSON.stringify(steps, null, 2)}</pre> */}
                        <div className="pasos" ref={hScrollRef}>
                            {steps && steps.map((paso, i) => (
                                <div key={i}>
                                    <DStep
                                        key={paso.id}
                                        step={paso}
                                        index={i}
                                        onClick={() => {
                                            console.log(paso)

                                            if (!paso.disabled || (identity && identity.idRole === 1)) {
                                                setLoading(true);
                                                setPasoSeleccionado(paso);
                                                handleStepClick(paso.id);

                                                setTimeout(() => {
                                                    setLoading(false);
                                                }, 150)

                                            }
                                        }}
                                    />
                                </div>
                            ))}
                        </div>

                        {loading && (
                            // <span>Cargando...</span>
                            <div style={{
                                width: "100vw",
                                height: "50vh",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center"
                            }}>
                                <CSpinner style={{ color: COLORS.secondaryColor }} />
                            </div>

                        )}

                        {pasoSeleccionado && (
                            <div className={`${loading ? "d-none" : ""} subpasos`} ref={hScrollRef2}>
                                {pasoSeleccionado.substeps.map((subpaso, i) => (
                                    <div key={i} >
                                        <DSubstep key={subpaso.id} substep={subpaso} onComplete={undefined} />
                                    </div>
                                ))}
                            </div>
                        )}
                    </CRow>
                </CCardBody>
            </CCard>
        </CContainer>
    )

};


export default HomePage;
