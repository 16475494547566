import * as Yup from 'yup';

const validationCompanySchema = (isAdmin: boolean) =>
    Yup.lazy(values => {
        const baseSchema: { [key: string]: Yup.AnySchema } = {
            companyNameTest1: Yup.string().required('El nombre provisional de la empresa 1 es requerido.'),
            companyNameTest2: Yup.string().required('El nombre provisional de la empresa 2 es requerido.'),
            companyNameTest3: Yup.string().required('El nombre provisional de la empresa 3 es requerido.'),
            description: Yup.string().required('La descripción de la empresa es requerida.'),
        };

        if (isAdmin) {
            baseSchema.companyName = Yup.string().required('Es necesario el nombre definitivo');
        }

        return Yup.object().shape(baseSchema);
    });

export default validationCompanySchema;
