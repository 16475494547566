export enum RouteName {
    login = "login",
    home = "home",
    selectAdmin = "selectAdmin",
    usuarios = "usuarios",
    usuariosCrear = "usuariosCrear",
    usuariosActualizar = "usuariosActualizar",
    usuariosEliminar = "usuariosEliminar",
    billing = "billing",
    compania = "compania",
    companiaDetail = "companiaDetail",
    documentAccesses = "documentAccesses",
    documentAccessesCrear = "documentAccessesCrear",
    // documentAccessesActualizar = "documentAccessesActualizar",

}

export const BASE_ROUTE = "/dashboard"

export const ROUTE_NAME: { [key in RouteName]: string } = {

    login: "/login",
    home: "/home",
    selectAdmin: "/selectAdmin",

    usuarios: "/usuarios",
    usuariosCrear: "/usuarios/crear",
    usuariosActualizar: "/usuarios/actualizar",
    usuariosEliminar: "/usuarios/eliminar",

    billing: "/compania/:id/facturacion",
    // billing: "/facturacion",


    compania: "/compania/crear",

    // documentAccesses: "/documentos",
    documentAccesses: "/compania/:id/documentos",
    documentAccessesCrear: "/documentos/crear",
    // documentAccessesActualizar: "/documentos/actualizar",

    // TODO El id aqui no pinta nada, debe de ser dinamico
    // TODO El id aqui no pinta nada, debe de ser dinamico
    // TODO El id aqui no pinta nada, debe de ser dinamico
    // TODO El id aqui no pinta nada, debe de ser dinamico
    // TODO El id aqui no pinta nada, debe de ser dinamico
    companiaDetail: "/compania/:id/detalle",


    // usuariosCrear: "/usuarios/crear",
    // usuariosActualizar: "/usuarios/actualizar",
    // usuariosEliminar: "/usuarios/eliminar",

}

export const ROUTE_NAME_ID = (id: any, name: RouteName): string | void => {
    const routeChoosed = ROUTE_NAME[name];

    if (!routeChoosed || !id) {
        console.error(id)
        console.error("La ruta no existe", routeChoosed);
        return "/dashboard/home";
    }

    return routeChoosed.replace(/:id/, id.toString());
};