import { useNavigate, useParams } from "react-router-dom";
import { useCompany } from "../../../../core/hooks/db/company/useCompany";
import { useCompanyRelation } from "../../../../core/hooks/db/companyRelation/useCompanyRelation";
import { useCallback, useContext, useEffect, useState } from "react";
import { LoginService } from "../../../../core/services/login/login-service";
import { Company } from "../../../../core/models/db/company";
import { CompanyRelation } from "../../../../core/models/db/companyRelation";
import { CButton, CCard, CCardBody, CCardFooter, CCol, CContainer, CFormInput, CInputGroup, CInputGroupText, CRow } from "@coreui/react-pro";
import { COLORS } from "../../../../core/constants/colors";
import { cilUser, cilWarning } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import RowCompanyRelation from "./components/CompanyRelationRow";
import { useSelector } from "react-redux";
import { RootState } from "../../../../core/store/store";
import ModalCover from "../../../components/modalCover/ModalCover";
import DeleteModal from "../../../components/actionBar/components/DeleteModal";
import { useLoader } from "../../../components/loader/hook/useLoader";
import ButtonLoadingSucessForm from "../../../components/buttonLoadingSucessForm/ButtonLoadingSucessForm";
import { Util } from "../../../../core/util/util";
import CompanyAuxForm from "./CompanyAuxForm";
import { useUser } from "../../../../core/hooks/db/user/useUser";
import { BASE_ROUTE } from "../../../../core/constants/route_name";
import useCheckMainProduct from "../../../../core/hooks/checkMainProduct/useCheckMainProduct";
import WarnBuyMainProduct from "../../../components/warnBuyMainProduct/WarnBuyMainProduct";


const CompanyForm = () => {

    const navigate = useNavigate();
    const { id } = useParams();
    const { company: COMPANY_REDUX, idCompany } = useSelector((state: RootState) => state.dashboardStep);

    const { checkAdmin } = useUser();
    const { getCompanyById, updateCompany } = useCompany();
    const { getCompanyRelationByCompany,
        addCompanyRelation,
        updateCompanyRelation,
        deleteCompanyRelation } = useCompanyRelation();

    const [company, setCompany] = useState<Company>(COMPANY_REDUX);
    const [companyRelationList, setCompanyRelationList] = useState<CompanyRelation[]>([]);
    const [companyRelationList_BACKUP, setCompanyRelationList_BACKUP] = useState<CompanyRelation[]>([]);

    const [isAdmin, setIsAdmin] = useState(false);
    const [isEdit, setIsEdit] = useState(true);

    const { loading, style, className, handleLoading, changeStyle, changeClassName } = useLoader({
        isLoading: false
    })

    /**
  * Registros creados para la tabla companyRelations
  */
    const [deletedRows, setDeletedRows] = useState<CompanyRelation[]>([]);

    // Después de comprobar si los inputs de todos los rows son válidos
    const [isChildDataValid, setIsChildDataValid] = useState(false);
    const [validities, setValidities] = useState<boolean[]>([]);

    const [totalPercentage, setTotalPercentage] = useState<number>(0);


    const {
        hasMainProduct,
        isLoading: loadingCheckMainProduct,

    } = useCheckMainProduct();



    useEffect(() => {
        (async () => {

            // if (!COMPANY_REDUX) {
            //     navigate(BASE_ROUTE);
            // }

            await initPage();
        })();
    }, [id])

    const initPage = async () => {
        try {
            handleLoading(true);
            const identity = await LoginService.getIdentityAsync();
            const isAdmin = identity.idRole === 1;
            const idUser = identity.idUser;

            const token = await LoginService.getTokenAsync();
            let promiseCompany;

            // debugger;
            if (!company || !id || company?.id != +id) {
                promiseCompany = await getCompanyById(token, id)
            }

            let ID_COMPANY = promiseCompany ? id : company.id

            const result = await Promise.all([
                // getCompanyById(token, id),
                promiseCompany,
                getCompanyRelationByCompany(token, ID_COMPANY),
                checkAdmin(token)
            ]);


            // Aquí solamentye entrará si coge la compañía de la base de datos
            if (result[0] && result[0].ok && (isAdmin || !isAdmin && result[0].item.idUserFk == idUser)) {
                setCompany(result[0].item)
                if (COMPANY_REDUX && result[0].item.id != COMPANY_REDUX.id) {
                    console.warn("No hay permisos para hacer esto");
                    navigate("/login");
                } else {
                    // console.warn("OK", company);
                }

            } else {
                if (id && COMPANY_REDUX.id != +id) {
                    console.warn("No existen permisos para hacer esto");
                    navigate("/login");
                } else {
                    // console.warn("OK", company);
                }
            }

            if (result[1] && result[1].ok) {
                setCompanyRelationList([...result[1].item].map((row: any) => {

                    return {
                        ...row,
                        idTemp: Util.getUniqueId(2)
                    }
                }))
                setCompanyRelationList_BACKUP([...result[1].item].map((row: any) => {

                    return {
                        ...row,
                        idTemp: Util.getUniqueId(2)
                    }
                }))

                setValidities([...result[1].item].map(r => true))
            } else {
                setCompanyRelationList([]);
                setCompanyRelationList_BACKUP([])

            }

            setIsAdmin(result[2] && result[2].item === true);


            handleLoading(false);

        } catch (error) {
            handleLoading(false);

            // debugger;

            console.error(error)
        }
    }


    const addNewSocio = (newPartner: CompanyRelation) => {
        let aux: any = newPartner;
        aux.idTemp = Util.getUniqueId(2);
        setCompanyRelationList(prevState => [...prevState, aux]);
    }


    /**
  * Borra el registro
  * @param index 
  * @param rowToDelete 
  */
    const handleRowDeletion = (index, rowToDelete: CompanyRelation) => {

        // Actualmente el owner es el creador de la empresa. No se puede borrar
        if (rowToDelete.relationType === "owner") {
            console.warn("Se ha intentado borrar un owner");
            return;
        }

        // Lo eliminamos de la lista
        let aux = [...companyRelationList];
        let itemDeleted = aux.splice(index, 1);
        setCompanyRelationList([...aux]);

        // Lo quitamos de las validaciones de los formularios
        let validitiexAux = [...validities];
        setValidities(prev => {
            validitiexAux.splice(index, 1);
            return validitiexAux;
        })

        // Lo introducimos en el array de elementos a borrar si finalmente se aceptan los cambios
        if (itemDeleted.length === 1 && itemDeleted[0].id) {
            setDeletedRows(prevState => [...prevState, itemDeleted[0]]);
        }


        // 16/10/23 Necesario para que refresque bien el listado
        handleLoading(true);
        setTimeout(() => {
            handleLoading(false)
        }, 100)
    }

    /**
 * Comprueba si es valido todos los formularios
 * @param isValid 
 */
    const handleChildValidityChange = (index, isValid) => {
        console.log(index, isValid)
        // setValidities(prevValidities => {
        //     const newValidities = [...prevValidities];
        //     newValidities[index] = isValid;
        //     return newValidities;
        // });
        const newValidities = [...validities];
        newValidities[index] = isValid;
        setValidities(newValidities);
    }

    useEffect(() => {
        setIsChildDataValid(validities.every(v => v));
    }, [validities])

    /**
     * Actualiza en padre lo que ocurre en los formularios hijo
     * @param index 
     * @param item 
     */
    const handleRowChange = (index, item) => {
        const newCompanyRelationList = [...companyRelationList];
        newCompanyRelationList[index] = item;
        setCompanyRelationList(newCompanyRelationList);
    }

    /**
  * Crea el registro en la base de datos
  * @param token 
  * @param id 
  */
    const handleAddCompanyRelation = async (token: any, companyRelation: CompanyRelation) => {
        try {
            const result = await addCompanyRelation(token,
                {
                    ...companyRelation,
                    idCompanyFk: company.id,
                    relationType: "partner",
                });
            if (result && result.ok) {

            }

        } catch (e) {
            console.error(e);
        }
    }

    /**
     * Borra el registro en la base de datos
     * @param token 
     * @param list 
     */
    const handleDeleteCompanyRelation = async (token: any, list: number[]) => {
        try {
            const IS_DELETE = true;
            const result = await deleteCompanyRelation(token, list, IS_DELETE);
            if (result && result.ok) {

            }

        } catch (e) {
            console.error(e);
        }
    }


    /**
* Centraliza la modificación en la base de datos de todos los rows
*/
    const submit = async () => {
        const token = await LoginService.getTokenAsync();
        // handleLoading(true);


        const addRowPromises = companyRelationList.filter(row => !row.id).map(row => handleAddCompanyRelation(token, row));
        const updateRowPromises = companyRelationList.filter(row => row.id).map(row => handleUpdateCompanyRelation(token, row));
        const deleteRowPromises = [handleDeleteCompanyRelation(token, deletedRows.filter(row => row.id).map(row => row.id))];


        try {
            await Promise.all([...addRowPromises, ...updateRowPromises, ...deleteRowPromises]);

            // Después de que todas las operaciones han terminado, haces el get para obtener todos los datos actualizados.
            const resultGet = await getCompanyRelationByCompany(token, company.id);

            if (resultGet && resultGet.ok) {
                setDeletedRows([]);
                setCompanyRelationList([...resultGet.item].map((row: any) => {

                    return {
                        ...row,
                        idTemp: Util.getUniqueId(2)
                    }
                }))
                setCompanyRelationList_BACKUP([...resultGet.item].map((row: any) => {

                    return {
                        ...row,
                        idTemp: Util.getUniqueId(2)
                    }
                }))
            } else {
                throw Error(resultGet);
            }
            // handleLoading(false);

        } catch (error: any) {
            console.error("Error al procesar las operaciones:", error);
            throw Error(error);
            // handleLoading(false);
            // handleModal(false)

        }

    }

    /**
    * Modifica el registro en la base de datos
    * @param token 
    * @param id 
    */
    const handleUpdateCompanyRelation = async (token: any, companyRelation: CompanyRelation) => {
        try {
            const result = await updateCompanyRelation(token, companyRelation);
            if (result && result.ok) { }
        } catch (e) {
            console.error(e);
        }
    }


    useEffect(() => {
        calculateTotalPercentage();
    }, [companyRelationList, deletedRows]);

    const calculateTotalPercentage = () => {
        const total = companyRelationList.reduce((acc, row) => acc + (+row.companyPercentage || 0), 0);
        if (total !== totalPercentage) {
            setTotalPercentage(total);
        }
    };


    const handleBackup = async () => {
        setCompanyRelationList([...companyRelationList_BACKUP.map(row => {
            return {
                ...row,
                idTemp: Util.getUniqueId(2)
            }
        })])
        setDeletedRows([]);
        setValidities([...companyRelationList_BACKUP.map(r => true)]);
    }


    if (!loadingCheckMainProduct && !hasMainProduct && company) {
        return (
            <>
                <WarnBuyMainProduct company={company} />
            </>
        )
    }


    return (
        <CContainer className="mb-4 mt-4">
            <CCard>

                <CCardBody style={{
                    backgroundColor: COLORS.tertiaryColor,
                    borderRadius: 5,
                    borderColor: COLORS.tertiaryColor
                }}>

                    {company && (
                        <CompanyAuxForm
                            isAdmin={isAdmin}
                            company={company}
                            updateCompany={updateCompany}
                        />
                    )}

                    {/* <pre style={{ color: "white" }}>{JSON.stringify(isChildDataValid, null, 2)}</pre> */}
                    <div style={{ marginTop: 50, marginBottom: 60 }}>
                        {isAdmin && (
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "98%" }}>
                                <div style={{ color: COLORS.secondaryColor, fontSize: 30, fontWeight: 700 }}>Socios</div>
                                <div style={{ flex: 1 }}>
                                    <ButtonLoadingSucessForm
                                        primaryColor={COLORS.principalColor}
                                        secondaryColor={COLORS.secondaryColor}
                                        loadingButton={loading}
                                        className="d-flex d-row"
                                        changeToBackup={handleBackup}
                                        onSubmit={() => submit()}
                                        setDisabled={setIsEdit}
                                        typeAction={"update"}
                                        disabled={!isChildDataValid || totalPercentage !== 100}
                                    />
                                </div>
                            </div>
                        )}



                        <CRow className="justify-content-end align-items-center mt-3">

                            {!loading && totalPercentage !== 100 && (
                                <CCol sm="auto">
                                    <div className='d-flex align-items-center' style={{ height: "25px", fontSize: 20, color: "red" }}>
                                        <CIcon size="sm" icon={cilWarning} />
                                        <div className='small' style={{ marginLeft: '5px' }}>El porcentaje total no es 100</div>
                                    </div>
                                </CCol>
                            )}
                        </CRow>


                    </div>




                    {/* <pre style={{ color: "white" }}>{JSON.stringify(isChildDataValid, null, 2)}</pre> */}
                    {companyRelationList && companyRelationList.map((cr: any, i) => {
                        return (
                            <>
                                {/* <pre style={{ color: "white" }}>{JSON.stringify(company.idUserFk, null, 2)}</pre>
                                <pre style={{ color: "white" }}>{JSON.stringify(cr.idUserFk, null, 2)}</pre>
                                <pre style={{ color: "white" }}>{JSON.stringify(isAdmin && isEdit && cr.id && (company.idUserFk !== cr.idUserFk), null, 2)}</pre>
                                <pre style={{ color: "white" }}>{JSON.stringify(isAdmin, null, 2)}</pre>
                                <pre style={{ color: "white" }}>{JSON.stringify(isEdit, null, 2)}</pre> */}


                                <RowCompanyRelation
                                    key={cr.idTemp}
                                    index={i}
                                    isMainUser={company.idUserFk === cr.idUserFk}
                                    companyRelation={cr}
                                    disabled={!isAdmin || isEdit}
                                    onDelete={isAdmin && !isEdit ? handleRowDeletion : null}
                                    onValidityChange={handleChildValidityChange}
                                    onDataChange={handleRowChange}
                                />

                                {/* <SocioDetails key={i} partner={cr} index={i} isAdmin={isAdmin} /> */}

                            </>
                        )
                    })}
                    <CRow>
                        {isAdmin && !isEdit && (
                            <CRow>
                                <div style={{ textAlign: 'right', marginBottom: '1rem', marginTop: 20 }}>
                                    <CButton
                                        style={{
                                            backgroundColor: COLORS.secondaryColor,
                                            color: "white",
                                            border: 'none',
                                            padding: '0.5rem 1rem',
                                            borderRadius: '5px',
                                            marginRight: 21
                                        }}
                                        disabled={isEdit}
                                        onClick={() => {
                                            setValidities(prev => [...prev, true]);
                                            addNewSocio(new CompanyRelation())
                                        }} >Agregar</CButton>
                                </div>
                            </CRow>
                        )}
                    </CRow>



                </CCardBody>



            </CCard>


        </CContainer>





    )
}

export default CompanyForm;

