import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { Pagination } from '../../../models/pagination';
import { UserService } from '../../../services/db/user/user.service';
import { User } from '../../../models/db/user';
import { ReasonForDeactivationProps } from '../../check-table/useCheckTable';
import { LoginService } from '../../../services/login/login-service';
// import { Auth } from '../interfaces/routes/interfaces';



export const useUser = () => {


    const register = (user: User) => {
        return new Promise<any>((res, rej) => {
            LoginService.register(user)
                .then(({ data }) => {
                    console.log(data)
                    if (data.ok) {
                        res(data);
                    } else {
                        res(undefined)
                    }
                })
                .catch((e) => {
                    console.log(e)
                    rej(e)
                })

        })
    }


    const getAllIdUser = (token: any, reasonForDeactivation: ReasonForDeactivationProps[]) => {
        return new Promise<any>((res, rej) => {
            UserService.getAllId(token, reasonForDeactivation)
                .then(({ data }) => {
                    console.log(data)
                    if (data.ok) {
                        res(data);
                    } else {
                        res(undefined)
                    }
                })
                .catch((e) => {
                    console.log(e)
                    rej(e)
                })

        })
    }


    const getUserById = (token, id) => {
        return new Promise<any>((res, rej) => {
            UserService.get(token, id)
                .then(({ data }) => {
                    console.log(data)
                    if (data.ok) {
                        res(data);
                    } else {
                        res(undefined)
                    }
                })
                .catch((e) => {
                    console.log(e)
                    rej(e)
                })

        })
    }

    const getUser = (token, pagination: Pagination = { page: 1, itemsPerPage: 5 }) => {
        return new Promise<any>((res, rej) => {
            UserService.getAll(token, pagination)
                .then(({ data }) => {
                    console.log(data)
                    if (data.ok) {
                        res(data);
                    } else {
                        res(undefined)
                    }
                })
                .catch((e) => {
                    console.log(e)
                    rej(e)
                })

        })
    }


    const addUser = (token, user: User) => {
        return new Promise<any>((res, rej) => {
            UserService.add(token, { ...user })
                .then(({ data }) => {
                    console.log(data)
                    if (data.ok) {
                        res(data);
                    } else {
                        res(undefined)
                    }
                })
                .catch((e) => {
                    console.log(e)
                    rej(e)
                })

        })
    }


    const updateUser = (token, user: User) => {
        return new Promise<any>((res, rej) => {
            UserService.update(token, { ...user })
                .then(({ data }) => {
                    console.log(data)
                    if (data.ok) {
                        res(data);
                    } else {
                        res(undefined)
                    }
                })
                .catch((e) => {
                    console.log(e)
                    rej(e)
                })

        })
    }


    const deleteUser = (token, idList: string[] | number[], action: boolean) => {
        return new Promise<any>((res, rej) => {
            UserService.delete(token, idList, action)
                .then(({ data }) => {
                    console.log(data)
                    if (data.ok) {
                        res(data);
                    } else {
                        res(undefined)
                    }
                })
                .catch((e) => {
                    console.log(e)
                    rej(e)
                })

        })
    }

    const checkAdmin = (token) => {
        return new Promise<any>((res, rej) => {
            UserService.checkAdmin(token)
                .then(({ data }) => {
                    console.log(data)
                    if (data.ok) {
                        res(data);
                    } else {
                        res(undefined)
                    }
                })
                .catch((e) => {
                    console.log(e)
                    rej(e)
                })

        })
    }

    const checkPassword = (token, id: any, password: any) => {
        return new Promise<any>((res, rej) => {
            UserService.checkPassword(token, id, password)
                .then(({ data }) => {
                    console.log(data)
                    if (data.ok) {
                        res(data);
                    } else {
                        res(undefined)
                    }
                })
                .catch((e) => {
                    console.log(e)
                    rej(e)
                })

        })
    }

    const checkUsername = (token, id: any, username: string) => {
        return new Promise<any>((res, rej) => {
            UserService.checkUsername(token, id, username)
                .then(({ data }) => {
                    // console.log(data)
                    if (data.ok) {
                        res(data);
                    } else {
                        res(undefined)
                    }
                })
                .catch((e) => {
                    console.log(e)
                    rej(e)
                })

        })
    }


    const checkPasswordPublic = (id: any, password: any) => {
        return new Promise<any>((res, rej) => {
            UserService.checkPasswordPublic(id, password)
                .then(({ data }) => {
                    console.log(data)
                    if (data.ok) {
                        res(data);
                    } else {
                        res(undefined)
                    }
                })
                .catch((e) => {
                    console.log(e)
                    rej(e)
                })

        })
    }

    const checkUsernamePublic = (id: any, username: string) => {
        return new Promise<any>((res, rej) => {
            UserService.checkUsernamePublic(id, username)
                .then(({ data }) => {
                    // console.log(data)
                    if (data.ok) {
                        res(data);
                    } else {
                        res(undefined)
                    }
                })
                .catch((e) => {
                    console.log(e)
                    rej(e)
                })

        })
    }

    return {
        register,
        getAllIdUser,
        getUserById,
        getUser,
        addUser,
        updateUser,
        deleteUser,
        checkAdmin,
        checkPassword,
        checkUsername,
        checkPasswordPublic,
        checkUsernamePublic,

        getById: getUserById,
        get: getUser,
        add: addUser,
        update: updateUser,
        delete: deleteUser,

        autocomplete: getUser,

    }
}