import { CContainer, CCard, CCardBody, CRow, CButton } from "@coreui/react-pro"
import { useContext, useEffect, useState } from "react";
import { COLORS } from "../../../core/constants/colors";
import { useNavigate } from "react-router-dom";
import { useLoader } from "../loader/hook/useLoader";
import { Util } from "../../../core/util/util";
import { useSelector } from "react-redux";
import { BASE_ROUTE } from "../../../core/constants/route_name";
import { RootState } from "../../../core/store/store";
import { DataBasicEncoder } from "../../../core/util/data-basic-encoder";
import { useProduct } from "../../../core/hooks/db/product/useProduct";
import { useUser } from "../../../core/hooks/db/user/useUser";
import { LoginService } from "../../../core/services/login/login-service";
import { User } from "../../../core/models/db/user";
import { Product } from "../../../core/models/db/product";
import { Company } from "../../../core/models/db/company";
import { LoaderContext } from "../loader/context/LoaderContext";

interface WarnBuyMainProductProps {
    company: Company;
}
const WarnBuyMainProduct = (props: WarnBuyMainProductProps) => {
    const navigate = useNavigate();
    // const { company: companyRedux } = useSelector((state: RootState) => state.dashboardStep);
    const { company } = props;

    const { getMainProduct } = useProduct();
    const { getUserById } = useUser();

    const { handleLoading, changeStyle } = useContext(LoaderContext);


    const [isAdmin, setIsAdmin] = useState<boolean>(false);
    const [blockPayment, setBlockPayment] = useState<boolean>(false);



    useEffect(() => {
        (async () => {
            const identity = await LoginService.getIdentityAsync();
            setIsAdmin(identity.idRole === 1);
        })();


    }, [])


    const goToPayment = async () => {

        changeStyle({ backgroundColor: "rgba(111, 214, 250, 0.5)" })
        handleLoading(true);


        const token = await LoginService.getTokenAsync();
        let data: {
            user: User,
            company: Company,
            product: Product,
            expire: any,
            isPaymentCorrect: boolean,
            isPaymentError: boolean,

        } = {} as any

        const result = await Promise.all([
            getMainProduct(token),
            getUserById(token, company.idUserFk)
        ]);

        if (result[0] && result[0].ok) {
            data.product = result[0].item;
        } else {
            setBlockPayment(true);
        }

        if (result[1] && result[1].ok) {
            data.user = {
                id: result[1].item.id,
                email: result[1].item.email
            }
        } else {
            setBlockPayment(true);
        }

        data.company = company;

        console.log(data);

        // Agrega 20 minutos al tiempo actual
        const currentTime = new Date();
        const expiryTime = new Date(currentTime.getTime() + 12000000 * 60 * 1000);
        data.expire = expiryTime.toISOString();
        data.isPaymentCorrect = false;
        data.isPaymentError = false;

        await Util.delay(1000);
        handleLoading(false);
        const encodedData = encodeURIComponent(DataBasicEncoder.encode(data));
        navigate(`${BASE_ROUTE}/pago/${encodedData}`, { state: { encodedData } });
    }

    // PaymentPage
    return (
        <CContainer className="mb-4 mt-4">
            <CCard>
                <CCardBody className="no-company-container">

                    <CRow>
                        {/* <pre>{JSON.stringify(company, null, 2)}</pre> */}
                        <div style={{ display: "flex", flexDirection: "column" }}>
                            {isAdmin
                                ?
                                (
                                    <span className="message">
                                        El usuario no ha hecho efectivo la compra de inicial para esta empresa</span>
                                )
                                : (
                                    <>
                                        <span className="message">
                                            Tienes que comprar X producto para usar nuestros servicios  </span>
                                        <div style={{ display: "flex", justifyContent: "center", marginTop: 50 }}>
                                            <CButton
                                                style={{ backgroundColor: COLORS.secondaryColor }}
                                                onClick={goToPayment}
                                            >
                                                Comprar
                                            </CButton>
                                        </div>

                                    </>
                                )

                            }
                        </div>
                    </CRow>
                </CCardBody>
            </CCard >
        </CContainer >
    )
}

export default WarnBuyMainProduct
